import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-igh-icon--checked-false';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    className={className}
    data-test={TEST_ID}
  >
    <path
      stroke='#9B9C99'
      d='M2 12C2 6.478 6.478 2 12 2s10 4.478 10 10-4.478 10-10 10S2 17.522 2 12z'
    />
  </svg>
);

const SvgCheckedFalse = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgCheckedFalse.displayName = 'CheckedFalse';
export default SvgCheckedFalse;
