import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--checkmark';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 16 16'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#fff'
      fillRule='evenodd'
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M13.829 4.173a.592.592 0 010 .833l-7.445 7.488a.584.584 0 01-.828 0L2.172 9.09a.592.592 0 010-.833c.228-.23.599-.23.828 0l2.97 2.988L13 4.173c.229-.23.6-.23.829 0z'
      clipRule='evenodd'
    />
  </svg>
);

const SvgCheckmark = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgCheckmark.displayName = 'Checkmark';
export default SvgCheckmark;
