import * as React from 'react';
import { useTheme } from '@emotion/react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import type { SelectChangeEvent } from '@mui/material';
import FormHelperText, { FormHelperTextProps } from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

import Typography from '../Typography';
import { DropdownOption } from '../../types';
import { rgba } from '../../utils/helpers';

export interface DropdownProps {
  label: string;
  options: DropdownOption[];
  selectProps?: SelectProps<string>;
  error?: string;
  value?: string;
  width?: string;
  onChange?: (event: SelectChangeEvent<string>) => void;
  hidden?: boolean;
  disabled?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  label,
  options,
  selectProps,
  error,
  value,
  width,
  onChange,
  hidden,
  disabled = false
}) => {
  const theme = useTheme();
  const errorColor = theme.iuiPalette.semantic.red100;
  const getStandardOrErrorColor = (standardColor: string): string =>
    error ? errorColor : standardColor;

  return (
    <FormControl
      variant='standard'
      sx={{
        width: width ?? '100%',
        minWidth: 100,
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
          borderBottom: `2px solid ${getStandardOrErrorColor(theme.iuiPalette.primaryAlt[200])}`
        },
        display: hidden ? 'none' : 'block',
        opacity: disabled ? '0.3' : 1,
        '& .MuiInputLabel-root': {
          top: '-2px'
        },
        '& .MuiInput-root.MuiInput-root.MuiInput-root': {
          marginTop: '15px'
        }
      }}
      data-test='iui-component-dropdown'
    >
      <InputLabel id={label} sx={{ padding: '0 20px 0 0' }}>
        <Typography
          variant='meta-l-caps-alt'
          color={getStandardOrErrorColor(theme.iuiPalette.grays.grays[600])}
          as='span'
        >
          {label}
        </Typography>
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        disabled={disabled}
        {...selectProps}
        label={label}
        labelId={label}
        sx={{
          width: '100%',
          '&:before': {
            borderBottom: `2px solid ${getStandardOrErrorColor(theme.iuiPalette.grays.grays[300])}`
          },
          '&.Mui-disabled:before': {
            borderBottomStyle: 'solid'
          },
          '& .MuiSvgIcon-root': {
            color: theme.iuiPalette.grays.grays[600],
            display: 'block'
          },
          '& .MuiSelect-select': {
            padding: '4px 20px 4px 0'
          },
          '& .muiSelect-select p': {
            fontSize: '1rem'
          },
          '& .MuiSelect-select:focus': {
            backgroundColor: theme.iuiPalette.grays.white ?? 'white'
          },
          '&:after': {
            borderBottom: `2px solid ${getStandardOrErrorColor(theme.iuiPalette.primaryAlt[200])}`
          }
        }}
        MenuProps={{
          PaperProps: {
            style: {
              marginTop: -30,
              borderRadius: 8,
              boxShadow: `0 2px 7px 0 ${rgba(theme.shadowColor, 0.15)}`
            },
            sx: {
              '& .MuiMenuItem-root:hover': {
                backgroundColor: theme.dropdown.hover
              },
              '& .MuiMenuItem-root.Mui-selected': {
                backgroundColor: theme.dropdown.selected
              },
              '& .MuiMenuItem-root.Mui-selected:hover': {
                backgroundColor: theme.dropdown.selected
              },
              '& .MuiButtonBase-root.MuiMenuItem-root': {
                minHeight: 56
              }
            }
          }
        }}
      >
        {options.map(({ label: optionLabel, value: optionValue }) => (
          <MenuItem key={optionValue} value={optionValue} disableRipple>
            <Typography
              variant='body-m-alt'
              color={theme.iuiPalette.grays.black}
              overflow='hidden'
              textOverflow='ellipsis'
              whiteSpace='nowrap'
            >
              {optionLabel}
            </Typography>
          </MenuItem>
        ))}
      </Select>
      {error && (
        <FormHelperText error>
          <Typography variant='meta-s-alt' color={errorColor} margin='8px 0 0'>
            {error}
          </Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Dropdown;
