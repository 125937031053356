import * as React from 'react';
import styled from '@emotion/styled';
import { EmotionStyledProps } from '../../types';
import { Box, SectionTitle } from '../../components';
import defaultBreakpoints from '../../themes/breakpoints';
import { MediaQuery } from '../../mixins/mediaQuery';

const Wrapper = styled(Box)`
  width: 100%;
  padding: 40px 0 24px;
  ${(props: EmotionStyledProps) =>
    MediaQuery(props.theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    padding: 40px 0 32px;
  }
  ${(props: EmotionStyledProps) =>
    MediaQuery(props.theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    padding: 72px 0;
  }

  h3:after {
    margin: 20px auto 40px auto;
    ${(props: EmotionStyledProps) =>
      MediaQuery(props.theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
      margin: 24px auto 72px auto;
    }
  }
`;

const FlexWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  margin-top: 40px;
  ${(props: EmotionStyledProps) =>
    MediaQuery(props.theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    margin-top: 72px;
    flex-direction: row;
  }
  & > * {
    flex: 1 1 0px;
  }
`;

interface TitleWithFlexChildrenProps {
  title: string;
  children: React.ReactNode;
}

const TitleWithFlexChildren: React.FC<TitleWithFlexChildrenProps> = ({ title, children }) => (
  <Wrapper data-test='iui-module-title-with-flex-children'>
    <SectionTitle level='3' variant='tertiary'>
      {title}
    </SectionTitle>
    <FlexWrapper>{children}</FlexWrapper>
  </Wrapper>
);

export default TitleWithFlexChildren;
