import styled, { StyledComponent } from '@emotion/styled';
import React from 'react';

const styledLogo = (
  svg: React.FC
): StyledComponent<{
  height?: string;
  width?: string;
  top?: string;
}> => styled(svg)<{ height?: string; width?: string; top?: string }>`
  ${({ height }) => (height ? `height: ${height};` : '')}
  ${({ width }) => (width ? `width: ${width};` : 'width: auto;')}
  position: relative;
  ${({ top }) => (top ? `top: ${top};` : '')}
`;

export default styledLogo;
