import * as React from 'react';
import { useTheme } from '@emotion/react';

import { GraphicProps } from '../../../types';
import GraphicsWrapper from '../GraphicsWrapper';
import FastaffGraphic from './Fastaff';
import IngenovisGraphic from './Ingenovis';
import TrustaffGraphic from './Trustaff';

const Graphic: React.FC<GraphicProps> = (props) => {
  const theme = useTheme();
  if (theme.name === 'fastaff') {
    return <FastaffGraphic {...props} />;
  }

  if (!theme) {
    return <IngenovisGraphic {...props} />;
  }

  if (theme.name === 'trustaff') {
    return <TrustaffGraphic {...props} />;
  }

  return <IngenovisGraphic {...props} />;
};

const CompleteSkillsListGraphic: React.FC<GraphicProps> = (props) => {
  const { wrapperStyle } = props;
  if (wrapperStyle) {
    return (
      <GraphicsWrapper type={wrapperStyle}>
        <Graphic {...props} />
      </GraphicsWrapper>
    );
  }
  return <Graphic {...props} />;
};

CompleteSkillsListGraphic.displayName = 'CompleteSkillsList';

export default CompleteSkillsListGraphic;
