import { css, SerializedStyles } from '@emotion/react';

import { TypographyToCssProps } from './typography.types';
import { MediaQuery } from '../../mixins/mediaQuery';
import { EmotionStyledProps } from '../../types';
import defaultBreakpoints from '../../themes/breakpoints';

interface TypographyToCssPropsCustom extends TypographyToCssProps, EmotionStyledProps {}

export const typographyToCss = ({
  fontSize,
  lineHeight,
  mobileFontSize,
  mobileLineHeight,
  fontStyle,
  fontFamily,
  fontWeight,
  textUnderline,
  uppercase,
  textTransform,
  theme,
  margin,
  padding,
  overflow,
  color,
  cursor,
  whiteSpace,
  wordWrap,
  letterSpacing,
  textOverflow,
  display,
  textAlign,
  onClick,
  width,
  customLineHeight,
  flex,
  wordBreak,
  hoverColor,
  minWidth,
  customFontWeight,
  activeColor,
  opacity,
  zIndex,
  maxWidth
}: TypographyToCssPropsCustom): SerializedStyles => css`
  font-family: ${fontFamily};
  font-weight: ${customFontWeight || fontWeight};
  font-size: ${mobileFontSize || fontSize}rem;
  line-height: ${customLineHeight || mobileLineHeight || lineHeight}rem;
  font-style: ${fontStyle};
  ${uppercase ? 'text-transform: uppercase;' : ''}
  ${textUnderline ? 'text-decoration: underline;' : 'text-decoration: none;'}
    ${MediaQuery(theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    font-size: ${fontSize}rem;
    line-height: ${customLineHeight || lineHeight}rem;
  }
  ${margin ? `margin: ${margin};` : 'margin: 0;'}
  ${padding ? `padding: ${padding};` : ''}
  ${overflow ? `overflow: ${overflow};` : ''}
  ${color ? `color: ${color};` : ''}
  ${cursor ? `cursor: ${cursor};` : ''}
  ${whiteSpace ? `white-space: ${whiteSpace};` : ''}
  ${wordWrap ? `word-wrap: ${wordWrap};` : ''}
  ${letterSpacing ? `letter-spacing: ${letterSpacing};` : ''}
  ${textOverflow ? `text-overflow: ${textOverflow};` : ''}
  ${display ? `display: ${display};` : ''}
  ${textAlign ? `text-align: ${textAlign};` : ''}
  ${width ? `width: ${width};` : ''}
  ${flex ? `flex: ${flex};` : ''}
  ${wordBreak ? `word-break: ${wordBreak};` : ''}
  ${minWidth ? `min-width: ${minWidth};` : ''}
  ${textTransform ? `text-transform: ${textTransform};` : ''}
  ${opacity ? `opacity: ${opacity};` : ''}
  ${zIndex ? `z-index: ${zIndex};` : ''}
  ${maxWidth ? `max-width: ${maxWidth};` : ''}



  &:hover {
    ${onClick ? 'cursor: pointer;' : ''}
    ${hoverColor ? `color: ${hoverColor};` : ''}
  }

  &:active {
    ${activeColor ? `color: ${activeColor};` : ''}
  }
`;
