import React, { useContext } from 'react';
import { useTheme } from '@emotion/react';

import styled from '@emotion/styled';
import { ISubnavContext, SubnavContext } from './subnavBarProvider';
import Typography from '../Typography';

export const StyledLink = styled.a`
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  padding: 0.875rem 1rem 0.5rem;
  cursor: pointer;
  color: ${({ theme }) => theme.navbar.link.color};
  border-bottom: 2px solid transparent;

  span {
    color: ${({ theme }) => theme.iuiPalette.primary[200]};
    font-weight: 700;
  }

  &.is-active {
    border-color: ${({ theme }) => theme.iuiPalette.brand[3]};
    span {
      color: var(--color-white);
    }
  }
`;

export interface subnavLink {
  label: string;
  scrollToId?: string;
  isMobile?: boolean;
  onClick?: () => void;
  isActive?: boolean;
}

const SubnavLink: React.FC<subnavLink> = ({
  label,
  scrollToId,
  isMobile = false,
  onClick,
  isActive
}) => {
  const { subNavbar } = useTheme();
  const { activeSubnavLinkId, setActiveSubnavLinkId } = useContext<ISubnavContext>(SubnavContext);

  const handleClick = () => {
    if (scrollToId) {
      setActiveSubnavLinkId(scrollToId);
      const ele = document.getElementById(scrollToId);
      if (ele !== null) {
        const extra = isMobile ? 240 : 100; // not sure where extra 240 is coming from for mobile, need to see over time
        const top = ele.offsetTop - extra - subNavbar.height; // may need to also account for navigation
        window.scrollTo({
          top,
          behavior: 'smooth'
        });
      }
    }
    if (onClick) {
      onClick();
    }
  };

  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  // do not remove data-testid - this unless required :: it is used here https://github.com/ingenovishealth/nurse-portal-broken-link-finder
  return (
    <StyledLink
      id={label}
      onClick={handleClick}
      onKeyPress={handleKeypress}
      tabIndex={0}
      className={isActive || activeSubnavLinkId === scrollToId ? 'is-active' : ''}
      data-testid='subnav-link'
    >
      <Typography variant='h6-alt' as='span'>
        {label}
      </Typography>
    </StyledLink>
  );
};

export default SubnavLink;
