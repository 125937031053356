import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--trustaff-quote';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 99 80'
    className={className}
    data-test={TEST_ID}
  >
    <g clipPath='url(#clip0_3656_478)'>
      <path
        fill='#FFB05A'
        d='M0 80V53.235a70.862 70.862 0 015.294-27.647A84.032 84.032 0 0123.236 0L38.53 12.059a71.954 71.954 0 00-11.177 13.823 38.99 38.99 0 00-5 13.236h18.908V80H0zm57.353 0V53.235a70.862 70.862 0 015.294-27.647A84.034 84.034 0 0180.588 0l15.295 12.059a71.954 71.954 0 00-11.177 13.823 38.992 38.992 0 00-5 13.236h18.908V80h-41.26z'
      />
    </g>
    <defs>
      <clipPath id='clip0_3656_478'>
        <path fill='#fff' d='M0 0h98.529v80H0z' />
      </clipPath>
    </defs>
  </svg>
);

const SvgTrustaffQuote = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgTrustaffQuote.displayName = 'TrustaffQuote';
export default SvgTrustaffQuote;
