import { Breakpoints } from '../types';

const defaultBreakpoints: Breakpoints = {
  small: 0,
  medium: 768,
  large: 1024,
  xLarge: 1280,
  xxLarge: 1440
};

export default defaultBreakpoints;
