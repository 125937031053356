import * as React from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import Typography from '../Typography';
import Box from '../Box';
import getTypographyCss from '../../mixins/getTypographyCss';
import { BaseTheme } from '../../types';

interface TextAreaProps {
  label: string;
  disabled?: boolean;
  placeholder: string;
  charLimit: number;
  /* Parent component controls state */
  value?: string;
  description?: string;
  onChange: (val: string) => void;
  id?: string; // if we have more than one text area in a page
}

const StyledLabel = styled.label<{ disabled?: boolean }>`
  display: inline-block;
  ${({ disabled }) => (disabled ? 'opacity: 0.3;' : '')}
  ${({ theme }) => getTypographyCss({ variant: 'meta-l-caps-alt', theme })}
  margin-bottom: 10px;
  color: ${({ theme }) => theme?.iuiPalette?.grays?.grays[600] ?? '#707070'};
`;
const StyledTextArea = styled.textarea`
  width: 100%;
  resize: none;
  ${({ disabled }) => (disabled ? 'opacity: 0.3;' : '')}
  ${({ theme }) => getTypographyCss({ variant: 'body-m', theme })}
  background-color: ${({ theme }) => theme?.iuiPalette?.grays?.grays[100] ?? 'GhostWhite'};
  margin-bottom: 8px;
  border: none;
  border-radius: 4px;
  padding: 1rem;
  min-height: 104px;

  &::placeholder {
    color: ${({ theme }) => theme?.iuiPalette?.grays?.grays[600] ?? 'gray'};
  }
`;

const StyledSpan = styled.span<{ isInvalid: boolean; theme?: BaseTheme }>`
  ${({ isInvalid, theme }) =>
    isInvalid && `color: ${theme?.iuiPalette?.semantic?.red100 ?? 'red'};`}
`;

const TextArea: React.FC<TextAreaProps> = ({
  label,
  id = 'textarea',
  placeholder,
  value,
  onChange,
  charLimit,
  description,
  disabled = false
}) => {
  const theme = useTheme();
  const handleTextAreaOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    /* Enables growing and shrinking of the text area container */
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;

    onChange(e.target.value);
  };
  return (
    <Box width='100%' data-test='iui-component-text-area'>
      <StyledLabel htmlFor={id} disabled={disabled}>
        {label}
      </StyledLabel>
      <Typography
        margin='0 0 6px'
        variant='body-s'
        color={theme.iuiPalette.grays.grays[600]}
        opacity={disabled ? 0.5 : 1}
      >
        {description}
      </Typography>
      <StyledTextArea
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={handleTextAreaOnChange}
        disabled={disabled}
      />
      <Typography
        textAlign='right'
        variant='body-m'
        color={theme.iuiPalette.grays.grays[600]}
        opacity={disabled ? 0.5 : 1}
      >
        <StyledSpan isInvalid={(value?.length ?? 0) > charLimit}>{value?.length ?? 0}</StyledSpan>/
        {charLimit}
      </Typography>
    </Box>
  );
};

TextArea.defaultProps = {
  value: undefined,
  disabled: false,
  id: 'textarea'
};

export default TextArea;
