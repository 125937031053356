import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ButtonProps } from '.';
import { ButtonVariants, ButtonColorways } from './button.types';
import { EmotionStyledProps, BaseTheme } from '../../types';
import defaultBreakpoints from '../../themes/breakpoints';

import shadow from '../../mixins/shadow';
import { MediaQuery } from '../../mixins/mediaQuery';

import { TypographyProps } from '../Typography/typography.types';
import Typography from '../Typography';

interface StyledButtonProps extends ButtonProps {
  hasIcon: boolean;
  margin?: string;
  theme: BaseTheme;
}

interface StyledIconContainerProps {
  iconToRight?: boolean;
  isLoading?: boolean;
}

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledIconContainer = styled.div<StyledIconContainerProps>`
  display: inline-flex;
  align-items: center;
  padding-left: ${({ iconToRight }: StyledIconContainerProps) =>
    iconToRight !== true ? '0' : `.55rem`};
  padding-right: ${({ iconToRight }: StyledIconContainerProps) =>
    iconToRight === true ? '0' : `.55rem`};
`;

export const StyledLoadingContainer = styled.div`
  display: inline-block;
  margin: 0 auto;
  position: absolute;
  > div {
    position: absolute;
    left: -2rem;
    top: 0;
  }
`;

export const StyledTypography = styled(Typography)<{ isLoading: boolean | undefined }>`
  visibility: ${({ isLoading }) => (isLoading ? 'hidden' : 'visible')};
`;

const primary = ({ variant, isLoading, theme, withBorder }: StyledButtonProps) => {
  // note: primary is default, see index.tsx
  const isPrimary = variant === ButtonVariants.PRIMARY;

  if (isPrimary) {
    return css`
      padding: ${theme.buttons?.primary?.padding ?? theme.buttons?.padding ?? '8px 12px'};
      border-radius: ${theme.buttons?.primary?.radius ?? theme.buttons?.radius ?? '0'};
      font-size: ${theme?.buttons?.primary?.fontSize ?? '1rem'};
      line-height: ${theme?.buttons?.primary?.lineHeight ?? '1rem'};

      ${withBorder ? `padding: ${theme?.buttons?.primary?.withBorderPadding ?? '7px 12px'};` : ''}

      ${isLoading &&
      `
        cursor: not-allowed;
        padding-left: calc(40px + 2rem);
        &:focus {
          text-decoration: none;
          outline: none;
        }
      `};
    `;
  }
  return '';
};

const secondary = ({ variant, isLoading, theme, withBorder }: StyledButtonProps) => {
  const isSecondary = variant === ButtonVariants.SECONDARY;

  if (isSecondary) {
    return css`
      font-weight: ${theme.buttons?.secondary?.weight ?? theme.buttons?.weight ?? '700'};
      padding: ${theme.buttons?.secondary?.padding ?? theme.buttons?.padding ?? '12px 16px'};
      border-radius: ${theme.buttons?.secondary?.radius ?? theme.buttons?.radius ?? '0'};
      font-size: ${theme?.buttons?.secondary?.fontSize ?? '0.875rem'};
      line-height: ${theme?.buttons?.secondary?.lineHeight ?? '1rem'};

      ${withBorder ? `padding: ${theme?.buttons?.secondary?.withBorderPadding ?? '7px 12px'};` : ''}

      ${isLoading &&
      `
        cursor: not-allowed;
        padding-left: calc(20px + 2rem);
        &:focus {
          text-decoration: none;
          outline: none;
        }
      `}
    `;
  }
  return '';
};

const tertiary = ({ variant, theme, isLoading, hasIcon }: StyledButtonProps) => {
  const isTertiary = variant === ButtonVariants.TERTIARY;

  if (isTertiary) {
    return css`
      font-weight: ${hasIcon ? 400 : 700};

      padding: ${theme.buttons?.tertiary?.padding ?? theme.buttons?.padding ?? '4px 12px'};
      border-radius: ${theme.buttons?.tertiary?.radius ?? theme.buttons?.radius ?? '0'};
      font-size: ${theme?.buttons?.tertiary?.fontSize ?? '0.875rem'};
      line-height: ${theme?.buttons?.tertiary?.lineHeight ?? '1rem'};

      border: 1px solid ${theme.buttons?.colorways?.light?.static?.borderColor ?? '#DFE6F6'};

      box-shadow: none;
      ${theme.buttons?.tertiary?.radius ? `border-radius: ${theme.buttons?.tertiary?.radius};` : ''}

      &[disabled] {
        border: 1px solid ${theme.buttons?.colorways?.light?.disabled?.borderColor ?? '#dfe6f6'};

        > div {
          opacity: 0.5;
        }
      }

      ${isLoading &&
      `
        cursor: not-allowed;
        padding-left: calc(15px + 2rem);
        &:focus {
          text-decoration: none;
          outline: none;
        }
      `}
    `;
  }
  return '';
};

const badge = ({ theme, variant }: StyledButtonProps) => {
  if (variant === ButtonVariants.BADGE) {
    return css`
      cursor: pointer;
      font-size: ${theme.buttons?.badge?.fontSize};
      line-height: ${theme.buttons?.badge?.lineHeight};
      vertical-align: middle;
      background: transparent;
      padding: 10px 8px;
      border-radius: 8px;
      border: 1px solid ${theme.buttons?.badge?.default};
      box-shadow: none;
      span {
        color: ${theme.buttons?.badge?.default};
      }
      &:focus,
      &:hover,
      &:active,
      &:disabled {
        background: transparent;
      }
      &:hover {
        span {
          color: ${theme.buttons?.badge?.hover};
        }
        border: 1px solid ${theme.buttons?.badge?.hover};
      }
      &:active {
        span {
          color: ${theme.buttons?.badge?.active};
        }
        border: 1px solid ${theme.buttons?.badge?.active};
      }
      &:disabled {
        span {
          color: ${theme.buttons?.badge?.disabled};
        }
        border: 1px solid ${theme.buttons?.badge?.disabled};
        opacity: 0.3;
        cursor: not-allowed;
      }
    `;
  }
  return '';
};

const auth0 = ({ theme, variant }: StyledButtonProps) => {
  if (variant === ButtonVariants.FAUX_AUTH0) {
    return css`
      cursor: pointer;
      vertical-align: middle;
      background: #415b80;
      padding: 4px 16px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      max-width: none;
      transition: background-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
      min-height: 52px;
      box-shadow: none;
      span {
        color: white;
        font-size: ${theme.buttons?.['faux-auth0']?.fontSize};
        line-height: ${theme.buttons?.['faux-auth0']?.lineHeight};
      }
      &:focus,
      &:active {
        background: #475b93;
      }
      &:hover {
        background: #3a5172;
      }
      &:focus {
        box-shadow: 0 0 0 4px rgba(99, 93, 255, 0.15);
      }
      &:active&:not([disabled]) {
        transform: none;
      }
    `;
  }
  return '';
};

const darkColorway = ({ colorway, theme, isLoading }: StyledButtonProps) => {
  // note: darkcolorway is default default, see index.tsx
  const isDark = colorway === ButtonColorways.DARK;

  if (isDark) {
    // attempt to pull bg from 'static.bg' value, else attempt to grab from colorway level else fallback to black
    const bg =
      theme.buttons?.colorways?.dark?.static?.bg ?? theme.buttons?.colorways?.dark?.bg ?? 'black';
    const bgH = theme.buttons?.colorways?.dark?.hover?.bg ?? bg;
    const bgA = theme.buttons?.colorways?.dark?.active?.bg ?? bg;
    const bgD = theme.buttons?.colorways?.dark?.disabled?.bg ?? '#bcc2cb';

    // attempt to pull color from 'static.color' value, else attempt to grab from colorway level else fallback to black
    const color =
      theme.buttons?.colorways?.dark?.static?.color ??
      theme.buttons?.colorways?.dark?.color ??
      'black';
    const colorH = theme.buttons?.colorways?.dark?.hover?.color ?? color;
    const colorA = theme.buttons?.colorways?.dark?.active?.color ?? color;

    return css`
      background: ${bg};
      color: ${color};

      ${!isLoading &&
      `
      &:hover,
      &:focus {
        background: ${bgH};
        color: ${colorH};
      }
      &:active {
        background: ${bgA};
        color: ${colorA};
      }
      
      `}
      &[disabled] {
        background: ${bgD};
        opacity: 0.3;
      }
    `;
  }
  return '';
};

const lightColorway = ({ colorway, theme, isLoading }: StyledButtonProps) => {
  const isLight = colorway === ButtonColorways.LIGHT;
  if (isLight) {
    // attempt to pull bg from 'static.bg' value, else attempt to grab from colorway level else fallback to black
    const bg =
      theme.buttons?.colorways?.light?.static?.bg ?? theme.buttons?.colorways?.light?.bg ?? 'black';
    const bgH = theme.buttons?.colorways?.light?.hover?.bg ?? bg;
    const bgA = theme.buttons?.colorways?.light?.active?.bg ?? bg;
    const bgD = theme.buttons?.colorways?.light?.disabled?.bg ?? '#bcc2cb';

    // attempt to pull color from 'static.color' value, else attempt to grab from colorway level else fallback to black
    const color =
      theme.buttons?.colorways?.light?.static?.color ??
      theme.buttons?.colorways?.light?.color ??
      'black';
    const colorH = theme.buttons?.colorways?.light?.hover?.color ?? color;
    const colorA = theme.buttons?.colorways?.light?.active?.color ?? color;

    return css`
      background: ${bg};
      color: ${color};

      ${!isLoading &&
      `
      &:hover,
      &:focus {
        background: ${bgH};
        color: ${colorH};
      }
      &:active {
        background: ${bgA};
        color: ${colorA};
      }
      `}
      &[disabled] {
        background: ${bgD};
        opacity: 0.4;
        > div {
          opacity: 0.5;
        }
      }
    `;
  }
  return '';
};

export const StyledButton = styled.button<StyledButtonProps>`
  ${({ theme, variant }) =>
    variant !== ButtonVariants.TERTIARY && shadow({ theme, level: 'medium' })}
  border: 0;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
  transition: transform 85ms ease-out;
  tab-index: 0;

  &:focus {
    outline: none;
  }
  ${({ fullWidthMobile, theme }) =>
    fullWidthMobile
      ? `
  width: 100%;
  max-width: 100%;
  ${MediaQuery(theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    width: auto;
    max-width: max-content;
  }
  `
      : 'max-width: max-content;'}
  ${({ fullWidth }) =>
    fullWidth &&
    `
    width: 100%;
    max-width: 100%;
    `}
  ${(props: EmotionStyledProps) =>
    MediaQuery(props.theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    grid-template-columns: repeat(4, 1fr);
  }

  &[disabled],
  &:active {
    box-shadow: none;
  }
  &[disabled] {
    cursor: not-allowed;
  }
  &:active&:not([disabled]) {
    ${({ isLoading }) => (!isLoading ? `transform: scale(0.962029);` : '')}
  }

  ${darkColorway}
  background: ${({ theme }) =>
    theme.buttons?.colorways?.dark?.static?.bg ?? theme.buttons?.colorways?.dark?.bg ?? 'yellow'};

  ${lightColorway}

  ${primary}
  ${secondary}
  ${tertiary}
  ${badge}
  ${auth0}
`;
