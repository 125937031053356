import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--document';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#616575'
      fillRule='evenodd'
      d='M4.379 1.879A3 3 0 016.5 1h7a1 1 0 01.707.293l7 7A1 1 0 0121.5 9v11a3 3 0 01-3 3h-12a3 3 0 01-3-3V4a3 3 0 01.879-2.121zM6.5 3a1 1 0 00-1 1v16a1 1 0 001 1h12a1 1 0 001-1V9.414L13.086 3H6.5z'
      clipRule='evenodd'
    />
    <path
      fill='#616575'
      fillRule='evenodd'
      d='M13.5 1a1 1 0 011 1v6h6a1 1 0 110 2h-7a1 1 0 01-1-1V2a1 1 0 011-1z'
      clipRule='evenodd'
    />
  </svg>
);

const SvgDocument = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgDocument.displayName = 'Document';
export default SvgDocument;
