/* eslint object-shorthand : 0 */
import * as React from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { SelectChangeEvent } from '@mui/material';

import ReactMarkdown from 'react-markdown';
import { rgba } from '../../utils/helpers';
import { Typography, Box, Dropdown, TextInput, Button, TextArea } from '../../components';
import defaultBreakpoints from '../../themes/breakpoints';
import { MediaQuery } from '../../mixins/mediaQuery';
import { useIsBreakpoint } from '../../hooks';
import { GeneralFormProps, FormSectionProps, FormInputProps } from './generalForm.types';

const Wrapper = styled.form`
  width: 100%;
  background-color: ${({ theme }) => theme?.iuiPalette?.grays.white ?? 'white'};
  border-radius: 8px;
  padding: 0;
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    padding: 40px 48px;
  }
`;

const FormSectionWrapper = styled.div`
  width: 100%;
  padding-bottom: 0;
`;

const StyledDropdownWrapper = styled(Box)`
  & > div {
    width: 100%;
  }
`;

export const FormInput: React.FC<FormInputProps> = ({
  type,
  label,
  dropdownOptions,
  placeholder = 'Enter your input here',
  charLimit = 200,
  value,
  setValue,
  error,
  errorMessage,
  onBlur,
  isPhoneInput
}) => {
  if (type === 'dropdown' && dropdownOptions && dropdownOptions.length) {
    const handleChange = (event: SelectChangeEvent<unknown>) => {
      setValue(event.target.value as string);
    };
    return (
      <StyledDropdownWrapper margin='0 0 1.5rem 0' width='100%'>
        <Dropdown
          label={label}
          selectProps={{ value: value, onChange: handleChange }}
          options={dropdownOptions}
        />
      </StyledDropdownWrapper>
    );
  }

  if (type === 'textArea') {
    return (
      <Box margin='0 0 1.5rem 0' width='100%'>
        <TextArea
          label={label}
          placeholder={placeholder}
          charLimit={charLimit}
          value={value}
          onChange={setValue}
        />
      </Box>
    );
  }

  return (
    <Box margin='0 0 1.5rem 0' width='100%'>
      <TextInput
        label={label}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
        error={error}
        errorMessage={errorMessage}
        onBlur={onBlur}
        isPhoneInput={isPhoneInput}
      />
    </Box>
  );
};

const FormSection: React.FC<FormSectionProps> = ({ title, inputs }) => {
  const { isDesktop } = useIsBreakpoint();
  return (
    <FormSectionWrapper>
      {!!title && (
        <Typography variant='h3' margin={isDesktop ? '0 0 24px 0' : '0 0 16px 0'}>
          {title}
        </Typography>
      )}
      {inputs.map((input) => (
        <FormInput {...input} key={input.label} />
      ))}
    </FormSectionWrapper>
  );
};

const FormSuccessMessage = ({
  title,
  text,
  formTitle
}: {
  title: string;
  text: string;
  formTitle: string;
}) => {
  const theme = useTheme();
  const { isDesktop } = useIsBreakpoint();
  return (
    <Box width='100%'>
      {!!formTitle && (
        <Typography variant='h3' margin={isDesktop ? '0 0 24px 0' : '0 0 32px 0'}>
          {formTitle}
        </Typography>
      )}
      <Box
        width='100%'
        background={rgba(theme?.iuiPalette?.semantic.green100, 0.1)}
        padding='16px 24px'
        borderRadius='4px'
      >
        <Typography textAlign='center' color={theme?.iuiPalette?.semantic.green100} variant='h5'>
          {title}
        </Typography>
        <Typography
          textAlign='center'
          color={theme?.iuiPalette?.semantic.green100}
          variant='body-m'
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

const GeneralForm: React.FC<GeneralFormProps> = ({
  title,
  description,
  formSections,
  submitButtonLabel,
  onSubmit,
  isSubmitButtonDisabled,
  haveSuccessfulySubmitted,
  successfulSubmitText,
  successfulSubmitTitle
}) => (
  <Wrapper
    autoComplete='on'
    onSubmit={(e) => {
      e.preventDefault();
      onSubmit();
    }}
    data-test='iui-module-general-form'
  >
    {haveSuccessfulySubmitted ? (
      <FormSuccessMessage
        text={successfulSubmitText}
        title={successfulSubmitTitle}
        formTitle={formSections[0].title ?? ''}
      />
    ) : (
      <>
        {title && (
          <Typography variant='h4' margin={description ? '0 0 8px 0' : '0 0 40px 0'}>
            {title}
          </Typography>
        )}
        {description && (
          <Typography as='div' variant='body-l' margin='0 0 40px 0'>
            <ReactMarkdown>{description}</ReactMarkdown>
          </Typography>
        )}

        {formSections.map((formSection, index) => (
          <FormSection
            {...formSection}
            key={formSection.title || (formSection.inputs[0]?.label ?? `form-section-${index}`)}
          />
        ))}
        <Button
          label={submitButtonLabel}
          disabled={isSubmitButtonDisabled}
          fullWidthMobile
          type='submit'
        />
      </>
    )}
  </Wrapper>
);

export default GeneralForm;
