import * as React from 'react';
import styled from '@emotion/styled';
import { GraphicProps } from '../../../types';
import { GetGraphicDimensions } from '../../../mixins/icons';

const TEST_ID = 'iui-component-graphic--fastaff';

const SVG: React.FC<GraphicProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 338 338'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#333F67'
      d='M230.157 150.623v52.885c0 10.086-27.163 21.826-60.623 21.826-33.46 0-60.623-11.74-60.623-21.826v-52.885h121.246z'
    />
    <path fill='#2A3453' d='M169 171.916l111-32.66v-3.309l-111 32.66-111-32.66v3.309l111 32.66z' />
    <path fill='#495683' d='M280 135.947l-111 32.66-111-32.66L169 113l111 22.947z' />
    <path fill='#B2C2E8' d='M222.259 153.558l-47.122-15.69.374-1.067 47.121 15.689-.373 1.068z' />
    <path
      fill='#B2C2E8'
      d='M221.458 173.677l-.053-21.133h1.174v21.133h-1.121zM224.713 179.12h-5.016l-3.202 11.953h11.42l-3.202-11.953z'
    />
    <path fill='#B2C2E8' d='M221.992 181.574a4.482 4.482 0 100-8.965 4.482 4.482 0 000 8.965z' />
    <path
      fill='#2A3453'
      d='M178.286 136.481c0 1.067-4.11 1.974-9.126 1.974-5.016 0-9.125-.853-9.125-1.974v-.32h18.251v.32z'
    />
    <path
      fill='#B2C2E8'
      d='M178.286 136.054c0 .8-4.11 1.708-9.126 1.708-5.016 0-9.125-.854-9.125-1.708s4.109-3.575 9.125-3.575c5.016.053 9.126 2.775 9.126 3.575z'
    />
  </svg>
);

const SvgFastaff = styled(SVG)`
  ${(props: GraphicProps) => GetGraphicDimensions(props)}
`;
SvgFastaff.displayName = 'Fastaff';
export default SvgFastaff;
