import styled from '@emotion/styled';
import React, { createContext } from 'react';

export const A = styled.a``;

export const LinkFallback: React.FC<{ children: React.ReactChildren; props: unknown }> = ({
  children,
  ...props
}) => <A {...props}>{children}</A>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LinkContext = createContext<React.ReactNode | any>(LinkFallback);
interface ILinker {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  CustomLink: React.ReactNode | any;
  children: React.ReactNode;
}
export const Linker: React.FC<ILinker> = ({ CustomLink, children }: ILinker) => (
  <LinkContext.Provider value={CustomLink}>{children}</LinkContext.Provider>
);
