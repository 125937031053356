import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--notification-dot';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 16 17'
    className={className}
    data-test={TEST_ID}
  >
    <circle cx={8} cy={8.5} r={2.667} fill='#E03842' />
  </svg>
);

const SvgNotificationDot = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgNotificationDot.displayName = 'NotificationDot';
export default SvgNotificationDot;
