import * as React from 'react';
import styled from '@emotion/styled';
import { GraphicProps } from '../../../types';
import { GetGraphicDimensions } from '../../../mixins/icons';

const TEST_ID = 'iui-component-graphic--fastaff';

const SVG: React.FC<GraphicProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 338 338'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#333F67'
      d='M91.785 115.518H245.67c6.754 0 13.553 6.798 13.553 13.552l-1.369 100.34c0 6.754-5.474 12.184-12.184 12.184H90.328c-6.754 0-12.184-5.474-12.184-12.184V129.203c-.044-7.593 6.092-13.685 13.64-13.685z'
    />
    <path
      fill='#616D98'
      d='M94.787 119.535h154.195c6.489 0 11.786 5.297 11.786 11.787v95.439c0 6.489-5.297 11.787-11.786 11.787H94.787c-6.49 0-11.787-5.298-11.787-11.787v-95.439c0-6.49 5.297-11.787 11.787-11.787z'
    />
    <path
      fill='#DFE6F6'
      d='M95.493 121.742h152.341c5.871 0 10.639 4.768 10.639 10.639v104.445c0 5.871-4.768 10.639-10.639 10.639H95.493c-5.871 0-10.639-4.768-10.639-10.639V132.425c0-5.915 4.768-10.683 10.639-10.683z'
    />
    <path
      fill='#616D98'
      d='M200.114 117.196h-5.209V99.891c0-3.443-2.648-6.268-5.915-6.268h-39.774c-3.267 0-5.915 2.825-5.915 6.268v17.349h-5.209V99.891c0-6.313 4.988-11.433 11.124-11.433h39.774c6.136 0 11.124 5.12 11.124 11.433v17.305z'
    />
    <path
      fill='#2A3453'
      d='M129.219 189.062c-.662 0-1.325-.044-1.987-.088-19.467-1.413-26.133-4.944-27.148-5.562-9.094-4.635-10.948-14.7-11.169-16.201-2.119-7.107-3.178-15.848-3.178-26.045h3.929c0 9.844 1.015 18.276 3.046 25.03l.044.132v.177c0 .088 1.368 9.446 9.138 13.419l.22.133c.044.044 5.916 3.576 25.383 4.988 3.09.221 5.739-.132 8.034-1.059-.971-.265-1.942-.662-2.957-1.104-3.399-1.545-4.591-4.988-3.311-9.403-4.105-1.015-7.593-3.796-10.065-8.034-2.207-3.708-3.443-8.431-3.487-12.934-.044-.839.044-1.81.22-2.825 1.104-6.181 5.298-11.169 11.257-13.332 6.048-2.207 12.581-1.059 17.481 3.046 4.503 3.796 6.578 9.27 6.18 16.333-.353 6.887-.882 16.466-4.855 23.662 2.825-1.369 5.65-3.664 8.784-6.666 13.023-12.449 20.88-32.534 20.969-32.711l3.664 1.413c-.353.838-8.255 21.056-21.896 34.123-4.37 4.194-9.403 8.388-15.583 9.05l-.088.088c-3.399 2.913-7.593 4.37-12.625 4.37zm3.664-14.038c0 .045 0 .089-.044.133-.751 3.222.75 3.929 1.324 4.194 2.119.971 4.105 1.412 5.959 1.456 5.695-6.18 6.313-18.011 6.71-25.25.309-5.827-1.236-10.109-4.811-13.111-3.797-3.178-8.873-4.061-13.597-2.384-4.635 1.678-7.902 5.562-8.74 10.33a11.91 11.91 0 00-.177 2.075c.044 7.328 3.973 15.892 11.08 17.348l.177.045c2.339-4.238 6.401-8.476 9.712-7.991 1.28.177 3.399 1.148 3.399 5.386 0 3.752-1.148 6.313-3.444 7.593-2.295 1.368-5.253.927-7.548.176zm1.501-3.619c1.501.441 3.134.662 4.105.088 1.192-.706 1.457-2.649 1.457-4.194 0-.839-.088-1.28-.177-1.501-1.103.221-3.62 2.561-5.385 5.607z'
    />
    <path
      fill='#2A3453'
      d='M87.68 143.152c-7.417 0-14.392-2.869-19.6-8.122-5.254-5.209-8.123-12.184-8.123-19.6v-2.34h3.929v2.34c0 6.356 2.472 12.316 6.974 16.819 4.503 4.502 10.463 6.974 16.82 6.974 13.11 0 23.793-10.682 23.793-23.793v-2.34h3.929v2.34c.044 15.274-12.405 27.722-27.723 27.722z'
    />
    <path
      fill='#9BA3C0'
      d='M115.093 113.223l-3.046-.221 1.81-23.264c.839-11.036-5.827-21.233-16.29-24.81l.972-2.868c11.786 4.017 19.335 15.538 18.364 27.943l-1.81 23.22zM60.53 113.222l-1.942-23.263c-1.015-12.405 6.49-23.927 18.232-28.032l1.015 2.87c-10.462 3.663-17.128 13.905-16.2 24.897l1.942 23.264-3.046.264z'
    />
    <path
      fill='#F5995C'
      d='M94.61 65.856l3.796.309c.442.044.84-.31.883-.75l.265-3.223c.044-.442-.309-.839-.75-.883L95.007 61a2.448 2.448 0 00-2.604 2.207c-.089 1.369.883 2.516 2.207 2.649zM80.087 65.812l-3.797.309c-.441.044-.839-.31-.883-.75l-.265-3.223c-.044-.442.31-.839.75-.883L79.69 61c1.325-.088 2.517.883 2.605 2.207.088 1.324-.883 2.516-2.207 2.605z'
    />
    <path
      fill='#333F67'
      d='M253.926 232.191l24.721-66.525c.927-3.885-2.516-11.389-5.121-14.524-2.472-2.957-7.416-7.902-14.479-7.902H108.736a12.799 12.799 0 00-12.493 10.065l-15.185 78.091c-1.634 9.138 8.078 19.026 16.2 19.026l-1.059-8.475h145.367a12.698 12.698 0 0012.36-9.756z'
    />
    <path
      fill='#495683'
      d='M260.945 241.77l17.702-74.603c1.942-8.167-4.194-16.025-12.537-16.025H114.386c-6.091 0-11.3 4.282-12.581 10.286l-15.803 74.604c-1.722 8.078 4.414 15.715 12.58 15.715h149.826c5.96 0 11.169-4.106 12.537-9.977z'
    />
    <path
      fill='#B2C2E8'
      d='M245.715 153.261h-9.623l-22.16 96.014h9.623l22.16-96.014zM149.525 153.261h-9.623l-22.116 96.014h9.579l22.16-96.014z'
    />
    <path fill='#333F67' d='M179.852 141.916a8.608 8.608 0 100-17.216 8.608 8.608 0 000 17.216z' />
    <path fill='#DFE2EC' d='M179.852 140.724a7.416 7.416 0 100-14.832 7.416 7.416 0 100 14.832z' />
    <path fill='#F5995C' d='M179.852 137.105a3.797 3.797 0 100-7.594 3.797 3.797 0 000 7.594z' />
    <mask
      id='mask0_1769_14141'
      style={{
        maskType: 'alpha'
      }}
      width={10}
      height={10}
      x={175}
      y={128}
      maskUnits='userSpaceOnUse'
    >
      <path fill='url(#pattern0)' d='M175.063 128.268h9.712v9.712h-9.712z' />
    </mask>
    <g mask='url(#mask0_1769_14141)'>
      <path
        fill='#1D243D'
        d='M179.852 137.105a3.797 3.797 0 100-7.594 3.797 3.797 0 000 7.594z'
        opacity={0.1}
      />
    </g>
    <defs>
      <pattern id='pattern0' width={1} height={1} patternContentUnits='objectBoundingBox'>
        <use xlinkHref='#image0_1769_14141' />
      </pattern>
      <image
        id='image0_1769_14141'
        xlinkHref='data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEASABIAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA'
      />
    </defs>
  </svg>
);

const SvgFastaff = styled(SVG)`
  ${(props: GraphicProps) => GetGraphicDimensions(props)}
`;
SvgFastaff.displayName = 'Fastaff';
export default SvgFastaff;
