export enum SectionTitleVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SECONDARY_ALT = 'secondary-alt',
  TERTIARY = 'tertiary'
}

export enum SectionTitleFixedColor {
  DEFAULT = 'default',
  BRAND1 = 'brand1',
  BRAND2 = 'brand2',
  BRAND4 = 'brand4'
}

export interface SectionTitleThemeOverrides {
  stroke?: string;
  strokeAlt?: string;
}
