import styled from '@emotion/styled';

import Image from '../Image';
import { linkStyles } from '../Link/link.styles';

export const CardWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-top: 177.77%;
  background-color: ${({ theme }) => theme?.iuiPalette?.grays.grays[300] ?? '#D9D9D9'};
  &:hover {
    cursor: pointer;
  }
`;

export const StyledDesktopImageCover = styled.div`
  z-index: 1;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  > h3 {
    display: none;
  }
  background: transparent;

  -webkit-transition: background-color 0.2s ease-in-out;
  -moz-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme?.iuiPalette?.brand[1] ?? '#675DC6'};
    opacity: 0.8;
    > h3 {
      display: block;
    }
  }
`;

export const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`;

export const StyledLink = styled.p`
  ${({ theme }) => linkStyles({ theme, hasIcon: false })}
`;
