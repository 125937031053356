import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--social-twitter-small';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#0F0027'
      d='M20 7.539a6.533 6.533 0 01-1.885.517 3.288 3.288 0 001.443-1.816 6.572 6.572 0 01-2.084.797 3.282 3.282 0 00-5.593 2.993A9.32 9.32 0 015.113 6.6a3.28 3.28 0 001.016 4.382 3.274 3.274 0 01-1.487-.41v.041a3.285 3.285 0 002.633 3.218 3.3 3.3 0 01-1.483.056 3.286 3.286 0 003.067 2.28A6.586 6.586 0 014 17.524 9.29 9.29 0 009.032 19c6.038 0 9.34-5 9.34-9.338 0-.142-.003-.284-.01-.425A6.647 6.647 0 0020 7.54z'
    />
  </svg>
);

const SvgSocialTwitterSmall = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgSocialTwitterSmall.displayName = 'SocialTwitterSmall';
export default SvgSocialTwitterSmall;
