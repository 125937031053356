import styled from '@emotion/styled';
import { css, SerializedStyles, Theme } from '@emotion/react';

import {
  CarouselVariant,
  GetVariantSlideStylesProps,
  StyledBoxProps,
  GetSliderButtonHoverAndActiveStylesProps
} from './carousel.types';
import {
  DESKTOP_ROW_GRID_GAP_321,
  TABLET_AND_MOBILE_ROW_GRID_GAP_321,
  ROW_GRID_GAP_2WE
} from './carousel.data';

import {
  getDesktopFlexBasisValue321,
  getTabletFlexBasisValue321,
  getFlexBasisValue2WithEdge
} from './carousel.utils';

import Box from '../Box';
import { ShadowMixin, ExactMediaQuery, MediaQuery } from '../../mixins';
import DefaultBreakpoints from '../../themes/breakpoints';
import { EmotionStyledProps } from '../../types';

const get321SlideStyles = (props: GetVariantSlideStylesProps): SerializedStyles => {
  const { theme, itemCount } = props;
  return css`
    padding: 0;
    height: auto;
    flex: unset;
    ${MediaQuery(theme?.iuiBreakpoints?.medium ?? DefaultBreakpoints.medium)} {
      flex: 0 0 ${getTabletFlexBasisValue321(itemCount)};
    }
    ${MediaQuery(theme?.iuiBreakpoints?.large ?? DefaultBreakpoints.large)} {
      flex: 0 0 ${getDesktopFlexBasisValue321(itemCount)};
    }
  `;
};

const get2WithEdgeSlideStyles = (props: GetVariantSlideStylesProps): SerializedStyles => {
  const { itemCount } = props;
  return css`
    padding: 0;
    height: auto;
    flex: 0 0 ${getFlexBasisValue2WithEdge(itemCount)};
  `;
};

const get321SliderStyles = (props: EmotionStyledProps): SerializedStyles => {
  const { theme } = props;
  return css`
    gap: ${TABLET_AND_MOBILE_ROW_GRID_GAP_321}px;
    ${MediaQuery(theme?.iuiBreakpoints?.large ?? DefaultBreakpoints.large)} {
      gap: ${DESKTOP_ROW_GRID_GAP_321}px;
    }
  `;
};

const getDotsBottomPosition = (theme: Theme, variant: CarouselVariant): SerializedStyles => {
  if (variant === '321') {
    return css`
      bottom: 2rem;
      ${MediaQuery(theme?.iuiBreakpoints?.large ?? DefaultBreakpoints.large)} {
        bottom: 0.5rem;
      }
    `;
  }
  return css`
    bottom: 1.5rem;
  `;
};

const get2WithEdgeSliderStyles = (): SerializedStyles => css`
  gap: ${ROW_GRID_GAP_2WE}px;
`;

const getSliderButtonHoverAndActiveStyles = (
  props: GetSliderButtonHoverAndActiveStylesProps
): SerializedStyles => {
  const { theme, isLeft, variant } = props;

  if (variant === '321') {
    return css`
      &:hover {
        svg {
          > path {
            fill: ${theme?.iuiPalette?.primary[300] ?? '#616D98'};
          }
        }
      }

      &:active {
        svg {
          > path {
            fill: ${theme?.iuiPalette?.primary[600] ?? '#2A3453'};
          }
        }
      }
    `;
  }

  return css`
    &:hover {
      svg {
        height: 22px;
      }
      &:before {
        background: var(--color-grays-200);
        border-radius: 50%;
        width: 30px;
        height: 30px;
        content: '';
        top: 7px;
        ${isLeft && 'left: 10px;'}
        ${!isLeft && 'left: 7px;'}
    display: block;
        position: absolute;
        z-index: -1;
        ${ShadowMixin({ level: 'slight', theme })}
      }
      &:active:before {
        box-shadow: none;
      }
    }
  `;
};

// keep the before:after.bg animations where they are, moving under condition doesn't work
export const StyledBox: React.FC<StyledBoxProps> = styled(Box)<StyledBoxProps>`
  position: relative;
  overflow: hidden;

  ul {
    padding: 0;
    list-style: none;
    width: 100%;
    transition: transform ${({ transitionMS }) => transitionMS}ms ease-out;
  }
  ul.slider {
    touch-action: pan-x;
    display: flex;
    flex-direction: row;
    width: ${({ sliderWidth }) => sliderWidth};
    transform: ${({ adjustedSlideWidth }) => `translate3d(${adjustedSlideWidth},  0px, 0px)`};
    ${({ theme, variant }) => {
      switch (variant) {
        case '321':
          return get321SliderStyles({ theme });
        case '2withEdge':
          return get2WithEdgeSliderStyles();
        default:
          return '';
      }
    }}
  }
  .slide {
    width: 100%;
    height: 100%;
    padding: 30px 0;

    ${({ theme, variant, itemCount }) =>
      variant === '321' ? get321SlideStyles({ theme, itemCount }) : ''}
    ${({ theme, variant, itemCount }) => {
      switch (variant) {
        case '321':
          return get321SlideStyles({ theme, itemCount });
        case '2withEdge':
          return get2WithEdgeSlideStyles({ theme, itemCount });
        default:
          return '';
      }
    }}
  }

  ${({ theme }) =>
    ExactMediaQuery(
      theme.iuiBreakpoints?.small ?? DefaultBreakpoints.small,
      theme.iuiBreakpoints?.medium ?? DefaultBreakpoints.medium
    )} {
    &[class] {
      padding: 0;
    }
    .slide > div {
      ${({ variant }) =>
        variant === 'default'
          ? `
      height: 100%;
      padding-bottom: 30px;
      `
          : ''}
    }

    .slide > div > div {
      display: flex;
      justify-content: center;
    }
  }

  ${({ arrowVariant }) =>
    arrowVariant === 'alt'
      ? `
        svg path {
          fill: var(--color-brand-2)
        }
        `
      : ''}
`;

export const StyledListElement = styled.li<{ index: number }>`
  left: ${({ index }) => `${index * 100}%`};
`;

export const Dots = styled.ul<{ variant: CarouselVariant }>`
  position: absolute;
  ${({ variant, theme }) => getDotsBottomPosition(theme, variant)}
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  line-height: 0;
`;

export const Dot = styled.button<{ variant: CarouselVariant }>`
  margin: 0;
  border: none;
  padding: 4px;
  overflow: hidden;
  background: transparent;
  cursor: pointer;

  &:before {
    width: 9px;
    display: block;
    height: 9px;
    content: '';
    border-radius: 50%;
    background-color: ${({ variant }) =>
      variant === '2withEdge' ? 'var(--color-grays-500)' : 'var(--color-grays-300)'};
    opacity: 0.5;
    transition: opacity 0.25s ease-in-out;
  }

  &[aria-selected='true']:before {
    background-color: var(--color-brand-3);
    opacity: 1;
  }
`;

export const SlideButton = styled.button<{
  isLeft?: boolean;
  variant: CarouselVariant;
}>`
  height: 45px;
  -webkit-transition: opacity 0.5s ease-out;
  transition: opacity 0.5s ease-out;
  z-index: 1;
  cursor: pointer;
  display: grid;
  place-items: center;
  background: transparent;
  position: absolute;
  padding: 0;
  min-width: 3rem;
  border: 0;
  top: calc(50% - 23px);
  ${({ isLeft, variant }) => isLeft && `left: ${variant === '321' ? '0' : '10px'};`}
  ${({ isLeft, variant }) => !isLeft && `right: ${variant === '321' ? '0' : '10px'};`}

  svg {
    transition: all 0.05s ease-out;
    ${({ isLeft }) => !isLeft && 'transform: rotate(180deg)'}
  }

  ${({ theme, isLeft, variant }) => getSliderButtonHoverAndActiveStyles({ theme, isLeft, variant })}
`;

/**
 * Acts as the Container component but shows cards to the left and right when needed
 */
export const Variant321Container = styled.div`
  ${(props) => MediaQuery(props.theme?.iuiBreakpoints?.large ?? DefaultBreakpoints.large)} {
    padding: 0;
    margin: 0 32px;
  }
  ${(props) => MediaQuery(props.theme?.iuiBreakpoints?.xLarge ?? DefaultBreakpoints.xLarge)} {
    margin: 0 auto;
    max-width: 1472px;
    padding: 0 56px;
  }

  & > div {
    width: 100%;
    overflow: hidden;
    padding: 1rem 20px 4.75rem 20px;
    ${(props) => MediaQuery(props.theme?.iuiBreakpoints?.medium ?? DefaultBreakpoints.medium)} {
      padding: 1rem 78px 4.75rem 24px;
    }
    ${(props) => MediaQuery(props.theme?.iuiBreakpoints?.large ?? DefaultBreakpoints.large)} {
      padding: 1rem 16px 3rem 16px;
    }
  }
`;

// Does the job of our container component whith no padding
export const Variant2WithEdgeContainer = styled.div`
  margin: 0 auto;
  max-width: 1472px;

  & > div {
    width: 100%;
    overflow: hidden;
    padding: 0 20px 3.5rem 0;
  }
`;
