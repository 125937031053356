/* eslint-disable no-nested-ternary */

import * as React from 'react';
import styled from '@emotion/styled';
import { safeProps } from '../../utils/safeProps';
import { EmotionStyledProps } from '../../types';

// exported to use within storybook only
export enum IconLinkVariants {
  PRIMARY = 'primary',
  INVERTED = 'inverted',
  SECONDARY = 'secondary'
}

export interface IconLinkProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  href: string;
  target?: 'blank' | 'self';
  alt: string;
  variant?: `${IconLinkVariants}`;
  margin?: string;
}

interface StyledIconProps extends EmotionStyledProps {
  variant: IconLinkVariants;
}

const IconAnchor = styled.a<StyledIconProps>`
  border-radius: 100px;
  background: ${({ theme, variant }) =>
    variant === IconLinkVariants.PRIMARY
      ? theme.iuiPalette.primary[500]
      : variant === IconLinkVariants.INVERTED
      ? theme.iuiPalette.grays.white
      : theme.iuiPalette.secondary[100]};

  color: ${({ theme, variant }) =>
    variant === IconLinkVariants.PRIMARY
      ? theme.iuiPalette.grays.white
      : variant === IconLinkVariants.INVERTED
      ? theme.iuiPalette.primary[500]
      : theme.iuiPalette.brand[3]};

  align-items: center;
  display: inline-flex;
  height: 2rem;
  width: 2rem;

  &:focus {
    outline: none;
  }

  &:hover {
    background: ${({ theme, variant }) =>
      variant === IconLinkVariants.PRIMARY
        ? theme.iuiPalette.primary[600]
        : theme.iuiPalette.grays.grays[300]};
  }

  &:active {
    background: ${({ theme, variant }) =>
      variant === IconLinkVariants.PRIMARY
        ? theme.iuiPalette.primary[700]
        : theme.iuiPalette.grays.grays[400]};
  }

  > svg {
    display: block;
    margin: 0 auto;
    text-align: center;
  }
`;

export const IconLink: React.FC<IconLinkProps> = (props: IconLinkProps) => {
  const {
    icon = <></>,
    alt,
    href,
    target = 'blank',
    variant = IconLinkVariants.PRIMARY,
    ...rest
  } = props;
  const Icon = icon;

  return (
    <IconAnchor
      href={href}
      variant={variant}
      target={`_${target}`}
      {...safeProps(rest)}
      data-test='iui-component-icon-link'
    >
      <Icon />
      <span className='sr-only'>{alt}</span>
    </IconAnchor>
  );
};

export default IconLink;
