import React from 'react';

// eslint-disable-next-line react/require-default-props
const SVG: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    viewBox='0 0 144 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    data-test='iui-component-logo--fas'
  >
    <g clipPath='url(#clip0_365:3162)'>
      <path
        d='M41.9432 16.8438H31.2419V27.4896H26.1068V1.20312H45.1033V6.05208H31.2419V12.0312H41.9432V16.8438Z'
        fill='#333F67'
      />
      <path
        d='M62.4119 27.4894H57.7077V25.4842C56.379 27.1248 54.6194 27.9998 52.3571 27.9998C49.8793 27.9998 47.7965 27.0884 46.1446 25.2655C44.4927 23.4061 43.6668 21.1457 43.6668 18.4113C43.6668 15.6769 44.4568 13.4165 46.1446 11.5571C47.7965 9.69775 49.8793 8.82275 52.3571 8.82275C54.6194 8.82275 56.4149 9.62484 57.7077 11.3384V9.33317H62.4119V27.4894ZM53.183 13.38C51.7825 13.38 50.6693 13.854 49.8075 14.8019C48.9456 15.7863 48.5147 16.9894 48.5147 18.3748C48.5147 19.7603 48.9456 20.9998 49.8075 21.9478C50.6693 22.9321 51.7825 23.3696 53.183 23.3696C54.5835 23.3696 55.6608 22.8957 56.4868 21.9478C57.3486 21.0363 57.7795 19.8332 57.7795 18.3748C57.7795 16.9165 57.3486 15.7134 56.4868 14.8019C55.6608 13.8175 54.5476 13.38 53.183 13.38Z'
        fill='#333F67'
      />
      <path
        d='M78.6075 14.3644C76.5606 13.38 74.6933 12.9061 72.8618 12.9061C71.9641 12.9061 71.2459 13.0519 70.815 13.3436C70.3481 13.5988 70.1327 14.0363 70.1327 14.5467C70.1327 14.8019 70.1686 15.0207 70.3481 15.203C70.4918 15.3488 70.7072 15.5311 70.9945 15.6405C71.3177 15.7498 71.5691 15.8592 71.8923 15.8957C72.1436 15.9321 72.5746 16.005 73.0773 16.1144L74.6933 16.3332C78.7152 16.9894 80.7262 18.7759 80.7262 21.7655C80.7262 23.6613 79.9721 25.1196 78.5357 26.2863C77.0992 27.4165 75.0524 27.9634 72.5027 27.9634C69.199 27.9634 66.5416 27.2707 64.5307 25.8488L66.4698 22.1665C68.2294 23.3696 70.2763 23.953 72.5746 23.953C74.7651 23.953 75.8783 23.4061 75.8783 22.2759C75.8783 21.8384 75.6628 21.4738 75.196 21.255C74.7292 20.9998 73.9751 20.7811 72.8978 20.6717L71.4613 20.453C67.3317 19.8696 65.2489 18.0103 65.2489 14.9113C65.2489 13.0519 65.9312 11.5936 67.2958 10.4998C68.6244 9.479 70.4918 8.93213 72.8618 8.93213C75.591 8.93213 78.1047 9.51546 80.3671 10.6821L78.6075 14.3644Z'
        fill='#333F67'
      />
      <path
        d='M95.7726 13.6353H89.3087V20.2707C89.3087 21.3644 89.5601 22.13 90.0628 22.604C90.5297 23.078 91.1761 23.3696 92.002 23.3696C92.9716 23.3696 94.0848 23.005 95.3776 22.1665L97.1372 26.0311C95.3057 27.3436 93.3307 27.9269 91.1761 27.9269C88.9855 27.9269 87.2618 27.3436 86.1486 26.0675C85.0354 24.828 84.4249 23.0415 84.4249 20.6353V13.5988H80.9417V9.22379H84.4249V3.7915H89.2369V9.22379H95.7008V13.6353H95.7726Z'
        fill='#333F67'
      />
      <path
        d='M115.775 27.4894H111.07V25.4842C109.742 27.1248 107.982 27.9998 105.72 27.9998C103.242 27.9998 101.159 27.0884 99.5073 25.2655C97.8554 23.4061 97.0294 21.1457 97.0294 18.4113C97.0294 15.6769 97.8195 13.4165 99.5073 11.5571C101.159 9.69775 103.242 8.82275 105.72 8.82275C107.982 8.82275 109.778 9.62484 111.07 11.3384V9.33317H115.775V27.4894ZM106.582 13.38C105.181 13.38 104.068 13.854 103.206 14.8019C102.344 15.7863 101.913 16.9894 101.913 18.3748C101.913 19.7603 102.344 20.9998 103.206 21.9478C104.068 22.9321 105.181 23.3696 106.582 23.3696C107.982 23.3696 109.059 22.8957 109.885 21.9478C110.747 21.0363 111.178 19.8332 111.178 18.3748C111.178 16.9165 110.747 15.7134 109.885 14.8019C109.023 13.8175 107.91 13.38 106.582 13.38Z'
        fill='#333F67'
      />
      <path
        d='M128.846 13.6354H123.28V27.4896H118.468V13.6354V9.29688V7.07292C118.468 4.88542 119.006 3.17188 120.12 1.89583C121.233 0.65625 122.813 0 124.896 0C126.943 0 128.738 0.546875 130.426 1.64063L128.487 5.43229C127.481 4.84896 126.44 4.55729 125.434 4.55729C124.034 4.55729 123.352 5.43229 123.352 7.10938V9.29688H128.918V13.6354H128.846Z'
        fill='#333F67'
      />
      <path
        d='M142.456 13.6354H136.89V27.4896H132.078V13.6354H128.702V9.29688H132.078V7.07292C132.078 4.88542 132.616 3.17188 133.73 1.89583C134.843 0.65625 136.423 0 138.506 0C140.553 0 142.348 0.546875 144.036 1.64063L142.097 5.43229C141.091 4.84896 140.05 4.55729 139.044 4.55729C137.644 4.55729 136.962 5.43229 136.962 7.10938V9.29688H142.492V13.6354H142.456Z'
        fill='#333F67'
      />
      <path d='M20.7202 5.61475V27.5991H15.2977V5.61475H20.7202Z' fill='#E89254' />
      <path d='M11.2399 5.61475V27.5991H7.14614L7.11023 5.61475H11.2399Z' fill='#E89254' />
      <path d='M3.05237 5.61475V27.5991H0V5.61475H3.05237Z' fill='#E89254' />
    </g>
    <defs>
      <clipPath id='clip0_365:3162'>
        <rect width='144' height='28' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;
