import * as React from 'react';
import styled from '@emotion/styled';
import { GraphicProps } from '../../../types';
import { GetGraphicDimensions } from '../../../mixins/icons';

const TEST_ID = 'iui-component-graphic--ingenovis';

const SVG: React.FC<GraphicProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 338 338'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#333F67'
      d='M228.605 150.017h9.486a5.919 5.919 0 005.909-5.909V90.646a5.919 5.919 0 00-5.909-5.909h-9.486a5.919 5.919 0 00-5.909 5.91v53.461a5.893 5.893 0 005.909 5.909z'
    />
    <path fill='url(#pattern0)' d='M217.631 80.637h18.892v75.168h-18.892z' />
    <path
      fill='#333F67'
      d='M226.514 150.017h1.246a3.817 3.817 0 003.819-3.819V88.556a3.817 3.817 0 00-3.819-3.819h-1.246a3.816 3.816 0 00-3.818 3.82v57.641a3.792 3.792 0 003.818 3.819z'
    />
    <path
      fill='#333F67'
      d='M227.479 254.086H97.926c-2.251 0-4.1-1.85-4.1-4.101V77.824c0-2.251 1.849-4.1 4.1-4.1h129.553c2.251 0 4.1 1.849 4.1 4.1v172.121c0 2.291-1.849 4.141-4.1 4.141z'
    />
    <path fill='url(#pattern1)' d='M90.208 73.402H235.72v182.894H90.208z' />
    <path fill='#E5E6EB' d='M223.138 84.778H102.226v158.294h120.912V84.778z' />
    <path
      fill='#F6F6F6'
      d='M217.912 246.77l-120.63-7.798 10.17-157.933 120.67 7.758-10.21 157.973z'
    />
    <path
      fill='#fff'
      d='M214.455 249.342L94.308 235.756l17.807-157.29 120.147 13.587-17.807 157.289z'
    />
    <path
      fill='#F5995C'
      d='M131.369 126.783c5.461 0 9.888-4.427 9.888-9.888 0-5.462-4.427-9.889-9.888-9.889s-9.888 4.427-9.888 9.889c0 5.461 4.427 9.888 9.888 9.888z'
    />
    <path
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={1.608}
      d='M127.148 116.453l2.372 2.934 6.03-4.824'
    />
    <path
      stroke='#B2C2E8'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={4.02}
      d='M149.779 112.955l40.237 4.543'
    />
    <path
      stroke='#B2C2E8'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={2.157}
      d='M148.051 121.075l63.269 7.155M147.247 128.109l63.269 7.155'
    />
    <path
      fill='#F5995C'
      d='M126.947 165.854c5.462 0 9.889-4.427 9.889-9.888s-4.427-9.889-9.889-9.889c-5.461 0-9.888 4.428-9.888 9.889 0 5.461 4.427 9.888 9.888 9.888z'
    />
    <path
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={1.608}
      d='M122.727 155.564l2.372 2.934 6.029-4.824'
    />
    <path
      stroke='#B2C2E8'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={4.02}
      d='M145.358 152.026l40.236 4.583'
    />
    <path
      stroke='#B2C2E8'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={2.157}
      d='M143.629 160.186l63.27 7.155M142.825 167.221l63.27 7.155'
    />
    <path
      fill='#F5995C'
      d='M122.526 186.756c1.005 0 2.01.201 2.974.563 4.261 1.648 6.392 6.432 4.784 10.692-1.206 3.176-4.341 5.306-7.718 5.306a8.495 8.495 0 01-2.974-.562c-4.261-1.649-6.392-6.432-4.784-10.693 1.206-3.135 4.301-5.306 7.718-5.306zm0-1.608a9.914 9.914 0 00-9.245 6.351c-1.97 5.105.603 10.813 5.708 12.783a9.981 9.981 0 003.537.643 9.915 9.915 0 009.245-6.351c1.97-5.105-.603-10.813-5.708-12.782-1.165-.402-2.371-.644-3.537-.644z'
      opacity={0.28}
    />
    <path
      stroke='#B2C2E8'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={4.02}
      d='M140.936 191.138l40.237 4.542'
    />
    <path
      stroke='#B2C2E8'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={2.157}
      d='M139.208 199.257l63.269 7.155M138.404 206.292l63.269 7.155'
    />
    <defs>
      <pattern id='pattern0' width={1} height={1} patternContentUnits='objectBoundingBox'>
        <use xlinkHref='#image0_1201_2784' />
      </pattern>
      <pattern id='pattern1' width={1} height={1} patternContentUnits='objectBoundingBox'>
        <use xlinkHref='#image1_1201_2784' />
      </pattern>
      <image
        id='image0_1201_2784'
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAC7CAYAAAD10Mf5AAAACXBIWXMAAAsSAAALEgHS3X78AAAA'
      />
      <image
        id='image1_1201_2784'
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAWoAAAHHCAYAAACWWhLTAAAACXBIWXMAAAsSAAALEgHS3X78AAAA'
      />
    </defs>
  </svg>
);

const SvgIngenovis = styled(SVG)`
  ${(props: GraphicProps) => GetGraphicDimensions(props)}
`;
SvgIngenovis.displayName = 'Ingenovis';
export default SvgIngenovis;
