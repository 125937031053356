import { rgba } from '../utils/helpers';

import { FastaffDefaultLogo } from '../components/Logos';
import { BaseTheme, Palette } from '../types';
import defaultBreakpoints from './breakpoints';
import {
  FastaffDefaultTypographyStyles,
  FastaffDefaultFonts,
  IngenovisDefaultFonts,
  IngenovisDefaultTypographyStyles
} from './typography';
import { NavbarHeight, MastheadHeight, FooterHeight, MinFooterHeight } from '../constants';

/* Also the default theme for Cura */
const FastaffColorPalette: Palette = {
  brand: {
    1: '#333F67',
    2: '#4664C3',
    3: '#E27536',
    4: '#E9C24D'
  },
  primary: {
    100: '#DFE2EC',
    200: '#9BA3C0',
    300: '#616D98',
    400: '#495683',
    500: '#333F67',
    600: '#2A3453',
    700: '#1D243D'
  },
  primaryAlt: {
    100: '#DFE6F6',
    200: '#B2C2E8',
    300: '#758BD2',
    400: '#4664C3',
    500: '#284CA4'
  },
  secondary: {
    100: '#FFE2CC',
    200: '#F8B98C',
    300: '#F5995C',
    400: '#E27536',
    500: '#BF5A2B',
    600: '#A54927'
  },
  secondaryAlt: {
    100: '#FCEFCA',
    200: '#F9E19A',
    300: '#F3D26E',
    400: '#E9C24D',
    500: '#D4A721',
    600: '#C5931B'
  },
  grays: {
    black: '#1D243D',
    white: '#FFFFFF',
    grays: {
      100: '#FCFCFC',
      200: '#F6F6F6',
      300: '#E5E6EB',
      400: '#BCBEC7',
      500: '#9497A4',
      600: '#616575',
      700: '#464B5D'
    }
  },
  semantic: {
    green100: '#00854B',
    red100: '#E03842',
    red200: '#B00020'
  },
  gradient: 'linear-gradient(to right bottom, #675DC6, #302599)'
};

export const FastaffTheme: BaseTheme = {
  name: 'fastaff',
  iuiBreakpoints: defaultBreakpoints,
  iuiPalette: FastaffColorPalette,
  iuiTypography: {
    fonts: FastaffDefaultFonts,
    styles: FastaffDefaultTypographyStyles,
    baseDirectory: './fonts/',
    ingenovisFonts: IngenovisDefaultFonts,
    ingenovisStyles: IngenovisDefaultTypographyStyles
  },
  boldTextFontWeight: 600,
  shadowColor: FastaffColorPalette.brand[1],
  metaThemeColor: FastaffColorPalette.brand[1],
  focusColor: 'cyan',
  fixedHeights: {
    navBar: {
      desktop: NavbarHeight.DESKTOP,
      mobile: NavbarHeight.MOBILE
    },
    masthead: MastheadHeight,
    footer: FooterHeight,
    minFooter: MinFooterHeight
  },
  icon: {
    default: FastaffColorPalette.grays.black,
    invert: FastaffColorPalette.grays.white,
    primary: FastaffColorPalette.brand[1],
    secondary: FastaffColorPalette.brand[3]
  },
  body: {
    color: FastaffColorPalette.grays.black
  },
  link: {
    base: {
      color: FastaffColorPalette.brand[2],
      hoverColor: FastaffColorPalette.primaryAlt[300],
      focusColor: FastaffColorPalette.primaryAlt[500]
    },
    alt: {
      color: FastaffColorPalette.grays.white,
      hoverColor: FastaffColorPalette.primary[200],
      focusColor: FastaffColorPalette.primary[300]
    }
  },
  buttons: {
    radius: '100px',
    primary: {
      font: 'button-primary',
      weight: 700,
      padding: '16px 40px',
      withBorderPadding: '15px 40px',
      fontSize: '1rem',
      lineHeight: '1rem'
    },
    secondary: {
      font: 'button-secondary',
      weight: 700,
      padding: '12px 16px',
      withBorderPadding: '7px 12px',
      fontSize: '0.875rem',
      lineHeight: '1rem'
    },
    tertiary: {
      font: 'button-tertiary',
      padding: '4px 12px',
      fontSize: '0.875rem',
      lineHeight: '1rem'
    },
    'faux-auth0': {
      font: 'button-primary',
      weight: 700,
      padding: '16px 40px',
      withBorderPadding: '15px 40px',
      fontSize: '1.125rem',
      lineHeight: '1.13rem'
    },
    badge: {
      font: 'meta-m-alt',
      default: FastaffColorPalette.grays.black,
      hover: FastaffColorPalette.brand[4],
      pressed: FastaffColorPalette.secondary[500],
      disabled: FastaffColorPalette.grays.black,
      active: FastaffColorPalette.brand[3],
      fontSize: '1rem',
      lineHeight: '0.75rem'
    },
    colorways: {
      dark: {
        color: FastaffColorPalette.grays.white, // this will set all if you remove static/hover/active
        static: {
          bg: FastaffColorPalette.brand[1]
        },
        hover: {
          bg: FastaffColorPalette.primary[400]
        },
        active: {
          bg: FastaffColorPalette.primary[600]
        },
        disabled: {
          bg: FastaffColorPalette.primary[600]
        }
      },
      light: {
        static: {
          color: FastaffColorPalette.brand[1],
          bg: FastaffColorPalette.grays.white
        },
        hover: {
          color: FastaffColorPalette.primary[400],
          bg: FastaffColorPalette.grays.grays[100]
        },
        active: {
          color: FastaffColorPalette.brand[1],
          bg: FastaffColorPalette.grays.grays[200]
        },
        disabled: {
          bg: FastaffColorPalette.grays.grays[200]
        }
      }
    }
  },
  textButtons: {
    colors: {
      static: FastaffColorPalette.brand[2],
      hover: FastaffColorPalette.primaryAlt[300],
      active: FastaffColorPalette.primaryAlt[500],
      disabled: FastaffColorPalette.primaryAlt[500]
    }
  },
  toggles: {
    small: {
      width: '52px',
      height: '24px',
      radius: '100px'
    },
    large: {
      width: '52px',
      height: '32px',
      radius: '100px'
    },
    colors: {
      enabled: FastaffColorPalette.primaryAlt[400],
      hover: FastaffColorPalette.primaryAlt[300],
      active: FastaffColorPalette.primaryAlt[500],
      disabled: FastaffColorPalette.primaryAlt[400]
    }
  },
  snackbar: {
    default: FastaffColorPalette.primary[700],
    error: FastaffColorPalette.semantic.red100
  },
  borderRadius: '4px',
  footer: {
    bg: FastaffColorPalette.grays.grays[200],
    color: FastaffColorPalette.grays.black,
    phoneColor: FastaffColorPalette.secondary[500],
    fontFamily: 'Acumin Pro',
    maxPerRow: 3,
    legals: {
      bg: FastaffColorPalette.primary[600],
      color: FastaffColorPalette.grays.white
    }
  },
  TitleWith3IconCards: {
    titleColor: FastaffColorPalette.brand[1],
    backgroundColor: FastaffColorPalette.primary[500],
    dividerColor: FastaffColorPalette.secondary[400],
    linkHoverColor: FastaffColorPalette.primary[200],
    linkActiveColor: FastaffColorPalette.primary[300]
  },
  logos: {
    default: FastaffDefaultLogo
  },
  navbar: {
    logoHeight: { large: '28px', small: '24px' },
    logoWidth: { large: 'auto', small: 'auto' },
    link: {
      color: FastaffColorPalette.primary[700],
      hoverColor: FastaffColorPalette.primaryAlt[400]
    },
    signInLink: {
      color: FastaffColorPalette.primary[700]
    },
    hover: {
      color: FastaffColorPalette.brand[1],
      bg: FastaffColorPalette.grays.grays[200]
    },
    selected: {
      color: FastaffColorPalette.brand[1],
      underline: FastaffColorPalette.brand[1]
    }
  },
  navMenu: {
    activeBorder: FastaffColorPalette.primary[300],
    activeLink: FastaffColorPalette.brand[1],
    title: FastaffColorPalette.grays.grays[600],
    divider: FastaffColorPalette.grays.grays[400],
    department: FastaffColorPalette.grays.grays[500],
    version: FastaffColorPalette.grays.grays[600],
    border: FastaffColorPalette.grays.grays[300]
  },
  pageTitle: {
    bg: FastaffColorPalette.primary[700],
    bgAlt: FastaffColorPalette.primary[500]
  },
  subNavbar: {
    bg: FastaffColorPalette.brand[1],
    height: 48
  },
  checkbox: {
    default: {
      restingSelected: FastaffColorPalette.brand[2],
      restingUnselected: FastaffColorPalette.grays.grays[400],
      hover: FastaffColorPalette.primaryAlt[300],
      pressed: FastaffColorPalette.primaryAlt[500]
    },
    leadGen: {
      resting: `1px solid ${FastaffColorPalette.grays.grays[400]}`,
      hoverSelected: `1px solid ${FastaffColorPalette.grays.grays[300]}`,
      hoverUnselected: `2px solid ${FastaffColorPalette.grays.grays[300]}`,
      pressedSelected: `1px solid ${FastaffColorPalette.grays.grays[500]}`,
      pressedUnselected: `2px solid ${FastaffColorPalette.grays.grays[500]}`
    }
  },
  select: {
    divider: FastaffColorPalette.grays.grays[400],
    label: FastaffColorPalette.grays.black,
    value: {
      active: FastaffColorPalette.brand[1],
      selected: FastaffColorPalette.grays.grays[700],
      error: FastaffColorPalette.semantic.red100
    },
    menuList: {
      activeBackground: FastaffColorPalette.grays.grays[300]
    }
  },
  heading: {
    stroke: FastaffColorPalette.brand[3]
  },
  jobSearch: {
    selectBorder: FastaffColorPalette.grays.grays[300]
  },
  avatar: {
    bg: FastaffColorPalette.brand[1]
  },
  contactBanner: {
    bg: FastaffColorPalette.primary[600]
  },
  jobCard: {
    jobTitleColor: FastaffColorPalette.brand[1],
    jobTitleHoverColor: FastaffColorPalette.primary[400],
    jobTitleActiveColor: FastaffColorPalette.primary[600],
    locationColor: FastaffColorPalette.grays.grays[700],
    detailTitleColor: FastaffColorPalette.grays.grays[700]
  },
  share: {
    iconColor: FastaffColorPalette.brand[1],
    textColor: FastaffColorPalette.brand[1],
    textHoverColor: FastaffColorPalette.primaryAlt[300],
    activeColor: FastaffColorPalette.primaryAlt[500],
    iconWrapperColor: FastaffColorPalette.primary[100],
    iconHoverColor: FastaffColorPalette.primary[400],
    backgroundColor: FastaffColorPalette.primary[100],
    backgroundHoverColor: FastaffColorPalette.primary[200]
  },
  referral: {
    bg: FastaffColorPalette.primary[500],
    text: FastaffColorPalette.grays.white
  },
  toast: {
    standard: {
      color: FastaffColorPalette.primary[700]
    },
    success: {
      color: FastaffColorPalette.semantic.green100
    },
    error: {
      color: FastaffColorPalette.semantic.red100
    }
  },
  modal: {
    backgroundColor: FastaffColorPalette.grays.grays[100]
  },
  dropdown: {
    hover: FastaffColorPalette.grays.grays[200],
    selected: FastaffColorPalette.grays.grays[300]
  },
  legalBar: {
    default: {
      backgroundColor: FastaffColorPalette.primary[600],
      textColor: FastaffColorPalette.grays.white
    },
    inverted: {
      backgroundColor: FastaffColorPalette.grays.white,
      textColor: FastaffColorPalette.grays.grays[600],
      borderColor: FastaffColorPalette.grays.grays[200]
    }
  },
  newsModule: {
    background: rgba(FastaffColorPalette.primary[100], 0.4)
  },
  pagination: {
    selectedHoverBackgroundColor: FastaffColorPalette.primary[400]
  },
  contactCard: {
    iconType: 'secondary',
    headerVariant: 'h5-alt'
  },
  Graphics: {
    primary: FastaffColorPalette.primaryAlt[100],
    secondary: FastaffColorPalette.secondaryAlt[100]
  }
};
