import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--social-facebook';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 10 17'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='currentColor'
      d='M8.796 9.434l.454-2.922H6.416V4.615c0-.8.396-1.579 1.665-1.579h1.288V.548S8.2.35 7.082.35c-2.334 0-3.86 1.4-3.86 3.934v2.228H.629v2.922h2.595V16.5h3.193V9.434h2.38z'
    />
  </svg>
);

const SvgSocialFacebook = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgSocialFacebook.displayName = 'SocialFacebook';
export default SvgSocialFacebook;
