import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--functions-trustaff';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 104 104'
    className={className}
    data-test={TEST_ID}
  >
    <circle cx={52} cy={52} r={52} fill='#FFF7EC' />
    <path
      fill='#C5C7F2'
      d='M63.243 83.744H40.868a5.116 5.116 0 01-5.12-5.12V26.182a5.116 5.116 0 015.12-5.121h22.375a5.116 5.116 0 015.121 5.12V78.64c0 2.815-2.289 5.104-5.12 5.104z'
    />
    <path
      fill='#E8EAFF'
      d='M63.095 83.052h-22.08a4.525 4.525 0 01-4.528-4.528V26.331a4.525 4.525 0 014.528-4.528h22.08a4.525 4.525 0 014.527 4.528v52.193a4.536 4.536 0 01-4.527 4.528z'
    />
    <path
      stroke='#8A8FD9'
      strokeLinecap='round'
      strokeMiterlimit={10}
      strokeWidth={1.865}
      d='M46.3 80.352h11.51'
    />
    <path
      fill='#FFB05A'
      d='M56.213 19.877h-1.696a.313.313 0 01-.313-.313V17.85c0-.18.148-.313.313-.313h1.696c.18 0 .313.149.313.313v1.696a.307.307 0 01-.313.33z'
    />
    <path
      fill='#fff'
      d='M55.208 19.218c-.033 0-.05-.017-.066-.033l-.478-.477a.1.1 0 010-.132.1.1 0 01.132 0l.412.411.79-.79a.1.1 0 01.132 0 .1.1 0 010 .132l-.857.856c-.016.033-.032.033-.065.033z'
    />
    <path
      fill='#302599'
      d='M69.631 19.35H58.863a.373.373 0 01-.379-.38v-.526c0-.214.165-.379.38-.379H69.63c.214 0 .379.165.379.379v.527c-.017.214-.181.379-.379.379z'
    />
    <path
      fill='#C5C7F2'
      d='M46.779 39.14v4.528c0 .642-.215 1.219-.56 1.696a2.41 2.41 0 01-.264.313 1.968 1.968 0 01-.197.181 2.354 2.354 0 01-.33.23 2.764 2.764 0 01-1.465.412H30.215a2.931 2.931 0 01-1.498-.428c-.066-.033-.116-.082-.181-.132a3.182 3.182 0 01-.577-.576 2.82 2.82 0 01-.148-.23 2.765 2.765 0 01-.412-1.466V39.14h19.38z'
    />
    <path
      fill='#302599'
      d='M35.433 44.212h-6.207a.295.295 0 01-.296-.297v-.724c0-.165.131-.297.296-.297h6.207c.165 0 .297.132.297.297v.724a.295.295 0 01-.297.297zM39.747 42.367H29.308a.383.383 0 01-.378-.378v-.56c0-.198.164-.379.378-.379h10.44c.197 0 .378.165.378.379v.56c-.017.197-.181.379-.379.379zM46.779 39.14H27.383V28.274a2.832 2.832 0 012.832-2.832h13.748a2.828 2.828 0 012.832 2.815l-.017 10.883zM44.901 59.376H17.57a1.876 1.876 0 01-1.877-1.877v-4.05c0-1.038.84-1.878 1.877-1.878h27.349c1.037 0 1.877.84 1.877 1.877v4.05a1.901 1.901 0 01-1.894 1.878z'
    />
    <path fill='#8A8FD9' d='M42.822 54.67l-1.456 1.455.163.163 1.455-1.455-.163-.163z' />
    <path fill='#8A8FD9' d='M40.207 54.672l-.163.163 1.455 1.455.163-.163-1.455-1.455z' />
    <path
      fill='#C5C7F2'
      d='M32.52 56.215H20.023a.425.425 0 01-.428-.429v-.625c0-.247.197-.428.428-.428H32.52c.247 0 .428.197.428.428v.625a.425.425 0 01-.428.428z'
    />
    <path
      fill='#302599'
      d='M48.967 71.659H36.866a2.997 2.997 0 010-5.994h12.101a2.997 2.997 0 012.997 2.997c-.016 1.663-1.35 2.997-2.997 2.997z'
    />
    <path
      fill='#C5C7F2'
      d='M47.025 69.337h-8.216a.33.33 0 01-.33-.33v-.658a.33.33 0 01.33-.33h8.216a.33.33 0 01.33.33v.659c0 .164-.15.329-.33.329z'
    />
    <path
      fill='#302599'
      d='M79.625 68.086H62.666a4.065 4.065 0 01-4.066-4.067V38.367a4.065 4.065 0 014.066-4.067h16.96a4.065 4.065 0 014.066 4.067v25.635c0 2.256-1.827 4.084-4.067 4.084z'
    />
    <path
      fill='#C5C7F2'
      d='M75.164 54.058h-8.035a.373.373 0 01-.379-.38v-.938c0-.214.165-.378.379-.378h8.035c.214 0 .378.164.378.378v.939a.383.383 0 01-.378.379zM77.897 51.67H64.395a.478.478 0 01-.477-.477v-.725c0-.263.214-.477.477-.477h13.502c.263 0 .477.214.477.477v.725a.478.478 0 01-.477.477z'
    />
    <path
      fill='#fff'
      d='M75.279 65.847h-8.282a2.041 2.041 0 110-4.084h8.282c1.136 0 2.041.922 2.041 2.042a2.03 2.03 0 01-2.041 2.042z'
    />
    <path
      fill='#302599'
      d='M73.946 64.25H68.33a.225.225 0 01-.23-.231v-.445c0-.131.098-.23.23-.23h5.615c.131 0 .23.099.23.23v.445c0 .132-.099.23-.23.23z'
    />
    <path
      fill='#fff'
      d='M69.714 42.253h.181c.132 0 .214-.082.214-.181 0-.099-.082-.181-.214-.181-.131 0-.214.098-.214.197h-.395c.017-.313.247-.51.642-.51.346 0 .61.18.626.444 0 .23-.181.346-.296.363v.016a.372.372 0 01.345.395c0 .313-.329.494-.675.494-.444 0-.642-.247-.642-.527h.395c0 .116.115.198.264.198.148 0 .247-.082.247-.198 0-.115-.083-.197-.247-.197h-.181v-.313h-.05zM70.652 43.602l.527-2.09h.379l-.527 2.09h-.379zM72.859 41.61v1.054h.198v.33h-.198v.28h-.412v-.28h-.806v-.347c.18-.362.395-.707.592-1.053h.626v.016zm-.412 1.054v-.741h-.016c-.148.247-.28.477-.412.74h.428z'
    />
    <path
      fill='#FFB05A'
      d='M71.146 47.159a4.884 4.884 0 01-4.874-4.874c0-.23.182-.411.412-.411.23 0 .412.18.412.411 0 2.24 1.81 4.05 4.05 4.05 2.24 0 4.05-1.81 4.05-4.05 0-2.239-1.81-4.05-4.05-4.05a.408.408 0 01-.412-.412c0-.23.182-.411.412-.411a4.884 4.884 0 014.874 4.873 4.884 4.884 0 01-4.874 4.874z'
    />
    <path
      fill='#302599'
      d='M69.632 23.482H58.864a.373.373 0 01-.379-.378v-.527c0-.214.165-.379.379-.379h10.768c.214 0 .379.165.379.379v.527c-.017.214-.181.378-.379.378z'
    />
    <path
      fill='#FFB05A'
      d='M56.213 24.01h-1.696a.313.313 0 01-.313-.314v-1.712c0-.181.148-.313.313-.313h1.696c.18 0 .313.148.313.313v1.696a.307.307 0 01-.313.33z'
    />
    <path
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={0.637}
      d='M54.73 22.774l.478.478.873-.856'
    />
    <path
      fill='#302599'
      d='M69.632 27.615H58.864a.373.373 0 01-.379-.379v-.527c0-.214.165-.378.379-.378h10.768c.214 0 .379.164.379.378v.527c-.017.214-.181.379-.379.379z'
    />
    <path
      fill='#FFB05A'
      d='M56.213 28.142h-1.696a.313.313 0 01-.313-.313v-1.696c0-.181.148-.313.313-.313h1.696c.18 0 .313.148.313.313v1.696c.016.165-.132.313-.313.313z'
    />
    <path
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={0.637}
      d='M54.73 26.907l.478.494.873-.873'
    />
  </svg>
);

const SvgFunctionsTrustaff = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgFunctionsTrustaff.displayName = 'FunctionsTrustaff';
export default SvgFunctionsTrustaff;
