/* eslint react/prop-types : 0 */
import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { CustomTypographyProps, TypographyProps, TypographyToCssProps } from './typography.types';
import { headerType } from './typography.utils';
import { typographyToCss } from './typography.mixins';
import { makeLastSpaceNonBreaking, getStylesList } from '../../utils';

const TypographyBaseComponent = styled.p<TypographyToCssProps>`
  ${typographyToCss}
`;

const Typography = React.forwardRef<
  HTMLParagraphElement,
  React.PropsWithChildren<CustomTypographyProps>
>((props, ref) => {
  const theme = useTheme();
  const {
    variant,
    useIngenovisStyles,
    analyticsId,
    as,
    children,
    className,
    wordBreak,
    color,
    ...rest
  } = props;

  const fVariant = variant || 'body-l';
  const hType = headerType(fVariant);
  const stylesList = getStylesList(theme, useIngenovisStyles);
  const variantStyles: TypographyProps = stylesList[fVariant];

  // making a best effort to make last space character a non line breaking character.
  const nonorphanedIfString =
    typeof children === 'string' && !wordBreak ? makeLastSpaceNonBreaking(children) : children;

  return (
    <TypographyBaseComponent
      data-analytics-id={analyticsId}
      as={as || hType}
      className={className}
      {...variantStyles}
      {...rest}
      color={color || theme?.iuiPalette?.grays?.black || 'black'}
      data-test='iui-component-typography'
      ref={ref}
    >
      {nonorphanedIfString}
    </TypographyBaseComponent>
  );
});

Typography.displayName = 'Typography';

export default Typography;
