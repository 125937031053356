import * as React from 'react';
import MuiAvatar from '@mui/material/Avatar';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import FastaffIcon from './FastaffIcon';
import USNIcon from './USNIcon';
import getInitials from '../../utils/getInitials';
import { safeProps } from '../../utils/safeProps';

export interface AvatarProps {
  name: string;
  src?: string;
  height?: number;
  width?: number;
  variant?: 'circular' | 'rounded' | 'square';
  ref?: React.RefObject<HTMLImageElement>;
  badge?: React.ReactNode;
}

export interface BrandAvatarProps {
  height?: number;
  width?: number;
  variant?: 'circular' | 'rounded' | 'square';
  ref?: React.RefObject<HTMLImageElement>;
}

// Use the default Avatar font because Acumin Pro looks off-center
const Initials = styled.p`
  font-weight: 600;
`;

const BadgeWrap = styled.div`
  span {
    border: 2px solid white;
    padding: 3px;
  }
  position: absolute;
  top: -4px;
  right: -4px;
  line-height: 0;
`;

const WrappedMuiAvatar = styled(MuiAvatar)`
  &.MuiAvatar-root {
    overflow: visible;
  }
`;

const Avatar: React.FC<AvatarProps> = (props) => {
  const theme = useTheme();
  const { name, width, height, src, badge = null, ...rest } = props;
  const initials = getInitials(name);
  return (
    <WrappedMuiAvatar
      alt={name}
      src={src}
      sx={{
        width,
        height
      }}
      {...safeProps(rest)}
      data-test='iui-component-avatar'
    >
      {/* fallback if img src fails to load */}
      <MuiAvatar
        alt={name}
        sx={{
          width,
          height,
          color: theme.iuiPalette.grays.white ?? 'white',
          bgcolor: theme.avatar.bg ?? theme.iuiPalette.primary[500] ?? '#333F67'
        }}
        {...safeProps(rest)}
        data-test='Avatar-fallback'
      >
        <Initials>{initials}</Initials>
      </MuiAvatar>
      <BadgeWrap>{badge}</BadgeWrap>
    </WrappedMuiAvatar>
  );
};

export const FastaffAvatar: React.FC<BrandAvatarProps> = ({ width, height, ...rest }) => {
  const theme = useTheme();
  return (
    <MuiAvatar
      alt='Fastaff'
      src={FastaffIcon}
      sx={{
        width,
        height,
        bgcolor: theme.iuiPalette.grays.white ?? 'white',
        color: theme.avatar.bg ?? theme.iuiPalette.primary[500] ?? '#333F67'
      }}
      {...safeProps(rest)}
      data-test='Fastaff-avatar'
    >
      FS
    </MuiAvatar>
  );
};

export const USNAvatar: React.FC<BrandAvatarProps> = ({ width, height, ...rest }) => {
  const theme = useTheme();
  return (
    <MuiAvatar
      alt='US Nursing'
      src={USNIcon}
      sx={{
        width,
        height,
        bgcolor: theme.iuiPalette.grays.white ?? 'white',
        color: theme.avatar.bg ?? theme.iuiPalette.primary[500] ?? '#1B307D'
      }}
      {...safeProps(rest)}
      data-test='USNursing-avatar'
    >
      UN
    </MuiAvatar>
  );
};

export default Avatar;
