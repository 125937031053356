import React from 'react';
import { Pagination as MuiPagination, PaginationProps, PaginationItem } from '@mui/material';
import styled from '@emotion/styled';
import { EmotionStyledProps } from '../../types';
import { GetTypographyCss } from '../../mixins';
import { pxToRem } from '../../mixins/pxToRem';

import { ChevronLeftIcon, ChevronRightIcon } from '../Icons';
import { useIsBreakpoint } from '../../hooks';

const StyledPagination = styled(MuiPagination)`
  .MuiPagination-ul {
    gap: 16px;
    > li {
      margin-bottom: 0;
    }
  }
  .MuiButtonBase-root.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-page {
    ${({ theme }: EmotionStyledProps) => GetTypographyCss({ variant: 'body-m', theme })};
    color: ${({ theme }: EmotionStyledProps) => theme?.iuiPalette?.grays.grays[600] ?? 'gray'};
    height: 40px;
    margin: 0;
    padding: 0;
    width: 40px;
    border-radius: 50%;

    &:hover {
      ${({ theme }: EmotionStyledProps) => GetTypographyCss({ variant: 'body-m-alt', theme })};
      background-color: ${({ theme }: EmotionStyledProps) =>
        theme?.iuiPalette?.grays.grays[300] ?? 'gainsboro'};
    }

    &.Mui-selected {
      ${({ theme }: EmotionStyledProps) => GetTypographyCss({ variant: 'body-m-alt', theme })};
      background-color: ${({ theme }: EmotionStyledProps) => theme?.iuiPalette?.brand[1] ?? 'navy'};
      color: white;
      &:hover {
        background-color: ${({ theme }: EmotionStyledProps) =>
          theme?.pagination?.selectedHoverBackgroundColor ?? 'blue'};
      }
      &:active {
        background-color: ${({ theme }: EmotionStyledProps) =>
          theme?.iuiPalette?.primary[600] ?? '#2A3453'};
      }
    }
  }
  .MuiPaginationItem-root.MuiPaginationItem-previousNext {
    margin: 0;
    padding: 0;
    > svg {
      width: ${pxToRem('24px')};
      height: ${pxToRem('24px')};
      > path {
        fill: ${({ theme }: EmotionStyledProps) => theme?.iuiPalette?.grays.black ?? 'black'};
      }
    }

    &.Mui-disabled {
      opacity: 0.3;
    }

    &:not(.Mui-disabled) {
      &:hover {
        background-color: transparent;
      }
    }
  }

  .MuiPaginationItem-root.MuiPaginationItem-ellipsis {
    margin: 0;
    padding: 0;
    width: 40px;
  }
`;

const Pagination: React.FC<PaginationProps> = (props) => {
  const { isMobile } = useIsBreakpoint();
  return (
    <StyledPagination
      boundaryCount={1}
      siblingCount={isMobile ? 0 : 1}
      renderItem={(item) => (
        <PaginationItem
          components={{ previous: ChevronLeftIcon, next: ChevronRightIcon }}
          {...item}
        />
      )}
      {...props}
      data-test='iui-component-pagination'
    />
  );
};

export default Pagination;
