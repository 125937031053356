import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--calendar';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 40 40'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#E27536'
      d='M28.333 20H20v8.333h8.333V20zM26.667 1.667V5H13.333V1.667H10V5H8.333c-1.85 0-3.316 1.5-3.316 3.333L5 31.667A3.332 3.332 0 008.333 35h23.334C33.5 35 35 33.5 35 31.667V8.333C35 6.5 33.5 5 31.667 5H30V1.667h-3.333zm5 30H8.333V13.333h23.334v18.334z'
    />
  </svg>
);

const SvgCalendar = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgCalendar.displayName = 'Calendar';
export default SvgCalendar;
