import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--social-instagram';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 17 16'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='currentColor'
      d='M8.503 3.956C6.232 3.956 4.4 5.76 4.4 7.996c0 2.236 1.832 4.04 4.103 4.04 2.272 0 4.104-1.804 4.104-4.04 0-2.236-1.832-4.04-4.104-4.04zm0 6.666c-1.468 0-2.668-1.178-2.668-2.626 0-1.449 1.197-2.626 2.668-2.626 1.472 0 2.668 1.177 2.668 2.626 0 1.448-1.2 2.626-2.668 2.626zm5.229-6.83a.948.948 0 01-.957.941.948.948 0 01-.957-.942.95.95 0 01.957-.942.95.95 0 01.957.942zm2.718.955c-.061-1.262-.354-2.38-1.293-3.3C14.22.524 13.085.236 11.803.173 10.482.1 6.521.1 5.2.174 3.92.234 2.785.522 1.846 1.443c-.94.92-1.228 2.039-1.293 3.3-.075 1.302-.075 5.2 0 6.501.061 1.262.354 2.38 1.293 3.301.94.921 2.072 1.21 3.354 1.273 1.321.074 5.282.074 6.603 0 1.282-.06 2.418-.348 3.354-1.273.936-.92 1.229-2.039 1.293-3.3.075-1.302.075-5.197 0-6.498zm-1.707 7.893a2.68 2.68 0 01-1.522 1.498c-1.053.41-3.553.316-4.718.316-1.164 0-3.668.091-4.718-.316a2.68 2.68 0 01-1.521-1.498c-.418-1.037-.321-3.498-.321-4.644s-.093-3.61.321-4.644a2.68 2.68 0 011.521-1.498c1.054-.411 3.554-.316 4.718-.316 1.165 0 3.668-.092 4.718.316.7.274 1.24.805 1.522 1.498.418 1.037.321 3.498.321 4.644s.097 3.61-.321 4.644z'
    />
  </svg>
);

const SvgSocialInstagram = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgSocialInstagram.displayName = 'SocialInstagram';
export default SvgSocialInstagram;
