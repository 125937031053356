import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--home';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#1D243D'
      fillRule='evenodd'
      d='M11.386 1.21a1 1 0 011.228 0l9 7A1 1 0 0122 9v11a3 3 0 01-3 3H5a3 3 0 01-3-3V9a1 1 0 01.386-.79l9-7zM4 9.49V20a1 1 0 001 1h14a1 1 0 001-1V9.49l-8-6.223-8 6.222z'
      clipRule='evenodd'
    />
    <path
      fill='#1D243D'
      fillRule='evenodd'
      d='M8 12a1 1 0 011-1h6a1 1 0 011 1v10a1 1 0 11-2 0v-9h-4v9a1 1 0 11-2 0V12z'
      clipRule='evenodd'
    />
  </svg>
);

const SvgHome = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgHome.displayName = 'Home';
export default SvgHome;
