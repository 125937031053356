import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--quote';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 99 81'
    className={className}
    data-test={TEST_ID}
  >
    <g clipPath='url(#clip0_3976_13170)'>
      <path
        fill='#F8B98C'
        d='M40.235.334l7.087 10.323c-15.774 5.419-23.547 11.612-23.547 20.129 0 5.677 2.286 7.742 8.459 10.322 5.029 2.58 11.201 7.226 11.201 17.807 0 11.613-8.458 21.419-20.346 21.419C9.601 80.334 0 67.431 0 49.882 0 24.334 13.716 6.528 40.235.334zm51.436 0l6.858 10.323c-15.773 5.419-23.317 11.612-23.317 20.129 0 5.677 2.057 7.742 8.164 10.322 5.03 2.58 11.202 7.226 11.202 17.807 0 11.613-8.459 21.419-20.346 21.419-13.488 0-23.09-12.903-23.09-30.452C51.209 24.334 64.924 6.528 91.72.334h-.049z'
      />
    </g>
    <defs>
      <clipPath id='clip0_3976_13170'>
        <path fill='#fff' d='M0 0h98.529v80H0z' transform='translate(0 .334)' />
      </clipPath>
    </defs>
  </svg>
);

const SvgQuote = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgQuote.displayName = 'Quote';
export default SvgQuote;
