import { BaseTheme } from './theme';

/* Export all the theme types */
export * from './theme';

/**
 * Interface to enable emotion styled components to consume the theme
 * Needs to be imported to avoid Typescript error
 * See if we can remove in the future
 */
export interface EmotionStyledProps {
  theme: BaseTheme;
}

export type IconType = 'default' | 'primary' | 'secondary' | 'inverted' | 'secondary-500';

export interface IconProps extends Partial<EmotionStyledProps> {
  iconType?: IconType;
  customColor?: string;
  colorStroke?: boolean;
  className?: string;
  dimensions?: number; // common value for width and height
  height?: number;
  width?: number;
}

export interface GraphicProps extends Partial<EmotionStyledProps> {
  className?: string;
  dimensions?: number; // common value for width and height
  height?: number;
  width?: number;
  wrapperStyle?: 'primary' | 'secondary';
}

export enum LinkTypes {
  INTERNAL = 'internal',
  EXTERNAL = 'external'
}

export interface NavSubSection {
  label: string;
  route: string;
  type: `${LinkTypes}`;
  subsections?: NavSubSection[];
}

// navigation can have subnav within subnav within subnav etc.
// atm only one level deep
export interface NavSection {
  label: string;
  route?: string;
  type?: `${LinkTypes}`;
  subsections?: NavSubSection[];
}

/* Use as a type in an interface when needing to pass internal routes to a component */
export type FooterLink = {
  label: string;
  link: string;
  prefetch?: boolean;
  type?: `${LinkTypes}`;
};

export interface MultiselectOption {
  value: string;
  label: string;
}

export interface DropdownOption {
  label: string;
  value: string | number;
}

export interface CarouselCardProps {
  isCompact?: boolean;
  imageSrc?: string;
  imageAlt?: string;
  title: string;
  description: string;
  buttonText?: string;
  buttonHref?: string;
  icon?: JSX.Element; // icon instead of image
}

export interface LocationCardProps {
  imageSrc: string;
  imageAlt: string;
  href: string;
  ctaLabel: string;
}

export type NewsType = 'article' | 'broadcast' | 'pdf';
export type ArticleType = 'blog' | 'news' | 'press';
export type ShiftType = 'day' | 'night' | 'rotation' | 'other' | 'evening';

export enum ResourceTypes {
  NEWS = 'news',
  BLOG = 'blog',
  PRESS = 'press',
  WEBINAR = 'webinar',
  CHECKLIST = 'checklist',
  QA = 'qa'
}

export interface CardProps {
  imgSrc: string;
  imgAlt: string;
  title: string;
  subText: string;
  link: string;
  linkText?: string;
  imgFocusArea?: string;
  resourceType?: `${ResourceTypes}` | string;
  author?: string;
}
