import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const SIZE = '1rem';
const BORDER_SIZE = '3px';
const COLOR = 'currentColor';
const DURATION = '1s';

// 🤷‍♂️
const spin = keyframes`
  0%   { opacity:1; transform: rotate(0);}
  50%  { opacity:0.3; transform: rotate(180deg);}
  100% { opacity:1; transform: rotate(360deg); }
`;

export const StyledSpinner = styled.div`
  margin-right: 5px;
  width: ${SIZE};
  height: ${SIZE};
  border: ${BORDER_SIZE} solid ${COLOR};
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  animation: ${spin} ${DURATION} linear infinite;
  top: 50%;
`;
