import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--assignment';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 40 40'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#E27536'
      d='M11.667 25h11.666v3.333H11.667V25zm0-6.667h16.666v3.334H11.667v-3.334zm0-6.666h16.666V15H11.667v-3.333zm20-6.667H24.7C24 3.067 22.167 1.667 20 1.667c-2.167 0-4 1.4-4.7 3.333H8.333c-.233 0-.45.017-.666.067a3.346 3.346 0 00-1.684.916c-.3.3-.55.667-.716 1.067A3.21 3.21 0 005 8.333v23.334c0 .45.1.9.267 1.3.166.4.416.75.716 1.066.45.45 1.034.784 1.684.917.216.033.433.05.666.05h23.334C33.5 35 35 33.5 35 31.667V8.333C35 6.5 33.5 5 31.667 5zM20 4.583c.683 0 1.25.567 1.25 1.25A1.26 1.26 0 0120 7.083a1.26 1.26 0 01-1.25-1.25c0-.683.567-1.25 1.25-1.25zm11.667 27.084H8.333V8.333h23.334v23.334z'
    />
  </svg>
);

const SvgAssignment = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgAssignment.displayName = 'Assignment';
export default SvgAssignment;
