import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--paid-outline';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 40 40'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#E27536'
      d='M19.999 3.333C10.799 3.333 3.332 10.8 3.332 20c0 9.2 7.467 16.667 16.667 16.667 9.2 0 16.666-7.467 16.666-16.667C36.665 10.8 29.2 3.333 20 3.333zm0 30c-7.35 0-13.334-5.983-13.334-13.333C6.665 12.65 12.65 6.667 20 6.667S33.332 12.65 33.332 20c0 7.35-5.983 13.333-13.333 13.333zM21.482 18.5c-2.967-.983-4.4-1.6-4.4-3.167 0-1.7 1.85-2.316 3.017-2.316 2.183 0 2.983 1.65 3.166 2.233l2.634-1.117c-.25-.733-1.367-3.183-4.434-3.716V8.333H18.55v2.1c-4.334.934-4.367 4.75-4.367 4.934 0 3.783 3.75 4.85 5.583 5.516 2.634.934 3.8 1.784 3.8 3.384 0 1.883-1.75 2.683-3.3 2.683-3.033 0-3.9-3.117-4-3.483L13.5 24.583c1.05 3.65 3.8 4.634 5.033 4.934v2.15h2.917V29.6c.866-.15 5.033-.983 5.033-5.367.017-2.316-1-4.35-5-5.733z'
    />
  </svg>
);

const SvgPaidOutline = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgPaidOutline.displayName = 'PaidOutline';
export default SvgPaidOutline;
