import * as React from 'react';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { useTheme } from '@emotion/react';
import { GetTypographyCss } from '../../mixins';

const StyledTextField = styled(TextField)`
  ${({ disabled }) => (disabled ? 'opacity: 0.3;' : '')}
  .MuiInput-root {
    padding: 4px 0 5px;
    margin-top: 15px;
  }
  .MuiFormLabel-root {
    ${({ theme }) => GetTypographyCss({ variant: 'meta-l-caps-alt', theme })}
    color: ${({ error, theme }) => {
      if (error) {
        return theme?.iuiPalette?.semantic?.red100 ?? 'red';
      }
      return theme?.iuiPalette?.grays?.grays?.[600] ?? 'gray';
    }};
    &.Mui-focused {
      color: ${({ error, theme }) => {
        if (error) {
          return theme?.iuiPalette?.semantic?.red100 ?? 'red';
        }
        return theme?.iuiPalette?.grays?.grays?.[600] ?? 'gray';
      }};
    }
  }
  .MuiInputLabel-shrink {
    ${({ theme }) => GetTypographyCss({ variant: 'meta-s-caps-alt', theme })}
    transform: translate(0, 1.5px) scale(1);
  }
  .MuiInputBase-root {
    padding: 4px 4px 4px 0;
    color: ${({ theme }) => theme?.iuiPalette?.grays?.black ?? 'black'};
  }
  .MuiInput-input {
    ${({ theme }) => GetTypographyCss({ variant: 'body-m-alt', theme })}
    min-height: 24px;
    padding: 0;
  }
  .MuiFormHelperText-root {
    ${({ theme }) => GetTypographyCss({ variant: 'meta-s-alt', theme })}
    margin-top: 8px;
  }
  .MuiInput-underline {
    &:before {
      border-bottom: 2px solid
        ${({ error, theme }) => {
          if (error) {
            return theme?.iuiPalette?.semantic?.red100 ?? 'red';
          }
          return theme?.iuiPalette?.grays?.grays?.[300] ?? 'gray';
        }};
    }
    &:hover:not(.Mui-disabled):before,
    &:after {
      border-bottom: 2px solid
        ${({ error, theme }) => {
          if (error) {
            return theme?.iuiPalette?.semantic?.red100 ?? 'red';
          }
          return theme?.iuiPalette?.primaryAlt?.[200] ?? 'blue';
        }};
    }
    &.Mui-focused {
      &:hover:not(.Mui-disabled):before,
      &:after {
        border-bottom: 2px solid
          ${({ error, theme }) => {
            if (error) {
              return theme?.iuiPalette?.semantic?.red100 ?? 'red';
            }
            return theme?.iuiPalette?.primaryAlt?.[300] ?? 'blue';
          }};
      }
    }
  }
`;

export interface DatePickerProps {
  label: string;
  value: Date | null;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  maxDate?: Date;
  minDate?: Date;
  disablePast?: boolean;
  disableFuture?: boolean;
  views?: Array<'day' | 'month' | 'year'>;
  onChange: (value: Date | null) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({
  label,
  value,
  disabled,
  error,
  errorMessage,
  maxDate,
  minDate,
  disablePast,
  disableFuture,
  views,
  onChange
}) => {
  const theme = useTheme();
  const calHeaderCss = GetTypographyCss({ variant: 'h6', theme });
  const calBodyCss = GetTypographyCss({ variant: 'meta-m', theme });
  const yearPickerCss = GetTypographyCss({ variant: 'body-m', theme });

  const renderPickerDay = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ) => <PickersDay {...pickersDayProps} />;

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <LocalizationProvider dateAdapter={AdapterDateFns as any}>
      <MuiDatePicker
        label={label}
        value={value}
        onChange={onChange}
        disablePast={disablePast}
        disableFuture={disableFuture}
        maxDate={maxDate}
        minDate={minDate}
        views={views}
        renderDay={renderPickerDay}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            variant='standard'
            helperText={errorMessage}
            error={error}
            disabled={disabled}
            fullWidth
          />
        )}
        OpenPickerButtonProps={{ style: { color: theme.iuiPalette.grays.grays[600] } }}
        PaperProps={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          sx: {
            '.MuiCalendarPicker-root': {
              'div:first-of-type': {
                'div > div': calHeaderCss,
                '.MuiSvgIcon-root': {
                  fill: theme.iuiPalette.grays.black
                }
              },
              'div:nth-of-type(2)': {
                '.MuiPickersDay-root': calBodyCss,
                'div > div > span': calBodyCss
              },
              '.Mui-selected, .Mui-selected:focus': {
                color: theme.iuiPalette.grays.white,
                backgroundColor: theme.iuiPalette.brand[3]
              },
              '.PrivatePickersYear-yearButton': yearPickerCss
            }
          }
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
