import { NavSection, NavSubSection } from '../../types';

export enum SectionStatusTypes {
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete'
}

export interface SideMenuSection extends NavSubSection {
  status?: SectionStatusTypes;
}
export interface SecondarySitemap extends NavSection {
  subsections?: SideMenuSection[];
  status?: SectionStatusTypes;
  totalPending?: number;
}

export interface ICondition {
  key: string;
  value: boolean;
}

export interface SideMenuProps {
  sitemap: SecondarySitemap[];
  activeRoute: string;
  recruiterName?: string;
  recruiterPhone?: string;
  recruiterEmail?: string;
  companyPhone?: string;
  companyEmail?: string;
  opHours?: string;
  conditions?: ICondition[];
  onLinkClick?: () => void;
}
