import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--expand';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#1D243D'
      fillRule='evenodd'
      d='M11.293 15.707a1 1 0 001.414 0l6-6a1 1 0 00-1.414-1.414L12 13.586 6.707 8.293a1 1 0 00-1.414 1.414l6 6z'
      clipRule='evenodd'
    />
  </svg>
);

const SvgExpand = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgExpand.displayName = 'Expand';
export default SvgExpand;
