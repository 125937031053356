import { IconProps, GraphicProps } from '../../types';

export const GetIconFillColor = (props: IconProps): string => {
  const { iconType, theme, customColor, colorStroke } = props;

  /* No added color */
  if ((!iconType && !customColor) || !theme || !theme.icon) {
    return '';
  }
  let fillColor;

  if (customColor) {
    fillColor = customColor;
  } else {
    switch (iconType) {
      case 'primary':
        fillColor = theme.icon.primary;
        break;
      case 'secondary':
        fillColor = theme.icon.secondary;
        break;
      case 'secondary-500':
        fillColor = theme.iuiPalette?.secondary[500] ?? theme.icon.secondary;
        break;
      case 'inverted':
        fillColor = theme.icon.invert;
        break;
      default:
        fillColor = theme.icon.default;
        break;
    }
  }

  return `
    > path {
      fill: ${fillColor};
      ${colorStroke ? `stroke: ${fillColor};` : ''}
    }`;
};

/**
 * Reusable function to set icon dimension
 */
export const GetIconDimensions = (props: IconProps): string => {
  const { dimensions, width, height } = props;

  /* If we have width or height values, use that */
  if (!!width || !!height) {
    return `
        ${width ? `width: ${width}px;` : ''}
        ${height ? `height: ${height}px;` : ''}`;
  }
  if (dimensions) {
    return `
      width: ${dimensions}px;  
      height: ${dimensions}px;`;
  }
  return '';
};

/* Reusable function to set graphic dimension
 */
export const GetGraphicDimensions = (props: GraphicProps): string => {
  const { dimensions, width, height } = props;

  /* If we have width or height values, use that */
  if (!!width || !!height) {
    return `
       ${width ? `width: ${width}px;` : ''}
       ${height ? `height: ${height}px;` : ''}`;
  }
  if (dimensions) {
    return `
     width: ${dimensions}px;  
     height: ${dimensions}px;`;
  }
  return '';
};
