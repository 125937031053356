import { TypographyVariant } from '../Typography/typography.types';

export enum ButtonVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  BADGE = 'badge',
  FAUX_AUTH0 = 'faux-auth0' // a button to mimic the one on auth0 universal login
}

export enum ButtonColorways {
  DARK = 'dark',
  LIGHT = 'light'
}

export enum ButtonTypes {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset'
}

// The interface used to customise the button component via a brand theme
interface ButtonTypeThemeOverrides {
  font?: TypographyVariant;
  weight?: number;
  padding?: string;
  radius?: string;
  withBorderPadding?: string;
  fontSize: string;
  lineHeight: string;
}

type ButtonColorwayMode = {
  bg?: string;
  color?: string;
  borderColor?: string;
};

type ButtonColorwayModes = {
  static?: ButtonColorwayMode;
  hover?: ButtonColorwayMode;
  active?: ButtonColorwayMode;
  disabled?: ButtonColorwayMode;
};

type ButtonColorwaysThemeOverrides = {
  dark: ButtonColorwayModes & ButtonColorwayMode;
  light: ButtonColorwayModes & ButtonColorwayMode;
};

interface BadgeButtonTypeThemeOverrides extends ButtonTypeThemeOverrides {
  default: string;
  hover: string;
  pressed: string;
  disabled: string;
  active: string;
}

export interface ButtonThemeOverrides {
  font?: string; // set font for all three types primary | secondary | tertiary
  padding?: string; // set padding for all three types primary | secondary | tertiary
  radius?: string; // set radius for all three types primary | secondary | tertiary
  weight?: number;

  primary?: ButtonTypeThemeOverrides;
  secondary?: ButtonTypeThemeOverrides;
  tertiary?: ButtonTypeThemeOverrides;
  ['faux-auth0']?: ButtonTypeThemeOverrides;
  badge?: BadgeButtonTypeThemeOverrides;
  colorways?: ButtonColorwaysThemeOverrides;
}

export type RefProps = {
  onClick?: () => void;
  href?: string;
};

export type RefType = number;
