import * as React from 'react';
import Box from '../../components/Box';
import styledLogo from '../../components/Logos/styledLogo';
import {
  FastaffDefaultLogo,
  USNursingDefaultLogo,
  TrustaffDefaultLogo,
  IngenovisDefaultLogo
} from '../../components/Logos';

const StyledIghLogo = styledLogo(IngenovisDefaultLogo);
const StyledTrustaffLogo = styledLogo(TrustaffDefaultLogo);
const StyledFastaffLogo = styledLogo(FastaffDefaultLogo);
const StyledUsnursingLogo = styledLogo(USNursingDefaultLogo);

const SharedNav: React.FC = () => (
  <Box width='100%' display='flex' justifyContent='center' background='#f8f8f8'>
    <Box
      width='100%'
      maxWidth='345px'
      padding='24px 0 0 0'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      gap='16px'
    >
      <StyledIghLogo height='48px' />
      <Box width='100%' display='flex' justifyContent='space-between' alignItems='flex-end'>
        <a href='http://trustaff.com/' target='_blank' rel='noreferrer'>
          <StyledTrustaffLogo height='18.7px' />
        </a>

        <a href='http://fastaff.com/' target='_blank' rel='noreferrer'>
          <StyledFastaffLogo height='18.7px' />
        </a>

        <a href='http://usnursing.com/' target='_blank' rel='noreferrer'>
          <StyledUsnursingLogo height='13px' />
        </a>
      </Box>
    </Box>
  </Box>
);

export default SharedNav;
