import styled from '@emotion/styled';

import { EmotionStyledProps } from '../../types';
import ContainerComp from '../../components/Container';
import { MediaQuery } from '../../mixins/mediaQuery';

export const GridItem = styled.div`
  grid-column: span 12;
  ${(props: EmotionStyledProps) => MediaQuery(props.theme.iuiBreakpoints.medium)} {
    &:nth-of-type(2n) {
      grid-column: 9 / span 4;
      max-width: 300px;
    }
    grid-column: auto / span 7;
  }

  ${(props: EmotionStyledProps) => MediaQuery(props.theme.iuiBreakpoints.large)} {
    grid-column: auto / span 7;

    &:nth-of-type(2n) {
      max-width: none;
      min-width: 280px;
    }
  }
`;

export const FooterNav = styled.ul<{ maxPerColumn: number; fontFamily: string }>`
  margin: 0;
  padding: 0;
  list-style: none;
  column-gap: 24px;

  a {
    font-family: ${({ fontFamily }) => fontFamily};
    line-height: 2rem;
    color: inherit;
    text-decoration: none;
    padding: 0.5rem 0;
    display: block;
    &:hover {
      text-decoration: underline;
      color: inherit;
    }
    &:active {
      opacity: 0.7;
    }
  }

  ${(props: EmotionStyledProps) => MediaQuery(props.theme.iuiBreakpoints.medium)} {
    display: grid;
    grid-template-rows: repeat(${(props) => props.maxPerColumn}, auto);
    grid-auto-flow: column;

    a {
      padding: 0.25rem 0;
    }
  }
`;

export const Container = styled(ContainerComp)<{ color: string }>`
  color: ${({ color }) => color};
`;

export const ModifiedGridItem = styled(GridItem)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const MobileAppsContainer = styled.div`
  margin-bottom: 1rem;

  > div {
    margin-top: 0.75rem;
    a:not(:last-of-type) {
      margin-right: 1rem;
    }
  }
`;

export const SealContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 350px;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 1.5rem;
  img {
    margin-right: 1.125rem;
  }
`;

export const StyledExternalLink = styled.a`
  &:hover {
    color: inherit;
  }
`;
