import styled from '@emotion/styled';

import defaultBreakpoints from '../../themes/breakpoints';
import { FavoriteBorderIcon, ShareIcon } from '../Icons';
import shadow from '../../mixins/shadow';
import { MediaQuery } from '../../mixins/mediaQuery';
import { JobCardProps } from '.';
import { EmotionStyledProps } from '../../types';

interface StyledIconProps extends EmotionStyledProps {
  disabled?: boolean;
}

export const Wrapper = styled.div`
  ${({ disabled }: JobCardProps) => (disabled ? 'opacity: 0.3;' : '')}
  width: 100%;
  overflow: hidden;
  display: flex;
  ${shadow({ level: 'light' })}
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.iuiPalette.grays.white ?? 'white'};

  flex-direction: column;
  border-radius: 8px;
  gap: unset;
  padding: 24px 20px 20px;
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    align-items: center;
    border-radius: 4px;
    gap: 24px;
    padding: 22px 24px 22px 24px;
    flex-direction: row;
  }

  &:hover {
    ${({ disabled }: JobCardProps) => !disabled && 'cursor: pointer;'}
    ${({ disabled }: JobCardProps) => !disabled && shadow({ level: 'heavy' })}
`;

export const TitleWrapper = styled.div`
  min-width: 0;
  flex: 2 1 auto;

  max-width: 100%;
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    max-width: 250px;
  }
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    max-width: 340px;
  }
`;

export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: unset;
  margin: 18px 0 20px 0;
  justify-content: space-between;
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    margin: auto;
    justify-content: flex-start;
    gap: 40px;

    ${({ theme }) =>
      theme.name === 'trustaff'
        ? `
        > div:nth-of-type(1) {
          width: 75px;
        }
        > div:nth-of-type(2) {
          width: 90px;
        }
      `
        : `
        > div:nth-of-type(1) {
          width: 35px;
        }
        > div:nth-of-type(2) {
          width: 70px;
        }
      `}
  }
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    gap: 63px;

    > div:nth-of-type(1) {
      width: 85px;
    }
    > div:nth-of-type(2) {
      width: 100px;
    }
  }
`;

export const SpacedSpan = styled.span`
  padding-left: 4px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.iuiPalette.primary[100]};
  margin-bottom: 8px;
`;

export const ActionWrapper = styled.div`
  margin-left: auto;
  display: flex;

  width: 100%;
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    width: auto;
  }
`;

export const ActionContentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    width: auto;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const IconRowWrapper = styled.div`
  display: flex;
  flex-direction: row;

  height: auto;
  gap: 24px;
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    height: 100%;
    gap: 16px;
  }
`;

export const IconWrapper = styled.div`
  ${({ disabled }: JobCardProps) =>
    !disabled &&
    `
      &:hover {
        cursor: pointer;
      }
    `}

  width: auto;
  height: auto;
  border: unset;
  border-radius: unset;
  display: inital;
  &:active {
    > svg {
      > path {
        fill: ${({ theme }) => theme.iuiPalette.primaryAlt[500]};
      }
    }
  }
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    width: 40px;
    height: 40px;
    border: 1px solid ${({ theme }) => theme.iuiPalette.grays.grays[300]};
    border-radius: 50%;
    display: grid;
    place-items: center;
  }
`;

export const StyledFavoriteBorderIcon = styled(FavoriteBorderIcon)`
  > path {
    fill: ${({ theme }) => theme.iuiPalette.brand[1]};
  }
  ${({ disabled, theme }: StyledIconProps) =>
    !disabled &&
    `
      &:hover {
        > path {
          fill: ${theme.iuiPalette.primaryAlt[300]};
        }
      }
    `}
`;

export const StyledShareIcon = styled(ShareIcon)<StyledIconProps>`
  > path {
    fill: ${({ theme }) => theme.iuiPalette.brand[1]};
  }

  ${({ disabled, theme }: StyledIconProps) =>
    !disabled &&
    `
      &:hover {
        > path {
          fill: ${theme.iuiPalette.primaryAlt[300]};
        }
      }
    `}
`;
