/* eslint-disable react/require-default-props */
import { useTheme } from '@emotion/react';
import React from 'react';
import Box from '../Box';
import { CheckmarkIcon } from '../Icons';
import Typography from '../Typography';
import { Wrapper } from './checkbox.styles';
import { CheckboxProps } from './checkbox.types';

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  label,
  disabled,
  padding,
  variant,
  leftIcon,
  rightIcon,
  onClick
}) => {
  const theme = useTheme();

  return (
    <Wrapper
      onClick={onClick}
      disabled={disabled}
      padding={padding}
      variant={variant}
      checked={checked}
      data-test='iui-component-checkbox'
    >
      <input type='checkbox' checked={checked} disabled={disabled} readOnly />
      <div className='check-icon'>
        {checked && (
          <CheckmarkIcon
            customColor={variant ? theme?.iuiPalette?.grays.black ?? 'black' : ''}
            colorStroke
          />
        )}
      </div>
      {leftIcon && (
        <Box
          display='flex'
          alignItems='center'
          margin='0 4px 0 0'
          opacity={disabled ? '0.3' : undefined}
        >
          {leftIcon}
        </Box>
      )}
      <Typography
        opacity={disabled ? 0.3 : undefined}
        variant={variant === 'leadGen' ? 'body-s' : 'body-m-alt'}
        overflow='hidden'
        textOverflow='ellipsis'
        as='div'
      >
        {label}
      </Typography>
      {rightIcon && (
        <Box
          display='flex'
          alignItems='center'
          margin='0 0 0 4px'
          opacity={disabled ? '0.3' : undefined}
        >
          {rightIcon}
        </Box>
      )}
    </Wrapper>
  );
};

export default Checkbox;
