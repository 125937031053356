import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--trustaff-brand-motif';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 213 196'
    className={className}
    data-test={TEST_ID}
  >
    <circle cx={220.5} cy={220.5} r={193.45} stroke='#E8EAFF' strokeWidth={10} />
    <circle cx={220.5} cy={220.5} r={220} stroke='#C5C7F2' />
    <circle cx={220.501} cy={220.5} r={135.475} stroke='#FF8300' />
    <circle cx={220.959} cy={220.959} r={83.403} stroke='#675DC6' strokeWidth={5} />
  </svg>
);

const SvgTrustaffBrandMotif = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgTrustaffBrandMotif.displayName = 'TrustaffBrandMotif';
export default SvgTrustaffBrandMotif;
