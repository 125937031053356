import * as React from 'react';
import styled from '@emotion/styled';
import Switch, { SwitchProps } from '@mui/material/Switch';

// https://mui.com/components/switches/
const LargeToggle = styled((props: SwitchProps) => (
  <Switch
    focusVisibleClassName='.Mui-focusVisible'
    disableRipple
    {...props}
    data-test='iui-component-toggle-large'
  />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.toggles.colors?.enabled,
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.toggles.colors?.active,
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#fff',
      opacity: 0.5
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.5
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#D9D9D9',
    opacity: 1,
    transition: 'background-color 300ms linear 0s'
  }
}));

export default LargeToggle;
