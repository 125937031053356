import * as React from 'react';
import styled from '@emotion/styled';
import { safeProps } from '../../utils/safeProps';
import { EmotionStyledProps } from '../../types';
import MobileAppApple from './mobileAppApple';
import MobileAppGoogle from './mobileAppGoogle';

export interface MobileAppLinkProps {
  href: string;
  company: string;
  store: 'google' | 'apple';
  margin?: string;
}

const IconAnchor = styled.a<EmotionStyledProps>`
  align-items: center;
  display: inline-flex;
  outline: none;
  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.85;
  }

  &:active {
    opacity: 0.65;
  }

  > svg {
    display: block;
    margin: 0 auto;
    text-align: center;
  }
`;

// exported for test file only
export const storeName = (store: string): string => {
  switch (store) {
    case 'apple':
      return 'Apple App Store';
    case 'google':
    default:
      return 'Google Play Store';
  }
};

export const MobileAppLink: React.FC<MobileAppLinkProps> = (props: MobileAppLinkProps) => {
  const { store, href, company, ...rest } = props;

  return (
    <IconAnchor
      href={href}
      target='_blank'
      {...safeProps(rest)}
      data-test='iui-component-mobile-app-link'
    >
      {store === 'apple' && <MobileAppApple />}
      {store === 'google' && <MobileAppGoogle />}
      <span className='sr-only'>
        Download the {company} Mobile App on the {storeName(store)}
      </span>
    </IconAnchor>
  );
};

export default MobileAppLink;
