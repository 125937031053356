/**
 * Receives a name as a prop and returns the initials
 * E.g. name = John Smith will return 'JS'
 */

const getInitials = (name: string): string => {
  if (name.length === 0) {
    return '';
  }
  // trim incase last char is space
  const parts = name.trim().split(' ');
  const isFullname = parts.length > 1;
  const firstInitial = parts[0].charAt(0).toUpperCase();
  return isFullname ? firstInitial + parts[parts.length - 1].charAt(0).toUpperCase() : firstInitial;
};

export default getInitials;
