import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--social-linkedin';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 16 16'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='currentColor'
      d='M3.694 15.376H.596V5.445h3.098v9.931zM2.144 4.09C1.152 4.09.35 3.273.35 2.287c0-.473.189-.928.525-1.263a1.798 1.798 0 012.537 0c.336.335.525.79.525 1.263 0 .986-.804 1.803-1.794 1.803zm13.142 11.286h-3.09v-4.834c0-1.153-.024-2.63-1.611-2.63-1.61 0-1.857 1.252-1.857 2.547v4.917H5.634V5.445h2.97V6.8h.044c.413-.78 1.423-1.604 2.93-1.604 3.135 0 3.711 2.055 3.711 4.725v5.455h-.003z'
    />
  </svg>
);

const SvgSocialLinkedin = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgSocialLinkedin.displayName = 'SocialLinkedin';
export default SvgSocialLinkedin;
