export enum BadgeTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning'
}

export enum BadgeIconTypes {
  TICK = 'tick',
  CIRCLE_TICK = 'circle-tick',
  EXCLAIM = 'exclaim'
}

export enum BadgeSizeTypes {
  DEFAULT = 'default',
  SMALL = 'small'
}

export interface BadgeProps {
  label?: string;
  icon?: `${BadgeIconTypes}`;
  type?: `${BadgeTypes}`;
  forceColor?: string;
  size?: `${BadgeSizeTypes}`;
}
