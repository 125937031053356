import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { GetTypographyCss } from '../../mixins';
import { CloseMiniIcon, SearchIcon } from '../Icons';
import Box from '../Box';
import shadow from '../../mixins/shadow';

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    padding: 0 16px 0 12px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.iuiPalette.grays.grays[400]};
    ${({ theme }) => GetTypographyCss({ variant: 'body-l', theme })};
  }
  .MuiInputBase-input,
  .MuiOutlinedInput-input {
    padding: 0 12px;
    border-radius: 0;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  &:hover,
  &:focus,
  &:focus-within,
  &:active {
    .MuiInputBase-root {
      ${shadow({ level: 'heavy' })}
    }
    .search-icon {
      path {
        fill: ${({ theme }) => theme.iuiPalette.primaryAlt[300]};
      }
    }
  }
`;

interface SearchInputProps {
  value: string;
  onChange: (val: string) => void;
  testId?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({ value, onChange, testId }) => (
  <StyledTextField
    fullWidth
    value={value}
    onChange={(e) => onChange(e.target.value)}
    variant='outlined'
    InputProps={{
      inputProps: {
        'data-testid': testId || 'search-input'
      },
      startAdornment: <SearchIcon dimensions={24} className='search-icon' />,
      endAdornment: (
        <Box
          display='flex'
          cursor='pointer'
          onClick={() => onChange('')}
          alignItems='center'
          data-testid='clear-search-input'
        >
          <CloseMiniIcon dimensions={16} />
        </Box>
      )
    }}
  />
);

export default SearchInput;
