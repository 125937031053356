import styled from '@emotion/styled';
import { css, Theme, SerializedStyles } from '@emotion/react';
import { checkboxVariants, WrapperProps } from './checkbox.types';

const getCheckBoxBorderStyles = (
  theme: Theme,
  variant?: checkboxVariants,
  checked?: boolean,
  disabled?: boolean
): SerializedStyles => {
  if (variant === 'leadGen') {
    return css`
      border: ${theme?.checkbox?.leadGen.resting ?? 'lightGray'};
      background-color: white;
      &:hover {
        border: ${checked
          ? theme?.checkbox?.leadGen.hoverSelected ?? 'whitesmoke'
          : theme?.checkbox?.leadGen.hoverUnselected ?? 'whitesmoke'};
      }
      &:active {
        border: ${checked
          ? theme?.checkbox?.leadGen.pressedSelected ?? 'gray'
          : theme?.checkbox?.leadGen.pressedUnselected ?? 'gray'};
      }
    `;
  }

  return css`
    border: 1px solid
      ${checked
        ? 'transparent'
        : `${theme.checkbox.default.restingUnselected}${disabled ? ' !important' : ''}`};
    background-color: ${checked
      ? `${theme.checkbox.default.restingSelected}${disabled ? ' !important' : ''}`
      : 'transparent'};
    &:hover {
      border: 2px solid ${checked ? 'transparent' : theme.checkbox.default.hover};
      background-color: ${checked ? theme.checkbox.default.hover : 'transparent'};
    }
    &:active {
      border: 2px solid ${checked ? 'transparent' : theme.checkbox.default.pressed};
      background-color: ${checked ? theme.checkbox.default.pressed : 'transparent'};
    }
  `;
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ padding }) => (padding ? `padding: ${padding};` : '')}
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  input:not([disabled]):focus + .check-icon {
    outline: 1px solid ${({ theme }) => theme.focusColor};
  }

  .check-icon {
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-right: 8px;
    width: 20px;
    height: 20px;
    ${({ theme, disabled, variant, checked }) =>
      getCheckBoxBorderStyles(theme, variant, checked, disabled)}
    ${({ disabled }) => disabled && 'opacity: 0.3;'}
  }
`;
