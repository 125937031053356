/* Props that match to the font file to use for the particular element */

import { TypographyVariants } from '@mui/material';

/* These fields stay consistent throughout the different themes */
export interface TypographyBaseProps {
  fontSize: number; // rem
  lineHeight: number; // rem
  mobileFontSize?: number;
  mobileLineHeight?: number; // rem
  fontStyle: string;
}

const baseVariants = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'body-l',
  'body-m',
  'body-s',
  'body-s-e',
  'meta-l',
  'meta-m',
  'meta-s',
  'button-primary',
  'button-secondary',
  'button-tertiary',
  'button-tertiary-icon',
  'button-text-link'
] as const;

/* Unique set of values for Typography Base props */
export type TypographyBaseVariant = typeof baseVariants[number];

/* Fields that only vary based on the theme */
export interface ThemedTypographyProps {
  fontFamily: string;
  fontWeight: number;
  textUnderline?: boolean;
  uppercase?: boolean;
}

const variants = [
  ...baseVariants,
  'h1-alt',
  'h2-alt',
  'h3-alt',
  'h4-alt',
  'h5-alt',
  'h6-alt',
  'body-l-alt',
  'body-m-alt',
  'body-s-alt',
  'body-s-alt-e',
  'body-l-link',
  'body-m-link',
  'body-s-link',
  'meta-l-caps',
  'meta-m-caps',
  'meta-s-caps',
  'meta-l-alt',
  'meta-m-alt',
  'meta-s-alt',
  'meta-l-caps-alt',
  'meta-m-caps-alt',
  'meta-s-caps-alt'
] as const;

/* All the variants */
export type TypographyVariant = typeof variants[number];

/* Complete props for the theme */
export interface TypographyProps extends TypographyBaseProps, ThemedTypographyProps {}

/* Base list of styes */
export type TypographyBaseList = { [index in TypographyBaseVariant]: TypographyBaseProps };

/* List of Theme specific styles */
export type ThemedTypographyList = { [index in TypographyVariant]: ThemedTypographyProps };

/* Full list of styles (Base styles + Theme specific ) */
export type TypographyList = { [index in TypographyVariant]: TypographyProps };

/* types of headers */
export type TypographyHeader = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

/* Props that use can pass to override styles */
/* NOTE: Add more as needed */
export interface CustomTypographyProps {
  variant?: TypographyVariant;
  useIngenovisStyles?: boolean;
  className?: string;
  margin?: string;
  padding?: string;
  color?: string;
  onClick?: () => void;
  onKeyPress?: React.KeyboardEventHandler<HTMLParagraphElement>;
  tabIndex?: number;
  cursor?: string;
  as?: React.ElementType;
  display?: string;
  textAlign?: string;
  title?: string;
  whiteSpace?: string;
  wordWrap?: string;
  letterSpacing?: string;
  textOverflow?: string;
  overflow?: string;
  analyticsId?: string;
  width?: string;
  customLineHeight?: number /* needs a different name than line height - TS2320 */;
  flex?: string;
  wordBreak?: string;
  opacity?: number;
  textTransform?: string;
  hoverColor?: string;
  minWidth?: string;
  customFontWeight?: string;
  activeColor?: string;
  zIndex?: string;
  role?: string;
  maxWidth?: string;
}

export interface TypographyToCssProps extends TypographyProps, CustomTypographyProps {}
export { variants as TypographyVariants };
