import React from 'react';
import styled from '@emotion/styled';

import shadow from '../../mixins/shadow';
import { CarouselCardProps } from '../../types';
import Box from '../Box';
import Button from '../Button';
import Typography from '../Typography';
import Image from '../Image';
import { useIsBreakpoint } from '../../hooks';
import defaultBreakpoints from '../../themes/breakpoints';
import { MediaQuery } from '../../mixins/mediaQuery';
import CommonImageSizes from '../Image/commonImageSizes';
import CompactCard from './CompactCard';

const ImageWrapper = styled.div<{ svgStyles?: boolean }>`
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-top: 75%; // 4-3 aspect ratio
  background-color: ${({ theme, svgStyles }) => {
    if (svgStyles) {
      return theme?.iuiPalette?.secondary[100] ?? 'BlanchedAlmond';
    }
    return theme?.iuiPalette?.grays.grays[200] ?? 'floralwhite';
  }};
  margin-bottom: 1rem;
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    margin-bottom: 1.5rem;
  }
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`;

const StyledSVGWrapper = styled.div<{ $isCompact?: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;

  > svg {
    height: 100%;
    width: ${($isCompact) => ($isCompact ? '100%' : '75%')}; // keeping the 4-3 aspect ratio
  }
`;

const CarouselCard: React.FC<CarouselCardProps> = ({
  isCompact,
  buttonText,
  buttonHref,
  imageSrc,
  imageAlt,
  title,
  description,
  icon
}) => {
  const { isDesktop, isMobile } = useIsBreakpoint();

  const renderImageSection = React.useMemo(() => {
    if (icon) {
      return <StyledSVGWrapper $isCompact={isCompact}>{icon}</StyledSVGWrapper>;
    }
    if (!!imageAlt && !!imageSrc) {
      if (isMobile) {
        return (
          <StyledImage
            alt={imageAlt}
            src={imageSrc}
            responsive={CommonImageSizes.fullWidthFourThreeRatio}
            options={{ vw: 100, f: 'top' }}
          />
        );
      }
      return (
        <StyledImage
          alt={imageAlt}
          src={imageSrc}
          responsive={CommonImageSizes.threeColFourThreeRatio}
          options={{ f: 'top' }}
        />
      );
    }

    return null;
  }, [icon, imageAlt, imageSrc, isMobile, isCompact]);

  if (isCompact) {
    return (
      <CompactCard
        buttonText={buttonText}
        buttonHref={buttonHref}
        title={title}
        description={description}
        renderImageSection={renderImageSection}
      />
    );
  }

  return (
    <Box
      width='100%'
      height='100%'
      boxShadowLevel='heavy'
      padding={isDesktop ? '32px' : '20px'}
      background='white'
      borderRadius='4px'
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      alignItems='center'
    >
      <Box display='flex' flexDirection='column' width='100%'>
        <ImageWrapper svgStyles={!!icon}>{renderImageSection}</ImageWrapper>
        <Typography variant='h4' margin='0 0 0.5rem 0'>
          {title}
        </Typography>
        <Typography variant={isDesktop ? 'body-m' : 'body-l'}>{description}</Typography>
      </Box>
      {buttonText && buttonHref && (
        <Button
          asLink
          href={buttonHref}
          label={buttonText}
          variant='secondary'
          colorway='light'
          margin={isDesktop ? '1rem 0 0 0' : '0.5rem 0 0 0'}
        />
      )}
    </Box>
  );
};

export default CarouselCard;
