import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--file';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#1D243D'
      fillRule='evenodd'
      d='M3.879 1.879A3 3 0 016 1h7a1 1 0 01.707.293l7 7A1 1 0 0121 9v11a3 3 0 01-3 3H6a3 3 0 01-3-3V4a3 3 0 01.879-2.121zM6 3a1 1 0 00-1 1v16a1 1 0 001 1h12a1 1 0 001-1V9.414L12.586 3H6z'
      clipRule='evenodd'
    />
    <path
      fill='#1D243D'
      fillRule='evenodd'
      d='M13 1a1 1 0 011 1v6h6a1 1 0 110 2h-7a1 1 0 01-1-1V2a1 1 0 011-1z'
      clipRule='evenodd'
    />
  </svg>
);

const SvgFile = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgFile.displayName = 'File';
export default SvgFile;
