export const DEFAULT_PROFILE_USERNAME = '';

export enum ZIndexLevel {
  MODAL = 666,
  NAV = 100,
  ABOVE_PAGE = 10,
  PAGE = 5,
  ABOVE_DEFAULT = 1,
  DEFAULT = 0,
  BEHIND = -1,
  OUT_OF_VIEW = -10
}

// these number might creep over time, to update, view component in browser and change the px value
export enum NavbarHeight {
  MOBILE = 72,
  DESKTOP = 80
}

export const MastheadHeight = 72;
export const FooterHeight = 374;
export const MinFooterHeight = 53;
