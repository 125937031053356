import { TypographyBaseList, TypographyBaseVariant, TypographyVariant } from './typography.types';

/* Values that are shared between all the themes */
/* Font size and line height are in rem, with default being 16px */
export const TypographyBaseListData: TypographyBaseList = {
  h1: {
    fontSize: 3.75,
    lineHeight: 5.25,
    mobileFontSize: 1.94,
    mobileLineHeight: 3,
    fontStyle: 'normal'
  },
  h2: {
    fontSize: 2.5,
    lineHeight: 3.5,
    mobileFontSize: 1.94,
    mobileLineHeight: 3,
    fontStyle: 'normal'
  },
  h3: {
    fontSize: 1.94,
    lineHeight: 3,
    mobileFontSize: 1.56,
    mobileLineHeight: 2.5,
    fontStyle: 'normal'
  },
  h4: {
    fontSize: 1.56,
    lineHeight: 2.5,
    mobileFontSize: 1.25,
    mobileLineHeight: 2,
    fontStyle: 'normal'
  },
  h5: {
    fontSize: 1.25,
    lineHeight: 2,
    mobileFontSize: 1.25,
    mobileLineHeight: 2,
    fontStyle: 'normal'
  },
  h6: {
    fontSize: 1,
    lineHeight: 1.5,
    mobileFontSize: 1,
    mobileLineHeight: 1.5,
    fontStyle: 'normal'
  },
  'body-l': {
    fontSize: 1.25,
    lineHeight: 2,
    mobileFontSize: 1,
    mobileLineHeight: 1.5,
    fontStyle: 'normal'
  },
  'body-m': {
    fontSize: 1,
    lineHeight: 1.5,
    fontStyle: 'normal'
  },
  'body-s': {
    fontSize: 0.875,
    lineHeight: 1.5,
    fontStyle: 'normal'
  },
  'body-s-e': {
    fontSize: 0.875,
    lineHeight: 1.5,
    fontStyle: 'italic'
  },
  'meta-l': {
    fontSize: 0.875,
    lineHeight: 1,
    fontStyle: 'normal'
  },
  'meta-m': {
    fontSize: 0.75,
    lineHeight: 1,
    fontStyle: 'normal'
  },
  'meta-s': {
    fontSize: 0.625,
    lineHeight: 0.625,
    fontStyle: 'normal'
  },
  'button-primary': {
    fontSize: 1,
    lineHeight: 1,
    fontStyle: 'normal'
  },
  'button-secondary': {
    fontSize: 0.875,
    lineHeight: 1,
    fontStyle: 'normal'
  },
  'button-tertiary': {
    fontSize: 0.875,
    lineHeight: 1,
    fontStyle: 'normal'
  },
  'button-tertiary-icon': {
    fontSize: 1,
    lineHeight: 1,
    fontStyle: 'normal'
  },
  'button-text-link': {
    fontSize: 1,
    lineHeight: 1.47,
    fontStyle: 'normal'
  }
};

/* Maps the base variant to the variant needed */
export const variantToBaseVariantMap: { [index in TypographyVariant]: TypographyBaseVariant } = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  'h1-alt': 'h1',
  'h2-alt': 'h2',
  'h3-alt': 'h3',
  'h4-alt': 'h4',
  'h5-alt': 'h5',
  'h6-alt': 'h6',
  'body-l': 'body-l',
  'body-m': 'body-m',
  'body-s': 'body-s',
  'body-s-e': 'body-s-e',
  'body-l-alt': 'body-l',
  'body-m-alt': 'body-m',
  'body-s-alt': 'body-s',
  'body-s-alt-e': 'body-s-e',
  'body-l-link': 'body-l',
  'body-m-link': 'body-m',
  'body-s-link': 'body-s',
  'meta-l': 'meta-l',
  'meta-m': 'meta-m',
  'meta-s': 'meta-s',
  'meta-l-caps': 'meta-l',
  'meta-m-caps': 'meta-m',
  'meta-s-caps': 'meta-s',
  'meta-l-alt': 'meta-l',
  'meta-m-alt': 'meta-m',
  'meta-s-alt': 'meta-s',
  'meta-l-caps-alt': 'meta-l',
  'meta-m-caps-alt': 'meta-m',
  'meta-s-caps-alt': 'meta-s',
  'button-primary': 'button-primary',
  'button-secondary': 'button-secondary',
  'button-tertiary': 'button-tertiary',
  'button-tertiary-icon': 'button-tertiary-icon',
  'button-text-link': 'button-text-link'
};
