import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--email';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#1D243D'
      fillRule='evenodd'
      d='M19.53 3.47a.75.75 0 010 1.06l-8.25 8.25a.75.75 0 11-1.06-1.06l8.25-8.25a.75.75 0 011.06 0z'
      clipRule='evenodd'
    />
    <path
      fill='#1D243D'
      fillRule='evenodd'
      d='M19.53 3.47a.75.75 0 01.178.778l-5.25 15a.75.75 0 01-1.393.057l-2.883-6.487-6.487-2.883a.75.75 0 01.057-1.393l15-5.25a.75.75 0 01.778.178zM6.033 9.333l5.022 2.232a.75.75 0 01.38.38l2.232 5.022 4.11-11.745-11.744 4.11z'
      clipRule='evenodd'
    />
  </svg>
);

const SvgEmail = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgEmail.displayName = 'Email';
export default SvgEmail;
