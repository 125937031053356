import styled from '@emotion/styled';
import { NavbarHeight, ZIndexLevel } from '../../constants';
import { ExactMediaQuery, MediaQuery } from '../../mixins/mediaQuery';
import { EmotionStyledProps } from '../../types/index';

export const StyledSubnavBarWrapper = styled.div<EmotionStyledProps>`
  background: ${(props) => props.theme.subNavbar.bg};
  flex-direction: column;
  position: sticky;
  top: ${NavbarHeight.MOBILE}px;
  z-index: ${ZIndexLevel.NAV};

  ${({ theme }) => MediaQuery(theme.iuiBreakpoints.xLarge)} {
    top: ${NavbarHeight.DESKTOP}px;
  }

  a {
    text-decoration: none;
  }

  > nav ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 0;
    }
  }

  ${({ theme }) => ExactMediaQuery(theme.iuiBreakpoints.small, theme.iuiBreakpoints.medium)} {
    cursor: pointer;

    a {
      margin-left: 50px;
      justify-content: center;
    }

    > nav {
      margin-right: 0;
      a {
        text-align: center;
      }

      ul {
        padding-right: 70px;
        flex-direction: column;
        width: 100%;
      }
    }

    a.is-active {
      border-color: transparent;
    }
    a:not(.is-active) {
      display: none;
    }

    &.is-open {
      &:after {
        top: 20px;
        border-top: none;
        border-bottom: 5px solid ${({ theme }) => theme.iuiPalette.brand[3]};
      }
      a:not(.is-active) {
        display: flex;
      }
    }
  }
`;

export const Toggler = styled.button<EmotionStyledProps & { isOpen: boolean }>`
  &:before {
    height: 0;
    position: absolute;
    right: 19px;
    top: 21px;
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${({ theme }) => theme.iuiPalette.brand[3]};
  }
  height: ${({ theme }) => theme.subNavbar.height}px;
  width: 100%;
  right: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  position: absolute;

  ${({ isOpen, theme }) =>
    isOpen === true &&
    `
    width: 50px;
    &:before {
      border-top: none;
      border-bottom: 5px solid ${theme.iuiPalette.brand[3]};
    }
  `}
`;
