import * as React from 'react';
import { useTheme } from '@emotion/react';

import { GraphicProps } from '../../../types';
import GraphicsWrapper from '../GraphicsWrapper';
import TrustaffGraphic from './Trustaff';

const Graphic: React.FC<GraphicProps> = (props) => {
  const theme = useTheme();
  if (theme.name === 'trustaff') {
    return <TrustaffGraphic {...props} />;
  }

  return <TrustaffGraphic {...props} />;
};

const JobSearchHospitalGraphic: React.FC<GraphicProps> = (props) => {
  const { wrapperStyle } = props;
  if (wrapperStyle) {
    return (
      <GraphicsWrapper type={wrapperStyle}>
        <Graphic {...props} />
      </GraphicsWrapper>
    );
  }
  return <Graphic {...props} />;
};

JobSearchHospitalGraphic.displayName = 'JobSearchHospital';

export default JobSearchHospitalGraphic;
