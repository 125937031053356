import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '../Icons';
import { LinkContext } from '../../providers/Linker';

const StyledButton = styled.button`
  padding: 0;
  cursor: pointer;
  font-family: helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1rem;
  vertical-align: middle;
  font-weight: 700;
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.textButtons.colors.static};
  .text-wrapper {
    display: flex;
    align-items: center;
    .left-icon {
      margin-right: 4px;
    }
    .right-icon {
      margin-left: 4px;
    }
  }
  &:hover {
    color: ${({ theme }) => theme.textButtons.colors.hover};
  }
  &:active {
    color: ${({ theme }) => theme.textButtons.colors.active};
  }
  &:disabled {
    color: ${({ theme }) => theme.textButtons.colors.disabled};
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

interface TextButtonProps {
  text: string;
  asLink?: boolean;
  href?: string;
  onClick?: () => void;
  disabled?: boolean;
  hasLeftIcon?: boolean;
  hasRightIcon?: boolean;
  className?: string;
}

const TextButton: React.FC<TextButtonProps> = ({
  text,
  asLink,
  href = '',
  onClick,
  disabled,
  hasLeftIcon,
  hasRightIcon,
  className
}) => {
  const theme = useTheme();

  const CustomLink = React.useContext(LinkContext);
  const button = (
    <StyledButton
      className={className}
      disabled={disabled}
      type='button'
      onClick={() => !asLink && !!onClick && onClick()}
      data-test='iui-component-text-button'
    >
      <div className='text-wrapper'>
        {hasLeftIcon && (
          <ChevronLeftIcon className='left-icon' customColor={theme.iuiPalette.grays.grays[400]} />
        )}
        <span>{text}</span>
        {hasRightIcon && (
          <ChevronRightIcon
            className='right-icon'
            customColor={theme.iuiPalette.grays.grays[400]}
          />
        )}
      </div>
    </StyledButton>
  );

  return asLink ? (
    <CustomLink href={href} passHref>
      {button}
    </CustomLink>
  ) : (
    button
  );
};

TextButton.defaultProps = {
  asLink: false,
  href: undefined,
  onClick: undefined,
  disabled: false,
  hasLeftIcon: false,
  hasRightIcon: false,
  className: undefined
};

export default TextButton;
