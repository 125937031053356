import * as React from 'react';
import styled from '@emotion/styled';
import shadow, { ShadowLevels } from '../../mixins/shadow';

export enum BoxAsTypes {
  DIV = 'div',
  ARTICLE = 'article',
  ASIDE = 'aside',
  DETAILS = 'details',
  CAPTION = 'caption',
  FIGCAPTION = 'figcaption',
  FIGURE = 'figure',
  FOOTER = 'footer',
  HEADER = 'header',
  MAIN = 'main',
  NAV = 'nav',
  SECTION = 'section',
  OL = 'ol',
  UL = 'ul'
}
export interface BoxProps {
  onClick?: () => void;
  as?: `${BoxAsTypes}`;
  margin?: string;
  padding?: string;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  height?: string;
  maxHeight?: string;
  minHeight?: string;
  display?: string;
  flexDirection?: string;
  background?: string;
  alignItems?: string;
  justifyContent?: string;
  overflow?: string;
  overflowX?: string;
  overflowY?: string;
  flexWrap?: string;
  borderBottom?: string;
  borderTop?: string;
  borderLeft?: string;
  position?: string;
  right?: string;
  top?: string;
  left?: string;
  flexGrow?: string;
  flexShrink?: string;
  flexBasis?: string;
  zIndex?: string;
  cursor?: string;
  outline?: string;
  bottom?: string;
  borderRadius?: string;
  border?: string;
  borderBottomLeft?: string;
  borderBottomRight?: string;
  borderRight?: string;
  boxShadowLevel?: `${ShadowLevels}`;
  children?: React.ReactNode;
  className?: string;
  gap?: string;
  transform?: string;
  id?: string;
  lineHeight?: string;
  opacity?: string;
}

const StyledBox = styled.div<BoxProps>`
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
  ${({ padding }) => (padding ? `padding: ${padding};` : '')}
  ${({ width }) => (width ? `width: ${width};` : '')}
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth};` : '')}
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : '')}
  ${({ height }) => (height ? `height: ${height};` : '')}
  ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight};` : '')}
  ${({ minHeight }) => (minHeight ? `min-height: ${minHeight};` : '')}
  ${({ display }) => (display ? `display: ${display};` : '')}
  ${({ flexDirection }) => (flexDirection ? `flex-direction: ${flexDirection};` : '')}
  ${({ background }) => (background ? `background: ${background};` : '')}
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems};` : '')}
  ${({ justifyContent }) => (justifyContent ? `justify-content: ${justifyContent};` : '')}
  ${({ overflow }) => (overflow ? `overflow: ${overflow};` : '')}
  ${({ overflowX }) => (overflowX ? `overflow-x: ${overflowX};` : '')}
  ${({ overflowY }) => (overflowY ? `overflow-y: ${overflowY};` : '')}
  ${({ flexWrap }) => (flexWrap ? `flex-wrap: ${flexWrap};` : '')}
  ${({ flexGrow }) => (flexGrow ? `flex-grow: ${flexGrow};` : '')}
  ${({ flexBasis }) => (flexBasis ? `flex-basis: ${flexBasis};` : '')}
  ${({ flexShrink }) => (flexShrink ? `flex-shrink: ${flexShrink};` : '')}
  ${({ border }) => (border ? `border: ${border};` : '')}
  ${({ borderBottom }) => (borderBottom ? `border-bottom: ${borderBottom};` : '')}
  ${({ borderTop }) => (borderTop ? `border-top: ${borderTop};` : '')}
  ${({ borderRight }) => (borderRight ? `border-right: ${borderRight};` : '')}
  ${({ borderLeft }) => (borderLeft ? `border-left: ${borderLeft};` : '')}
  ${({ borderBottomLeft }) => (borderBottomLeft ? `border-bottom-left: ${borderBottomLeft};` : '')}
  ${({ borderBottomRight }) =>
    borderBottomRight ? `border-bottom-right: ${borderBottomRight};` : ''}
  ${({ position }) => (position ? `position: ${position};` : '')}
  ${({ top }) => (top ? `top: ${top};` : '')}
  ${({ right }) => (right ? `right: ${right};` : '')}
  ${({ bottom }) => (bottom ? `bottom: ${bottom};` : '')}
  ${({ left }) => (left ? `left: ${left};` : '')}
  ${({ zIndex }) => (zIndex ? `z-index: ${zIndex};` : '')}
  ${({ cursor }) => (cursor ? `cursor: ${cursor};` : '')}
  ${({ outline }) => (outline ? `outline: ${outline};` : '')}
  ${({ transform }) => (transform ? `transform: ${transform};` : '')}
  ${({ borderRadius }) => (borderRadius ? `border-radius: ${borderRadius};` : '')}
  ${({ boxShadowLevel }) => (boxShadowLevel ? shadow({ level: boxShadowLevel }) : '')}
  ${({ gap }) => (gap ? `gap: ${gap};` : '')}
  ${({ lineHeight }) => (lineHeight ? `line-height: ${lineHeight};` : '')}
  ${({ opacity }) => (opacity ? `opacity: ${opacity};` : '')}
`;

const Box: React.FC<BoxProps> = ({ as, children, ...rest }) => (
  <StyledBox as={as} {...rest} data-test='iui-component-box'>
    {children}
  </StyledBox>
);

export default Box;
