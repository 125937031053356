import styled from '@emotion/styled';
import { Theme } from '@emotion/react';

import { BreadcrumbProps } from '.';

interface StyledBreadcrumbProps extends BreadcrumbProps {
  margin?: string;
  theme: Theme;
  variant?: 'primary' | 'article';
}

export const StyledBreadcrumbWrapper = styled.div<StyledBreadcrumbProps>`
  user-select: none;
  margin: 1rem 0 0;
  ${({ variant }) =>
    variant === 'article' &&
    `a {
    text-decoration: none;
  }`}
`;
