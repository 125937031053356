import styled from '@emotion/styled';
import { MediaQuery } from '../../mixins/mediaQuery';
import { EmotionStyledProps } from '../../types';
import defaultBreakpoints from '../../themes/breakpoints';

export interface ContainerProps {
  isPortal?: boolean; // TODO - revisit name
}

const Container = styled.div<ContainerProps>`
  margin: 0 20px;
  ${(props: EmotionStyledProps) =>
    MediaQuery(props.theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    margin: 0 24px;
  }
  ${(props: EmotionStyledProps) =>
    MediaQuery(props.theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    margin: ${({ isPortal }: ContainerProps) => (isPortal ? '0 72px' : '0 48px')};
  }
  ${(props: EmotionStyledProps) =>
    MediaQuery(props.theme?.iuiBreakpoints?.xLarge ?? defaultBreakpoints.xLarge)} {
    max-width: 1440px;
    padding: 0 72px;
    margin: 0 auto;
  }
`;

export default Container;
