import * as React from 'react';
import styled from '@emotion/styled';
import { GraphicProps } from '../../../types';
import { GetGraphicDimensions } from '../../../mixins/icons';

const TEST_ID = 'iui-component-graphic--trustaff';

const SVG: React.FC<GraphicProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 338 338'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#512D6C'
      d='M151.917 199.667v-8.292h-57.96l-.082 33.167c0 9.203 7.38 16.583 16.583 16.583h116.084c9.203 0 16.583-7.38 16.583-16.583v-33.167h-58.042v8.292h-33.166zm82.916-74.625h-33.249v-16.584L185 91.875h-33.166l-16.584 16.583v16.584h-33.083c-9.121 0-16.584 7.462-16.584 16.583V166.5c0 9.204 7.38 16.583 16.584 16.583h49.75V166.5h33.166v16.583h49.75c9.121 0 16.584-7.462 16.584-16.583v-24.875c0-9.121-7.463-16.583-16.584-16.583zm-49.75 0h-33.166v-16.584h33.166v16.584z'
    />
    <path
      fill='#FFB05A'
      d='M101.413 171.421c.195-1.463.293-2.976.293-4.586 0-1.561-.098-3.122-.342-4.586l9.907-7.708c.878-.683 1.122-2 .585-2.976l-9.369-16.197c-.586-1.073-1.806-1.415-2.88-1.073l-11.663 4.683c-2.44-1.854-5.026-3.415-7.906-4.586L78.282 122a2.36 2.36 0 00-2.343-2H57.2c-1.171 0-2.098.829-2.294 2l-1.756 12.392c-2.88 1.171-5.515 2.781-7.906 4.586l-11.663-4.683c-1.074-.391-2.294 0-2.88 1.073l-9.32 16.197c-.586 1.025-.39 2.293.585 2.976l9.907 7.708c-.244 1.464-.44 3.074-.44 4.586 0 1.513.098 3.123.342 4.586l-9.906 7.709c-.879.683-1.123 2-.586 2.976l9.37 16.197c.585 1.073 1.805 1.415 2.879 1.073l11.663-4.683c2.44 1.854 5.027 3.415 7.906 4.586l1.757 12.392c.244 1.17 1.17 2 2.342 2h18.74c1.17 0 2.147-.83 2.293-2l1.757-12.392c2.879-1.171 5.514-2.732 7.905-4.586l11.663 4.683c1.074.391 2.294 0 2.88-1.073l9.369-16.197c.586-1.074.342-2.293-.585-2.976l-9.809-7.709zM66.57 184.399c-9.663 0-17.568-7.904-17.568-17.564 0-9.659 7.905-17.563 17.568-17.563 9.662 0 17.568 7.904 17.568 17.563 0 9.66-7.906 17.564-17.568 17.564z'
    />
  </svg>
);

const SvgTrustaff = styled(SVG)`
  ${(props: GraphicProps) => GetGraphicDimensions(props)}
`;
SvgTrustaff.displayName = 'Trustaff';
export default SvgTrustaff;
