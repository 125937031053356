import * as React from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Typography from '../Typography';
import Box from '../Box';
import Button from '../Button';
import { IconProps, EmotionStyledProps } from '../../types';
import { useBreakpoints } from '../../hooks';
import defaultBreakpoints from '../../themes/breakpoints';
import { MediaQuery } from '../../mixins/mediaQuery';

interface ContactCardProps {
  Icon: React.FC<IconProps>;
  title: string;
  buttonText: string;
  onButtonClick: () => void;
  children: React.ReactNode;
}

const Wrapper = styled(Box)`
  padding: 28px 20px 36px;
  ${(props: EmotionStyledProps) =>
    MediaQuery(props.theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    padding: 32px 40px;
  }

  ${(props: EmotionStyledProps) =>
    MediaQuery(props.theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    padding: 28px 42px 50px;
  }
`;

const ContentWrapper = styled(Box)`
  padding-top: 16px;
  padding-left: 0px;
  ${(props: EmotionStyledProps) =>
    MediaQuery(props.theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    padding-top: 16px;
  }
  ${(props: EmotionStyledProps) =>
    MediaQuery(props.theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    padding-top: 20px;
    padding-left: 10px;
  }
`;

const ContactCard: React.FC<ContactCardProps> = ({
  Icon,
  title,
  buttonText,
  onButtonClick,
  children
}) => {
  const theme = useTheme();
  const breakpoint = useBreakpoints(theme.iuiBreakpoints);

  return (
    <Wrapper boxShadowLevel='light' background={theme?.iuiPalette?.grays?.white ?? 'white'}>
      <Box
        display='flex'
        flexDirection='column'
        alignItems={breakpoint === 'small' ? 'center' : 'flex-start'}
        width='100%'
        height='100%'
        data-test='iui-component-contact-card'
      >
        <Icon dimensions={62} iconType={theme?.contactCard?.iconType} />
        <ContentWrapper
          display='flex'
          flexDirection='column'
          alignItems={breakpoint === 'small' ? 'center' : 'flex-start'}
          width='100%'
          justifyContent='space-between'
          height='100%'
        >
          <Box
            display='flex'
            flexDirection='column'
            alignItems={breakpoint === 'small' ? 'center' : 'flex-start'}
            width='100%'
          >
            <Typography
              variant={theme?.contactCard?.headerVariant}
              margin='0 0 16px 0'
              textAlign={breakpoint === 'small' ? 'center' : ''}
            >
              {title}
            </Typography>
            {children}
          </Box>
          <Button label={buttonText} onClick={onButtonClick} margin='32px 0 0 0' fullWidthMobile />
        </ContentWrapper>
      </Box>
    </Wrapper>
  );
};

export default ContactCard;
