import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--functions-fastaff';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 128 128'
    className={className}
    data-test={TEST_ID}
  >
    <circle cx={64} cy={64} r={64} fill='#FCEFCA' />
    <g clipPath='url(#clip0_390:7215)'>
      <path
        fill='#B2C2E8'
        d='M76.506 99.308H51.617a5.69 5.69 0 01-5.695-5.695v-58.33a5.69 5.69 0 015.696-5.696h24.888a5.69 5.69 0 015.696 5.696v58.348c0 3.132-2.546 5.677-5.696 5.677z'
      />
      <path
        fill='#DFE6F6'
        d='M76.341 98.54H51.782a5.033 5.033 0 01-5.036-5.037V35.448a5.033 5.033 0 015.036-5.036h24.56a5.034 5.034 0 015.036 5.036v58.055a5.045 5.045 0 01-5.037 5.036z'
      />
      <path
        stroke='#758BD2'
        strokeLinecap='round'
        strokeMiterlimit={10}
        strokeWidth={1.865}
        d='M57.661 95.536h12.802'
      />
      <path
        fill='#F5995C'
        d='M68.686 28.269h-1.887a.348.348 0 01-.348-.348v-1.905c0-.201.165-.347.348-.347h1.886c.202 0 .348.164.348.347v1.887a.341.341 0 01-.347.366z'
      />
      <path
        fill='#fff'
        d='M67.568 27.537c-.036 0-.055-.019-.073-.037l-.531-.531a.111.111 0 010-.147.111.111 0 01.147 0l.457.458.88-.879a.111.111 0 01.146 0 .111.111 0 010 .146l-.952.953c-.019.037-.037.037-.074.037z'
      />
      <path
        fill='#333F67'
        d='M83.611 27.683H71.634a.414.414 0 01-.421-.421v-.586c0-.238.183-.422.421-.422h11.977c.239 0 .422.183.422.422v.586c-.019.238-.202.42-.422.42z'
      />
      <path
        fill='#B2C2E8'
        d='M58.192 49.696v5.037a3.19 3.19 0 01-.623 1.886 2.68 2.68 0 01-.293.348 2.198 2.198 0 01-.22.201c-.11.092-.237.184-.366.257a3.073 3.073 0 01-1.63.458H39.768a3.26 3.26 0 01-1.666-.477c-.073-.036-.128-.091-.202-.146a3.536 3.536 0 01-.64-.641c-.056-.073-.11-.165-.165-.257a3.073 3.073 0 01-.458-1.63v-5.036h21.555z'
      />
      <path
        fill='#333F67'
        d='M45.574 55.337H38.67a.328.328 0 01-.33-.33v-.805c0-.183.146-.33.33-.33h6.904c.183 0 .33.147.33.33v.806c0 .183-.147.33-.33.33zM50.372 53.286h-11.61a.426.426 0 01-.422-.421v-.623c0-.22.183-.421.421-.421h11.611c.22 0 .421.183.421.421v.623c-.018.22-.201.42-.42.42zM58.192 49.696H36.618V37.61a3.15 3.15 0 013.15-3.15H55.06c1.74 0 3.15 1.41 3.15 3.132l-.018 12.105zM56.104 72.204H25.703a2.087 2.087 0 01-2.088-2.088v-4.505c0-1.154.934-2.088 2.088-2.088h30.42c1.153 0 2.087.934 2.087 2.088v4.505a2.114 2.114 0 01-2.106 2.088z'
      />
      <path fill='#758BD2' d='M53.79 66.97l-1.618 1.618.181.182 1.619-1.619-.181-.181z' />
      <path fill='#758BD2' d='M50.883 66.972l-.182.18 1.619 1.62.181-.182-1.618-1.618z' />
      <path
        fill='#B2C2E8'
        d='M42.331 68.688h-13.9a.473.473 0 01-.476-.476v-.696c0-.275.22-.476.476-.476h13.9c.275 0 .477.22.477.476v.696c0 .274-.22.476-.477.476z'
      />
      <path
        fill='#333F67'
        d='M60.628 85.867h-13.46a3.334 3.334 0 010-6.667h13.46a3.334 3.334 0 013.333 3.333c-.018 1.85-1.502 3.334-3.333 3.334z'
      />
      <path
        fill='#B2C2E8'
        d='M58.467 83.284h-9.139a.367.367 0 01-.366-.366v-.733c0-.201.165-.366.366-.366h9.139c.201 0 .366.165.366.366v.733a.38.38 0 01-.366.366z'
      />
      <path
        fill='#333F67'
        d='M94.728 81.892H75.864a4.521 4.521 0 01-4.523-4.523V48.836a4.522 4.522 0 014.523-4.524h18.864a4.522 4.522 0 014.523 4.524V77.35c0 2.51-2.033 4.542-4.523 4.542z'
      />
      <path
        fill='#B2C2E8'
        d='M89.765 66.289h-8.938a.415.415 0 01-.42-.421v-1.044c0-.239.182-.422.42-.422h8.938c.238 0 .42.183.42.422v1.044a.426.426 0 01-.42.42zM92.805 63.633H77.788a.532.532 0 01-.531-.53v-.807c0-.293.238-.53.53-.53h15.018c.293 0 .531.237.531.53v.806a.532.532 0 01-.53.531z'
      />
      <path
        fill='#fff'
        d='M89.893 79.402h-9.212a2.27 2.27 0 110-4.542h9.212a2.27 2.27 0 012.27 2.27 2.259 2.259 0 01-2.27 2.272z'
      />
      <path
        fill='#333F67'
        d='M88.41 77.625h-6.245a.25.25 0 01-.257-.257v-.494a.25.25 0 01.257-.256h6.245a.25.25 0 01.256.256v.495a.25.25 0 01-.256.256z'
      />
      <path
        fill='#fff'
        d='M83.703 53.158h.201c.147 0 .238-.092.238-.201 0-.11-.091-.202-.238-.202-.146 0-.238.11-.238.22h-.44c.019-.348.275-.568.715-.568.384 0 .677.202.696.495a.402.402 0 01-.33.403v.018a.413.413 0 01.385.44c0 .347-.367.549-.751.549-.495 0-.714-.275-.714-.586h.44c0 .128.127.22.292.22.165 0 .275-.092.275-.22s-.092-.22-.275-.22h-.201v-.348h-.055zM84.747 54.66l.586-2.326h.421l-.586 2.325h-.42zM87.2 52.444v1.172h.22v.366h-.22v.311h-.457v-.311h-.897v-.385c.201-.403.44-.787.659-1.172h.696v.019zm-.457 1.172v-.824h-.018c-.165.274-.312.53-.458.824h.476z'
      />
      <path
        stroke='url(#paint0_linear_390:7215)'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        strokeWidth={2.701}
        d='M85.296 48.231a4.955 4.955 0 014.963 4.963 4.955 4.955 0 01-4.963 4.963 4.955 4.955 0 01-4.963-4.963'
      />
      <path
        fill='#F5995C'
        d='M85.297 58.615a5.432 5.432 0 01-5.421-5.42c0-.257.201-.459.458-.459.256 0 .458.202.458.458a4.502 4.502 0 004.505 4.506 4.502 4.502 0 004.505-4.506 4.502 4.502 0 00-4.505-4.505.453.453 0 01-.458-.458c0-.256.201-.458.458-.458a5.432 5.432 0 015.42 5.421 5.432 5.432 0 01-5.42 5.421z'
      />
      <path
        fill='#333F67'
        d='M83.611 32.28H71.634a.414.414 0 01-.421-.422v-.586c0-.238.183-.42.421-.42h11.977c.239 0 .422.182.422.42v.586c-.019.238-.202.422-.422.422z'
      />
      <path
        fill='#F5995C'
        d='M68.686 32.866h-1.887a.348.348 0 01-.348-.348v-1.905c0-.201.165-.348.348-.348h1.886c.202 0 .348.165.348.348V32.5a.341.341 0 01-.347.367z'
      />
      <path
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        strokeWidth={0.637}
        d='M67.037 31.492l.531.531.97-.952'
      />
      <path
        fill='#333F67'
        d='M83.611 36.877H71.634a.414.414 0 01-.421-.422v-.585c0-.239.183-.422.421-.422h11.977c.239 0 .422.183.422.422v.586c-.019.238-.202.42-.422.42z'
      />
      <path
        fill='#F5995C'
        d='M68.686 37.463h-1.887a.348.348 0 01-.348-.348v-1.887c0-.201.165-.348.348-.348h1.886c.202 0 .348.165.348.348v1.887c.019.183-.146.348-.347.348z'
      />
      <path
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        strokeWidth={0.637}
        d='M67.037 36.09l.531.549.97-.971'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_390:7215'
        x1={79.864}
        x2={90.717}
        y1={53.196}
        y2={53.196}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FFE19F' />
        <stop offset={1} stopColor='#FFCA59' />
      </linearGradient>
      <clipPath id='clip0_390:7215'>
        <path fill='#fff' d='M0 0h75.636v73.64H0z' transform='translate(23.615 25.669)' />
      </clipPath>
    </defs>
  </svg>
);

const SvgFunctionsFastaff = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgFunctionsFastaff.displayName = 'FunctionsFastaff';
export default SvgFunctionsFastaff;
