import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--evening-shift-trustaff';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#FFB05A'
      d='M4.963 7.5L3.548 8.913l1.79 1.789 1.414-1.415L4.962 7.5zM4 14.95H1v1h3v-1zM13 5h-2v2.95h2V5zM19.035 7.5l-1.789 1.788 1.407 1.407 1.79-1.789L19.034 7.5zM23 14.95h-3v1h3v-1z'
    />
    <path fill='#A174C1' d='M23 17.95H1v2h22v-2z' />
    <path fill='#FFB05A' d='M12 10a6 6 0 00-6 6h12a6 6 0 00-6-6z' />
  </svg>
);

const SvgEveningShiftTrustaff = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgEveningShiftTrustaff.displayName = 'EveningShiftTrustaff';
export default SvgEveningShiftTrustaff;
