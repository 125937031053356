import React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@emotion/react';
import { PageTitleProps } from '.';
import { EmotionStyledProps } from '../../types';
import { MediaQuery, ExactMediaQuery } from '../../mixins/mediaQuery';
import defaultBreakpoints from '../../themes/breakpoints';
import { ZIndexLevel } from '../../constants';

interface StyledPageTitleWrapper extends PageTitleProps {
  theme: Theme;
  hasSubheading: boolean;
}

const truncateStyle = `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledPageTitleWrapper = styled.div<StyledPageTitleWrapper>`
  background: ${(props) => props.theme.pageTitle.bg};
  flex-direction: column;
  .button-wrap button {
    white-space: nowrap;
  }
  ${(props) =>
    ExactMediaQuery(
      props.theme?.iuiBreakpoints?.small ?? defaultBreakpoints.small,
      props.theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium
    )} {
    .button-wrap button {
      white-space: normal;
    }
  }

  .title-wrapper {
    position: relative;
    margin: ${({ hasSubheading }) => (hasSubheading ? '5rem 0 0.5rem' : '5rem 0 1.25rem')};
    ${(props: EmotionStyledProps) =>
      MediaQuery(props.theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
      justify-content: space-between;
      display: flex;
      margin: ${({ hasSubheading }) => (hasSubheading ? '7.75rem 0 0.5rem' : '7.75rem 0 1.25rem')};
    }
    ${(props: EmotionStyledProps) =>
      MediaQuery(props.theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
      margin: ${({ hasSubheading }) => (hasSubheading ? '7.75rem 0 0.5rem' : '7.75rem 0 2.5rem')};
    }
  }

  h1 {
    ${(props) => props.truncate && truncateStyle}
    margin-bottom: 0.75rem;
    ${(props: EmotionStyledProps) =>
      MediaQuery(props.theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      display: block;
      height: 4px;
      width: 40px;
      background: ${(props) => props.theme?.iuiPalette?.brand[3] ?? 'var(--color-brand-3)'};
      margin-bottom: 0.5rem;
    }
  }

  .button-wrap {
    width: 100%;

    span,
    button {
      width: 100%;
    }
    ${(props: EmotionStyledProps) =>
      MediaQuery(props.theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: auto;

      span {
        width: auto;
      }
      button {
        margin-left: 3rem;
        width: auto;
      }
    }
  }

  h2 {
    margin-bottom: 2.5rem;
  }

  overflow: hidden;
  position: relative;
  div:not(.bg) {
    z-index: ${ZIndexLevel.ABOVE_DEFAULT + 1};
  }
  .bg {
    position: absolute;
  }
  .bg--fastaff {
    top: -205px;
    right: 10px;
  }
  .bg--usnursing {
    position: relative;
    top: unset;
    right: 0;

    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      right: 0;
      bottom: 0;
      position: absolute;

      border-bottom: 120px solid ${(props) => props.theme.pageTitle.bgAlt};
      border-left: 120px solid transparent;
    }

    &::after {
      content: '';
      display: block;
      width: 120px;
      height: 120px;
      right: 0;
      bottom: 0;
      position: absolute;

      background: linear-gradient(315deg, rgba(27, 48, 125, 0.8) 0%, rgba(27, 48, 125, 0) 50%);
    }
  }

  .bg--trustaff {
    bottom: -10px;
    right: 0;
  }
`;

export const TrustaffBG: React.FC<{ color: string }> = ({ color }) => (
  <svg
    width='302'
    height='225'
    viewBox='0 0 302 225'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M530.819 283.274C530.819 422.832 417.748 535.963 278.27 535.963C138.792 535.963 25.7215 422.832 25.7215 283.274C25.7215 143.717 138.792 30.5853 278.27 30.5853C417.748 30.5853 530.819 143.717 530.819 283.274Z'
      stroke={color}
      strokeWidth='3.78776'
    />
    <path
      d='M560.668 283.275C560.668 439.325 434.235 565.827 278.271 565.827C122.308 565.827 -4.12482 439.325 -4.12482 283.275C-4.12482 127.226 122.308 0.722835 278.271 0.722835C434.235 0.722835 560.668 127.226 560.668 283.275Z'
      stroke={color}
      strokeWidth='0.378776'
    />
    <path
      d='M451.786 283.276C451.786 379.158 374.101 456.886 278.272 456.886C182.443 456.886 104.758 379.158 104.758 283.276C104.758 187.393 182.443 109.665 278.272 109.665C374.101 109.665 451.786 187.393 451.786 283.276Z'
      stroke={color}
      strokeWidth='0.378776'
    />
    <path
      d='M387.819 283.505C387.819 343.914 338.875 392.885 278.5 392.885C218.125 392.885 169.181 343.914 169.181 283.505C169.181 223.095 218.125 174.125 278.5 174.125C338.875 174.125 387.819 223.095 387.819 283.505Z'
      stroke={color}
      strokeWidth='1.89388'
    />
  </svg>
);

export const FastaffBG: React.FC<{ color: string }> = ({ color }) => (
  <svg
    width='693'
    height='764'
    viewBox='0 0 693 764'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M657.974 382.245L658.066 382.134L657.974 382.024L338.825 0.17182H373.331L692.572 382.134L373.331 763.828H338.825L657.974 382.245Z'
      fill={color}
      stroke={color}
      strokeWidth='0.343639'
    />
    <path
      d='M174.816 764L494.201 382.134L174.816 0'
      stroke={color}
      strokeWidth='1.7182'
      strokeMiterlimit='10'
    />
    <path
      d='M19.2422 764L338.627 382.134L19.2422 0'
      stroke={color}
      strokeWidth='1.7182'
      strokeMiterlimit='10'
    />
    <path
      d='M-136 764L183.385 382.134L-136 0'
      stroke={color}
      strokeWidth='1.7182'
      strokeMiterlimit='10'
    />
  </svg>
);
