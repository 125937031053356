import { FontFamily } from '../../../types';
import { ThemedTypographyList } from '../../../components/Typography/typography.types';
import { combineTypographyStyles } from '../../../components/Typography/typography.utils';

export const USNursingDefaultFonts: FontFamily[] = [
  {
    name: 'Heebo',
    variants: [
      { file: 'Heebo-Bold.ttf', style: 'normal', weight: 700 },
      { file: 'Heebo-Light.ttf', style: 'normal', weight: 300 },
      { file: 'Heebo-Regular.ttf', style: 'normal', weight: 400 },
      { file: 'Heebo-Medium.ttf', style: 'normal', weight: 500 }
    ]
  }
];

const USNursingSpecificTheme: ThemedTypographyList = {
  h1: {
    fontFamily: 'Heebo',
    fontWeight: 700
  },
  h2: {
    fontFamily: 'Heebo',
    fontWeight: 700
  },
  h3: {
    fontFamily: 'Heebo',
    fontWeight: 700
  },
  h4: {
    fontFamily: 'Heebo',
    fontWeight: 700
  },
  h5: {
    fontFamily: 'Heebo',
    fontWeight: 700
  },
  h6: {
    fontFamily: 'Heebo',
    fontWeight: 700
  },
  'h1-alt': {
    fontFamily: 'Heebo',
    fontWeight: 300
  },
  'h2-alt': {
    fontFamily: 'Heebo',
    fontWeight: 300
  },
  'h3-alt': {
    fontFamily: 'Heebo',
    fontWeight: 300
  },
  'h4-alt': {
    fontFamily: 'Heebo',
    fontWeight: 300
  },
  'h5-alt': {
    fontFamily: 'Heebo',
    fontWeight: 300
  },
  'h6-alt': {
    fontFamily: 'Heebo',
    fontWeight: 300
  },
  'body-l': {
    fontFamily: 'Heebo',
    fontWeight: 400
  },
  'body-m': {
    fontFamily: 'Heebo',
    fontWeight: 400
  },
  'body-s': {
    fontFamily: 'Heebo',
    fontWeight: 400
  },
  'body-s-e': {
    fontFamily: 'Heebo',
    fontWeight: 400
  },
  'body-l-alt': {
    fontFamily: 'Heebo',
    fontWeight: 500
  },
  'body-m-alt': {
    fontFamily: 'Heebo',
    fontWeight: 500
  },
  'body-s-alt': {
    fontFamily: 'Heebo',
    fontWeight: 500
  },
  'body-s-alt-e': {
    fontFamily: 'Heebo',
    fontWeight: 500
  },
  'body-l-link': {
    fontFamily: 'Heebo',
    fontWeight: 400,
    textUnderline: true
  },
  'body-m-link': {
    fontFamily: 'Heebo',
    fontWeight: 400,
    textUnderline: true
  },
  'body-s-link': {
    fontFamily: 'Heebo',
    fontWeight: 400,
    textUnderline: true
  },
  'meta-l': {
    fontFamily: 'Heebo',
    fontWeight: 400
  },
  'meta-m': {
    fontFamily: 'Heebo',
    fontWeight: 400
  },
  'meta-s': {
    fontFamily: 'Heebo',
    fontWeight: 400
  },
  'meta-l-caps': {
    fontFamily: 'Heebo',
    fontWeight: 400,
    uppercase: true
  },
  'meta-m-caps': {
    fontFamily: 'Heebo',
    fontWeight: 400,
    uppercase: true
  },
  'meta-s-caps': {
    fontFamily: 'Heebo',
    fontWeight: 400,
    uppercase: true
  },
  'meta-l-alt': {
    fontFamily: 'Heebo',
    fontWeight: 500
  },
  'meta-m-alt': {
    fontFamily: 'Heebo',
    fontWeight: 500
  },
  'meta-s-alt': {
    fontFamily: 'Heebo',
    fontWeight: 500
  },
  'meta-l-caps-alt': {
    fontFamily: 'Heebo',
    fontWeight: 500,
    uppercase: true
  },
  'meta-m-caps-alt': {
    fontFamily: 'Heebo',
    fontWeight: 500,
    uppercase: true
  },
  'meta-s-caps-alt': {
    fontFamily: 'Heebo',
    fontWeight: 500,
    uppercase: true
  },
  'button-primary': {
    fontFamily: 'Heebo',
    fontWeight: 700
  },
  'button-secondary': {
    fontFamily: 'Heebo',
    fontWeight: 700
  },
  'button-tertiary': {
    fontFamily: 'Heebo',
    fontWeight: 700
  },
  'button-tertiary-icon': {
    fontFamily: 'Heebo',
    fontWeight: 400
  },
  'button-text-link': {
    fontFamily: 'Heebo',
    fontWeight: 400
  }
};

export const USNursingDefaultTypographyStyles = combineTypographyStyles(USNursingSpecificTheme);
