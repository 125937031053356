import * as React from 'react';
import styled from '@emotion/styled';
import { GraphicProps } from '../../../types';
import { GetGraphicDimensions } from '../../../mixins/icons';

const TEST_ID = 'iui-component-graphic--ingenovis';

const SVG: React.FC<GraphicProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 338 338'
    className={className}
    data-test={TEST_ID}
  >
    <path fill='#616D98' d='M266.813 108.994l9.413 4.038v133.533l-9.413 6.105' />
    <path fill='#333F67' d='M62.126 252.67h204.687V108.998H62.126V252.67z' />
    <path
      fill='#F5995C'
      d='M276.222 118.971a5.9 5.9 0 01-2.336-.482l-8.298-3.556H62.126a5.93 5.93 0 01-5.927-5.927 5.93 5.93 0 015.927-5.928h204.687c.807 0 1.606.164 2.347.482l9.413 4.039a5.926 5.926 0 01-2.351 11.372z'
    />
    <path fill='#495683' d='M123.718 260.506h73.604V77.24h-73.604v183.266z' />
    <path fill='#616D98' d='M197.318 260.423l9.413-7.425V83.168l-9.413-4.909' />
    <path
      fill='#F5995C'
      d='M140.188 113.886h12.447v-12.447h15.762v12.447h12.448v15.762h-12.448v12.447h-15.762v-12.447h-12.447v-15.762zM174.973 179.413h-28.91a2.612 2.612 0 00-2.611 2.612v.601a2.612 2.612 0 002.611 2.612h28.91a2.613 2.613 0 002.612-2.612v-.601a2.613 2.613 0 00-2.612-2.612zM174.973 163.505h-28.91a2.611 2.611 0 00-2.611 2.612v.6a2.611 2.611 0 002.611 2.612h28.91a2.612 2.612 0 002.612-2.612v-.6a2.612 2.612 0 00-2.612-2.612z'
    />
    <path fill='#fff' d='M81.026 156.254h32.331v-19.019h-32.33v19.019z' />
    <path
      fill='#9BA3C0'
      d='M113.357 158.229H81.026a1.977 1.977 0 01-1.976-1.975v-19.019a1.974 1.974 0 011.976-1.976h32.331a1.973 1.973 0 011.976 1.976v19.019a1.975 1.975 0 01-1.976 1.975zm-30.355-3.951h28.379v-15.067h-28.38v15.067z'
    />
    <path fill='#fff' d='M217.949 156.254h32.331v-19.019h-32.331v19.019z' />
    <path
      fill='#9BA3C0'
      d='M250.28 158.229h-32.327a1.978 1.978 0 01-1.976-1.975v-19.019a1.973 1.973 0 011.976-1.976h32.327a1.973 1.973 0 011.976 1.976v19.019a1.975 1.975 0 01-1.976 1.975zm-30.351-3.951h28.375v-15.067h-28.375v15.067z'
    />
    <path fill='#fff' d='M81.026 190.339h32.331v-19.018h-32.33v19.018z' />
    <path
      fill='#9BA3C0'
      d='M113.357 192.315H81.026a1.974 1.974 0 01-1.976-1.976v-19.014a1.974 1.974 0 011.976-1.976h32.331a1.973 1.973 0 011.976 1.976v19.014a1.973 1.973 0 01-1.976 1.976zm-30.355-3.952h28.379V173.3h-28.38v15.063z'
    />
    <path fill='#fff' d='M217.949 190.339h32.331v-19.018h-32.331v19.018z' />
    <path
      fill='#9BA3C0'
      d='M250.28 192.315h-32.327a1.975 1.975 0 01-1.976-1.976v-19.014a1.973 1.973 0 011.976-1.976h32.327a1.973 1.973 0 011.976 1.976v19.014a1.973 1.973 0 01-1.976 1.976zm-30.351-3.952h28.375V173.3h-28.375v15.063z'
    />
    <path fill='#fff' d='M81.026 224.429h32.331V205.41h-32.33v19.019z' />
    <path
      fill='#9BA3C0'
      d='M113.357 226.404H81.026a1.977 1.977 0 01-1.976-1.975V205.41a1.974 1.974 0 011.976-1.976h32.331a1.973 1.973 0 011.976 1.976v19.019a1.975 1.975 0 01-1.976 1.975zm-30.355-3.951h28.379v-15.067h-28.38v15.067z'
    />
    <path fill='#fff' d='M217.949 224.429h32.331V205.41h-32.331v19.019z' />
    <path
      fill='#9BA3C0'
      d='M250.28 226.404h-32.327a1.978 1.978 0 01-1.976-1.975V205.41a1.973 1.973 0 011.976-1.976h32.327a1.973 1.973 0 011.976 1.976v19.019a1.975 1.975 0 01-1.976 1.975zm-30.351-3.951h28.375v-15.067h-28.375v15.067z'
    />
    <path fill='#fff' d='M135.28 260.423h50.472v-44.276H135.28v44.276z' />
    <path fill='#9BA3C0' d='M162.494 216.146h-3.952v44.277h3.952v-44.277z' />
    <path
      fill='#9BA3C0'
      d='M187.728 260.51h-3.951v-42.388H137.26v42.313h-3.952v-44.257a1.973 1.973 0 011.976-1.976h50.468a1.973 1.973 0 011.976 1.976v44.332z'
    />
    <path
      fill='#F5995C'
      d='M206.932 87.893a5.881 5.881 0 01-2.331-.482l-8.298-3.556h-72.589a5.926 5.926 0 01-4.191-10.119A5.926 5.926 0 01123.714 72h73.81c.803 0 1.597.162 2.335.478l9.413 4.039a5.93 5.93 0 013.473 6.642 5.928 5.928 0 01-5.813 4.734z'
    />
    <path stroke='#495683' strokeMiterlimit={10} strokeWidth={2.772} d='M62.126 253.105h61.588' />
    <path
      stroke='#495683'
      strokeLinecap='round'
      strokeMiterlimit={10}
      strokeWidth={2.772}
      d='M123.714 260.51h73.604l9.413-7.512 60.082-.328 8.919-5.785'
    />
  </svg>
);

const SvgIngenovis = styled(SVG)`
  ${(props: GraphicProps) => GetGraphicDimensions(props)}
`;
SvgIngenovis.displayName = 'Ingenovis';
export default SvgIngenovis;
