import * as React from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import Box from '../../components/Box';
import Grid from '../../components/Grid';
import MobileAppLink from '../../components/MobileAppLink';
import Typography from '../../components/Typography';
import { safeProps } from '../../utils/safeProps';
import { FooterLink } from '../../types';
import sealApproval from './sealApproval';
import { FooterProps } from './footer.types';
import {
  Container,
  GridItem,
  FooterNav,
  MobileAppsContainer,
  SealContainer,
  ModifiedGridItem,
  StyledExternalLink
} from './footer.styles';
import { ContactBanner } from '../../components/ContactBanner';
import LegalBar from '../../components/LegalBar';
import SocialGroup from '../../components/SocialGroup';
import { LinkContext } from '../../providers/Linker';

interface calMaxLinksPerColumnProps {
  totalLinks: number;
  totalColumns: number;
  minLinksToFillCols: number;
  defaultMaxPerRow: number;
}
export const calMaxLinksPerColumn = ({
  totalLinks, // the total amount of links need to be filled into columns
  totalColumns, // the amount of columns that should have close to an even amount of links
  minLinksToFillCols, // min amount of links to try spread evenly before resorting to defaultMaxPerRow
  defaultMaxPerRow // if totalLinks < minLinksToFillCols use defaultMaxPerRow as value -- the lowest common amount
}: calMaxLinksPerColumnProps): number =>
  totalLinks > minLinksToFillCols ? Math.round(totalLinks / totalColumns) : defaultMaxPerRow;

export const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  const {
    company,
    privacyURL,
    termsURL,
    doNotSellPersonalInfoURL,
    seal,
    linkedinURL,
    facebookURL,
    twitterURL,
    instagramURL,
    appstoreURL,
    googleplayURL,
    contactNumber,
    contactHours,
    currentYear,
    sitemap = [],
    sealUrl,
    ...rest
  } = props;
  const theme = useTheme();

  const CustomLink = React.useContext(LinkContext);

  const { footer: footerOpts, iuiPalette } = theme;
  const {
    bg = iuiPalette.grays.grays[100],
    color = iuiPalette.primary[500],
    legals,
    social = 'primary', // changes the color of the social icons
    fontFamily,
    maxPerRow: defaultMaxPerRow = 3,
    phoneColor
  } = footerOpts;
  const { bg: legalsBg = iuiPalette.brand[4], color: legalsColor = iuiPalette.grays.white } =
    legals;

  const hasSeal = seal && seal.length;
  const hasMobileApp = appstoreURL || googleplayURL;
  const hasSocials = linkedinURL || facebookURL || twitterURL || instagramURL;

  const totalLinks = Array.isArray(sitemap) ? sitemap.length : 0; // should be derived from links in props...
  const totalColumns = 3;
  const minLinksToFillCols = 12;

  // basically we want to use grid-template-rows: repeat(DYNAMIC_NUMBER, 1fr); in css
  // if there's 3 columns, DYNAMIC_NUMBER should be total / 3 to make the items spread close to evenly across all cols
  const maxPerColumn = calMaxLinksPerColumn({
    totalLinks,
    totalColumns,
    minLinksToFillCols,
    defaultMaxPerRow
  });

  const SealBaseComponent = () => {
    if (hasSeal) {
      return (
        <SealContainer>
          <img
            alt='The Joint Commission Gold Seal of Approval logo'
            width='65'
            height='65'
            src={sealApproval}
            loading='lazy'
          />
          <Typography variant='meta-m' color={color}>
            {seal}
          </Typography>
        </SealContainer>
      );
    }
    return null;
  };

  const StyledSealCustomLink = styled(CustomLink)`
    text-decoration: none;
  `;

  const SealComponentWithUrl = () => {
    if (sealUrl && hasSeal) {
      return (
        <StyledSealCustomLink href={sealUrl} to={sealUrl}>
          <SealBaseComponent />
        </StyledSealCustomLink>
      );
    }
    return <SealBaseComponent />;
  };

  return (
    <Box as='footer' {...safeProps(rest)} background={bg} data-test='iui-module-footer'>
      <Box background={bg} padding='42px 0'>
        <Container color={color}>
          <Grid>
            <ModifiedGridItem>
              <FooterNav maxPerColumn={maxPerColumn} fontFamily={fontFamily}>
                {Array.isArray(sitemap) &&
                  sitemap.length &&
                  sitemap.map((page: FooterLink, index) => (
                    <li key={`sitemap-link-${index.toString()}`}>
                      {page.type === 'external' ? (
                        <StyledExternalLink href={page.link} target='_blank'>
                          {page.label}
                        </StyledExternalLink>
                      ) : (
                        <CustomLink href={page.link} to={page.link}>
                          {page.label}
                        </CustomLink>
                      )}
                    </li>
                  ))}
              </FooterNav>
              {contactHours && contactNumber && (
                <div style={{ marginBottom: '-21px' }}>
                  <ContactBanner
                    footerBanner
                    reversed
                    phoneNumber={contactNumber}
                    timings={contactHours}
                    phoneTextColor={phoneColor}
                    textColor={color}
                  />
                </div>
              )}
            </ModifiedGridItem>
            <GridItem>
              {hasSocials && (
                <SocialGroup
                  company={company}
                  variant={social}
                  facebookURL={facebookURL}
                  linkedinURL={linkedinURL}
                  twitterURL={twitterURL}
                  instagramURL={instagramURL}
                />
              )}
              {hasMobileApp && (
                <MobileAppsContainer>
                  <Typography variant='meta-m' color={color}>
                    Download our mobile app
                  </Typography>
                  <div>
                    {appstoreURL && (
                      <MobileAppLink store='apple' company={company} href={appstoreURL} />
                    )}
                    {googleplayURL && (
                      <MobileAppLink store='google' company={company} href={googleplayURL} />
                    )}
                  </div>
                </MobileAppsContainer>
              )}
              <SealComponentWithUrl />
            </GridItem>
          </Grid>
        </Container>
      </Box>
      <LegalBar
        companyName={company}
        termsUrl={termsURL}
        privacyUrl={privacyURL}
        doNotSellPersonalInfoUrl={doNotSellPersonalInfoURL}
        currentYear={currentYear}
      />
    </Box>
  );
};

export default Footer;
