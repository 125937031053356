import React from 'react';
import styled from '@emotion/styled';

import { MediaQuery } from '../../mixins/mediaQuery';
import { EmotionStyledProps } from '../../types';

import defaultBreakpoints from '../../themes/breakpoints';

/* Desktop - Tablet - Mobile respectively */
type GridColumnsInput = [number, number, number];

/* Desktop - Tablet - Mobile respectively */
type GridColumnStartsInput = [number | 'auto', number | 'auto', number | 'auto'];

export interface GridProps {
  children?: React.ReactNode;
  className?: string;
  rowGap?: string;
}
export interface GridColProps {
  columns: GridColumnsInput;
  columnStarts?: GridColumnStartsInput /* Optional start points for columns */;
  className?: string;
  children?: React.ReactNode;
  alignSelf?: string;
}

interface GridColWrapperProps {
  alignSelf?: string;
  columns: GridColumnsInput;
  columnStarts?: GridColumnStartsInput;
}

const Wrapper = styled.div<{ rowGap?: string }>`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  ${({ rowGap }) => (rowGap ? `row-gap: ${rowGap};` : '')}
  ${(props: EmotionStyledProps) =>
    MediaQuery(props.theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    grid-template-columns: repeat(4, 1fr);
  }
  ${(props: EmotionStyledProps) =>
    MediaQuery(props.theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    grid-template-columns: repeat(12, 1fr);
    column-gap: 24px;
  }
`;

const Grid: React.FC<GridProps> = ({ children, className, rowGap }) => (
  <Wrapper className={className} rowGap={rowGap} data-test='iui-component-grid'>
    {children}
  </Wrapper>
);

const GridColumnsWrapper = styled.div<GridColWrapperProps>`
  grid-column: ${(props: GridColWrapperProps) =>
    `${props.columnStarts ? props.columnStarts[2] : 'auto'} / span ${props.columns[2]} `};
  ${(props: GridColWrapperProps) => (props.alignSelf ? `align-self: ${props.alignSelf};` : '')}
  ${(props: EmotionStyledProps) =>
    MediaQuery(props.theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    grid-column: ${(props: GridColWrapperProps) =>
      `${props.columnStarts ? props.columnStarts[1] : 'auto'} / span ${props.columns[1]} `};
  }
  ${(props: EmotionStyledProps) =>
    MediaQuery(props.theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    grid-column: ${(props: GridColWrapperProps) =>
      `${props.columnStarts ? props.columnStarts[0] : 'auto'} / span ${props.columns[0]} `};
  }
`;

export const GridColumns: React.FC<GridColProps> = ({
  columns,
  columnStarts,
  children,
  alignSelf,
  className
}) => (
  <GridColumnsWrapper
    alignSelf={alignSelf}
    columns={columns}
    className={className}
    columnStarts={columnStarts}
  >
    {children}
  </GridColumnsWrapper>
);

export default Grid;
