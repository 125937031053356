import * as React from 'react';
import Typography from '../Typography';

import { StyledHeadingWrapper } from './sectionTitle.styles';
import { safeProps } from '../../utils/safeProps';
import { toSentenceCase, childrenToDeepCase } from '../../utils/typography-helpers';
import { SectionTitleFixedColor, SectionTitleVariants } from './sectionTitle.types';

export interface SectionTitleProps {
  level: 2 | 3 | 4 | '2' | '3' | '4';
  as?: React.ElementType;
  color?: string;
  lineColor?: `${SectionTitleFixedColor}`;
  variant?: `${SectionTitleVariants}`;
  margin?: string;
  truncate?: boolean;
  children?: React.ReactNode | string;
  forcedSentenceCase?: boolean;
  dividerWidth?: string;
  dividerHeight?: string;
  dividerMargin?: string;
}

export const SectionTitle: React.FC<SectionTitleProps> = (props: SectionTitleProps) => {
  const {
    level = 2,
    children,
    variant = SectionTitleVariants.PRIMARY,
    lineColor = SectionTitleFixedColor.DEFAULT,
    color,
    as: ele,
    forcedSentenceCase = false, // not forcing sentence case by default, case is as passed in
    ...rest
  } = props;
  const textColor = color ?? 'inherit';

  return (
    <StyledHeadingWrapper
      {...safeProps(rest)}
      variant={variant}
      lineColor={lineColor}
      data-test='iui-component-section-title'
    >
      <Typography variant={`h${level}`} color={textColor} as={ele}>
        {forcedSentenceCase ? childrenToDeepCase(children, toSentenceCase) : children}
      </Typography>
    </StyledHeadingWrapper>
  );
};

export default SectionTitle;
