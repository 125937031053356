import * as React from 'react';
import MuiDialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import styled from '@emotion/styled';
import shadow from '../../mixins/shadow';
import Box from '../Box';

export interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  headerContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  scroll?: 'all' | 'body';
  ariaLabelledby?: string;
  ariaDescribedby?: string;
  maxWidth?: string;
  maxHeight?: string;
  wrapChildren?: boolean;
  showShadow?: boolean;
}

const StyledMuiDialog = styled(MuiDialog)<{ $maxWidth?: string; $maxHeight?: string }>`
  & .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.6);
  }
  & .MuiDialog-paper {
    background-color: ${({ theme }) => theme.modal.backgroundColor ?? '#f9f9f9'};
    border-radius: 8px;
    ${shadow({ level: 'heavy' })}
    max-width: ${({ $maxWidth }) => $maxWidth || '540px'};
    max-height: ${({ $maxHeight }) => $maxHeight || '482px'};
  }
`;

const ShadowWrapper = styled(Box)<{ showShadow?: boolean }>`
  background: ${({ showShadow }) =>
    showShadow
      ? 'linear-gradient(0deg, #ffffff 65%, rgba(255, 255, 255, 0.8) 85%, rgba(255, 255, 255, 0))'
      : 'inherit'};
`;

const Dialog: React.FC<DialogProps> = ({
  isOpen,
  scroll,
  onClose,
  ariaDescribedby,
  ariaLabelledby,
  children,
  headerContent,
  footerContent,
  maxWidth,
  maxHeight,
  showShadow = false,
  wrapChildren = true
}) => (
  <StyledMuiDialog
    open={isOpen}
    onClose={onClose}
    scroll={scroll === 'all' ? 'body' : 'paper'}
    aria-labelledby={ariaLabelledby}
    aria-describedby={ariaDescribedby}
    fullWidth
    data-test='iui-component-dialog'
    $maxWidth={maxWidth}
    $maxHeight={maxHeight}
  >
    {headerContent && (
      <Box width='100%' padding='32px 24px 0px'>
        {headerContent}
      </Box>
    )}
    {wrapChildren ? <DialogContent>{children}</DialogContent> : children}

    {footerContent && (
      <ShadowWrapper
        showShadow={showShadow}
        width='100%'
        padding='0 24px 32px'
        position='relative'
        margin='-36px 0 0'
      >
        {footerContent}
      </ShadowWrapper>
    )}
  </StyledMuiDialog>
);

export default Dialog;
