import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { EmotionStyledProps } from '../../types';
import { Button } from '../Button';
import Typography from '../Typography';
import { ZIndexLevel } from '../../constants';

export interface SnackbarProps {
  notification: string;
  actionText?: string;
  show?: boolean;
  onClose: () => void;
  autoClose?: boolean;
  position?: string;
}

const SnackbarCSS = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 40px;
`;

export interface WrapperProps extends Partial<EmotionStyledProps> {
  $position?: string;
}

const Wrapper = styled.div<WrapperProps>`
  background: ${(props: EmotionStyledProps) => props.theme.snackbar.default};
  color: white;
  position: ${({ $position }: WrapperProps) => $position || 'absolute'};
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${ZIndexLevel.ABOVE_PAGE};
  box-sizing: border-box;
  ${SnackbarCSS}
`;

const Snackbar: React.FC<SnackbarProps> = ({
  show,
  onClose,
  notification,
  actionText = 'Got it',
  autoClose = true,
  position
}) => {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  useEffect(() => {
    if (autoClose && show) {
      timer.current = setTimeout(onClose, 4000);
    }
    /* eslint-disable no-unused-expressions */
    () => timer.current && clearTimeout(timer.current);
  }, [show, onClose, autoClose, timer]);
  if (!show) {
    return null;
  }
  return (
    <Wrapper $position={position} data-test='iui-component-snackbar'>
      <Typography variant='h5' color='white'>
        {notification}
      </Typography>
      <Button onClick={onClose} label={actionText} colorway='light' />
    </Wrapper>
  );
};

export default Snackbar;
