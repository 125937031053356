import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--checklist-fastaff';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 128 128'
    className={className}
    data-test={TEST_ID}
  >
    <circle cx={64} cy={64} r={64} fill='#FCEFCA' />
    <g clipPath='url(#clip0_390:7209)'>
      <path
        fill='#333F67'
        d='M84.4 57.503h3.246a2.026 2.026 0 002.022-2.022V37.184a2.026 2.026 0 00-2.022-2.022h-3.247a2.026 2.026 0 00-2.022 2.022V55.48c0 1.128.908 2.022 2.022 2.022z'
      />
      <path fill='url(#pattern0)' d='M80.644 33.758h6.466v25.726h-6.466z' />
      <path
        fill='#333F67'
        d='M83.684 57.503h.426c.716 0 1.307-.578 1.307-1.307V36.47c0-.716-.578-1.307-1.307-1.307h-.426c-.715 0-1.307.577-1.307 1.306v19.728c0 .73.578 1.307 1.307 1.307z'
      />
      <path
        fill='#333F67'
        d='M84.014 93.12h-44.34a1.41 1.41 0 01-1.402-1.403V32.795c0-.77.632-1.403 1.403-1.403h44.339c.77 0 1.403.633 1.403 1.403v58.908c0 .784-.633 1.417-1.403 1.417z'
      />
      <path fill='url(#pattern1)' d='M37.034 31.282h49.801v62.595H37.034z' />
      <path fill='#E5E6EB' d='M82.529 35.175H41.148v54.176h41.38V35.175z' />
      <path fill='#F6F6F6' d='M80.74 90.616l-41.285-2.669 3.48-54.052 41.3 2.656-3.495 54.065z' />
      <path fill='#fff' d='M79.556 91.497l-41.12-4.65 6.095-53.832 41.12 4.65-6.094 53.832z' />
      <path fill='#F5995C' d='M51.12 49.551a3.384 3.384 0 100-6.768 3.384 3.384 0 000 6.768z' />
      <path
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        strokeWidth={0.55}
        d='M49.677 46.016l.811 1.004 2.064-1.65'
      />
      <path
        stroke='#758BD2'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        strokeWidth={1.376}
        d='M57.422 44.819l13.77 1.554'
      />
      <path
        stroke='#758BD2'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        strokeWidth={0.738}
        d='M56.83 47.598l21.654 2.449M56.555 50.005l21.653 2.45'
      />
      <path fill='#F5995C' d='M49.608 62.923a3.384 3.384 0 100-6.768 3.384 3.384 0 000 6.768z' />
      <path
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        strokeWidth={0.55}
        d='M48.163 59.401l.812 1.005 2.063-1.651'
      />
      <path
        stroke='#758BD2'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        strokeWidth={1.376}
        d='M55.908 58.19l13.771 1.57'
      />
      <path
        stroke='#758BD2'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        strokeWidth={0.738}
        d='M55.316 60.983l21.654 2.45M55.042 63.391l21.654 2.449'
      />
      <path
        fill='#F5995C'
        d='M48.094 70.077a2.849 2.849 0 012.655 3.852 2.849 2.849 0 01-2.641 1.816 2.849 2.849 0 01-2.655-3.852 2.855 2.855 0 012.641-1.816zm0-.55a3.386 3.386 0 00-1.21 6.548 3.393 3.393 0 004.375-1.953 3.386 3.386 0 00-1.954-4.375 3.753 3.753 0 00-1.21-.22z'
        opacity={0.28}
      />
      <path
        stroke='#758BD2'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        strokeWidth={1.376}
        d='M54.395 71.577l13.771 1.554'
      />
      <path
        stroke='#758BD2'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        strokeWidth={0.738}
        d='M53.804 74.356l21.653 2.448M53.528 76.763l21.654 2.449'
      />
    </g>
    <defs>
      <pattern id='pattern0' width={1} height={1} patternContentUnits='objectBoundingBox'>
        <use xlinkHref='#image0_390:7209' />
      </pattern>
      <pattern id='pattern1' width={1} height={1} patternContentUnits='objectBoundingBox'>
        <use xlinkHref='#image1_390:7209' />
      </pattern>
      <image
        id='image0_390:7209'
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAC7CAYAAAD10Mf5AAAACXBIWXMAAAsSAAALEgHS3X78AAAA'
      />
      <image
        id='image1_390:7209'
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAWoAAAHHCAYAAACWWhLTAAAACXBIWXMAAAsSAAALEgHS3X78AAAA'
      />
      <clipPath id='clip0_390:7209'>
        <path fill='#fff' d='M0 0h52.706v62.973H0z' transform='translate(36.963 31.145)' />
      </clipPath>
    </defs>
  </svg>
);

const SvgChecklistFastaff = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgChecklistFastaff.displayName = 'ChecklistFastaff';
export default SvgChecklistFastaff;
