import { ThemeProvider as IuiThemeProvider } from '@emotion/react';
import { BaseTheme, CompanyCode, IthemeOptions } from '../types';
import { CardioTheme } from './theme-cardio';
import { FastaffTheme } from './theme-fastaff';
import { TrustaffTheme } from './theme-trustaff';
import { USNursingTheme } from './theme-usnursing';
import DefaultBreakpoints from './breakpoints';

const DEFAULT_THEME = CompanyCode.FASTAFF;

const GetTheme = (brand: string): BaseTheme => {
  switch (brand) {
    case CompanyCode.USNURSING:
      return USNursingTheme;
    case CompanyCode.TRUSTAFF:
      return TrustaffTheme;
    case CompanyCode.CARDIO:
      return CardioTheme;
    default:
      return FastaffTheme;
  }
};

const CreateTheme = (themeOptions: IthemeOptions): BaseTheme => {
  const { brand, fontDirectory } = themeOptions;
  const baseTheme = GetTheme(brand);

  // if we ever need to modify the base theme we can do that here

  // remove mui from what we show
  const constructedTheme: BaseTheme = {
    ...baseTheme,
    iuiTypography: {
      ...baseTheme.iuiTypography,
      ...(fontDirectory && { baseDirectory: themeOptions.fontDirectory })
    }
  };

  return constructedTheme;
};

export {
  DEFAULT_THEME,
  CreateTheme,
  GetTheme,
  FastaffTheme,
  TrustaffTheme,
  USNursingTheme,
  DefaultBreakpoints,
  IuiThemeProvider
};
