import { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import useBreakpoints from '../useBreakpoints';
import { BreakpointSizes } from '../../types/theme';

const useIsBreakpoint = (): { isMobile: boolean; isTablet: boolean; isDesktop: boolean } => {
  const theme = useTheme();
  const breakpoint = useBreakpoints(theme.iuiBreakpoints);
  const isMobile = useMemo(() => breakpoint === BreakpointSizes.SMALL, [breakpoint]);
  const isTablet = useMemo(() => breakpoint === BreakpointSizes.MEDIUM, [breakpoint]);
  const isDesktop = useMemo(
    () =>
      breakpoint === BreakpointSizes.LARGE ||
      breakpoint === BreakpointSizes.XLARGE ||
      breakpoint === BreakpointSizes.XXLARGE,
    [breakpoint]
  );
  return { isMobile, isTablet, isDesktop };
};

export default useIsBreakpoint;
