import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--social-facebook-small';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#0F0027'
      d='M16 8.828h-2.725V6.96c0-.701.445-.865.758-.865h1.923V3.011L13.308 3C10.368 3 9.7 5.3 9.7 6.773v2.055H8v3.179h1.7V21h3.575v-8.993h2.413L16 8.828z'
    />
  </svg>
);

const SvgSocialFacebookSmall = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgSocialFacebookSmall.displayName = 'SocialFacebookSmall';
export default SvgSocialFacebookSmall;
