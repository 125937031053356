import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--profile';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#1D243D'
      fillRule='evenodd'
      d='M4.464 15.464A5 5 0 018 14h8a5 5 0 015 5v2a1 1 0 11-2 0v-2a3 3 0 00-3-3H8a3 3 0 00-3 3v2a1 1 0 11-2 0v-2a5 5 0 011.464-3.536zM12 4a3 3 0 100 6 3 3 0 000-6zM7 7a5 5 0 1110 0A5 5 0 017 7z'
      clipRule='evenodd'
    />
  </svg>
);

const SvgProfile = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgProfile.displayName = 'Profile';
export default SvgProfile;
