import React from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Drawer } from '@mui/material';

import Box from '../Box';
import SideMenu from '../SideMenu';
import UserMenu from '../UserMenu';
import { NavSection } from '../../types';
import { SideMenuProps } from '../SideMenu/side-menu.types';
import IconButton from '../IconButton';
import { CancelIcon } from '../Icons';

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    left: unset;
    width: 295px;
  }
`;

const Wrapper = styled(Box)`
  ${({ theme }) => `
    color: ${theme?.iuiPalette?.primary[700] ?? '#1D243D'};
    background-color: ${theme?.iuiPalette?.grays?.grays[100] ?? '#FCFCFC'};
  `}
`;

interface MobileUserMenuProps extends SideMenuProps {
  userSitemap: NavSection[];

  activeRoute: string;
  username: string | null;
  userTitle: string;
  userDepartment: string;
  isOpen: boolean;
  onClose: () => void;
}

const MobileUserMenu: React.FC<MobileUserMenuProps> = ({
  userSitemap,
  sitemap,
  activeRoute,
  username,
  userTitle: title,
  userDepartment: department,
  isOpen,
  onClose,

  recruiterName,
  recruiterPhone,
  recruiterEmail,
  companyPhone,
  companyEmail,
  opHours
}) => {
  const theme = useTheme();

  const hasSecondaryNav = sitemap && Array.isArray(sitemap);
  return (
    <StyledDrawer anchor='right' open={isOpen} onClose={onClose}>
      <Wrapper padding='1.5rem 0' height='100%' data-test='iui-component-mobile-user-menu'>
        <IconButton
          aria-label='cancel'
          icon={CancelIcon}
          size={24}
          onClick={onClose}
          margin='0 0 0 .75rem'
        />
        <UserMenu
          username={username ?? ''}
          title={title}
          department={department}
          sitemap={userSitemap}
          activeRoute={activeRoute}
          nonDropdownMode
          onClose={onClose}
        />

        {hasSecondaryNav && (
          <>
            <Box margin='1rem' borderBottom={`1px solid ${theme?.navMenu?.border ?? '#E5E6EB'}`} />
            <SideMenu
              activeRoute={activeRoute}
              sitemap={sitemap}
              recruiterName={recruiterName}
              recruiterPhone={recruiterPhone}
              recruiterEmail={recruiterEmail}
              companyPhone={companyPhone}
              companyEmail={companyEmail}
              opHours={opHours}
              onLinkClick={onClose}
            />
          </>
        )}
      </Wrapper>
    </StyledDrawer>
  );
};

export default MobileUserMenu;
