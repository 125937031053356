/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import React, { useMemo, useState } from 'react';
import { useIsBreakpoint } from '../../hooks';
import { MultiselectOption } from '../../types';
import Box from '../Box';
import Checkbox from '../Checkbox';
import { CancelIcon, SearchIcon } from '../Icons';
import TextInput from '../TextInput';
import Typography from '../Typography';

const Divider = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.select.divider};
  margin: 8px 24px;
`;

const InputMenuItem = styled(MenuItem)`
  min-height: auto;
  padding: 0 24px;
  &.Mui-focusVisible,
  &:hover {
    background-color: transparent;
  }
`;

const StyledMenuItem = styled(MenuItem)<{ disabled?: boolean }>`
  min-height: auto;
  padding: 0;
  &:hover {
    background: ${({ theme, disabled }) =>
      disabled ? 'none' : theme.select.menuList.activeBackground};
  }
`;

const ScrollableArea = styled.div<{ $allowSearch?: boolean; $isCompact?: boolean }>`
  padding: ${({ $allowSearch }) => ($allowSearch ? '32px 0' : '0 0 32px 0')};
  max-height: ${({ $allowSearch, $isCompact }) =>
    $isCompact ? ($allowSearch ? '222px' : '260px') : $allowSearch ? '256px' : '304px'};
  // design wants scrollbar to always be present
  overflow-y: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const StyledTextInput = styled(TextInput)`
  &:hover {
    .search-icon {
      path {
        fill: ${({ theme }) => theme.iuiPalette.primaryAlt[200]};
      }
    }
  }
`;

interface MultiselectCoreProps {
  label: string;
  items: MultiselectOption[];
  selectedValues: string[];
  allowSearch?: boolean;
  showSelectAll?: boolean;
  isCompact?: boolean;
  onChange: (items: string[]) => void;
}

const MultiselectCore: React.FC<MultiselectCoreProps> = ({
  label,
  items,
  selectedValues,
  onChange,
  allowSearch,
  showSelectAll = true,
  isCompact
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const onAllClick = () => {
    onChange(selectedValues.length < items.length ? items.map((item) => item.value) : []);
  };

  const onItemClick = (val: string) => {
    const updated = selectedValues.includes(val)
      ? selectedValues.filter((item) => item !== val)
      : [...selectedValues, val];
    onChange(updated);
  };
  const searchWords = useMemo(
    () =>
      searchTerm
        ? searchTerm
            .trim()
            .split(' ')
            .map((word) => `(${word})`)
            .join('|')
        : '',
    [searchTerm]
  );

  const filteredItems = searchTerm
    ? items.filter((item) => item.label.toLowerCase().match(new RegExp(searchWords, 'g')))
    : items;

  const { isMobile } = useIsBreakpoint();
  const noResultsTextVariant = isMobile ? 'body-l' : 'body-m';

  return (
    <div data-test='iui-component-multi-select-core'>
      {allowSearch && (
        <InputMenuItem onKeyDown={(e) => e.stopPropagation()} disableRipple>
          <StyledTextInput
            label={`Search ${label}`}
            onChange={(e) => {
              setSearchTerm(e.target.value.toLowerCase());
            }}
            value={searchTerm}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {!searchTerm ? (
                    <Box margin='0 0 6px 0'>
                      <SearchIcon className='search-icon' dimensions={24} />
                    </Box>
                  ) : (
                    <Box margin='0 0 6px 0' onClick={() => setSearchTerm('')} cursor='pointer'>
                      <CancelIcon dimensions={24} />
                    </Box>
                  )}
                </InputAdornment>
              )
            }}
          />
        </InputMenuItem>
      )}
      {filteredItems.length === 0 && !!searchTerm ? (
        <Box padding='0 24px 32px'>
          <Typography variant={noResultsTextVariant}>No results found:</Typography>
          <br />
          <Typography variant={noResultsTextVariant}>
            We couldn’t find a match for{' '}
            <Typography as='span' variant={`${noResultsTextVariant}-alt`}>
              “{`${searchTerm}`}”
            </Typography>
            . Please try another search.
          </Typography>
        </Box>
      ) : (
        <div>
          <ScrollableArea $allowSearch={allowSearch} $isCompact={isCompact}>
            {!searchTerm && showSelectAll && (
              <div>
                <StyledMenuItem onClick={onAllClick} disableRipple>
                  <Checkbox
                    padding='8px 24px'
                    checked={selectedValues.length === items.length && selectedValues.length !== 0}
                    label='All'
                  />
                </StyledMenuItem>
                <Divider />
              </div>
            )}
            {filteredItems.map((item) => (
              <StyledMenuItem
                key={item.value}
                onClick={() => onItemClick(item.value)}
                disableRipple
              >
                <Checkbox
                  padding='8px 24px'
                  checked={selectedValues.includes(item.value)}
                  label={item.label}
                />
              </StyledMenuItem>
            ))}
          </ScrollableArea>
        </div>
      )}
    </div>
  );
};

MultiselectCore.defaultProps = {
  allowSearch: undefined,
  showSelectAll: true
};

export default MultiselectCore;
