import * as React from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { useIsBreakpoint } from '../../hooks';
import Typography from '../Typography';
import Box from '../Box';
import { PhoneIcon } from '../Icons';
import { pxToRem } from '../../mixins';

export interface ContactBannerProps {
  phoneNumber: string;
  timings: string;
  reversed?: boolean;

  footerBanner?: boolean;
  phoneTextColor?: string;
  textColor?: string;
}

const StyledPhoneIcon = styled(PhoneIcon)`
  flex-shrink: 0;
`;

export const ContactBanner: React.FC<ContactBannerProps> = ({
  phoneNumber,
  timings,
  reversed = false,
  footerBanner = false,
  phoneTextColor,
  textColor
}) => {
  const theme = useTheme();
  const { isMobile } = useIsBreakpoint();
  const phoneColor = phoneTextColor || theme?.iuiPalette?.grays.white;

  const timeColor = textColor || theme?.iuiPalette?.grays.white;
  const justifyContent = reversed || isMobile ? 'flex-start' : 'center';
  const padding = footerBanner ? '21px 8px 21px 0' : '8px';
  let timingsTextAlign = reversed ? 'right' : 'center';
  if (footerBanner) {
    timingsTextAlign = 'left';
  }
  return (
    <Box
      width='100%'
      background={!reversed ? theme?.contactBanner.bg ?? 'navy' : 'transparent'}
      display='flex'
      justifyContent={justifyContent}
      padding={padding}
      flexDirection={isMobile ? 'column' : 'row'}
      margin={footerBanner && isMobile ? '-3px' : '0'}
      alignItems='center'
      data-test='iui-component-contact-banner'
    >
      {isMobile ? (
        <>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent={reversed ? 'flex-start' : 'center'}
            margin='0 0 4px 0'
            alignItems='center'
            width='100%'
          >
            {footerBanner && (
              <span style={{ marginBottom: isMobile ? '-11px' : '0' }}>
                <StyledPhoneIcon
                  dimensions={24}
                  customColor={phoneColor}
                  iconType={!reversed ? 'inverted' : 'secondary-500'}
                />
              </span>
            )}
            {!footerBanner && (
              <StyledPhoneIcon
                customColor={phoneColor}
                iconType={!reversed ? 'inverted' : 'secondary-500'}
                dimensions={24}
              />
            )}

            <Typography
              as='span'
              variant='meta-l-alt'
              color={phoneColor}
              customLineHeight={pxToRem(14) as number}
              margin='0 0 0 8px'
              whiteSpace='nowrap'
              overflow='hidden'
              textOverflow='ellipsis'
            >
              {phoneNumber}
            </Typography>
          </Box>
          <Typography
            as='span'
            width='100%'
            variant='meta-l'
            color={timeColor}
            whiteSpace='nowrap'
            overflow='hidden'
            textOverflow='ellipsis'
            textAlign={timingsTextAlign}
            padding={footerBanner && isMobile ? '0 0 0 34px' : '0'}
          >
            {timings}
          </Typography>
        </>
      ) : (
        <>
          <StyledPhoneIcon
            customColor={phoneColor}
            iconType={!reversed ? 'inverted' : 'secondary-500'}
            dimensions={24}
          />
          <Typography
            as='span'
            variant='meta-l-alt'
            color={phoneColor}
            margin='0 0 0 8px'
            customLineHeight={pxToRem(14) as number}
            minWidth='max-content'
          >
            {phoneNumber}
          </Typography>
          <Typography as='span' variant='meta-l' color={timeColor} margin='0 6px'>
            |
          </Typography>
          <Typography
            as='span'
            variant='meta-l'
            color={timeColor}
            customLineHeight={pxToRem(14) as number}
            minWidth='max-content'
          >
            {timings}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default ContactBanner;
