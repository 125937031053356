import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--cancel';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#0F0027'
      fillRule='evenodd'
      d='M6.91 5.637A.9.9 0 005.638 6.91l5.173 5.173-5.004 5.005a.9.9 0 001.274 1.274l5.004-5.005 4.836 4.836a.9.9 0 001.274-1.274l-4.836-4.836 5.005-5.004a.901.901 0 00-1.274-1.274l-5.005 5.004-5.173-5.173z'
      clipRule='evenodd'
    />
  </svg>
);

const SvgCancel = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgCancel.displayName = 'Cancel';
export default SvgCancel;
