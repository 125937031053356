import styled from '@emotion/styled';
import * as React from 'react';
import { LinkContext } from '../../providers/Linker';
import { safeProps } from '../../utils/safeProps';
import { linkStyles, StyledIconContainer, StyledWrapper } from './link.styles';

export { linkColorCss } from './link.styles';

export interface LinkProps {
  label: React.ReactNode;
  href: string;
  icon?: JSX.Element;
  iconToRight?: boolean;
  margin?: string;
  onClick?: () => void;
  'aria-describedby'?: string;
  disabled?: boolean;
  padding?: string;
  inline?: boolean;
  as?: React.ElementType;
  variant?: 'base' | 'alt';
  className?: string;
}

export const Link: React.FC<LinkProps> = (props) => {
  const {
    label,
    href,
    icon,
    iconToRight,
    margin,
    onClick,
    disabled,
    padding,
    inline = false,
    as,
    variant,
    className,
    ...rest
  } = props;
  const Icon = icon;
  const CustomLink = React.useContext(LinkContext);
  const StyledLink = styled(CustomLink)`
    ${({ theme }) => linkStyles({ ...props, theme, hasIcon: !!Icon })}
  `;
  const StyledSpan = styled.span`
    ${({ theme }) => linkStyles({ ...props, theme, hasIcon: !!Icon })}
  `;

  const content = (
    <StyledWrapper disabled={disabled}>
      {Icon && !iconToRight && (
        <StyledIconContainer iconToRight={iconToRight}>{Icon}</StyledIconContainer>
      )}
      {label}
      {Icon && iconToRight && (
        <StyledIconContainer iconToRight={iconToRight}>{Icon}</StyledIconContainer>
      )}
    </StyledWrapper>
  );

  return disabled ? (
    <StyledSpan className='is-disabled' data-test='iui-component-link' {...safeProps(rest)}>
      {content}
    </StyledSpan>
  ) : (
    <StyledLink
      as={as}
      className={className}
      data-test='iui-component-link'
      to={href}
      href={href}
      onClick={onClick}
      {...safeProps(rest)}
    >
      {content}
    </StyledLink>
  );
};

export default Link;
