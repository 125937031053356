import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--rotation-shift-trustaff';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#FFB05A'
      d='M5.224 4.12L4.076 5.302l1.484 1.51 1.148-1.181L5.224 4.12zM4.442 10.333H2V12h2.442v-1.667zM11.768 2H10.14v2.5h1.628V2zM16.768 4.143L15.256 5.57 13.744 7.12c-.697-.595-1.627-.952-2.79-.952-2.675 0-4.884 2.262-4.884 5 0 1.071.349 2.143.93 2.857L4.093 17l1.163 1.19 2.907-2.976 1.163-1.19L13.744 9.5l1.163-1.19 1.512-1.548 1.511-1.548-1.162-1.071z'
    />
    <path
      fill='#A174C1'
      d='M16.767 11.167c-2.907 0-5.232 2.38-5.232 5.357 0 2.976 2.325 5.357 5.232 5.357S22 19.501 22 16.524c0-.238 0-.595-.116-.833-.582.833-1.512 1.31-2.559 1.31-1.744 0-3.14-1.43-3.14-3.215 0-1.072.466-2.024 1.28-2.62h-.698z'
    />
  </svg>
);

const SvgRotationShiftTrustaff = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgRotationShiftTrustaff.displayName = 'RotationShiftTrustaff';
export default SvgRotationShiftTrustaff;
