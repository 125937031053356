/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */

import React, { useState } from 'react';
import styled from '@emotion/styled';
import { MultiselectOption } from '../../types';
import { useMultiselectItems } from '../../hooks';
import MultiselectCore from '../MultiselectCore';
import Typography from '../Typography';
import Button from '../Button';
import Box from '../Box';
import Drawer from '../Drawer';
import { CancelIcon } from '../Icons';
import Dialog from '../Dialog';
import TextButton from '../TextButton';

interface MultiselectDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  label: string;
  items: MultiselectOption[];
  value: string[];
  onChange: (items: string[]) => void;
  allowSearch?: boolean;
  isDrawer?: boolean;
}

const StyledTextButton = styled(TextButton)`
  flex-shrink: 0;
  margin-right: 32px;
`;

const ActionsWrapper = styled.div<{ $isDrawer?: boolean }>`
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -48px;
  padding: 48px 24px 32px;
  background: linear-gradient(
    0deg,
    #ffffff 65%,
    rgba(255, 255, 255, 0.8) 85%,
    rgba(255, 255, 255, 0)
  );
  ${({ $isDrawer }) =>
    $isDrawer
      ? ''
      : `border-bottom-right-radius: 8px;
         border-bottom-left-radius: 8px;
         `}
`;

const StyledButton = styled(Button)`
  flex-grow: 1;
  max-width: 207px;
`;

const MultiselectDrawer: React.FC<MultiselectDrawerProps> = ({
  isOpen,
  onClose,
  label,
  items,
  value,
  onChange,
  allowSearch,
  isDrawer
}) => {
  const [tempValue, setTempValue] = useState(value);
  const { allSelected, noneSelected } = useMultiselectItems(items, tempValue);

  const getLabel = () => {
    const text = noneSelected
      ? `${label}`
      : allSelected
      ? `${label} (All)`
      : `${label} (${tempValue.length})`;
    return text;
  };

  const onDoneClick = () => {
    onChange(tempValue);
    onClose();
  };

  const onCancel = () => {
    setTempValue(value);
    onClose();
  };

  const getContent = () => (
    <>
      <Box padding='32px 24px' display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='body-m-alt'>{getLabel()}</Typography>
        <Box onClick={onCancel} cursor='pointer'>
          <CancelIcon dimensions={24} />
        </Box>
      </Box>
      <MultiselectCore
        label={label}
        items={items}
        selectedValues={tempValue}
        onChange={setTempValue}
        allowSearch={allowSearch}
        isCompact
      />
      <ActionsWrapper $isDrawer={isDrawer}>
        <StyledTextButton text='Clear filters' onClick={() => setTempValue([])} />
        <StyledButton
          variant='secondary'
          label='Done'
          onClick={onDoneClick}
          disabled={tempValue.length === 0}
        />
      </ActionsWrapper>
    </>
  );

  const testid = 'iui-component-multi-select-modal';
  return isDrawer ? (
    <Drawer isOpen={isOpen} onClose={onCancel} data-test={testid}>
      {getContent()}
    </Drawer>
  ) : (
    <Dialog
      isOpen={isOpen}
      onClose={onCancel}
      data-test={testid}
      maxWidth='375px'
      maxHeight='511px'
      wrapChildren={false}
    >
      {getContent()}
    </Dialog>
  );
};

export default MultiselectDrawer;
