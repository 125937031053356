import React from 'react';
import { Theme, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Menu from '@mui/material/Menu';
import Box from '../Box';
import Typography from '../Typography';
import { ShadowMixin } from '../../mixins';
import useIsBreakpoint from '../../hooks/useIsBreakpoint';
import { SecondarySitemap } from '../SideMenu/side-menu.types';
import { getBadgeTypeFromStatus } from '../SideMenu';
import Badge from '../Badge';
import { BadgeTypes } from '../Badge/badge.types';
import { LinkContext } from '../../providers/Linker';

interface UserMenuProps {
  username: string;
  title: string;
  department: string;
  sitemap: SecondarySitemap[];
  activeRoute: string;

  isOpen?: boolean;
  anchorElement?: null | HTMLElement;
  onClose?: () => void;
  nonDropdownMode?: boolean;
}

const StyledMenu = styled(Menu)`
  & .MuiMenu-list {
    width: 100%;
  }
  & .MuiPaper-root.MuiMenu-paper {
    ${ShadowMixin({ level: 'super' })}
    background: ${({ theme }) => theme.iuiPalette.grays.grays[100]};
    width: 196px;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  list-style: none;
`;

const StyledULMenu = styled.ul`
  list-style: none;
`;

const StyledMenuItem = styled.li<{ $isActive?: boolean }>`
  position: relative;
  .tier-1-badge-wrap {
    position: absolute;
    right: 5px;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    width: 20px;
    pointer-events: none;
  }

  a {
    padding: 0 1rem;
    display: block;
    text-decoration: none;
    width: 100%;
    cursor: pointer;
  }
  p {
    white-space: nowrap;
  }
  &:hover {
    background: ${({ theme }) => theme.iuiPalette.grays.grays[200]};
  }
  ${({ theme, $isActive }) =>
    $isActive &&
    `
      border-left: 3px solid ${theme.navMenu.activeBorder};
      background: ${theme.iuiPalette.grays.grays[200]};
    `}
`;

const CommonUserMenu = ({
  username,
  title,
  department,
  sitemap,
  activeRoute,
  theme,
  onClose
}: Partial<UserMenuProps> & { theme: Theme }) => {
  const { isDesktop } = useIsBreakpoint();
  const CustomLink = React.useContext(LinkContext);

  const borderMargin = isDesktop ? '4px 12px 0px 12px' : '.75rem 1rem 1rem';

  return (
    <>
      <li>
        <Box padding='1rem'>
          <Typography
            variant='h5'
            overflow='hidden'
            textOverflow='ellipsis'
            whiteSpace='break-spaces'
            wordBreak='break-word'
          >
            {username}
          </Typography>
          <Typography
            variant='body-s'
            color={theme.iuiPalette.grays.grays[600]}
            overflow='hidden'
            whiteSpace='nowrap'
            textOverflow='ellipsis'
          >
            {title} | {department}
          </Typography>
        </Box>

        <Box
          height='1px'
          background={theme?.iuiPalette?.grays.grays[300] ?? 'gray'}
          margin={borderMargin}
        />
      </li>

      {(sitemap ?? []).map((section) => {
        const { status, totalPending = 0, route } = section;
        const isActive = route === activeRoute;
        const badgeType = status && getBadgeTypeFromStatus(status);

        return (
          <StyledMenuItem $isActive={isActive} key={route}>
            <CustomLink href={route} to={route} onClick={onClose}>
              <Typography
                customLineHeight={3}
                variant='button-secondary'
                color={isActive ? theme.navMenu.activeLink : undefined}
                as='span'
              >
                {section.label}
              </Typography>
            </CustomLink>
            {badgeType && (
              <div className='tier-1-badge-wrap'>
                <Badge
                  type={badgeType.type}
                  {...(badgeType.type === BadgeTypes.ERROR
                    ? { label: totalPending.toString() }
                    : {})}
                  {...(badgeType.type === BadgeTypes.WARNING
                    ? {
                        icon: badgeType.icon,
                        forceColor: theme.iuiPalette.brand[4]
                      }
                    : {})}
                  {...(badgeType.type === BadgeTypes.SUCCESS ? { icon: badgeType.icon } : {})}
                />
              </div>
            )}
          </StyledMenuItem>
        );
      })}
    </>
  );
};

const UserMenu: React.FC<UserMenuProps> = ({
  username,
  title,
  department,
  anchorElement,
  isOpen,
  sitemap,
  activeRoute,
  onClose,
  nonDropdownMode = false
}) => {
  const theme = useTheme();

  return !nonDropdownMode && onClose ? (
    <StyledMenu
      anchorEl={anchorElement}
      open={isOpen ?? false}
      id='user-menu'
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <CommonUserMenu
        username={username}
        title={title}
        department={department}
        sitemap={sitemap}
        theme={theme}
        activeRoute={activeRoute}
        onClose={onClose}
      />
    </StyledMenu>
  ) : (
    <StyledULMenu>
      <CommonUserMenu
        username={username}
        title={title}
        department={department}
        sitemap={sitemap}
        theme={theme}
        activeRoute={activeRoute}
        onClose={onClose}
      />
    </StyledULMenu>
  );
};

export default UserMenu;
