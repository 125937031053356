import * as React from 'react';

const createMarkup = (str: string): React.ReactNode => (
  // eslint-disable-next-line react/no-danger
  <span dangerouslySetInnerHTML={{ __html: str }} />
);
type RGB = {
  r: number;
  b: number;
  g: number;
};

function hexToRgb(hex): RGB | null {
  // https://stackoverflow.com/a/5624139
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const formatted = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(formatted);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
}

const rgb = (hex: string): string => {
  const val = hexToRgb(hex);
  if (!val) {
    return hex;
  }
  return `rgb(${val.r}, ${val.g}, ${val.b})`;
};
const rgba = (hex: string, trans: number): string => {
  const val = hexToRgb(hex);
  if (!val) {
    return hex;
  }
  return `rgba(${val.r}, ${val.g}, ${val.b}, ${trans})`;
};

const assertNever = (x: never): never => {
  throw new Error(`Unexpected object: ${x}`);
};

const removeSpaces = (s: string): string => s.replace(/ /g, '');

export { createMarkup, rgba, rgb, assertNever, removeSpaces };
