import * as React from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Box,
  BriefcaseFastaffIcon,
  BriefcaseTrustaffIcon,
  FunctionsFastaffIcon,
  FunctionsTrustaffIcon,
  ChecklistFastaffIcon,
  ChecklistTrustaffIcon,
  Typography,
  Container,
  Grid,
  GridColumns,
  ChevronRightIcon,
  Link
} from '../../components';
import { useBreakpoints } from '../../hooks';
import { IconProps, EmotionStyledProps, CompanyCode } from '../../types';
import { pxToRemMarginAndPadding } from '../../mixins/pxToRem';

import { MediaQuery } from '../../mixins/mediaQuery';
import defaultBreakpoints from '../../themes/breakpoints';

type svgOptions = 'checklist' | 'functions' | 'briefcase';

type svgBrandMap = { [index in svgOptions]: React.FC<IconProps> };

type svgMap = { [index in CompanyCode]: svgBrandMap };

const svgIconMap: svgMap = {
  [CompanyCode.FASTAFF]: {
    checklist: ChecklistFastaffIcon,
    functions: FunctionsFastaffIcon,
    briefcase: BriefcaseFastaffIcon
  },
  [CompanyCode.USNURSING]: {
    checklist: ChecklistFastaffIcon,
    functions: FunctionsFastaffIcon,
    briefcase: BriefcaseFastaffIcon
  },
  [CompanyCode.TRUSTAFF]: {
    checklist: ChecklistTrustaffIcon,
    functions: FunctionsTrustaffIcon,
    briefcase: BriefcaseTrustaffIcon
  },
  [CompanyCode.CARDIO]: {
    checklist: ChecklistTrustaffIcon, // todo update
    functions: FunctionsTrustaffIcon, // todo update
    briefcase: BriefcaseTrustaffIcon // todo update
  }
};

const getSvgIcon = (brand: CompanyCode, option: svgOptions) => svgIconMap[brand][option];

interface WorkSectionProps {
  svgKey: svgOptions;
  title: string;
  description: string;
  breakpoint: string | undefined;
}

interface LinkWithArrowProps {
  title: string;
  link?: string;
  padding?: string;
  iconColor?: string;
}

type LinkFields = {
  title: string;
  url?: string;
};

type IconCardFields = {
  svgKey: svgOptions;
  title: string;
  description: string;
};

export interface TitleWith3IconCardsProps {
  title: string;
  link1: LinkFields;
  link2: LinkFields;
  iconCard1: IconCardFields;
  iconCard2: IconCardFields;
  iconCard3: IconCardFields;
  className?: string;
}

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  flex-shrink: 0;
`;

const WorkSection: React.FC<WorkSectionProps> = ({ svgKey, title, description, breakpoint }) => {
  const theme = useTheme();

  const SVGComponent = getSvgIcon(
    theme.name === 'trustaff' ? CompanyCode.TRUSTAFF : CompanyCode.FASTAFF,
    svgKey
  );
  let iconDimenstions = 128;
  let titlePadding = pxToRemMarginAndPadding('48px 0 16px 0');
  if (breakpoint === 'medium') {
    iconDimenstions = 104;
    titlePadding = pxToRemMarginAndPadding('32px 0 16px 0');
  }
  return (
    <GridColumns columns={[3, 1, 2]}>
      <Box
        width='100%'
        padding={`${breakpoint === 'medium' ? '24px' : '80px'} 0 19px 0`}
        background={theme.iuiPalette.grays.white}
        height='100%'
      >
        <Box
          width='100%'
          background={theme.iuiPalette.grays.white}
          display='flex'
          flexDirection='column'
          alignItems='center'
        >
          <SVGComponent dimensions={iconDimenstions} />
          <Typography
            variant={breakpoint === 'medium' ? 'h6' : 'h4'}
            padding={titlePadding}
            color={theme.TitleWith3IconCards.titleColor || 'black'}
            width={breakpoint === 'medium' ? '100%' : '75%'}
            textAlign='center'
          >
            {title}
          </Typography>
          <Typography
            variant='body-m'
            color={theme.iuiPalette.grays.black}
            width={breakpoint === 'medium' ? '100%' : '75%'}
            textAlign='center'
          >
            {description}
          </Typography>
        </Box>
      </Box>
    </GridColumns>
  );
};

const LinkWithArrow: React.FC<LinkWithArrowProps> = ({
  title,
  link = '',
  padding = '',
  iconColor
}) => {
  const arrowIcon = (
    <StyledChevronRightIcon iconType='secondary' dimensions={24} customColor={iconColor} />
  );
  return (
    <Box padding={padding}>
      <Link icon={arrowIcon} label={title} iconToRight href={link} variant='alt' />
    </Box>
  );
};

LinkWithArrow.defaultProps = {
  link: '',
  padding: ''
};

const StyledGrid = styled(Grid)`
  background-color: ${(props: EmotionStyledProps) => props.theme.iuiPalette.grays.white};
`;

const SyledDivider = styled(Box)`
  overflow: hidden;
  margin: 16px 0 20px 0;
  width: 189px;
  ${(props: EmotionStyledProps) =>
    MediaQuery(props.theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    width: 144px;
  }
  ${(props: EmotionStyledProps) =>
    MediaQuery(props.theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    margin: 24px 0 28px 0;
    width: 196px;
  }
`;

const TitleWith3IconCards: React.FC<TitleWith3IconCardsProps> = ({
  title,
  link1,
  link2,
  iconCard1,
  iconCard2,
  iconCard3,
  className
}) => {
  const theme = useTheme();
  const breakpoint = useBreakpoints(theme.iuiBreakpoints);

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='row'
      background={`${
        breakpoint === 'small'
          ? theme.TitleWith3IconCards.backgroundColor
          : `linear-gradient(to right, ${theme.TitleWith3IconCards.backgroundColor} 0 50%, ${theme.iuiPalette.grays.white} 50% 100%)`
      }`}
      boxShadowLevel='light'
      className={className}
      data-test='iui-module-title-with-3-icon-cards'
    >
      <Container>
        <StyledGrid>
          <GridColumns columns={[3, 1, 2]}>
            <Box
              width='100%'
              height='100%'
              display='flex'
              flexDirection='column'
              justifyContent='flex-start'
              background={theme.TitleWith3IconCards.backgroundColor}
              padding='72px 0 90px 0'
              overflow='hidden'
            >
              <Typography variant='h1' color={theme.iuiPalette.grays.white} padding='0 10% 0 0'>
                {title}
              </Typography>
              <SyledDivider height='2px' background={theme.TitleWith3IconCards.dividerColor} />
              <LinkWithArrow
                title={link1.title}
                link={link1.url}
                padding={`0 0 ${
                  breakpoint === 'small' || breakpoint === 'medium' ? '24' : '28'
                }px 0`}
                iconColor={theme.TitleWith3IconCards.arrowIconColor}
              />
              <LinkWithArrow
                title={link2.title}
                link={link2.url}
                iconColor={theme.TitleWith3IconCards.arrowIconColor}
              />
            </Box>
          </GridColumns>
          {breakpoint !== 'small' && (
            <>
              <WorkSection
                svgKey={iconCard1.svgKey}
                title={iconCard1.title}
                description={iconCard1.description}
                breakpoint={breakpoint}
              />
              <WorkSection
                svgKey={iconCard2.svgKey}
                title={iconCard2.title}
                description={iconCard2.description}
                breakpoint={breakpoint}
              />
              <WorkSection
                svgKey={iconCard3.svgKey}
                title={iconCard3.title}
                description={iconCard3.description}
                breakpoint={breakpoint}
              />
            </>
          )}
        </StyledGrid>
      </Container>
    </Box>
  );
};

export default TitleWith3IconCards;
