import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--small-tick';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 9 7'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#fff'
      fillRule='evenodd'
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M7.57.653a.35.35 0 010 .494l-4.45 4.45a.35.35 0 01-.495 0L.603 3.574a.35.35 0 01.494-.495l1.775 1.775L7.074.653a.35.35 0 01.495 0z'
      clipRule='evenodd'
    />
  </svg>
);

const SvgSmallTick = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgSmallTick.displayName = 'SmallTick';
export default SvgSmallTick;
