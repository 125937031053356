import * as React from 'react';
import styled from '@emotion/styled';
import { GraphicProps } from '../../../types';
import { GetGraphicDimensions } from '../../../mixins/icons';

const TEST_ID = 'iui-component-graphic--trustaff';

const SVG: React.FC<GraphicProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 338 338'
    className={className}
    data-test={TEST_ID}
  >
    <g clipPath='url(#clip0_3979_30583)'>
      <path fill='silver' d='M222 72H86v193h136V72z' />
      <path fill='#fff' d='M219.882 69.353H85.765v192.941h134.117V69.353z' />
      <path
        fill='silver'
        d='M118.388 148.765c-.549-.044-1.066-.435-1.445-1.092s-.59-1.53-.59-2.438c0-.907.211-1.78.59-2.437.379-.657.896-1.048 1.445-1.092h42.989c.548.044 1.066.435 1.444 1.092.379.657.591 1.53.591 2.437 0 .908-.212 1.781-.591 2.438-.378.657-.896 1.048-1.444 1.092h-42.989zM117.553 155.824a1.21 1.21 0 01-.849-.345 1.166 1.166 0 010-1.664c.225-.22.53-.344.849-.344h70.541c.319 0 .624.124.849.344a1.166 1.166 0 010 1.664 1.21 1.21 0 01-.849.345h-70.541zM118.388 179.059c-.549-.044-1.066-.435-1.445-1.092s-.59-1.53-.59-2.438c0-.907.211-1.78.59-2.437.379-.658.896-1.048 1.445-1.092h42.989c.548.044 1.066.434 1.444 1.092.379.657.591 1.53.591 2.437 0 .908-.212 1.781-.591 2.438-.378.657-.896 1.048-1.444 1.092h-42.989zM117.636 186.115a1.224 1.224 0 01-.902-.315 1.163 1.163 0 01-.282-1.327 1.207 1.207 0 01.7-.64c.155-.054.32-.076.484-.066h70.532c.305.02.591.152.801.37a1.16 1.16 0 010 1.608c-.21.218-.496.351-.801.37h-70.532z'
      />
      <path
        fill='#E0C9F1'
        d='M117.636 195.527a1.225 1.225 0 01-.902-.316 1.163 1.163 0 01-.282-1.326 1.186 1.186 0 01.7-.64c.155-.054.32-.077.484-.066h70.532c.305.019.591.151.801.369a1.162 1.162 0 010 1.609c-.21.218-.496.35-.801.37h-70.532z'
      />
      <path
        fill='silver'
        d='M118.388 213.059c-.549-.044-1.066-.435-1.445-1.092s-.59-1.53-.59-2.438c0-.907.211-1.78.59-2.437.379-.658.896-1.048 1.445-1.092h42.989c.548.044 1.066.434 1.444 1.092.379.657.591 1.53.591 2.437 0 .908-.212 1.781-.591 2.438-.378.657-.896 1.048-1.444 1.092h-42.989zM117.636 220.115a1.224 1.224 0 01-.902-.315 1.163 1.163 0 01-.282-1.327 1.207 1.207 0 01.7-.64c.155-.054.32-.076.484-.066h70.532c.305.02.591.152.801.37a1.16 1.16 0 010 1.608c-.21.218-.496.351-.801.37h-70.532zM117.636 229.527a1.224 1.224 0 01-.902-.315 1.163 1.163 0 01-.282-1.327 1.192 1.192 0 01.7-.639c.155-.055.32-.077.484-.067h70.532c.305.02.591.152.801.37a1.16 1.16 0 010 1.608c-.21.218-.496.351-.801.37h-70.532zM117.636 120.527a1.225 1.225 0 01-.902-.316 1.163 1.163 0 01-.282-1.326 1.186 1.186 0 01.7-.64c.155-.054.32-.077.484-.066h70.532c.305.019.591.151.801.369a1.162 1.162 0 010 1.609c-.21.218-.496.35-.801.37h-70.532zM117.636 127.586a1.225 1.225 0 01-.902-.316 1.163 1.163 0 01-.282-1.326 1.186 1.186 0 01.7-.64c.155-.054.32-.077.484-.066h70.532c.305.019.591.152.801.37a1.16 1.16 0 010 1.608c-.21.218-.496.35-.801.37h-70.532zM118.388 113.47c-.549-.043-1.066-.434-1.445-1.091s-.59-1.53-.59-2.438c0-.908.211-1.781.59-2.438.379-.657.896-1.048 1.445-1.091h42.989c.548.043 1.066.434 1.444 1.091.379.657.591 1.53.591 2.438 0 .908-.212 1.781-.591 2.438-.378.657-.896 1.048-1.444 1.091h-42.989z'
      />
      <path
        fill='#3D155B'
        d='M277.951 249.549l-55.812-55.705a10.477 10.477 0 00-7.51-2.956 22.709 22.709 0 00-5.61 4.093 20.15 20.15 0 00-3.982 5.546 10.19 10.19 0 002.982 7.868l55.812 55.706a10.329 10.329 0 0014.352 0c3.872-3.865 3.872-10.686-.232-14.552z'
      />
      <path
        fill='#A174C1'
        d='M214.629 190.888l-9.084-9.071c-5.39 6.184-7.151 7.126-9.592 9.298l9.108 9.095v.317a20.152 20.152 0 013.983-5.547 22.701 22.701 0 015.585-4.092z'
      />
      <path
        fill='#512D6C'
        d='M154.09 67c-38.381 0-69.607 31.166-69.607 69.478 0 38.311 31.226 69.473 69.607 69.473s69.611-31.166 69.611-69.473C223.701 98.17 192.475 67 154.09 67zm0 126.239a56.95 56.95 0 01-31.599-9.565 56.787 56.787 0 01-20.949-25.475 56.665 56.665 0 01-3.238-32.799 56.736 56.736 0 0115.566-29.066A56.905 56.905 0 01142.991 80.8a56.974 56.974 0 0132.861 3.231 56.855 56.855 0 0125.525 20.909 56.688 56.688 0 01-7.074 71.675 56.864 56.864 0 01-18.45 12.304 56.957 56.957 0 01-21.763 4.321z'
      />
      <path
        fill='#E0C9F1'
        d='M154.09 79.711c-31.36 0-56.87 25.466-56.87 56.766 0 31.3 25.51 56.761 56.87 56.761 31.36 0 56.87-25.465 56.87-56.761 0-31.296-25.498-56.766-56.87-56.766zm0 107.55a50.956 50.956 0 01-28.268-8.565 50.808 50.808 0 01-18.736-22.797 50.695 50.695 0 01-2.89-29.344 50.758 50.758 0 0113.93-26.001 50.914 50.914 0 0126.056-13.896 50.976 50.976 0 0129.399 2.894 50.866 50.866 0 0122.834 18.708 50.715 50.715 0 01-6.336 64.133 50.876 50.876 0 01-16.512 11.007 50.979 50.979 0 01-19.477 3.861z'
      />
    </g>
    <defs>
      <clipPath id='clip0_3979_30583'>
        <path fill='#fff' d='M0 0h207.059v200H0z' transform='translate(74 67)' />
      </clipPath>
    </defs>
  </svg>
);

const SvgTrustaff = styled(SVG)`
  ${(props: GraphicProps) => GetGraphicDimensions(props)}
`;
SvgTrustaff.displayName = 'Trustaff';
export default SvgTrustaff;
