import { Theme } from '@emotion/react';
import { StyledComponent } from '@emotion/styled';
import {
  DayShiftIcon,
  NightShiftIcon,
  RotationShiftIcon,
  OtherShiftIcon,
  EveningShiftIcon
} from '../../components/Icons';

import { IconProps, ShiftType } from '../../types';

const getShiftIcon = (
  type: ShiftType
): StyledComponent<
  IconProps & {
    children?: React.ReactNode;
  } & {
    theme?: Theme | undefined;
  }
> => {
  switch (type) {
    case 'day':
      return DayShiftIcon;
    case 'evening':
      return EveningShiftIcon;
    case 'night':
      return NightShiftIcon;
    case 'rotation':
      return RotationShiftIcon;
    case 'other':
    default:
      return OtherShiftIcon;
  }
};

export default getShiftIcon;
