import * as React from 'react';
import { StyledSpinner } from './spinner.styles';

export const Spinner: React.FC = () => (
  // const { ...rest } = props;
  // todo there's probably a bunch of spinner sizes to add

  <StyledSpinner data-test='iui-component-spinner' />
);

export default Spinner;
