import styled from '@emotion/styled';
import { BadgeIconTypes, BadgeSizeTypes } from './badge.types';

export const StyledWrap = styled.span<{
  type?: string;
  icon?: string;
  bg: string;
  isDot: boolean;
  size: string;
}>`
  border-radius: 100px;
  display: inline-flex;
  height: 14px;
  width: 14px;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  ${({ icon, bg }) => icon !== BadgeIconTypes.TICK && `background: ${bg};`}

  ${({ isDot, size }) =>
    isDot &&
    size === BadgeSizeTypes.DEFAULT &&
    ` height: 14px;
      width: 14px;
    `}

    ${({ isDot, size }) =>
    isDot &&
    size === BadgeSizeTypes.SMALL &&
    ` height: 6px;
      width: 6px;
    `}

  position: relative;
  svg {
    position: absolute;
    ${({ icon }) =>
      icon === BadgeIconTypes.CIRCLE_TICK &&
      ` width: 9px;
        height: 9px;
        margin-bottom: -1px;
      `}

    ${({ icon }) =>
      icon === BadgeIconTypes.EXCLAIM &&
      ` width: 14px;
        height: 14px;
      `}
  }
`;
