import * as React from 'react';
import styled from '@emotion/styled';
import { GraphicProps } from '../../../types';
import { GetGraphicDimensions } from '../../../mixins/icons';

const TEST_ID = 'iui-component-graphic--trustaff';

const SVG: React.FC<GraphicProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 338 338'
    className={className}
    data-test={TEST_ID}
  >
    <rect width={194.394} height={118.796} x={74.606} y={122.937} fill='#512D6C' rx={4} />
    <rect width={195.376} height={118.796} x={69} y={117.331} fill='#fff' rx={4} />
    <mask
      id='mask0_4908_34051'
      style={{
        maskType: 'alpha'
      }}
      width={64}
      height={64}
      x={95}
      y={151}
      maskUnits='userSpaceOnUse'
    >
      <circle cx={126.64} cy={182.826} r={31.417} fill='#fff' />
    </mask>
    <g mask='url(#mask0_4908_34051)'>
      <circle cx={127.131} cy={181.1} r={34.853} fill='#E0C9F1' />
      <path
        fill='#512D6C'
        fillRule='evenodd'
        d='M107.677 195.208a12.906 12.906 0 019.127-3.781h20.654a12.908 12.908 0 0112.909 12.908v5.164a2.582 2.582 0 11-5.163 0v-5.164a7.741 7.741 0 00-2.269-5.476 7.742 7.742 0 00-5.477-2.269h-20.654a7.741 7.741 0 00-5.476 2.269 7.741 7.741 0 00-2.269 5.476v5.164a2.582 2.582 0 11-5.163 0v-5.164c0-3.423 1.36-6.707 3.781-9.127zM127.131 165.609a7.746 7.746 0 10.002 15.492 7.746 7.746 0 00-.002-15.492zm-12.908 7.746c0-7.13 5.779-12.909 12.908-12.909 7.13 0 12.909 5.779 12.909 12.909 0 7.129-5.779 12.908-12.909 12.908-7.129 0-12.908-5.779-12.908-12.908z'
        clipRule='evenodd'
      />
      <circle cx={127.131} cy={173.354} r={9.036} fill='#512D6C' />
      <path fill='#512D6C' d='M109.059 196.59h37.435v20.654h-37.435z' />
    </g>
    <rect width={61.962} height={6.454} x={180.093} y={155.246} fill='#A174C1' rx={2} />
    <rect width={61.962} height={6.454} x={180.093} y={177.19} fill='#A174C1' rx={2} />
    <rect width={61.962} height={6.454} x={180.093} y={199.135} fill='#A174C1' rx={2} />
    <path
      fill='#784B99'
      d='M146.841 116.645a4 4 0 014-4h43.635a4 4 0 014 4v15.363h-51.635v-15.363z'
    />
    <path fill='#A174C1' d='M165.462 101a4 4 0 014-4h7.961a4 4 0 014 4v19.942h-15.961V101z' />
    <path fill='#512D6C' d='M146.841 132.011h51.635v1.291h-51.635z' />
  </svg>
);

const SvgTrustaff = styled(SVG)`
  ${(props: GraphicProps) => GetGraphicDimensions(props)}
`;
SvgTrustaff.displayName = 'Trustaff';
export default SvgTrustaff;
