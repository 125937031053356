import { useState, useEffect } from 'react';
import { Breakpoints } from '../../types';
import defaultBreakpoints from '../../themes/breakpoints';
import useIsSsr from '../useIsSSR';

/* Get the current breakpoint of the window */
const getBreakpoint = (breakpoints: Breakpoints): keyof Breakpoints | undefined => {
  const windowWidth = window.innerWidth;
  const selectedBreakpoints = breakpoints || defaultBreakpoints;
  const breakpointKeys = Object.keys(selectedBreakpoints) as Array<keyof Breakpoints>;

  /* Find breakpoint which current window width falls within */
  for (let i = 0; i < breakpointKeys.length; i += 1) {
    /* if width is smaller than the breakpoint, return previous key */
    const breakPointMinimumValue = selectedBreakpoints[breakpointKeys[i]];
    if (windowWidth < breakPointMinimumValue) {
      return breakpointKeys[i - 1];
    }
  }
  /* If width is large than all breakpoints, return last key */
  return breakpointKeys[breakpointKeys.length - 1];
};

const useBreakpoints = (breakpoints: Breakpoints): keyof Breakpoints | undefined => {
  const [breakpoint, setBreakpoint] = useState<keyof Breakpoints | undefined>();
  const isSsr = useIsSsr();

  useEffect(() => {
    if (!isSsr) {
      const handleResize = () => {
        setBreakpoint(getBreakpoint(breakpoints));
      };
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }
    return undefined;
  }, [isSsr, breakpoints]);

  return breakpoint;
};

export default useBreakpoints;
