import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--evening-shift';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    className={className}
    data-test={TEST_ID}
  >
    <g clipPath='url(#clip0_486_3021)'>
      <path
        fill='#E9C24D'
        d='M4.963 7.5L3.549 8.914l1.789 1.789 1.414-1.415L4.962 7.5zM4 14.95H1v1h3v-1zM13 5h-2v2.95h2V5zM19.035 7.5l-1.79 1.788 1.408 1.407 1.789-1.789L19.035 7.5zM23 14.95h-3v1h3v-1z'
      />
      <path fill='#758BD2' d='M23 17.95H1v2h22v-2z' />
      <path fill='#E9C24D' d='M12 10a6 6 0 00-6 6h12a6 6 0 00-6-6z' />
    </g>
    <defs>
      <clipPath id='clip0_486_3021'>
        <path fill='#fff' d='M0 0h22v14.95H0z' transform='translate(1 5)' />
      </clipPath>
    </defs>
  </svg>
);

const SvgEveningShift = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgEveningShift.displayName = 'EveningShift';
export default SvgEveningShift;
