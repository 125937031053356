import * as React from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import Box from '../Box';
import Typography from '../Typography';
import Container from '../Container';
import { MediaQuery, GetTypographyCss } from '../../mixins';
import { LinkContext } from '../../providers/Linker';

interface LegalBarProps {
  variant?: 'default' | 'inverted';
  companyName: string;
  termsUrl?: string;
  privacyUrl?: string;
  doNotSellPersonalInfoUrl?: string;
  currentYear?: string;
  margin?: string;
  className?: string;
}

const LegalsContainer = styled(Container)`
  align-items: flex-start;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem 1.5rem;

  ${(props) => MediaQuery(props.theme.iuiBreakpoints.medium)} {
    flex-direction: row;
    align-items: center;
  }
`;

const LegalBar: React.FC<LegalBarProps> = ({
  variant,
  companyName,
  termsUrl,
  privacyUrl,
  doNotSellPersonalInfoUrl,
  currentYear,
  margin,
  className
}) => {
  const theme = useTheme();
  const CustomLink = React.useContext(LinkContext);

  const backgroundColor = React.useMemo(
    () =>
      variant === 'inverted'
        ? theme?.legalBar?.inverted.backgroundColor ?? 'white'
        : theme?.legalBar?.default.backgroundColor ?? 'navy',
    [variant, theme]
  );

  const textColor = React.useMemo(
    () =>
      variant === 'inverted'
        ? theme?.legalBar?.inverted.textColor ?? 'navy'
        : theme?.legalBar?.default.textColor ?? 'white',
    [variant, theme]
  );

  const borderTopColor = React.useMemo(
    () =>
      variant === 'inverted'
        ? theme?.legalBar?.inverted.borderColor ?? 'transparent'
        : 'transparent',
    [variant, theme]
  );

  const StyledCustomLink = styled(CustomLink)`
    ${GetTypographyCss({ variant: 'meta-l', theme })}
    color: ${textColor};
    text-decoration: none;
    flex-shrink: 0;
    &:hover,
    &:active {
      text-decoration: underline;
    }
    &:active {
      opacity: 0.7;
    }
  `;

  return (
    <Box
      background={backgroundColor}
      display='flex'
      padding='1.125rem 0'
      margin={margin || ''}
      borderTop={`1px solid ${borderTopColor}`}
      data-test='iui-component-legal-bar'
      flexShrink='0'
      className={className}
    >
      <LegalsContainer>
        <Typography variant='meta-l' color={textColor} textTransform='capitalize'>
          {companyName} © {currentYear ?? new Date().getFullYear()} All rights reserved.
        </Typography>
        <Box display='flex' flexDirection='row' gap='1rem 1.5rem' flexWrap='wrap'>
          {termsUrl && (
            <StyledCustomLink href={termsUrl} to={termsUrl}>
              Terms of use
            </StyledCustomLink>
          )}
          {privacyUrl && (
            <StyledCustomLink href={privacyUrl} to={privacyUrl}>
              Privacy Policy
            </StyledCustomLink>
          )}
          {doNotSellPersonalInfoUrl && (
            <StyledCustomLink href={doNotSellPersonalInfoUrl} to={doNotSellPersonalInfoUrl}>
              Do Not Sell My Personal Info
            </StyledCustomLink>
          )}
        </Box>
      </LegalsContainer>
    </Box>
  );
};

export default LegalBar;
