import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import Typography from '../Typography';
import Button from '../Button';
import { GridColumns } from '../Grid';
import Image from '../Image';
import { SizelessOptions } from '../Image/image.types';

import { useBreakpoints } from '../../hooks';
import DefaultBreakpoints from '../../themes/breakpoints';

import { CarouselGrid, QuoteCol, StyledTypography, Wrapper } from './carouselItem.styles';

export interface CarouselItemProps {
  item: {
    img: string;
    imgAlt?: string;
    width: number;
    height: number;
    heading: string;
    body: string;
    href?: string;
    cta?: string;
    radius?: number;
  };
  variant: 'quote' | 'app';
  isTextCentered?: boolean;
  hasImageShadow?: boolean;
  imageOptions?: SizelessOptions;
}

const ImageCol = styled(GridColumns)`
  display: grid;
  place-items: center;
`;

const carouselOneOneRatio = [
  {
    size: {
      w: 178,
      h: 178
    },
    maxWidth: 480
  },
  {
    size: {
      w: 200,
      h: 200
    },
    maxWidth: 580
  },
  {
    size: {
      w: 250,
      h: 250
    },
    maxWidth: 650
  },
  {
    size: {
      w: 292,
      h: 292
    },
    maxWidth: DefaultBreakpoints.medium
  },
  {
    size: {
      w: 372,
      h: 372
    },
    maxWidth: DefaultBreakpoints.xLarge
  },
  {
    size: {
      w: 416,
      h: 416
    },
    maxWidth: DefaultBreakpoints.xxLarge
  }
];

const CarouselItem: React.FC<CarouselItemProps> = ({
  item,
  variant,
  isTextCentered,
  hasImageShadow,
  imageOptions
}) => {
  const theme = useTheme();
  const breakpoint = useBreakpoints(theme.iuiBreakpoints);
  const isQuoteVariant = variant === 'quote';
  const quoteHeadingLevel = breakpoint === 'small' ? 'h6' : 'h5';
  const headingVariant = isQuoteVariant ? quoteHeadingLevel : 'h2';
  const bodyVariant = isQuoteVariant ? 'meta-l-caps' : 'body-l';
  return (
    <Wrapper
      className={`variant--${variant}`}
      hasImageShadow={!!hasImageShadow}
      data-test='iui-component-carousel-item'
    >
      <CarouselGrid>
        <ImageCol columns={[4, 2, 2]} columnStarts={[2, 'auto', 'auto']}>
          <div className='image-wrapper' style={{ borderRadius: `${item.radius}px` }}>
            <Image
              alt={item.imgAlt ? item.imgAlt : ''}
              src={item.img}
              responsive={carouselOneOneRatio}
              options={imageOptions}
              loading='lazy'
            />
          </div>
        </ImageCol>
        <QuoteCol isTextCentered={!!isTextCentered}>
          {isQuoteVariant && (
            <StyledTypography variant='h1' as='span' aria-hidden='true' className='dropCap'>
              “
            </StyledTypography>
          )}
          <Typography variant={headingVariant} as='p'>
            {isQuoteVariant && <span className='sr-only'>“</span>}
            {item.heading}
            {isQuoteVariant && (
              <>
                {' '}
                <Typography variant='h5' as='span' className='endQuote'>
                  ”
                </Typography>
              </>
            )}
          </Typography>
          <Typography className='body' variant={bodyVariant}>
            {item.body}
          </Typography>
          {item.cta && (
            <Button
              label={item.cta}
              asLink
              href={item.href}
              colorway='dark'
              margin='2.375rem 0 0'
              fullWidthMobile
            />
          )}
        </QuoteCol>
      </CarouselGrid>
    </Wrapper>
  );
};

export default CarouselItem;
