import { Theme } from '@emotion/react';

const MediaQuery = (minBreakpoint: number): string => `@media (min-width: ${minBreakpoint}px)`;
const ExactMediaQuery = (min: number, max: number): string =>
  `@media (min-width: ${min}px) and (max-width:${max}px)`;

const AtAllBreakpoints = (theme: Theme, css: string): string => `
  ${css};
  ${MediaQuery(theme.iuiBreakpoints.medium)} {
    ${css};
  }
  ${MediaQuery(theme.iuiBreakpoints.large)} {
    ${css};
  }
  ${MediaQuery(theme.iuiBreakpoints.xLarge)} {
    ${css};
  }
  ${MediaQuery(theme.iuiBreakpoints.xxLarge)} {
    ${css};
  }
`;

export { MediaQuery, ExactMediaQuery, AtAllBreakpoints };
