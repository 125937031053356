import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--operational-flexibility';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#1D243D'
      d='M9.073 6.314h1.73v4.263c.331.237.639.497.947.758.284-.26.616-.521.947-.758V6.314h1.729a.79.79 0 00.687-.426c.118-.261.094-.569-.095-.782l-2.676-3.34c-.284-.355-.876-.355-1.16 0l-2.7 3.34a.732.732 0 00-.095.781.79.79 0 00.686.427zM5.782 10.884h-.45V9.156a.79.79 0 00-.427-.687c-.26-.119-.568-.095-.781.095l-3.34 2.652a.79.79 0 00-.284.592c0 .237.095.45.284.592l3.34 2.677a.724.724 0 00.473.166.738.738 0 00.758-.758v-1.73h.45c2.748 0 4.998 2.25 4.998 5.021v3.648a.95.95 0 00.947.947.95.95 0 00.947-.947v-3.648c0-3.813-3.102-6.892-6.916-6.892zM22.716 11.216l-3.34-2.652a.732.732 0 00-.781-.095.738.738 0 00-.427.687v1.729h-.45c-1.847 0-3.505.71-4.737 1.87.38.545.687 1.114.948 1.73a4.969 4.969 0 013.79-1.753h.45v1.729a.79.79 0 00.426.687.755.755 0 00.331.07.723.723 0 00.474-.165l3.316-2.653a.79.79 0 00.284-.592.75.75 0 00-.284-.592z'
    />
  </svg>
);

const SvgOperationalFlexibility = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgOperationalFlexibility.displayName = 'OperationalFlexibility';
export default SvgOperationalFlexibility;
