import { FontFamily } from '../../../types';
import { ThemedTypographyList } from '../../../components/Typography/typography.types';
import { combineTypographyStyles } from '../../../components/Typography/typography.utils';

export const TrustaffDefaultFonts: FontFamily[] = [
  {
    name: 'Nunito Sans',
    variants: [
      { file: 'NunitoSans-Bold.ttf', style: 'normal', weight: 700 },
      {
        file: 'NunitoSans-Light.ttf',
        style: 'normal',
        weight: 300
      }
    ]
  },
  {
    name: 'Open Sans',
    variants: [
      { file: 'OpenSans-Regular.ttf', style: 'normal', weight: 400 },
      { file: 'OpenSans-Italic.ttf', style: 'italic', weight: 400 },
      {
        file: 'OpenSans-SemiBold.ttf',
        style: 'normal',
        weight: 600
      },
      {
        file: 'OpenSans-SemiBoldItalic.ttf',
        style: 'italic',
        weight: 600
      }
    ]
  }
];

const TrustaffSpecificTheme: ThemedTypographyList = {
  h1: {
    fontFamily: 'Nunito Sans',
    fontWeight: 700
  },
  h2: {
    fontFamily: 'Nunito Sans',
    fontWeight: 700
  },
  h3: {
    fontFamily: 'Nunito Sans',
    fontWeight: 700
  },
  h4: {
    fontFamily: 'Nunito Sans',
    fontWeight: 700
  },
  h5: {
    fontFamily: 'Nunito Sans',
    fontWeight: 700
  },
  h6: {
    fontFamily: 'Nunito Sans',
    fontWeight: 700
  },
  'h1-alt': {
    fontFamily: 'Nunito Sans',
    fontWeight: 300
  },
  'h2-alt': {
    fontFamily: 'Nunito Sans',
    fontWeight: 300
  },
  'h3-alt': {
    fontFamily: 'Nunito Sans',
    fontWeight: 300
  },
  'h4-alt': {
    fontFamily: 'Nunito Sans',
    fontWeight: 300
  },
  'h5-alt': {
    fontFamily: 'Nunito Sans',
    fontWeight: 300
  },
  'h6-alt': {
    fontFamily: 'Nunito Sans',
    fontWeight: 300
  },
  'body-l': {
    fontFamily: 'Open Sans',
    fontWeight: 400
  },
  'body-m': {
    fontFamily: 'Open Sans',
    fontWeight: 400
  },
  'body-s': {
    fontFamily: 'Open Sans',
    fontWeight: 400
  },
  'body-s-e': {
    fontFamily: 'Open Sans',
    fontWeight: 400
  },
  'body-l-alt': {
    fontFamily: 'Open Sans',
    fontWeight: 600
  },
  'body-m-alt': {
    fontFamily: 'Open Sans',
    fontWeight: 600
  },
  'body-s-alt': {
    fontFamily: 'Open Sans',
    fontWeight: 600
  },
  'body-s-alt-e': {
    fontFamily: 'Open Sans',
    fontWeight: 600
  },
  'body-l-link': {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    textUnderline: true
  },
  'body-m-link': {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    textUnderline: true
  },
  'body-s-link': {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    textUnderline: true
  },
  'meta-l': {
    fontFamily: 'Open Sans',
    fontWeight: 400
  },
  'meta-m': {
    fontFamily: 'Open Sans',
    fontWeight: 400
  },
  'meta-s': {
    fontFamily: 'Open Sans',
    fontWeight: 400
  },
  'meta-l-caps': {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    uppercase: true
  },
  'meta-m-caps': {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    uppercase: true
  },
  'meta-s-caps': {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    uppercase: true
  },
  'meta-l-alt': {
    fontFamily: 'Open Sans',
    fontWeight: 600
  },
  'meta-m-alt': {
    fontFamily: 'Open Sans',
    fontWeight: 600
  },
  'meta-s-alt': {
    fontFamily: 'Open Sans',
    fontWeight: 600
  },
  'meta-l-caps-alt': {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    uppercase: true
  },
  'meta-m-caps-alt': {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    uppercase: true
  },
  'meta-s-caps-alt': {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    uppercase: true
  },
  'button-primary': {
    fontFamily: 'Nunito Sans',
    fontWeight: 700
  },
  'button-secondary': {
    fontFamily: 'Open Sans',
    fontWeight: 700
  },
  'button-tertiary': {
    fontFamily: 'Open Sans',
    fontWeight: 700
  },
  'button-tertiary-icon': {
    fontFamily: 'Open Sans',
    fontWeight: 400
  },
  'button-text-link': {
    fontFamily: 'Open Sans',
    fontWeight: 400
  }
};

export const TrustaffDefaultTypographyStyles = combineTypographyStyles(TrustaffSpecificTheme);
