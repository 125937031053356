/* 321 variant constants */
export const DESKTOP_ROW_GRID_GAP_321 = 32;
export const TABLET_AND_MOBILE_ROW_GRID_GAP_321 = 16;
export const DESKTOP_ITEMS_PER_PAGE_321 = 3;
export const TABLET_ITEMS_PER_PAGE_321 = 2;

/* 2withEdge variant constants */
export const ROW_GRID_GAP_2WE = 8;
export const ITEMS_PER_PAGE_2WE = 2;

/**
 * The space taken by gaps in a page, divided by the number of cards per page
 * Number of gaps in each page = (number of cards - 1). eg 3 cards have 2 gaps in between
 * Multiply this by Grid Gap to get total width taken up by gaps
 * To get the width per card -> Divide total by number of cards per page
 */
export const TABLET_SPACE_TAKEN_PER_PAGE_BY_GAPS_321 =
  (TABLET_AND_MOBILE_ROW_GRID_GAP_321 * (TABLET_ITEMS_PER_PAGE_321 - 1)) /
  TABLET_ITEMS_PER_PAGE_321;
export const DESKTOP_SPACE_TAKEN_PER_PAGE_BY_GAPS_321 =
  (DESKTOP_ROW_GRID_GAP_321 * (DESKTOP_ITEMS_PER_PAGE_321 - 1)) / DESKTOP_ITEMS_PER_PAGE_321;
export const SPACE_TAKE_PER_PAGE_BY_GAPS_2WE =
  (ROW_GRID_GAP_2WE * (ITEMS_PER_PAGE_2WE - 1)) / ITEMS_PER_PAGE_2WE;
