import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-igh-icon--checkmark';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#171717'
      fillRule='evenodd'
      stroke='#1D243D'
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M22.198 5.293a1 1 0 010 1.414L9.485 19.42a1 1 0 01-1.414 0l-5.778-5.778a1 1 0 111.414-1.414l5.071 5.071L20.783 5.293a1 1 0 011.415 0z'
      clipRule='evenodd'
    />
  </svg>
);

const SvgCheckmark = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgCheckmark.displayName = 'Checkmark';
export default SvgCheckmark;
