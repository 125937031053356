import styled from '@emotion/styled';
import { css, Theme } from '@emotion/react';

import { ProgressBarProps } from '.';
import { ProgressBarVariants } from './progress-bar.types';

interface StyledProgressBarProps extends ProgressBarProps {
  theme: Theme;
  dash: number;
  circumference: number;
  strokeWidth: number;
  innerCircleSize: number;
  margin?: string;
}

const circular = ({
  variant,
  theme,
  size,
  innerCircleSize,
  dash,
  circumference,
  strokeWidth,
  margin
}: StyledProgressBarProps) => {
  const isCircular = variant === ProgressBarVariants.CIRCULAR;

  if (isCircular) {
    return css`
      margin: ${margin};
      width: ${size}px;
      height: ${size}px;
      background: ${theme.iuiPalette.primaryAlt[100]};
      border-radius: 50%;
      position: relative;

      .progress-circular__bar {
        transition: 0.35s stroke-dashoffset;
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
        stroke-dashoffset: ${dash};
        stroke-dasharray: ${circumference} ${circumference};
        stroke-linecap: round;
      }

      .progress-circular__content {
        background: white;
        width: ${innerCircleSize}px;
        height: ${innerCircleSize}px;
        top: ${strokeWidth}px;
        left: ${strokeWidth}px;
        border-radius: 50%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `;
  }
  return '';
};

// eslint-disable-next-line arrow-body-style
const horizontal = ({ variant, theme }: StyledProgressBarProps) => {
  // const isHorizontal = variant === ProgressBarVariants.HORIZONTAL;
  // if (isHorizontal) {
  //   return css`
  //     background: red;
  //   `;
  // }
  return '';
};

export const StyledProgressBarWrapper = styled.div<StyledProgressBarProps>`
  ${horizontal}
  ${circular}
`;
