import styled from '@emotion/styled';
import { Theme } from '@emotion/react';

import { ToastProps } from '.';
import { ToastVariants } from './toast.types';
import defaultBreakpoints from '../../themes/breakpoints';
import { MediaQuery } from '../../mixins/mediaQuery';
import { shadow } from '../../mixins/shadow';
import { ZIndexLevel } from '../../constants';

interface StyledToastProps extends ToastProps {
  margin?: string;
  theme: Theme;
}

export const StyledToastWrapper = styled.div<StyledToastProps>`
  padding: 1rem 1.5rem;
  border-radius: 0;
  span {
    color: var(--color-white);
    line-height: 1.5rem;
  }
  position: fixed;
  margin: 0 auto;

  ${shadow({ level: 'light' })};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: ${ZIndexLevel.ABOVE_PAGE};
  svg {
    width: 3rem;
    height: 3rem;
    margin-top: -3px;
    margin-right: 0.75rem;
  }

  ${(props) =>
    props.variant === ToastVariants.DEFAULT &&
    `
    background: ${props.theme?.toast?.standard.color ?? 'var(--color-primary-700)'};
  `}
  ${(props) =>
    props.variant === ToastVariants.ERROR &&
    `
    background: ${props.theme?.toast?.error.color ?? 'var(--color-semantic-red100)'};
  `}
  ${(props) =>
    props.variant === ToastVariants.SUCCESS &&
    `
    background: ${props.theme?.toast?.success.color ?? 'var(--color-semantic-green100)'};
  `}

  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    border-radius: 4px;
    bottom: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;
