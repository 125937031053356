import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--checklist-trustaff';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 104 104'
    className={className}
    data-test={TEST_ID}
  >
    <circle cx={52} cy={52} r={52} fill='#FFF7EC' />
    <path
      fill='#302599'
      d='M70.34 46.159h2.92a1.821 1.821 0 001.818-1.818V27.89a1.821 1.821 0 00-1.819-1.818h-2.918a1.821 1.821 0 00-1.819 1.818v16.45c0 1.014.817 1.818 1.819 1.818z'
    />
    <path fill='url(#pattern0)' d='M66.964 24.811h5.813v23.128h-5.813z' />
    <path
      fill='#302599'
      d='M69.697 46.159h.384c.643 0 1.175-.52 1.175-1.175V27.248c0-.643-.52-1.175-1.175-1.175h-.384c-.643 0-1.175.52-1.175 1.175v17.736c0 .655.52 1.175 1.175 1.175z'
    />
    <path
      fill='#302599'
      d='M69.994 78.18H30.132a1.267 1.267 0 01-1.262-1.261V23.946c0-.693.57-1.262 1.262-1.262h39.862c.693 0 1.262.57 1.262 1.262v52.96a1.27 1.27 0 01-1.262 1.274z'
    />
    <path fill='url(#pattern1)' d='M27.757 22.585H72.53V78.86H27.757z' />
    <path fill='#D9D9D9' d='M68.659 26.085H31.455v48.706H68.66V26.085z' />
    <path fill='#EDEDED' d='M67.05 75.93l-37.116-2.4 3.129-48.595 37.129 2.387-3.141 48.607z' />
    <path fill='#fff' d='M65.987 76.72l-36.968-4.18 5.479-48.396 36.968 4.18-5.479 48.397z' />
    <path fill='#FF8300' d='M40.422 39.01a3.043 3.043 0 100-6.085 3.043 3.043 0 000 6.085z' />
    <path
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={1.608}
      d='M39.124 35.831l.73.903 1.855-1.484'
    />
    <path
      stroke='#C5C7F2'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={4.02}
      d='M46.087 34.755l12.38 1.398'
    />
    <path
      stroke='#C5C7F2'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={2.157}
      d='M45.556 37.254l19.467 2.201M45.308 39.418l19.467 2.202'
    />
    <path fill='#FF8300' d='M39.061 51.032a3.043 3.043 0 100-6.085 3.043 3.043 0 000 6.085z' />
    <path
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={1.608}
      d='M37.763 47.866l.73.903 1.855-1.484'
    />
    <path
      stroke='#C5C7F2'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={4.02}
      d='M44.726 46.777l12.38 1.41'
    />
    <path
      stroke='#C5C7F2'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={2.157}
      d='M44.194 49.288l19.468 2.202M43.946 51.453l19.468 2.201'
    />
    <path
      fill='#FF8300'
      d='M37.7 57.464a2.561 2.561 0 012.387 3.463 2.56 2.56 0 01-2.374 1.632 2.561 2.561 0 01-2.387-3.463 2.567 2.567 0 012.375-1.633zm0-.495a3.044 3.044 0 100 6.085 3.044 3.044 0 001.088-5.887 3.376 3.376 0 00-1.087-.198z'
      opacity={0.28}
    />
    <path
      stroke='#C5C7F2'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={4.02}
      d='M43.365 58.812l12.38 1.397'
    />
    <path
      stroke='#C5C7F2'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={2.157}
      d='M42.834 61.31l19.467 2.201M42.586 63.474l19.467 2.202'
    />
    <defs>
      <pattern id='pattern0' width={1} height={1} patternContentUnits='objectBoundingBox'>
        <use xlinkHref='#image0_2685_12380' />
      </pattern>
      <pattern id='pattern1' width={1} height={1} patternContentUnits='objectBoundingBox'>
        <use xlinkHref='#image1_2685_12380' />
      </pattern>
      <image
        id='image0_2685_12380'
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAC7CAYAAAD10Mf5AAAACXBIWXMAAAsSAAALEgHS3X78AAAA'
      />
      <image
        id='image1_2685_12380'
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAWoAAAHHCAYAAACWWhLTAAAACXBIWXMAAAsSAAALEgHS3X78AAAA'
      />
    </defs>
  </svg>
);

const SvgChecklistTrustaff = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgChecklistTrustaff.displayName = 'ChecklistTrustaff';
export default SvgChecklistTrustaff;
