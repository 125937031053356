import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Grid from '../Grid';
import Typography from '../Typography';
import { MediaQuery, ShadowMixin, ExactMediaQuery } from '../../mixins';
import DefaultBreakpoints from '../../themes/breakpoints';

type WrapperProps = {
  hasImageShadow: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  ${({ theme }) => MediaQuery(theme.iuiBreakpoints?.medium)} {
    margin: 50px 0;
  }
  .image-wrapper {
    margin: 0 auto;
    width: 100%;
    border-radius: 50%;
    overflow: hidden;
    max-width: 180px;
    box-shadow: ${({ hasImageShadow }: WrapperProps) =>
      hasImageShadow ? '0px 5px 15px 0px rgba(27, 49, 125, 0.14)' : 'none'};

    ${({ theme }) => MediaQuery(theme.iuiBreakpoints?.medium)} {
      max-width: 245px;
    }
    ${({ theme }) => MediaQuery(theme.iuiBreakpoints?.large)} {
      max-width: 416px;
    }
    img {
      user-select: none;
    }
  }

  &.variant--app {
    background: var(--color-grays-200);
    border-radius: 1.5rem;
    padding: 50px 1rem;
    .image-wrapper {
      border-radius: 0;
      overflow: hidden;
      width: initial;
      height: initial;

      img {
        user-select: none;
      }
    }
    ${({ theme }) => MediaQuery(theme.iuiBreakpoints?.medium)} {
      padding: 50px 0;
    }
  }
`;

export const StyledTypography = styled(Typography)`
  line-height: 3rem;
`;

export const Col: React.FC = styled.div`
  grid-column: span 6;
  width: 100%;

  :nth-of-type(odd) {
    grid-column: 1/3;
    display: grid;
    place-items: center;

    ${({ theme }) => MediaQuery(theme.iuiBreakpoints?.large ?? DefaultBreakpoints.large)} {
      grid-column: 2/5;
    }
  }
  :nth-of-type(even) {
    grid-column: 3/5;

    ${({ theme }) => MediaQuery(theme.iuiBreakpoints?.large ?? DefaultBreakpoints.large)} {
      grid-column: 7/12;
    }
  }
`;

type QuoteColProps = {
  isTextCentered: boolean;
};

export const QuoteCol = styled(Col)<QuoteColProps>`
  position: relative;
  padding-right: 1rem;

  ${({ isTextCentered }: QuoteColProps) =>
    isTextCentered
      ? css`
          display: flex;
          justify-content: center;
          flex-flow: column nowrap;
        `
      : ''}

  .endQuote {
    color: var(--color-secondary-300);
  }
  .dropCap {
    color: var(--color-secondary-300);
    font-size: 120px;
    position: absolute;
    user-select: none;
    left: -70px;
    top: -20px;
  }
  .body {
    margin-top: 1.25rem;
  }

  ${({ theme }) =>
    ExactMediaQuery(
      theme.iuiBreakpoints?.small ?? DefaultBreakpoints.small,
      theme.iuiBreakpoints?.medium ?? DefaultBreakpoints.medium
    )} {
    position: static;
    margin-top: 2rem;

    .dropCap {
      top: 40px;
      left: 10px;
    }
  }
`;

export const CarouselGrid: React.FC = styled(Grid)`
  ${({ theme }) =>
    ExactMediaQuery(
      theme.iuiBreakpoints?.small ?? DefaultBreakpoints.small,
      theme.iuiBreakpoints?.medium ?? DefaultBreakpoints.medium
    )} {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    ${ShadowMixin({ level: 'light' })}
    padding: 30px 12px;
    position: relative;
    box-shadow: none;
  }
`;
