import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';
import { LinkProps } from '.';
import { EmotionStyledProps } from '../../types';

export interface StyledLinkProps extends Partial<LinkProps>, EmotionStyledProps {
  hasIcon: boolean;
  margin?: string;
  padding?: string;
  inline?: boolean; // whether or not this is an anchor style link or not. inline is generally what you'll use within text
}

export const StyledWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

interface StyledIconContainerProps {
  iconToRight?: boolean;
  isLoading?: boolean;
}

export const StyledIconContainer = styled.div<StyledIconContainerProps>`
  display: inline-flex;
  align-items: center;
  padding-left: ${({ iconToRight }) => (!iconToRight ? '0' : `0.5rem`)};
  padding-right: ${({ iconToRight }) => (iconToRight ? '0' : `0.5rem`)};
`;

export const primary = ({ padding, inline }: StyledLinkProps): SerializedStyles =>
  css`
    font-weight: ${inline ? 'inherit' : '700'};
    ${!inline ? `${padding || '4px 12px'} ` : ''};
  `;

export const linkColorCss = ({ theme, variant }: StyledLinkProps): SerializedStyles => {
  const { alt, base } = theme.link;
  const colorSelection = variant === 'alt' ? alt : base;

  return css`
    color: ${colorSelection.color};
    :not(.is-disabled) {
      &:hover {
        color: ${colorSelection.hoverColor};
      }
      &:focus,
      &:active {
        color: ${colorSelection.focusColor};
      }
    }
    &.is-disabled {
      cursor: not-allowed;
      color: ${colorSelection.focusColor};
      > div {
        opacity: 0.5;
      }
    }
  `;
};

export const linkStyles = (props: StyledLinkProps): SerializedStyles => {
  const { theme, inline, margin } = props;
  return css`
    cursor: pointer;
    font-family: ${`${theme.iuiTypography.styles['button-primary'].fontFamily}, helvetica, sans-serif`};
    font-size: 1rem;
    line-height: 1.5rem;
    vertical-align: middle;

    ${inline
      ? `
      display: inline-block;
      text-decoration: underline;
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      font-family: inherit;
      `
      : ''}

    ${margin ? `margin: ${margin};` : ''}

    ${linkColorCss(props)}
    ${primary(props)}

    &:focus {
      outline: none;
    }
  `;
};
