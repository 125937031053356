export interface ImageProps extends React.ComponentProps<'img'> {
  loaderUrl?: string;
  responsive?: ResponsiveSize[];
  options?: SizelessOptions;
  alt: string;
}

export type ResponsiveSize = {
  size: {
    w?: number;
    h?: number;
  };
  maxWidth?: number;
};

export enum CCSObjectFit {
  CONTAIN = 'contain',
  COVER = 'cover',
  FILL = 'fill',
  INSIDE = 'inside',
  OUTSIDE = 'outside'
}

export enum Fit {
  PAD = 'pad',
  THUMB = 'thumb',
  FILL = 'fill',
  SCALE = 'scale',
  CROP = 'crop'
}

export enum ImageFocus {
  CENTER = 'center',
  TOP = 'top',
  RIGHT = 'right',
  LEFT = 'left',
  BOTTOM = 'bottom',
  TOP_RIGHT = 'top_right',
  TOP_LEFT = 'top_left',
  BOTTOM_RIGHT = 'bottom_right',
  BOTTOM_LEFT = 'bottom_left',
  FACE = 'face', // detects largest face
  FACES = 'faces' // fit all faces detected in
}

export interface TransformOptions {
  width?: number;
  height?: number;
  fl?: 'progressive';
  fm?: 'jpg' | 'png' | 'svg';
  objectFit?: `${CCSObjectFit}`;
  fixedHeights?: [number, number, number]; // m, t, d
  fit?: `${Fit}`;
  f?: `${ImageFocus}`;
  dev?: boolean;
  vw?: number;
  transform?: string;
  borderRadius?: string;
}

export type SizelessOptions = Omit<TransformOptions, 'width' | 'height'>;
export type APISizelessOptions = Omit<
  TransformOptions,
  'dev' | 'vw' | 'fixedHeights' | 'borderRadius' | 'transform'
>;
