import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--social-twitter';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 16 13'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='currentColor'
      d='M14.078 3.04c.01.132.01.263.01.394 0 4.003-3.155 8.616-8.922 8.616A9.092 9.092 0 01.35 10.69c.252.029.495.038.757.038A6.424 6.424 0 005 9.434c-1.379-.028-2.534-.9-2.932-2.1.194.028.388.047.592.047.282 0 .563-.037.825-.103C2.05 6.997.972 5.778.972 4.306V4.27c.417.225.903.365 1.417.384C1.545 4.109.991 3.181.991 2.131c0-.562.156-1.078.427-1.528 1.544 1.838 3.864 3.038 6.466 3.169a3.306 3.306 0 01-.078-.694C7.806 1.41 9.204.05 10.942.05c.903 0 1.719.366 2.291.956a6.309 6.309 0 001.99-.731 3.042 3.042 0 01-1.378 1.669 6.464 6.464 0 001.806-.47 6.62 6.62 0 01-1.573 1.567z'
    />
  </svg>
);

const SvgSocialTwitter = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgSocialTwitter.displayName = 'SocialTwitter';
export default SvgSocialTwitter;
