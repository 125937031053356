import * as React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import Typography from '../Typography';
import Box from '../Box';
import { MediaQuery, pxToRemMarginAndPadding } from '../../mixins';
import defaultBreakpoints from '../../themes/breakpoints';
import { useIsBreakpoint } from '../../hooks';

/* Add variants, text-colors, etc if needed, keeping current styles as default */

type BannerVariant = 'small' | 'large';
interface PageTitleBannerProps {
  title: string;
  subTitle: string;
  imageSrc: string;
  imageAlt: string;
  variant?: BannerVariant;
}

const Wrapper = styled.div<{ imageSrc: string; $variant?: BannerVariant }>`
  position: relative;
  width: 100%;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('${(props) => props?.imageSrc}');
  background-position: 50% 80%;
  background-size: cover;
  ${({ $variant }) => ($variant === 'large' ? `height: 320px;` : '')}

  ${(props) => MediaQuery(props.theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    ${({ $variant }) => ($variant === 'large' ? `height: 464px;` : '')}
    padding: 36px 20px;
  }
`;

export const PageTitleBanner: React.FC<PageTitleBannerProps> = ({
  title,
  subTitle,
  imageSrc,
  imageAlt,
  variant = 'small'
}) => {
  const { isDesktop } = useIsBreakpoint();
  const theme = useTheme();
  return (
    <Wrapper
      imageSrc={imageSrc}
      title={imageAlt}
      data-test='iui-component-page-title-banner'
      $variant={variant}
    >
      <Box
        position='absolute'
        top='0'
        left='0'
        width='100%'
        height='100%'
        zIndex='1'
        background={`rgba(0, 0, 0, ${variant === 'large' ? '0.3' : '0.35'})`}
      />
      <Typography
        variant={variant === 'large' || !isDesktop ? 'h1' : 'h3'}
        color='white'
        margin={pxToRemMarginAndPadding('0 0 8px 0')}
        textAlign='center'
        zIndex='2'
      >
        {title}
      </Typography>
      {isDesktop && (
        <Typography
          variant='body-m'
          color='white'
          margin={pxToRemMarginAndPadding('0 0 21px 0')}
          textAlign='center'
          zIndex='2'
        >
          {subTitle}
        </Typography>
      )}

      <Box
        width={variant === 'large' ? '200px' : '80px'}
        height='3px'
        background={theme?.iuiPalette?.brand[3] ?? 'orange'}
        zIndex='2'
      />
    </Wrapper>
  );
};

export default PageTitleBanner;
