import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-igh-icon--checked-true';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#00854B'
      d='M12 1.5C6.202 1.5 1.5 6.202 1.5 12S6.202 22.5 12 22.5 22.5 17.798 22.5 12 17.798 1.5 12 1.5zm4.535 7.071L11.6 15.415a.745.745 0 01-1.212 0l-2.922-4.05a.188.188 0 01.152-.298h1.1c.238 0 .466.115.606.312l1.67 2.316 3.684-5.11a.75.75 0 01.607-.312h1.099c.152 0 .241.174.152.298z'
    />
  </svg>
);

const SvgCheckedTrue = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgCheckedTrue.displayName = 'CheckedTrue';
export default SvgCheckedTrue;
