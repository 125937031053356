import * as React from 'react';
import styled from '@emotion/styled';
import { GraphicProps } from '../../../types';
import { GetGraphicDimensions } from '../../../mixins/icons';

const TEST_ID = 'iui-component-graphic--trustaff';

const SVG: React.FC<GraphicProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 338 338'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#512D6C'
      d='M230.157 150.623v52.885c0 10.086-27.163 21.826-60.623 21.826-33.46 0-60.623-11.74-60.623-21.826v-52.885h121.246z'
    />
    <path
      fill='#3D155B'
      d='M169 171.915l111-32.659v-3.309l-111 32.66-111-32.66v3.309l111 32.659z'
    />
    <path fill='#784B99' d='M280 135.947l-111 32.66-111-32.66L169 113l111 22.947z' />
    <path fill='#E0C9F1' d='M222.259 153.558l-47.122-15.69.374-1.067 47.121 15.689-.373 1.068z' />
    <path
      fill='#E0C9F1'
      d='M221.458 173.676l-.053-21.132h1.174v21.132h-1.121zM224.714 179.12h-5.017l-3.202 11.953h11.42l-3.201-11.953z'
    />
    <path
      fill='#E0C9F1'
      d='M221.992 181.575a4.483 4.483 0 10-.001-8.965 4.483 4.483 0 00.001 8.965z'
    />
    <path
      fill='#3D155B'
      d='M178.286 136.481c0 1.067-4.11 1.974-9.126 1.974-5.016 0-9.125-.853-9.125-1.974v-.32h18.251v.32z'
    />
    <path
      fill='#E0C9F1'
      d='M178.286 136.054c0 .8-4.11 1.707-9.126 1.707-5.016 0-9.125-.853-9.125-1.707 0-.854 4.109-3.576 9.125-3.576 5.016.054 9.126 2.775 9.126 3.576z'
    />
  </svg>
);

const SvgTrustaff = styled(SVG)`
  ${(props: GraphicProps) => GetGraphicDimensions(props)}
`;
SvgTrustaff.displayName = 'Trustaff';
export default SvgTrustaff;
