import * as React from 'react';
import styled, { StyledComponent } from '@emotion/styled';
import { IconProps } from '../../types';

interface IconButtonProps {
  icon: StyledComponent<IconProps>;
  color?: string;
  hoverColor?: string;
  size?: number;
  type?: 'button' | 'submit' | 'reset';
  tooltip?: string;
  margin?: string;
  onClick?: () => void;
  className?: string;
}

const IconButton: React.FC<IconButtonProps> = ({
  icon: Icon,
  color,
  hoverColor,
  size,
  type,
  tooltip,
  margin,
  onClick,
  className
}) => {
  const StyledButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: ${margin};
    padding: 0;

    &:hover {
      svg {
        path {
          fill: ${hoverColor};
        }
      }
    }
  `;

  return (
    <StyledButton
      className={className}
      title={tooltip}
      type={type ?? 'button'}
      onClick={onClick}
      data-test='iui-component-icon-button'
    >
      <Icon dimensions={size} customColor={color} />
    </StyledButton>
  );
};

export default IconButton;
