import {
  TypographyVariant,
  TypographyHeader,
  ThemedTypographyList,
  TypographyList,
  TypographyBaseVariant,
  TypographyBaseProps,
  ThemedTypographyProps,
  TypographyProps
} from './typography.types';
import { TypographyBaseListData, variantToBaseVariantMap } from './typography.data';

const headerList: Array<TypographyHeader> = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

export const headerType = (variant: TypographyVariant): TypographyHeader | undefined => {
  const firstTwoCharacters = variant.substr(0, 2);
  let toReturn;
  headerList.some((header) => {
    if (header === firstTwoCharacters) {
      toReturn = header;
      return true;
    }
    return false;
  });
  return toReturn;
};

export const combineTypographyStyles = (themedStylesList: ThemedTypographyList): TypographyList => {
  const variants = Object.keys(themedStylesList);
  const typographyList: { [index in string]: TypographyProps } = {};
  variants.forEach((variant) => {
    const baseVariant: TypographyBaseVariant =
      variantToBaseVariantMap[variant as TypographyVariant];
    const baseVariantStyles: TypographyBaseProps = TypographyBaseListData[baseVariant];
    const themedStyles: ThemedTypographyProps = themedStylesList[variant as TypographyVariant];
    typographyList[variant] = {
      ...baseVariantStyles,
      ...themedStyles
    };
  });
  return typographyList as TypographyList;
};
