import styled from '@emotion/styled';
import React from 'react';
import { useTheme } from '@emotion/react';
import { QuoteIcon, TrustaffQuoteIcon } from '../Icons';
import Typography from '../Typography';
import useIsBreakpoint from '../../hooks/useIsBreakpoint';
import { MediaQuery } from '../../mixins/mediaQuery';
import shadow from '../../mixins/shadow';

export const TrustaffQuoteWrapper = styled.div`
  position: relative;
  .quotation-mark {
    position: absolute;
    left: 24px;
    top: 24px;
  }
  ${({ theme }) => MediaQuery(theme.iuiBreakpoints.large)} {
    padding-top: 32px;
    padding-left: 16px;
    .quotation-mark {
      left: 0px;
      top: 0px;
    }
  }
`;

export const TrustaffQuoteText = styled.div`
  ${({ theme }) => shadow({ level: 'light', theme })};
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  padding: 96px 24px 40px;

  ${({ theme }) => MediaQuery(theme.iuiBreakpoints.large)} {
    padding: 64px 48px 48px;
  }
`;

const TrustaffQuote: React.FC<{ quote?: string; author?: string }> = ({ quote, author }) => {
  const { isDesktop } = useIsBreakpoint();
  return (
    <TrustaffQuoteWrapper data-testid='trustaff-quote-wrapper'>
      <TrustaffQuoteIcon
        width={isDesktop ? 98.62 : 40}
        height={isDesktop ? 80 : 32}
        className='quotation-mark'
      />
      <TrustaffQuoteText>
        {quote && (
          <Typography variant='h5' as='span' aria-hidden='true'>
            {quote}
          </Typography>
        )}
        {author && (
          <Typography variant='h6' margin='24px 0 0 0'>
            {author}
          </Typography>
        )}
      </TrustaffQuoteText>
    </TrustaffQuoteWrapper>
  );
};

export const FastaffQuoteWrapper = styled.div`
  position: relative;
  padding-top: 16px;
  padding-left: 16px;
  .quotation-mark {
    position: absolute;
    left: 0;
    top: 0;
  }
  ${({ theme }) => MediaQuery(theme.iuiBreakpoints.large)} {
    padding-top: 32px;
  }
`;

export const FastaffQuoteText = styled.div`
  ${({ theme }) => shadow({ level: 'light', theme })};
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  padding: 48px 16px 40px;

  ${({ theme }) => MediaQuery(theme.iuiBreakpoints.large)} {
    padding: 64px 48px 48px;
  }
`;

const FastaffQuote: React.FC<{ quote?: string; author?: string }> = ({ quote, author }) => {
  const { isDesktop } = useIsBreakpoint();
  return (
    <FastaffQuoteWrapper data-testid='fastaff-quote-wrapper'>
      <QuoteIcon dimensions={isDesktop ? 80 : 48} className='quotation-mark' />
      <FastaffQuoteText>
        {quote && (
          <Typography variant='h4' as='span' aria-hidden='true'>
            {quote}
          </Typography>
        )}
        {author && (
          <Typography variant='meta-l-caps' margin='24px 0 0 0'>
            {author}
          </Typography>
        )}
      </FastaffQuoteText>
    </FastaffQuoteWrapper>
  );
};

const Quote: React.FC<{ quote?: string; author?: string }> = ({ quote, author }) => {
  const theme = useTheme();

  return theme.name === 'trustaff' ? (
    <TrustaffQuote quote={quote} author={author} />
  ) : (
    <FastaffQuote quote={quote} author={author} />
  );
};

export default Quote;
