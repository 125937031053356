import * as React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { alpha } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import LargeToggle from './LargeToggle';
import { ToggleProps } from '.';
import { ToggleVariants } from './toggle.types';

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '&.MuiSwitch-root': {
    padding: '12px 9px'
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.toggles.colors?.enabled,
    '&:hover': {
      backgroundColor: alpha(theme.toggles.colors?.hover ?? theme.iuiPalette.primary[200], 0.5)
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.toggles.colors?.enabled,
      opacity: 0.5
    }
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.toggles.colors?.enabled
  }
}));

const StyledToggle: React.FC<ToggleProps> = (props) => {
  const { variant = ToggleVariants.SMALL, ...rest } = props;
  const theme = useTheme();

  return variant === ToggleVariants.SMALL ? (
    <CustomSwitch theme={theme} {...rest} />
  ) : (
    <LargeToggle theme={theme} {...rest} />
  );
};

export default StyledToggle;
