import * as React from 'react';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import ReactMarkdown from 'react-markdown';

import ExpandIcon from '../Icons/Expand';
import Typography from '../Typography';
import defaultBreakpoints from '../../themes/breakpoints';
import { MediaQuery } from '../../mixins/mediaQuery';

export interface AccordionProps {
  title: string;
  description: string | React.ReactNode;
  id: string;
  isOpen?: boolean;
}

export interface AccordionWrapperProps {
  AccordionItems: AccordionProps[];
  openFirstOnInit?: boolean;
}

const StyledAccordion = styled(MuiAccordion)<{ expanded: boolean }>`
  &.MuiPaper-root {
    background-color: ${({ theme, expanded }) =>
      expanded
        ? theme?.iuiPalette?.grays.white ?? 'white'
        : theme?.iuiPalette?.grays.grays[200] ?? 'gray'};
    padding: 32px 16px;
    ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
      padding: 40px;
    }
    border: 1px solid ${({ theme }) => theme?.iuiPalette?.grays.grays[300] ?? 'darkgray'};
    box-shadow: none;
    border-radius: 0;

    &:not(:last-child) {
      border-bottom: 0 solid transparent;
    }
  }

  & .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
    ${({ theme }) => `
    border-bottom: 1px solid ${theme?.iuiPalette?.grays.grays[300] ?? 'darkgray'};
    padding-bottom: 24px;
    ${MediaQuery(theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
        padding-bottom: 40px;
      }
      & path {
        fill: ${theme.iuiPalette.brand[1]};
      }
    `}
  }
`;

const StyledExpandIcon = styled(ExpandIcon)`
  &:hover {
    path {
      fill: ${({ theme }) => theme.iuiPalette.primaryAlt[300]};
    }
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  &.MuiButtonBase-root {
    padding: 0;
    align-items: flex-start;
    min-height: auto;
    & > .MuiAccordionSummary-content {
      margin: 0px;
    }
  }
`;

// margin is added to for scenarios where react markdown + multiple paragraphs
const StyledAccordionDetails = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 24px 0 0 0;
    ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
      padding: 40px 0 0 0;
    }
  }

  p:not(:first-of-type) {
    margin-top: 1rem;
  }
`;

export const AccordionSubComponent: React.FC<AccordionProps> = ({
  title,
  description,
  id,
  isOpen = false
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState<boolean>(isOpen);

  const body =
    typeof description === 'string' ? (
      <Typography as='span' variant='body-l'>
        <ReactMarkdown>{description}</ReactMarkdown>
      </Typography>
    ) : (
      description
    );

  return (
    <StyledAccordion
      disableGutters
      square
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <StyledAccordionSummary
        expandIcon={
          <StyledExpandIcon
            customColor={theme?.iuiPalette?.grays.black ?? undefined}
            dimensions={24}
          />
        }
        id={id}
      >
        <Typography variant='h5'>{title}</Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails data-testid='Accordion-description'>{body}</StyledAccordionDetails>
    </StyledAccordion>
  );
};

const Accordion: React.FC<AccordionWrapperProps> = ({ AccordionItems, openFirstOnInit = true }) => (
  <div data-test='iui-component-accordion'>
    {AccordionItems.map((item, idx) => (
      <AccordionSubComponent {...item} isOpen={openFirstOnInit && idx === 0} key={item.id} />
    ))}
  </div>
);
export default Accordion;
