import * as React from 'react';
import styled from '@emotion/styled';

import ReactMarkdown from 'react-markdown';
import { Typography, Container, Grid, GridColumns, Button, SectionTitle } from '../../components';
import defaultBreakpoints from '../../themes/breakpoints';
import { MediaQuery } from '../../mixins/mediaQuery';

interface ContentBWithImageProps {
  imgUrl: string;
  imgAlt: string;
  title: string;
  bodyText: string;
  buttonText: string;
  onButtonClick: () => void;
  afterFold?: boolean;
}

const Wrapper = styled.div`
  ${({ theme }) => `background-color: ${theme?.iuiPalette?.grays.white ?? 'white'};`}
  width: 100%;
  padding: 20px 0;
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    padding: 64px 0;
  }
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    padding: 54px 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const StyledImage = styled.img`
  width: 100%;
  aspect-ratio: 4/3;
  padding-left: 0;
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    padding-left: 15%;
  }
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    padding-left: 0;
  }
`;

const PositionedContentGridColumns = styled(GridColumns)`
  grid-row: 2;
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    grid-row: 1;
  }
`;

const ContentBWithImage: React.FC<ContentBWithImageProps> = ({
  imgUrl,
  imgAlt,
  title,
  bodyText,
  buttonText,
  onButtonClick,
  afterFold = false
}) => (
  <Wrapper>
    <Container>
      <Grid rowGap='40px'>
        <PositionedContentGridColumns columns={[7, 2, 2]} alignSelf='center'>
          <ContentWrapper>
            <SectionTitle level='3' variant='secondary-alt' margin='0 0 2rem '>
              {title}
            </SectionTitle>
            <Typography as='span'>
              <ReactMarkdown>{bodyText}</ReactMarkdown>
            </Typography>
            <Button
              margin='24px 0 0'
              label={buttonText}
              onClick={onButtonClick}
              colorway='light'
              fullWidthMobile
            />
          </ContentWrapper>
        </PositionedContentGridColumns>
        <GridColumns columns={[4, 2, 2]} columnStarts={[9, 3, 'auto']} alignSelf='center'>
          <StyledImage src={imgUrl} alt={imgAlt} {...(afterFold ? { loading: 'lazy' } : {})} />
        </GridColumns>
      </Grid>
    </Container>
  </Wrapper>
);

export default ContentBWithImage;
