import React from 'react';
import styled from '@emotion/styled';

import shadow from '../../mixins/shadow';
import Button from '../Button';
import Typography from '../Typography';
import { useIsBreakpoint } from '../../hooks';
import defaultBreakpoints from '../../themes/breakpoints';
import { MediaQuery } from '../../mixins/mediaQuery';

const CompactWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px 20px 20px;
  background-color: white;
  ${({ theme }) => shadow({ level: 'heavy', theme })}
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    padding: 8px 32px 32px 32px;
  }
`;

const CompactBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

const CompactImageWrapper = styled.div`
  flex-shrink: 0;
  position: relative;
  height: 150px;
  width: 150px;
`;

const CompactCard: React.FC<{
  title: string;
  description: string;
  buttonText?: string;
  buttonHref?: string;
  renderImageSection: JSX.Element | null;
}> = ({ title, description, buttonText, buttonHref, renderImageSection }) => {
  const { isMobile } = useIsBreakpoint();
  return (
    <CompactWrapper>
      <CompactBodyWrapper>
        <CompactImageWrapper>{renderImageSection}</CompactImageWrapper>
        <Typography variant={isMobile ? 'h4' : 'h5'}>{title}</Typography>
        <Typography margin='8px 0' variant={isMobile ? 'body-l' : 'body-m'}>
          {description}
        </Typography>
      </CompactBodyWrapper>
      {buttonText && buttonHref && (
        <Button
          asLink
          href={buttonHref}
          label={buttonText}
          variant='secondary'
          colorway='light'
          fullWidthMobile
        />
      )}
    </CompactWrapper>
  );
};

export default CompactCard;
