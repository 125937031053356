/* internal function to only calculate the internal value */
const pxToRemInternal = (pxVal: number): number => pxVal / 16;

export const DEFAULT_MARGIN_PADDING_VALUE = '0.5rem';

const endsWithPx = (text: string): boolean => {
  if (text.length < 2) {
    return false;
  }
  return text.substring(text.length - 2, text.length) === 'px';
};

/* converts px value to value for REM (FOR A DEFAULT OF 16px) */
/* accepts values are either string or number (eg - 32px or 32) */
/* For invalud values, return 1rem */
export const pxToRem = (input: string | number): string | number => {
  if (typeof input === 'number') {
    return pxToRemInternal(input);
  }
  if (endsWithPx(input)) {
    /* remove px, convert to number */
    const extractedNumber = Number(input.slice(0, -2));
    // eslint-disable-note: Number.isNaN is not available in es5
    // eslint-disable-next-line no-restricted-globals
    return isNaN(extractedNumber) ? '1rem' : `${pxToRem(extractedNumber)}rem`;
  }
  const convertedNumber = Number(input);
  /* if a stirng that does not end with px */
  // eslint-disable-next-line no-restricted-globals
  return isNaN(convertedNumber) ? '1' : `${pxToRemInternal(convertedNumber)}`;
};

/* converts a margin or padding string with px values to that using rem (FOR A DEFAULT OF 16px) */
/* returns a default of 0.5rem on an invalid input */
export const pxToRemMarginAndPadding = (input: string): string => {
  const splitStrings = input.split(' ');

  /* (INVALID) if there are are more than 4 vlaues, return default */
  if (splitStrings.length > 4) {
    return DEFAULT_MARGIN_PADDING_VALUE;
  }

  /* (INVALID) if a value is not 0 and does not end with px, return default */
  if (splitStrings.some((value) => value !== '0' && !endsWithPx(value))) {
    return DEFAULT_MARGIN_PADDING_VALUE;
  }

  /* Conver px to rem */
  const convertedStrings = splitStrings.map((value) => (value === '0' ? '0' : pxToRem(value)));
  return convertedStrings.join(' ');
};
