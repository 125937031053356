import React from 'react';

// eslint-disable-next-line react/require-default-props
const SVG: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    viewBox='0 0 203 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    data-test='iui-component-logo--usn'
  >
    <path
      d='M96.7092 0.56665H105.763C108.24 0.56665 110.188 1.25451 111.488 2.54931C112.624 3.68226 113.152 5.17937 113.152 7.04064V7.12156C113.152 8.74006 112.787 10.0349 111.975 11.0464C111.163 12.058 110.148 12.7863 108.93 13.2719L113.721 20.3123H108.686L104.464 14.0406H104.383H101.013V20.3123H96.628V0.56665H96.7092ZM105.438 10.1158C106.494 10.1158 107.306 9.87301 107.915 9.38746C108.483 8.90191 108.808 8.21405 108.808 7.32388V7.28341C108.808 6.35278 108.483 5.66492 107.915 5.17937C107.306 4.69382 106.494 4.49151 105.438 4.49151H101.135V10.1562H105.438V10.1158Z'
      fill='#3B58BF'
    />
    <path d='M144.374 0.56665H148.758V20.2719H144.374V0.56665Z' fill='#3B58BF' />
    <path
      d='M190.292 19.8272C189.034 19.3417 187.937 18.6538 187.044 17.7232C186.11 16.833 185.42 15.7405 184.933 14.4862C184.446 13.2318 184.202 11.8561 184.202 10.4399C184.202 9.02376 184.446 7.68849 184.933 6.47462C185.42 5.22029 186.192 4.16826 187.125 3.23763C188.059 2.30699 189.115 1.57867 190.414 1.05266C191.673 0.486183 193.053 0.243408 194.515 0.243408C195.408 0.243408 196.179 0.324333 196.951 0.44572C197.681 0.567108 198.331 0.76942 198.94 0.931269C199.549 1.17404 200.117 1.41682 200.686 1.82144C201.254 2.14514 201.741 2.54977 202.229 2.99485L199.468 6.27231C199.102 5.94861 198.737 5.66537 198.331 5.38214C197.966 5.13936 197.6 4.93705 197.154 4.7752C196.707 4.57289 196.342 4.4515 195.854 4.41104C195.367 4.28965 194.839 4.28965 194.312 4.28965C193.5 4.28965 192.769 4.49196 192.079 4.7752C191.388 5.0989 190.779 5.50352 190.252 6.07C189.764 6.63647 189.318 7.24341 189.074 8.01219C188.749 8.74052 188.628 9.54977 188.628 10.359V10.4399C188.628 11.3706 188.749 12.1798 189.074 12.9081C189.399 13.6365 189.764 14.3243 190.333 14.8908C190.901 15.4573 191.51 15.9024 192.282 16.1856C193.012 16.5093 193.824 16.6307 194.636 16.6307C195.448 16.6307 196.179 16.5093 196.869 16.307C197.56 16.1047 198.169 15.8214 198.737 15.4168V12.544H194.352V8.90237H203V17.4804C201.985 18.3706 200.767 19.0989 199.387 19.7058C198.006 20.3128 196.382 20.6365 194.596 20.6365C192.972 20.5555 191.51 20.3128 190.292 19.8272Z'
      fill='#3B58BF'
    />
    <path
      d='M72.8364 18.3297C71.3342 16.8325 70.5222 14.6476 70.5222 11.8152V0.56665H74.907V11.6938C74.907 13.3123 75.2724 14.4857 76.0438 15.3759C76.7746 16.1851 77.8708 16.6302 79.17 16.6302C80.4692 16.6302 81.5248 16.2661 82.2962 15.4568C83.0676 14.6476 83.433 13.4742 83.433 11.8961V0.56665H87.8178V11.6938C87.8178 13.1909 87.6148 14.4857 87.2088 15.6187C86.8434 16.7516 86.1938 17.6823 85.463 18.4106C84.7322 19.1389 83.7984 19.7054 82.7022 20.0695C81.6466 20.4337 80.4692 20.636 79.0888 20.636C76.4498 20.5551 74.3792 19.8268 72.8364 18.3297Z'
      fill='#3B58BF'
    />
    <path
      d='M124.236 19.7464C122.815 19.2609 121.475 18.4516 120.298 17.3996L122.856 14.3649C123.749 15.0933 124.683 15.6597 125.657 16.1048C126.591 16.5499 127.646 16.7117 128.783 16.7117C129.676 16.7117 130.407 16.5094 130.894 16.2262C131.382 15.9025 131.625 15.417 131.625 14.8505V14.7696C131.625 14.4459 131.544 14.2031 131.503 14.0412C131.382 13.8389 131.179 13.5961 130.894 13.4343C130.57 13.232 130.204 13.0701 129.717 12.8678C129.23 12.6655 128.54 12.5037 127.728 12.3013C126.713 12.0586 125.779 11.7349 124.967 11.4921C124.155 11.1684 123.465 10.8042 122.896 10.3591C122.328 9.91406 121.881 9.34759 121.597 8.74065C121.272 8.05279 121.15 7.24354 121.15 6.3129V6.23198C121.15 5.3418 121.353 4.49209 121.638 3.80423C121.962 3.07591 122.45 2.42851 123.059 1.94296C123.668 1.45741 124.358 1.01232 125.251 0.76955C126.104 0.44585 126.997 0.324463 128.052 0.324463C129.473 0.324463 130.854 0.526775 132.031 0.931399C133.209 1.37649 134.345 1.98342 135.401 2.79267L133.168 6.02967C132.275 5.42273 131.422 4.97764 130.529 4.61348C129.636 4.24932 128.864 4.12793 127.971 4.12793C127.078 4.12793 126.469 4.33024 126.022 4.61348C125.576 4.93718 125.413 5.3418 125.413 5.86782V5.94874C125.413 6.27244 125.495 6.55568 125.616 6.75799C125.738 7.00076 125.982 7.20308 126.307 7.36493C126.631 7.56724 127.037 7.72909 127.606 7.9314C128.174 8.13371 128.864 8.29556 129.717 8.49788C130.732 8.74065 131.585 9.06435 132.397 9.38805C133.209 9.71175 133.818 10.1164 134.386 10.5615C134.954 11.0065 135.32 11.573 135.563 12.18C135.807 12.7869 136.01 13.5557 136.01 14.4054V14.4863C136.01 15.4979 135.807 16.3476 135.442 17.1164C135.076 17.8447 134.589 18.4921 133.939 19.0586C133.33 19.5441 132.559 19.9892 131.706 20.232C130.813 20.4748 129.839 20.6771 128.783 20.6771C127.159 20.5152 125.657 20.2724 124.236 19.7464Z'
      fill='#3B58BF'
    />
    <path
      d='M17.0928 11.8568C17.0928 17.8048 13.6824 20.9204 8.56677 20.9204C3.53237 20.9204 0.0407715 17.8048 0.0407715 12.0186V0.203613H2.35497V11.9377C2.35497 16.3481 4.70977 18.8568 8.64797 18.8568C12.4644 18.8568 14.8192 16.5504 14.8192 12.0996V0.203613H17.1334V11.8568H17.0928Z'
      fill='#3B58BF'
    />
    <path
      d='M31.1402 9.30636C35.6874 10.3179 37.7986 11.9769 37.7986 15.1329C37.7986 18.6936 34.8754 20.9595 30.8154 20.9595C27.5674 20.9595 24.8878 19.9075 22.4112 17.6821L23.8322 16.0231C25.9434 17.9653 28.014 18.9364 30.8966 18.9364C33.6574 18.9364 35.4438 17.5202 35.4438 15.4566C35.4438 13.5144 34.4288 12.4624 30.0846 11.5318C25.3344 10.5607 23.142 9.02312 23.142 5.58381C23.142 2.30636 26.0246 0 29.8816 0C32.886 0 34.9972 0.890173 37.1084 2.46821L35.728 4.28902C33.7792 2.75144 31.9116 2.06358 29.8004 2.06358C27.1208 2.06358 25.4156 3.56069 25.4156 5.42196C25.578 7.28324 26.6336 8.33526 31.1402 9.30636Z'
      fill='#3B58BF'
    />
    <path d='M48.0704 0.56665H44.0916V20.2719H48.0704V7.7285V0.56665Z' fill='#1B307D' />
    <path d='M57.3678 0.56665V13.0695V20.2719H61.5902V0.56665H57.3678Z' fill='#1B307D' />
    <path
      d='M57.3678 12.7054L48.0704 0.56665V7.7285L56.7994 19.5435L57.3678 20.2719V13.0695V12.7054Z'
      fill='#8FAEF2'
    />
    <path d='M162.928 0.56665H158.949V20.2719H162.928V7.7285V0.56665Z' fill='#1B307D' />
    <path d='M172.225 0.56665V13.0695V20.2719H176.448V0.56665H172.225Z' fill='#1B307D' />
    <path
      d='M172.225 12.7054L162.928 0.56665V7.7285L171.657 19.5435L172.225 20.2719V13.0695V12.7054Z'
      fill='#8FAEF2'
    />
  </svg>
);

export default SVG;
