import * as React from 'react';
import { useTheme } from '@emotion/react';

import {
  StyledButton,
  StyledTypography,
  StyledIconContainer,
  StyledLoadingContainer,
  StyledWrapper
} from './button.styles';
import Typography from '../Typography';
import Spinner from '../Spinner';
import { safeProps } from '../../utils/safeProps';
import { ButtonVariants, ButtonColorways, ButtonTypes, RefProps, RefType } from './button.types';
import { LinkContext } from '../../providers/Linker';

export interface ButtonProps {
  label: string;
  asLink?: boolean;
  href?: string;
  variant?: `${ButtonVariants}`; // break enum unions so consumers use as string types not enums
  colorway?: `${ButtonColorways}`; // break enum unions so consumers use as string types not enums
  type?: `${ButtonTypes}`;
  icon?: JSX.Element;
  isLoading?: boolean;
  iconToRight?: boolean;
  margin?: string;
  fullWidth?: boolean; // button to take full width at all breakpoints
  fullWidthMobile?: boolean; // button to take full width at the mobile breakpoint
  // anchorStyles?: boolean; // TODO: all the button to be styled as an anchor
  onClick?(): void;
  disabled?: boolean;
  'aria-label'?: string;
  'aria-describedby'?: string;
  withBorder?: boolean;
}

export const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const {
    label,
    asLink = false,
    type = ButtonTypes.BUTTON,
    variant = ButtonVariants.PRIMARY,
    colorway = ButtonColorways.DARK,
    icon,
    isLoading,
    iconToRight,
    margin,
    fullWidthMobile,
    onClick: onClickButton,
    href = '',
    ...rest
  } = props;

  // default colorway is dark, unless variant is tertiary, then colorway is light -- there's no dark tertiary
  const colorwayToUse = variant === ButtonVariants.TERTIARY ? ButtonColorways.LIGHT : colorway;
  const theme = useTheme();
  const colors = theme.buttons?.colorways && theme.buttons?.colorways[colorwayToUse];

  const CustomLink = React.useContext(LinkContext);

  const InnerButton: React.FC = () => (
    <StyledWrapper>
      {icon && !iconToRight && (
        <StyledIconContainer iconToRight={iconToRight} isLoading={isLoading}>
          {icon}
        </StyledIconContainer>
      )}
      {isLoading && (
        <StyledLoadingContainer>
          <Spinner />
          <Typography
            variant={theme.buttons[variant]?.font ?? 'meta-l'}
            as='span'
            color='inherit'
            useIngenovisStyles={variant === 'faux-auth0'}
          >
            {label}
          </Typography>
        </StyledLoadingContainer>
      )}

      <StyledTypography
        isLoading={isLoading}
        variant={theme.buttons[variant]?.font ?? 'meta-l'}
        as='span'
        color='inherit'
        useIngenovisStyles={variant === 'faux-auth0'}
      >
        {label}
      </StyledTypography>

      {icon && !isLoading && iconToRight && (
        <StyledIconContainer iconToRight={iconToRight}>{icon}</StyledIconContainer>
      )}
    </StyledWrapper>
  );

  const ButtonElement = () => (
    <StyledButton
      {...safeProps(rest)}
      as='button'
      variant={variant}
      colorway={colorwayToUse}
      isLoading={isLoading}
      hasIcon={!!icon}
      margin={margin}
      data-test='Button'
      fullWidthMobile={fullWidthMobile}
      type={type}
      onClick={onClickButton}
      onKeyPress={(e) => e.key === 'Enter' && !!onClickButton && onClickButton()}
      theme={theme}
    >
      <InnerButton />
    </StyledButton>
  );

  // eslint-disable-next-line react/prop-types
  const AnchorElement = React.forwardRef<RefType, RefProps>(({ onClick, href: link }, ref) => (
    <StyledButton
      {...safeProps(rest)}
      variant={variant}
      colorway={colorwayToUse}
      isLoading={isLoading}
      hasIcon={!!icon}
      margin={margin}
      data-test='iui-component-button'
      fullWidthMobile={fullWidthMobile}
      theme={theme}
    >
      <InnerButton />
    </StyledButton>
  ));
  AnchorElement.displayName = 'iuiAnchor';

  return asLink ? (
    <CustomLink href={href} to={href}>
      <AnchorElement />
    </CustomLink>
  ) : (
    <ButtonElement />
  );
};

export default Button;
