import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--exclaim';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 14 14'
    className={className}
    data-test={TEST_ID}
  >
    <path fill='#ffb05a' d='M7.7 10.5H6.3V9.1h1.4zm0-2.8H6.3V3.5h1.4z' />
  </svg>
);

const SvgExclaim = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgExclaim.displayName = 'Exclaim';
export default SvgExclaim;
