import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--briefcase-trustaff';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 104 104'
    className={className}
    data-test={TEST_ID}
  >
    <circle cx={52} cy={52} r={52} fill='#FFF7EC' />
    <path
      fill='#302599'
      d='M28.241 35.544h47.35c2.078 0 4.17 2.092 4.17 4.17l-.421 30.874a3.748 3.748 0 01-3.75 3.749H27.794a3.748 3.748 0 01-3.749-3.75V39.756a4.185 4.185 0 014.197-4.211z'
    />
    <path
      fill='#8A8FD9'
      d='M29.165 36.78H76.61a3.635 3.635 0 013.626 3.627v29.366a3.635 3.635 0 01-3.627 3.626H29.165a3.635 3.635 0 01-3.627-3.626V40.407a3.635 3.635 0 013.627-3.627z'
    />
    <path
      fill='#E8EAFF'
      d='M29.382 37.46h46.874a3.275 3.275 0 013.274 3.273V72.87a3.275 3.275 0 01-3.274 3.273H29.382a3.275 3.275 0 01-3.274-3.273V40.746a3.278 3.278 0 013.274-3.287z'
    />
    <path
      fill='#8A8FD9'
      d='M61.573 36.06H59.97v-5.324c0-1.06-.815-1.929-1.82-1.929H45.912c-1.005 0-1.82.87-1.82 1.929v5.338h-1.603v-5.338c0-1.943 1.535-3.518 3.423-3.518H58.15c1.888 0 3.423 1.575 3.423 3.518v5.324z'
    />
    <path
      fill='#100765'
      d='M39.759 58.173c-.204 0-.407-.014-.611-.027-5.99-.435-8.041-1.521-8.354-1.712-2.798-1.426-3.368-4.523-3.436-4.984-.652-2.187-.978-4.877-.978-8.014h1.209c0 3.029.312 5.623.937 7.701l.014.04v.055c0 .027.42 2.907 2.811 4.13l.068.04c.014.014 1.82 1.1 7.81 1.535.951.068 1.766-.04 2.472-.326a6.83 6.83 0 01-.91-.34c-1.046-.475-1.412-1.534-1.018-2.893-1.264-.312-2.337-1.168-3.097-2.472-.68-1.14-1.06-2.594-1.073-3.98a3.995 3.995 0 01.067-.869 5.319 5.319 0 013.464-4.102 5.404 5.404 0 015.379.937c1.385 1.168 2.024 2.853 1.901 5.026-.108 2.119-.271 5.066-1.494 7.28.87-.42 1.739-1.127 2.703-2.05 4.007-3.831 6.425-10.011 6.452-10.066l1.128.435c-.109.258-2.54 6.48-6.738 10.5-1.344 1.29-2.893 2.58-4.794 2.784l-.027.027c-1.046.897-2.337 1.345-3.885 1.345zm1.127-4.32c0 .014 0 .028-.013.041-.231.992.23 1.21.407 1.29.652.3 1.263.435 1.834.449 1.752-1.902 1.942-5.542 2.065-7.77.095-1.792-.38-3.11-1.481-4.033-1.168-.978-2.73-1.25-4.184-.734a4.12 4.12 0 00-2.689 3.179c-.04.23-.054.448-.054.638.013 2.255 1.222 4.89 3.409 5.338l.054.014c.72-1.304 1.97-2.608 2.989-2.459.394.054 1.045.353 1.045 1.657 0 1.155-.353 1.942-1.059 2.336-.706.421-1.616.286-2.323.055zm.462-1.113c.462.136.965.204 1.263.027.367-.217.449-.815.449-1.29 0-.258-.028-.394-.055-.462-.34.068-1.114.788-1.657 1.725z'
    />
    <path
      fill='#100765'
      d='M26.978 44.047a8.431 8.431 0 01-6.03-2.5 8.431 8.431 0 01-2.5-6.03v-.72h1.21v.72c0 1.956.76 3.79 2.145 5.175a7.264 7.264 0 005.175 2.146c4.034 0 7.321-3.287 7.321-7.321v-.72h1.21v.72c.013 4.7-3.817 8.53-8.53 8.53z'
    />
    <path
      fill='#C5C7F2'
      d='M35.413 34.838l-.937-.068.557-7.158a7.453 7.453 0 00-5.013-7.634l.3-.883a8.392 8.392 0 015.65 8.598l-.557 7.145zM18.624 34.838l-.598-7.158a8.413 8.413 0 015.61-8.625l.312.882c-3.219 1.128-5.27 4.279-4.985 7.661l.598 7.158-.937.082z'
    />
    <path
      fill='#FFB05A'
      d='M29.11 20.263l1.169.095a.256.256 0 00.271-.23l.082-.992a.256.256 0 00-.231-.272l-1.168-.095a.753.753 0 00-.802.68.758.758 0 00.68.814zM24.642 20.25l-1.169.095a.256.256 0 01-.271-.231l-.082-.992a.256.256 0 01.231-.271l1.168-.082c.408-.027.775.272.802.68.027.407-.272.774-.68.8z'
    />
    <path
      fill='#302599'
      d='M78.13 71.443l7.607-20.469c.285-1.195-.774-3.504-1.575-4.469-.761-.91-2.282-2.431-4.456-2.431H33.458a3.938 3.938 0 00-3.844 3.097l-4.672 24.028c-.503 2.812 2.485 5.854 4.985 5.854l-.326-2.608h44.728a3.907 3.907 0 003.803-3.002z'
    />
    <path
      fill='#675DC6'
      d='M80.29 74.39l5.447-22.954c.598-2.513-1.29-4.93-3.858-4.93H35.195c-1.874 0-3.477 1.317-3.87 3.164l-4.863 22.955c-.53 2.486 1.358 4.836 3.87 4.836h46.1a3.963 3.963 0 003.858-3.07z'
    />
    <path
      fill='#C5C7F2'
      d='M75.604 47.157h-2.961L65.824 76.7h2.961l6.819-29.543zM46.007 47.157h-2.96L36.24 76.7h2.948l6.818-29.543z'
    />
    <path fill='#302599' d='M55.34 43.667a2.649 2.649 0 10-.001-5.298 2.649 2.649 0 000 5.298z' />
    <path fill='#E8EAFF' d='M55.339 43.3a2.282 2.282 0 100-4.564 2.282 2.282 0 000 4.564z' />
    <path fill='#FFB05A' d='M55.339 42.186a1.168 1.168 0 100-2.336 1.168 1.168 0 000 2.336z' />
    <mask
      id='mask0_2685_12473'
      style={{
        maskType: 'alpha'
      }}
      width={4}
      height={4}
      x={53}
      y={39}
      maskUnits='userSpaceOnUse'
    >
      <path fill='url(#pattern0)' d='M53.865 39.467h2.988v2.988h-2.988z' />
    </mask>
    <g mask='url(#mask0_2685_12473)'>
      <path
        fill='#0F0027'
        d='M55.339 42.186a1.168 1.168 0 100-2.336 1.168 1.168 0 000 2.336z'
        opacity={0.1}
      />
    </g>
    <defs>
      <pattern id='pattern0' width={1} height={1} patternContentUnits='objectBoundingBox'>
        <use xlinkHref='#image0_2685_12473' />
      </pattern>
      <image
        id='image0_2685_12473'
        xlinkHref='data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEASABIAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA'
      />
    </defs>
  </svg>
);

const SvgBriefcaseTrustaff = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgBriefcaseTrustaff.displayName = 'BriefcaseTrustaff';
export default SvgBriefcaseTrustaff;
