import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--briefcase-fastaff';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 128 128'
    className={className}
    data-test={TEST_ID}
  >
    <circle cx={64} cy={64} r={64} fill='#FCEFCA' />
    <g clipPath='url(#clip0_390:7212)'>
      <path
        fill='#333F67'
        d='M37.574 45.696H90.24c2.311 0 4.638 2.327 4.638 4.639l-.468 34.34a4.169 4.169 0 01-4.17 4.17H37.075a4.168 4.168 0 01-4.17-4.17V50.38a4.655 4.655 0 014.669-4.684z'
      />
      <path
        fill='#616D98'
        d='M38.6 47.07h52.773a4.043 4.043 0 014.034 4.035v32.664a4.043 4.043 0 01-4.034 4.033H38.6a4.043 4.043 0 01-4.034-4.034V51.106A4.043 4.043 0 0138.6 47.07z'
      />
      <path
        fill='#DFE6F6'
        d='M38.842 47.826h52.139a3.643 3.643 0 013.64 3.641v35.746a3.643 3.643 0 01-3.64 3.641H38.842a3.643 3.643 0 01-3.64-3.64V51.481a3.646 3.646 0 013.64-3.656z'
      />
      <path
        fill='#616D98'
        d='M74.649 46.27h-1.783v-5.922c0-1.179-.906-2.146-2.024-2.146H57.229c-1.118 0-2.024.967-2.024 2.146v5.937h-1.783v-5.937c0-2.16 1.707-3.913 3.807-3.913h13.613c2.1 0 3.807 1.752 3.807 3.913v5.922z'
      />
      <path
        fill='#2A3453'
        d='M50.385 70.866c-.226 0-.453-.015-.68-.03-6.662-.484-8.944-1.692-9.29-1.904-3.113-1.586-3.748-5.03-3.823-5.544-.725-2.433-1.088-5.424-1.088-8.914h1.345c0 3.369.347 6.255 1.042 8.566l.015.045v.06c0 .031.468 3.234 3.127 4.594l.076.045c.015.015 2.024 1.224 8.687 1.707 1.058.076 1.964-.045 2.75-.362a7.569 7.569 0 01-1.012-.378c-1.164-.529-1.572-1.707-1.133-3.218-1.406-.348-2.6-1.3-3.445-2.75-.756-1.269-1.178-2.885-1.194-4.427-.015-.287.016-.619.076-.966.378-2.115 1.813-3.823 3.852-4.563a6.01 6.01 0 015.983 1.042c1.541 1.3 2.251 3.173 2.115 5.59-.12 2.357-.302 5.636-1.661 8.098.966-.468 1.933-1.254 3.006-2.28C63.59 61.015 66.28 54.14 66.31 54.08l1.254.483c-.12.287-2.825 7.207-7.493 11.679-1.496 1.435-3.218 2.87-5.333 3.097l-.03.03c-1.164.998-2.6 1.496-4.322 1.496zm1.254-4.804c0 .015 0 .03-.015.045-.257 1.103.257 1.345.453 1.435.726.333 1.406.484 2.04.499 1.95-2.115 2.16-6.164 2.297-8.642.105-1.994-.423-3.46-1.647-4.487-1.3-1.088-3.037-1.39-4.654-.816-1.586.574-2.704 1.904-2.99 3.535-.046.257-.061.499-.061.71.015 2.508 1.36 5.44 3.792 5.938l.06.015c.801-1.45 2.19-2.9 3.324-2.734.438.06 1.163.392 1.163 1.843 0 1.284-.393 2.16-1.178 2.598-.786.469-1.798.317-2.584.06zm.514-1.24c.514.152 1.073.227 1.405.031.408-.242.499-.906.499-1.435 0-.287-.03-.438-.06-.514-.378.076-1.24.876-1.844 1.919z'
      />
      <path
        fill='#2A3453'
        d='M36.169 55.154a9.378 9.378 0 01-6.708-2.78 9.378 9.378 0 01-2.78-6.708v-.8h1.344v.8a8.08 8.08 0 002.387 5.756 8.08 8.08 0 005.757 2.387c4.487 0 8.143-3.656 8.143-8.143v-.8h1.345v.8c.015 5.227-4.246 9.488-9.488 9.488z'
      />
      <path
        fill='#DFE2EC'
        d='M45.55 44.91l-1.042-.075.62-7.962a8.29 8.29 0 00-5.575-8.491l.332-.982a9.334 9.334 0 016.285 9.563l-.62 7.947zM26.877 44.91l-.665-7.961a9.358 9.358 0 016.24-9.594l.347.982c-3.58 1.254-5.862 4.76-5.544 8.521l.664 7.962-1.042.09z'
      />
      <path
        fill='#F5995C'
        d='M38.54 28.7l1.3.105a.284.284 0 00.302-.257l.09-1.102a.284.284 0 00-.256-.303l-1.3-.105a.838.838 0 00-.89.755.843.843 0 00.755.906zM33.57 28.684l-1.3.106a.284.284 0 01-.302-.257l-.09-1.103a.284.284 0 01.256-.302l1.3-.09c.453-.03.86.302.89.755.031.453-.301.861-.755.891z'
      />
      <path
        fill='#333F67'
        d='M93.066 85.627l8.46-22.768c.317-1.33-.861-3.898-1.752-4.97-.847-1.013-2.539-2.705-4.956-2.705H43.375a4.38 4.38 0 00-4.276 3.445l-5.197 26.726c-.559 3.127 2.765 6.512 5.545 6.512l-.363-2.901h49.751a4.346 4.346 0 004.23-3.339z'
      />
      <path
        fill='#495683'
        d='M95.468 88.905l6.059-25.533c.664-2.795-1.436-5.484-4.291-5.484H45.309c-2.085 0-3.867 1.466-4.305 3.52l-5.41 25.533c-.589 2.765 1.512 5.379 4.307 5.379h51.277a4.408 4.408 0 004.29-3.415z'
      />
      <path
        fill='#B2C2E8'
        d='M90.256 58.614h-3.294l-7.584 32.86h3.293l7.585-32.86zM57.334 58.614h-3.293l-7.57 32.86h3.28l7.583-32.86z'
      />
      <path fill='#333F67' d='M67.714 54.731a2.946 2.946 0 100-5.892 2.946 2.946 0 000 5.892z' />
      <path fill='#DFE2EC' d='M67.714 54.323a2.538 2.538 0 100-5.076 2.538 2.538 0 000 5.076z' />
      <path fill='#F5995C' d='M67.714 53.084a1.3 1.3 0 100-2.599 1.3 1.3 0 000 2.599z' />
      <mask
        id='mask0_390:7212'
        style={{
          maskType: 'alpha'
        }}
        width={4}
        height={4}
        x={66}
        y={50}
        maskUnits='userSpaceOnUse'
      >
        <path fill='url(#pattern0)' d='M66.075 50.06h3.324v3.324h-3.324z' />
      </mask>
      <g mask='url(#mask0_390:7212)'>
        <path
          fill='#1D243D'
          d='M67.714 53.084a1.3 1.3 0 100-2.599 1.3 1.3 0 000 2.599z'
          opacity={0.1}
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_390:7212'>
        <path fill='#fff' d='M0 0h75.979v65.711H0z' transform='translate(26.01 26.695)' />
      </clipPath>
      <pattern id='pattern0' width={1} height={1} patternContentUnits='objectBoundingBox'>
        <use xlinkHref='#image0_390:7212' />
      </pattern>
      <image
        id='image0_390:7212'
        xlinkHref='data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEASABIAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA'
      />
    </defs>
  </svg>
);

const SvgBriefcaseFastaff = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgBriefcaseFastaff.displayName = 'BriefcaseFastaff';
export default SvgBriefcaseFastaff;
