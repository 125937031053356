import * as React from 'react';
import { Theme } from '@emotion/react';

import { TypographyList } from '../components/Typography/typography.types';
import { DEFAULT_THEME, GetTheme } from '../themes';

const makeLastSpaceNonBreaking = (text: string): string => {
  if (!text.includes(' ')) {
    return text;
  }
  const trimmed = text.trim();
  const index = trimmed.lastIndexOf(' ');
  const replacement = '\u00a0';
  return trimmed.substr(0, index) + replacement + trimmed.substr(index + replacement.length);
};

// see: https://github.com/gouch/to-title-case/blob/master/to-title-case.js
const toTitleCase = (text: string): string => {
  const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i;
  const alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/;
  const wordSeparators = /([ :–—-])/;
  return text
    .split(wordSeparators)
    .map((current, index, array) => {
      if (
        /* Check for small words */
        current.search(smallWords) > -1 &&
        /* Skip first and last word */
        index !== 0 &&
        index !== array.length - 1 &&
        /* Ignore title end and subtitle start */
        array[index - 3] !== ':' &&
        array[index + 1] !== ':' &&
        /* Ignore small words that start a hyphenated phrase */
        (array[index + 1] !== '-' || (array[index - 1] === '-' && array[index + 1] === '-'))
      ) {
        return current.toLowerCase();
      }

      /* Ignore intentional capitalization */
      if (current.substr(1).search(/[A-Z]|\../) > -1) {
        return current;
      }

      /* Ignore URLs */
      if (array[index + 1] === ':' && array[index + 2] !== '') {
        return current;
      }

      /* Capitalize the first letter */
      return current.replace(alphanumericPattern, (match) => match.toUpperCase());
    })
    .join('');
};

const toSentenceCase = (text: string): string =>
  text.toLowerCase().replace(/(^\s*\w|[.!?]\s*\w)/g, (c) => c.toUpperCase());

const childrenToDeepCase = (
  // eslint-disable-note: typing here is getting far to complex
  // eslint-disable-next-line
  children: any,
  // eslint-disable-next-line
  transformer: any,
  isInRecursive = false
): React.ReactNode =>
  React.Children.map(children, (childNode) => {
    if (typeof childNode === 'string') {
      return !isInRecursive
        ? makeLastSpaceNonBreaking(transformer(childNode))
        : transformer(childNode);
    }

    if (typeof childNode.props.children === 'string')
      return React.cloneElement(childNode, [], transformer(childNode.props.children));
    return React.cloneElement(
      childNode,
      [],
      childrenToDeepCase(childNode.props.children, transformer, true)
    );
  });

const getStylesList = (theme?: Theme, useIngenovisStyles?: boolean): TypographyList => {
  if (!theme || !theme.iuiTypography) {
    return GetTheme(DEFAULT_THEME).iuiTypography.styles;
  }
  if (useIngenovisStyles) {
    return theme.iuiTypography.ingenovisStyles;
  }
  return theme.iuiTypography.styles;
};

export { makeLastSpaceNonBreaking, toTitleCase, toSentenceCase, childrenToDeepCase, getStylesList };
