import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--close-mini';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 16 16'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#D9D9D9'
      fillRule='evenodd'
      d='M8 16A8 8 0 108 0a8 8 0 000 16z'
      clipRule='evenodd'
    />
    <path
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M10.182 5.818L8 8l2.182 2.182M5.818 5.818L8 8l-2.182 2.182'
    />
  </svg>
);

const SvgCloseMini = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgCloseMini.displayName = 'CloseMini';
export default SvgCloseMini;
