import * as React from 'react';
import styled from '@emotion/styled';
import { GraphicProps } from '../../../types';
import { GetGraphicDimensions } from '../../../mixins/icons';

const TEST_ID = 'iui-component-graphic--ingenovis';

const SVG: React.FC<GraphicProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 338 338'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#333F67'
      d='M231.11 155.185h9.791a6.11 6.11 0 006.099-6.1V93.898c0-3.36-2.738-6.1-6.099-6.1h-9.791a6.109 6.109 0 00-6.099 6.1v55.187a6.11 6.11 0 006.099 6.1z'
    />
    <path fill='url(#pattern0)' d='M219.937 83.56h19.5v77.594h-19.5z' />
    <path
      fill='#333F67'
      d='M228.952 155.184h1.287a3.94 3.94 0 003.941-3.942V91.74a3.94 3.94 0 00-3.941-3.942h-1.287a3.94 3.94 0 00-3.941 3.942v59.502a3.94 3.94 0 003.941 3.942z'
    />
    <path
      fill='#333F67'
      d='M229.948 262.571H96.232c-2.323 0-4.232-1.909-4.232-4.233V80.662c0-2.324 1.909-4.233 4.232-4.233h133.716c2.324 0 4.232 1.909 4.232 4.233v177.676a4.223 4.223 0 01-4.232 4.233z'
    />
    <path fill='url(#pattern1)' d='M100.036 87.709H227.82v165.976H100.036z' />
    <path fill='#DFE2EC' d='M225.799 88.13H101.003v163.403h124.796V88.13z' />
    <path fill='url(#pattern2)' d='M95.058 82.73h137.741v175.934H95.058z' />
    <g filter='url(#filter0_d_1201_4181)'>
      <path fill='#fff' d='M225.799 88.13H101.003v163.403h124.796V88.13z' />
    </g>
    <path fill='url(#pattern3)' d='M171.396 176.506h69.7v73.029h-69.7z' opacity={0.2} />
    <path fill='url(#pattern4)' d='M171.811 218.415h35.68v31.12h-35.68z' opacity={0.6} />
    <path
      fill='#DFE2EC'
      d='M226.463 186.927l-7.343-5.352c12.363-16.058 22.569-26.971 24.312-25.685 1.784 1.286-5.477 14.357-16.969 31.037z'
    />
    <path
      fill='#F5995C'
      d='M224.721 189.5l-7.468-5.436c.622-.83 1.286-1.659 1.908-2.489l7.344 5.352a96.265 96.265 0 00-1.784 2.573z'
    />
    <path
      fill='#DFE2EC'
      d='M218.788 197.84c-9.542 13.112-18.628 24.274-24.769 30.706l-4.439-3.237c4.191-7.801 12.032-19.875 21.574-33.029a596.921 596.921 0 016.099-8.174l7.468 5.436c-1.909 2.655-3.859 5.435-5.933 8.298z'
    />
    <path
      fill='#F5995C'
      d='M194.019 228.504l-4.605 3.486-2.282 1.701c-.207.124-.456-.041-.373-.29l.913-2.698 1.908-5.477 4.439 3.278z'
    />
    <path
      stroke='#2A3453'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={0.83}
      d='M182.278 234.438c-6.721 1.535-10.289-4.315-15.807 3.402-.663.955 1.701-5.477-.871-6.805-2.448-1.286-4.854 3.029-6.472 6.888-.456 1.079 1.452-10.083-3.07-3.568-4.522 6.514.124.954-.54-2.614M128.717 237.882s11.036 3.485 13.774-11.743c2.531-14.066-11.658 12.158 5.725 11.121 0 0-5.02-5.519 3.112-4.938'
    />
    <path
      fill='#758BD2'
      d='M131.165 154.313a2.055 2.055 0 01-2.075-2.075c0-1.161.913-2.074 2.075-2.074h41.779c1.161 0 2.074.913 2.074 2.074a2.055 2.055 0 01-2.074 2.075h-41.779zM130.335 161.906c-.622 0-1.12-.498-1.12-1.12 0-.622.498-1.12 1.12-1.12h65.717c.623 0 1.12.498 1.12 1.12 0 .622-.497 1.12-1.12 1.12h-65.717zM130.335 169.209c-.622 0-1.12-.498-1.12-1.12 0-.622.498-1.12 1.12-1.12h65.717c.623 0 1.12.498 1.12 1.12 0 .622-.497 1.12-1.12 1.12h-65.717zM131.165 187.383a2.054 2.054 0 01-2.075-2.074c0-1.162.913-2.075 2.075-2.075h41.779c1.161 0 2.074.913 2.074 2.075a2.054 2.054 0 01-2.074 2.074h-41.779zM130.335 194.977c-.622 0-1.12-.498-1.12-1.12 0-.623.498-1.12 1.12-1.12h65.717c.623 0 1.12.497 1.12 1.12 0 .622-.497 1.12-1.12 1.12h-65.717zM130.335 202.28c-.622 0-1.12-.498-1.12-1.12 0-.623.498-1.121 1.12-1.121h65.717c.623 0 1.12.498 1.12 1.121 0 .622-.497 1.12-1.12 1.12h-65.717zM130.335 128.794c-.622 0-1.12-.498-1.12-1.12 0-.622.498-1.12 1.12-1.12h65.717c.623 0 1.121.498 1.121 1.12 0 .622-.498 1.12-1.121 1.12h-65.717zM130.335 136.097c-.622 0-1.12-.498-1.12-1.12 0-.622.498-1.12 1.12-1.12h65.717c.623 0 1.121.498 1.121 1.12 0 .622-.498 1.12-1.121 1.12h-65.717zM131.165 121.242a2.055 2.055 0 01-2.075-2.074c0-1.162.913-2.075 2.075-2.075h41.779c1.161 0 2.074.913 2.074 2.075a2.054 2.054 0 01-2.074 2.074h-41.779z'
    />
    <defs>
      <pattern id='pattern0' width={1} height={1} patternContentUnits='objectBoundingBox'>
        <use xlinkHref='#image0_1201_4181' />
      </pattern>
      <pattern id='pattern1' width={1} height={1} patternContentUnits='objectBoundingBox'>
        <use xlinkHref='#image1_1201_4181' />
      </pattern>
      <pattern id='pattern2' width={1} height={1} patternContentUnits='objectBoundingBox'>
        <use xlinkHref='#image2_1201_4181' />
      </pattern>
      <pattern id='pattern3' width={1} height={1} patternContentUnits='objectBoundingBox'>
        <use xlinkHref='#image3_1201_4181' />
      </pattern>
      <pattern id='pattern4' width={1} height={1} patternContentUnits='objectBoundingBox'>
        <use xlinkHref='#image4_1201_4181' />
      </pattern>
      <image
        id='image0_1201_4181'
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAC7CAYAAAD10Mf5AAAACXBIWXMAAAsSAAALEgHS3X78AAAA'
      />
      <image
        id='image1_1201_4181'
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAATQAAAGQCAYAAAAgDJxnAAAACXBIWXMAAAsSAAALEgHS3X78AAAA'
      />
      <image
        id='image2_1201_4181'
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUwAAAGoCAYAAAAtu9zLAAAACXBIWXMAAAsSAAALEgHS3X78AAAA'
      />
      <image
        id='image3_1201_4181'
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKgAAACwCAYAAACbziVCAAAACXBIWXMAAAsSAAALEgHS3X78AAAA'
      />
      <image
        id='image4_1201_4181'
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFYAAABLCAYAAADqHnCyAAAACXBIWXMAAAsSAAALEgHS3X78AAAA'
      />
      <filter
        id='filter0_d_1201_4181'
        width={128.797}
        height={167.403}
        x={99.503}
        y={87.13}
        colorInterpolationFilters='sRGB'
        filterUnits='userSpaceOnUse'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          result='hardAlpha'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dx={0.5} dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_1201_4181' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow_1201_4181' result='shape' />
      </filter>
    </defs>
  </svg>
);

const SvgIngenovis = styled(SVG)`
  ${(props: GraphicProps) => GetGraphicDimensions(props)}
`;
SvgIngenovis.displayName = 'Ingenovis';
export default SvgIngenovis;
