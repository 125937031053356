import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--invisible';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 18 15'
    className={className}
    data-test={TEST_ID}
  >
    <path
      id='a'
      d='M12.148 5.969a3.5 3.5 0 01-4.68 4.68l.768-.768a2.5 2.5 0 003.145-3.145l.767-.767zM5.82 12.297c.993.47 2.052.703 3.18.703 3.13 0 5.732-1.788 7.856-5.5-.837-1.463-1.749-2.628-2.738-3.501l.708-.708C15.994 4.337 17.052 5.74 18 7.5c-2.333 4.333-5.333 6.5-9 6.5a8.294 8.294 0 01-3.926-.957l.746-.746zM15.89.813L2.313 14.39a.5.5 0 01-.667-.744L3.393 11.9C2.138 10.837 1.007 9.37 0 7.5 2.333 3.167 5.333 1 9 1c1.51 0 2.907.367 4.19 1.102L15.147.146a.5.5 0 01.744.667zm-3.436 2.026A7.315 7.315 0 009 2C5.87 2 3.268 3.788 1.144 7.5c.9 1.572 1.884 2.798 2.959 3.69l1.893-1.893a3.5 3.5 0 014.801-4.801l1.657-1.657zm-2.396 2.395a2.5 2.5 0 00-3.324 3.324l3.324-3.324z'
    />
  </svg>
);

const SvgInvisible = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgInvisible.displayName = 'Invisible';
export default SvgInvisible;
