// makes overriding styles difficult(we really should try not to) and keeps any a11y logic within the component
interface DangerousProps {
  style?: unknown;
  role?: string;
}

export const safeProps = (
  // disable note: to difficult to type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: DangerousProps & any,
  excludes?: string[]
  // disable note: to difficult to type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Omit<DangerousProps, 'style' | 'role'> & any => {
  const { style, role, ...sProps } = props;

  return excludes
    ? Object.keys(sProps)
        .filter((key) => !excludes.includes(key))
        .reduce((cur, key) => Object.assign(cur, { [key]: sProps[key] }), {})
    : sProps;
};
