import * as React from 'react';
import { Drawer as MuiDrawer } from '@mui/material';
import styled from '@emotion/styled';

export interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

const StyledMuiDrawer = styled(MuiDrawer)`
  & .MuiBackdrop-root {
    background-color: rgba(15, 0, 39, 0.3);
  }
  & .MuiPaper-root.MuiDrawer-paper {
    background: ${({ theme }) => theme.modal.backgroundColor};
    border-radius: 8px 8px 0 0;
    max-height: 70%;
  }
`;

export const Drawer: React.FC<DrawerProps> = ({ isOpen, onClose, children }) => (
  <StyledMuiDrawer anchor='bottom' open={isOpen} onClose={onClose} data-test='iui-component-drawer'>
    {children}
  </StyledMuiDrawer>
);

export default Drawer;
