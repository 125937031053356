import styled from '@emotion/styled';
import { Theme } from '@emotion/react';
import { SectionTitleProps } from '.';
import { SectionTitleFixedColor, SectionTitleVariants } from './sectionTitle.types';

interface StyledSectionTitleProps extends SectionTitleProps {
  margin?: string;
  theme: Theme;
}

const truncateStyle = `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledHeadingWrapper = styled.div<StyledSectionTitleProps>`
  h2,
  h3,
  h4 {
    ${(props) => props.truncate && truncateStyle}
    ${(props) => (props.margin ? `margin: ${props.margin};` : '')}
  }

  &:before,
  h2:after,
  h3:after,
  h4:after {
    ${(props) =>
      props.lineColor === SectionTitleFixedColor.DEFAULT &&
      `
      background:  ${props.theme?.iuiPalette?.brand[3] ?? 'var(--color-brand-3)'};
    `}
    ${(props) =>
      props.lineColor === SectionTitleFixedColor.BRAND1 &&
      `
      background:  ${props.theme?.iuiPalette?.brand[1] ?? 'var(--color-brand-1)'};
    `}
    ${(props) =>
      props.lineColor === SectionTitleFixedColor.BRAND2 &&
      `
      background:  ${props.theme?.iuiPalette?.brand[2] ?? 'var(--color-brand-2)'};
    `}
    ${(props) =>
      props.lineColor === SectionTitleFixedColor.BRAND4 &&
      `
      background:  ${props.theme?.iuiPalette?.brand[4] ?? 'var(--color-brand-4)'};
    `}
  }

  h2,
  h3,
  h4 {
    &:after {
      ${(props) =>
        props.lineColor === SectionTitleFixedColor.DEFAULT &&
        `
          background: ${props.theme?.heading?.strokeAlt ?? 'var(--color-brand-3)'};
      `}
      ${(props) =>
        props.lineColor === SectionTitleFixedColor.BRAND1 &&
        `
        background:  ${props.theme?.iuiPalette?.brand[1] ?? 'var(--color-brand-1)'};
      `}
      ${(props) =>
        props.lineColor === SectionTitleFixedColor.BRAND2 &&
        `
        background:  ${props.theme?.iuiPalette?.brand[2] ?? 'var(--color-brand-2)'};
      `}
      ${(props) =>
        props.lineColor === SectionTitleFixedColor.BRAND4 &&
        `
        background:  ${props.theme?.iuiPalette?.brand[4] ?? 'var(--color-brand-4)'};
      `}
    }
  }

  ${(props) =>
    props.variant === SectionTitleVariants.PRIMARY &&
    `
    &:before {
      content: "";
      display: block;
      height: ${props.dividerHeight ?? '3px'};
      width: ${props.dividerWidth ?? '80px'};
      margin-bottom: 1.75rem;
    }
  `}

  ${(props) =>
    props.variant === SectionTitleVariants.SECONDARY ||
    props.variant === SectionTitleVariants.SECONDARY_ALT ||
    props.variant === SectionTitleVariants.TERTIARY
      ? `
      h2, h3, h4 {
        &:after {
          content: "";
          display: block;
          height: ${props.dividerHeight ?? '3px'};
          margin: ${props.dividerMargin ?? '1.5rem auto 0'};
        }
      }
    `
      : ''}

  ${(props) =>
    props.variant === SectionTitleVariants.SECONDARY &&
    `
    text-align: center;
    h2, h3, h4 {
      display: inline-flex;
      flex-direction: column;
      &:after {
        width: ${props.dividerWidth ?? '80%'};
        background: ${props.theme?.heading?.stroke ?? 'var(--color-brand-1)'};
      }
    }
  `}

  ${(props) =>
    props.variant === SectionTitleVariants.SECONDARY_ALT &&
    `
    h2, h3, h4 {
      &:after {
        width: ${props.dividerWidth ?? '100%'};
      }
    }
  `}

  ${(props) =>
    props.variant === SectionTitleVariants.TERTIARY &&
    `
    text-align: center;
    h2, h3, h4 {
      display: inline-flex;
      flex-direction: column;
      &:after {
        width: ${props.dividerWidth ?? '40%'};
      }
    }
  `}
`;
