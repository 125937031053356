/* eslint react/display-name : 0 */
import React, { RefCallback } from 'react';
import InputMask from 'react-input-mask';
import { StandardTextFieldProps, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import styled from '@emotion/styled';
import { EmotionStyledProps } from '../../types';
import { GetTypographyCss } from '../../mixins';
import { pxToRemMarginAndPadding, pxToRem } from '../../mixins/pxToRem';
import { safeProps } from '../../utils/safeProps';

interface TextFieldProps extends Partial<EmotionStyledProps & StandardTextFieldProps> {
  error?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  isPhoneInput?: boolean;
  inputPretext?: string;
  showPretextIfValue?: boolean;
}

const StyledTextInput = styled(TextField)<TextFieldProps>`
  ${({ disabled }: TextFieldProps) => (disabled ? 'opacity: 0.3;' : '')}
  .MuiInputAdornment-root {
    display: none;
    .MuiTypography-root {
      padding: ${pxToRemMarginAndPadding('0 0 3px')};
    }
  }
  .MuiInput-root {
    padding: 4px 0 5px;
    margin-top: 15px;
  }
  .MuiFormLabel-root.Mui-focused + .MuiInput-root .MuiInputAdornment-root,
  .MuiFormLabel-root + .MuiInput-root .MuiInputAdornment-root.has-forced-pretext {
    display: flex;
  }
  .MuiFormLabel-root {
    ${({ theme }) => GetTypographyCss({ variant: 'meta-l-caps-alt', theme })}
    color: ${({ error, theme }: TextFieldProps) => {
      if (error) {
        return theme?.iuiPalette?.semantic?.red100 ?? 'red';
      }
      return theme?.iuiPalette?.grays?.grays?.[600] ?? 'gray';
    }};
    &.Mui-focused {
      color: ${({ error, theme }: TextFieldProps) => {
        if (error) {
          return theme?.iuiPalette?.semantic?.red100 ?? 'red';
        }
        return theme?.iuiPalette?.grays?.grays?.[600] ?? 'gray';
      }};
    }
  }
  .MuiInputLabel-shrink {
    ${({ theme }) => GetTypographyCss({ variant: 'meta-s-caps-alt', theme })}
    transform: translate(0, 1.5px) scale(1);
  }
  .MuiInputBase-root {
    padding: 4px 0;
    color: ${({ theme }: TextFieldProps) => theme?.iuiPalette?.grays?.black ?? 'black'};
  }
  .MuiInput-input {
    ${({ theme }) => GetTypographyCss({ variant: 'body-m-alt', theme })}
    min-height: 24px;
    padding: 0;
  }
  .MuiFormHelperText-root {
    ${({ theme }) => GetTypographyCss({ variant: 'meta-s-alt', theme })}
    margin-top: ${pxToRem('8px')};
  }
  .MuiInput-underline {
    &:before {
      border-bottom: 2px solid
        ${({ error, theme }: TextFieldProps) => {
          if (error) {
            return theme?.iuiPalette?.semantic?.red100 ?? 'red';
          }
          return theme?.iuiPalette?.grays?.grays?.[300] ?? 'gray';
        }};
    }
    &:hover:not(.Mui-disabled):before,
    &:after {
      border-bottom: 2px solid
        ${({ error, theme }: TextFieldProps) => {
          if (error) {
            return theme?.iuiPalette?.semantic?.red100 ?? 'red';
          }
          return theme?.iuiPalette?.primaryAlt?.[200] ?? 'blue';
        }};
    }
    &.Mui-focused {
      &:hover:not(.Mui-disabled):before,
      &:after {
        border-bottom: 2px solid
          ${({ error, theme }: TextFieldProps) => {
            if (error) {
              return theme?.iuiPalette?.semantic?.red100 ?? 'red';
            }
            return theme?.iuiPalette?.primaryAlt?.[300] ?? 'blue';
          }};
      }
    }
  }
`;

const TextInput = React.forwardRef<RefCallback<HTMLInputElement>, TextFieldProps>((props, ref) => {
  const { errorMessage, isPhoneInput, inputPretext, showPretextIfValue = false, ...rest } = props;
  const { value } = rest;

  if (isPhoneInput) {
    return (
      <InputMask mask='(999) 999-9999' {...rest} data-test='iui-component-text-input'>
        {(inputProps) => (
          <StyledTextInput
            {...inputProps}
            {...safeProps(rest)}
            variant='standard'
            helperText={errorMessage}
            fullWidth
            ref={ref}
          />
        )}
      </InputMask>
    );
  }

  const showPretext = showPretextIfValue && value?.length;

  return (
    <StyledTextInput
      {...safeProps(rest)}
      variant='standard'
      helperText={errorMessage}
      fullWidth
      {...((inputPretext && inputPretext.length) || showPretext
        ? {
            InputProps: {
              startAdornment: (
                <InputAdornment
                  className={showPretext ? 'has-forced-pretext' : ''}
                  position='start'
                >
                  {inputPretext}
                </InputAdornment>
              )
            }
          }
        : {})}
    />
  );
});

TextInput.defaultProps = {
  error: false,
  disabled: false,
  errorMessage: undefined,
  value: undefined,
  onChange: undefined,
  isPhoneInput: false
};

export default TextInput;
