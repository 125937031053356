import * as React from 'react';
import styled from '@emotion/styled';
import { GraphicProps } from '../../../types';
import { GetGraphicDimensions } from '../../../mixins/icons';

const TEST_ID = 'iui-component-graphic--fastaff';

const SVG: React.FC<GraphicProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 338 338'
    className={className}
    data-test={TEST_ID}
  >
    <path fill='#fff' d='M254.2 107.739H83.177V229.26H254.2V107.739z' />
    <path
      fill='#333F67'
      d='M253.811 108.127v120.744H83.566V108.127h170.245zm.389-.388H83.177V229.26H254.2V107.739z'
    />
    <path
      fill='#9BA3C0'
      d='M253.344 108.593v120.395H84.5V108.593h168.844zm6.536-6.486H77.964v133.406H259.88V102.107z'
    />
    <path
      fill='#758BD2'
      d='M193.432 199.083h-75.164a2.357 2.357 0 00-2.373 2.37 2.356 2.356 0 002.373 2.369h75.164a2.356 2.356 0 002.373-2.369 2.381 2.381 0 00-2.373-2.37zM222.61 187.316H118.268a2.356 2.356 0 00-2.373 2.369 2.356 2.356 0 002.373 2.369H222.61a2.356 2.356 0 002.373-2.369 2.355 2.355 0 00-2.373-2.369zM222.61 175.509H118.268a2.356 2.356 0 00-2.373 2.369 2.356 2.356 0 002.373 2.369H222.61a2.355 2.355 0 002.373-2.369c.039-1.281-1.05-2.369-2.373-2.369zM222.61 163.742H118.268a2.356 2.356 0 00-2.373 2.369 2.356 2.356 0 002.373 2.369H222.61a2.356 2.356 0 002.373-2.369 2.355 2.355 0 00-2.373-2.369zM118.268 156.712H222.61a2.355 2.355 0 002.373-2.369c0-1.32-1.05-2.369-2.373-2.369H118.268a2.356 2.356 0 00-2.373 2.369 2.356 2.356 0 002.373 2.369z'
    />
    <rect width={77.296} height={9.198} x={132.653} y={134.551} fill='#758BD2' rx={4.599} />
    <path
      fill='#333F67'
      d='M259.258 102.107v132.784H78.12V102.107h181.138zm7.12-7.107H71v146.96h195.378V95z'
    />
    <path
      fill='#E27536'
      d='M216.541 230.891l13.772 5.67-12.332 29.866-3.346-11.34-10.427 5.709 12.333-29.905z'
    />
    <path
      fill='#E27536'
      d='M235.643 230.891l-13.772 5.67 12.333 29.866 3.346-11.34 10.426 5.709-12.333-29.905z'
    />
    <path fill='url(#pattern0)' d='M203.282 202.629h47.074v46.993h-47.074z' />
    <path
      fill='#F5995C'
      d='M226.073 245.688c11.194 0 20.269-9.059 20.269-20.234 0-11.175-9.075-20.234-20.269-20.234-11.195 0-20.27 9.059-20.27 20.234 0 11.175 9.075 20.234 20.27 20.234z'
    />
    <defs>
      <pattern id='pattern0' width={1} height={1} patternContentUnits='objectBoundingBox'>
        <use xlinkHref='#image0_1769_13426' />
      </pattern>
      <image
        id='image0_1769_13426'
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHoAAAB6CAYAAABwWUfkAAAACXBIWXMAAAsSAAALEgHS3X78AAAA'
      />
    </defs>
  </svg>
);

const SvgFastaff = styled(SVG)`
  ${(props: GraphicProps) => GetGraphicDimensions(props)}
`;
SvgFastaff.displayName = 'Fastaff';
export default SvgFastaff;
