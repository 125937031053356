import * as React from 'react';
import styled from '@emotion/styled';
import { GraphicProps } from '../../../types';
import { GetGraphicDimensions } from '../../../mixins/icons';

const TEST_ID = 'iui-component-graphic--trustaff';

const SVG: React.FC<GraphicProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 338 338'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#512D6C'
      d='M72.605 90.055h74.168l17.406 17.449h85.828l-4.158 117.639-174.144-.471.9-134.617z'
    />
    <path fill='#E0C9F1' d='M244.82 98.287H79.293v115.281H244.82V98.287z' />
    <path fill='#512D6C' d='M71.705 224.672l21.821-108.036h172.687l-20.364 108.507-174.144-.471z' />
  </svg>
);

const SvgTrustaff = styled(SVG)`
  ${(props: GraphicProps) => GetGraphicDimensions(props)}
`;
SvgTrustaff.displayName = 'Trustaff';
export default SvgTrustaff;
