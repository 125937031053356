import { useState, useEffect } from 'react';

const useIsSSR = (): boolean => {
  const [isSsr, setIsSsr] = useState(true);

  useEffect(() => {
    setIsSsr(false);
  }, []);

  return isSsr;
};

export default useIsSSR;
