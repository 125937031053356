import * as React from 'react';
/* 
  Currently MUI doesn't handle tabbing within menu - https://github.com/mui/material-ui/issues/20173
  This is a problem for accessability.
*/
import Menu from '@mui/material/Menu';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { useEffect } from 'react';
import UAParser from 'ua-parser-js';
import Typography from '../Typography';
import Box from '../Box';
import { ShadowMixin } from '../../mixins';
import {
  SocialFacebookSmallIcon,
  EmailIcon,
  SocialLinkedInSmallIcon,
  SocialTwitterSmallIcon,
  LinkIcon
} from '../Icons';
import { getSocialShareLink } from '../../utils';
import { Toast } from '../Toast';

export interface ShareProps {
  isOpen: boolean; // Open state is controlled by parent
  handleClose: () => void;
  anchorElement: null | HTMLElement; // The element the component anchors itself to
  title: string;
  url: string; // The url to share
  shareTitle?: string; // The title of the item to share
}

const StyledLink = styled.a`
  &:not([class]) {
    text-decoration: none;
  }
`;

const ShareLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ShareLinkBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  > p {
    color: ${({ theme }) => theme?.share.textColor ?? 'black'};
  }
  &:hover {
    cursor: pointer;
    > p {
      color: ${({ theme }) => theme?.share.textHoverColor ?? 'gray'};
      text-decoration: none;
    }
    > div {
      background-color: ${({ theme }) => theme?.share.backgroundHoverColor ?? '#9BA3C0'};
    }
    svg {
      > path {
        fill: ${({ theme }) => theme?.share.iconHoverColor ?? '#495683'};
      }
    }
  }
  &:active {
    > p {
      color: ${({ theme }) => theme?.share.activeColor ?? 'navy'};
    }
  }
`;

const StyledMenu = styled(Menu)`
  & .MuiPaper-root.MuiMenu-paper {
    ${ShadowMixin({ level: 'super' })}
    max-width: 216px;
    min-width: 216px;
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
  }
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme?.share?.backgroundColor ?? '#DFE2EC'};
`;

export const Share: React.FC<ShareProps> = ({
  title,
  isOpen,
  anchorElement,
  handleClose,
  url,
  shareTitle
}) => {
  const [isLinkCopied, setIsLinkCopied] = React.useState(false);
  const UA = new UAParser();

  const onCopyLink = React.useCallback(() => {
    navigator.clipboard.writeText(url);
    setIsLinkCopied(true);
  }, [url]);

  const theme = useTheme();

  // native web share (ignore if Mac OS since desktop safari supports web share)
  if (isOpen && navigator?.share && UA.getOS().name !== 'Mac OS') {
    navigator.share({ title: shareTitle, url }).then(handleClose).catch(handleClose);
    return null;
  }

  // fallback desktop menu
  return (
    <>
      <Toast
        message='Link copied'
        show={isLinkCopied}
        autoClose
        onAutoClose={() => setIsLinkCopied(false)}
        data-test='iui-component-share'
        variant='success'
      />

      <StyledMenu
        open={isOpen}
        anchorEl={anchorElement}
        id='share-menu-items'
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Typography variant='meta-l-alt'>{title}</Typography>
        <Box
          width='100%'
          height='1px'
          background={theme?.iuiPalette?.grays.grays[300] ?? 'gray'}
          margin='16px 0'
        />
        <ShareLinksContainer>
          <StyledLink href={getSocialShareLink('facebook', url, shareTitle)} target='_blank'>
            <ShareLinkBox role='button'>
              <IconWrapper>
                <SocialFacebookSmallIcon dimensions={16.3} customColor={theme.share.iconColor} />
              </IconWrapper>
              <Typography variant='meta-m-alt'>Facebook</Typography>
            </ShareLinkBox>
          </StyledLink>
          <StyledLink href={getSocialShareLink('linkedin', url, shareTitle)} target='_blank'>
            <ShareLinkBox role='button'>
              <IconWrapper>
                <SocialLinkedInSmallIcon dimensions={16.3} customColor={theme.share.iconColor} />
              </IconWrapper>
              <Typography variant='meta-m-alt'>LinkedIn</Typography>
            </ShareLinkBox>
          </StyledLink>
          <StyledLink href={getSocialShareLink('twitter', url, shareTitle)} target='_blank'>
            <ShareLinkBox role='button'>
              <IconWrapper>
                <SocialTwitterSmallIcon dimensions={16.3} customColor={theme.share.iconColor} />
              </IconWrapper>
              <Typography variant='meta-m-alt'>Twitter</Typography>
            </ShareLinkBox>
          </StyledLink>
          <StyledLink href={`mailto:?subject=${shareTitle}`} target='_blank'>
            <ShareLinkBox role='button'>
              <IconWrapper>
                <EmailIcon dimensions={16.3} customColor={theme.share.iconColor} />
              </IconWrapper>
              <Typography variant='meta-m-alt'>Email</Typography>
            </ShareLinkBox>
          </StyledLink>
          <ShareLinkBox
            role='button'
            onClick={onCopyLink}
            onKeyPress={(e) => e.key === 'Enter' && onCopyLink()}
          >
            <IconWrapper>
              <LinkIcon dimensions={16.3} customColor={theme.share.iconColor} />
            </IconWrapper>

            <Typography variant='meta-m-alt'>Copy link</Typography>
          </ShareLinkBox>
        </ShareLinksContainer>
      </StyledMenu>
    </>
  );
};

export default Share;
