import React, { useMemo, useState } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Drawer } from '@mui/material';

import { SearchIcon, MenuIcon } from '../Icons';
import Box from '../Box';
import Button from '../Button';
import Container from '../Container';
import NavMenu from '../NavMenu';
import Typography from '../Typography';
import { MediaQuery } from '../../mixins/mediaQuery';
import shadow from '../../mixins/shadow';
import Avatar from '../Avatar';
import Link from '../Link';
import UserMenu from '../UserMenu';
import MobileUserMenu from '../MobileUserMenu';
import IconButton from '../IconButton';
import { useBreakpoints, useIsBreakpoint } from '../../hooks';
import styledLogo from '../Logos/styledLogo';
import { BreakpointSizes, NavSection, NavSubSection } from '../../types';
import { DEFAULT_PROFILE_USERNAME, NavbarHeight, ZIndexLevel } from '../../constants';
import defaultBreakpoints from '../../themes/breakpoints';
import { SecondarySitemap, SideMenuProps } from '../SideMenu/side-menu.types';
import Badge from '../Badge';
import { LinkContext } from '../../providers/Linker';

const StyledBox = styled(Box)`
  ${shadow({ level: 'slight' })};
  position: sticky;
  top: 0;
  z-index: ${ZIndexLevel.NAV + 1};
`;

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    right: 0;
    ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
      right: unset;
      width: 455px;
    }
  }
`;

const NavLinks = styled.div`
  display: none;
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.xLarge ?? defaultBreakpoints.xLarge)} {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    a {
      height: 100%;
    }
  }
`;

const NavLinkWrapper = styled.div`
  position: relative;
  height: 100%;
  a {
    text-decoration: none;
    display: inline-block;
  }
  div.submenu {
    z-index: 200;
    display: none;
    position: absolute;
    top: 100%;
    background: white;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
    border-top: 2px solid ${({ theme }) => theme?.iuiPalette?.grays?.grays[300] ?? '#E5E6EB'};
    ${shadow({ level: 'light' })};
  }
  &:hover {
    div.submenu {
      display: block;
    }
  }
`;

const SubMenuItem = styled.div<{ $isActive?: boolean }>`
  a {
    text-decoration: none;
    width: 100%;
  }
  p {
    white-space: nowrap;
  }
  &:hover {
    background: ${({ theme }) => theme?.iuiPalette?.grays?.grays[200] ?? '#F6F6F6'};
    p {
      color: ${({ theme }) => theme?.navbar?.hover?.color ?? '#333F67'};
    }
  }
  border-left: 3px solid
    ${({ theme, $isActive }) =>
      $isActive ? theme?.navMenu?.activeBorder ?? '#616D98' : 'transparent'};
`;

const NavLink = styled.div<{ $isActive?: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 100%;
  padding: 0 16px;
  cursor: pointer;
  border-bottom: 4px solid
    ${({ $isActive, theme }) =>
      $isActive ? theme?.navbar?.selected?.underline ?? '#333F67' : 'transparent'};
  &:hover {
    background-color: ${({ theme }) => theme?.navbar?.hover?.bg ?? '#F6F6F6'};
    p {
      color: ${({ theme }) => theme?.navbar?.hover?.color ?? '#333F67'};
    }
  }
  p {
    color: ${({ $isActive, theme }) =>
      $isActive
        ? theme?.navbar?.selected?.color ?? '#333F67'
        : theme?.navbar?.link?.color ?? '#1D243D'};
  }
`;

const StyledBurgerMenuIcon = styled(IconButton)`
  z-index: 1;
  display: flex;
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.xLarge ?? defaultBreakpoints.xLarge)} {
    display: none;
  }
`;

const StyledSearchIcon = styled(IconButton)`
  display: none;
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.xLarge ?? defaultBreakpoints.xLarge)} {
    display: block;
  }
`;

const StyledAvatarButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  p {
    font-size: 14px;
  }
`;

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  button {
    display: none;
  }
  p.have-an-account {
    display: none;
  }

  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.xLarge ?? defaultBreakpoints.xLarge)} {
    button {
      display: block;
      flex-shrink: 0;
    }
    p.have-an-account {
      display: block;
    }
  }
`;

const SignInLink = styled(Link)`
  color: ${({ theme }) => theme?.navbar?.signInLink?.color ?? '#1D243D'};
`;

const ApplyNowLink = styled(Link)`
  color: ${({ theme }) => theme?.link?.base?.color ?? '#4664C3'};
`;

export interface NavbarProps extends SideMenuProps {
  sitemap: NavSection[];
  hasIncompleteProfile?: boolean;
  secondarySitemap: SecondarySitemap[];
  activeRoute: string; // the route that is currently active
  username?: string | null;
  userTitle?: string | null;
  userDepartment?: string | null;
  userSitemap: NavSection[];
  mobileUserSitemap: NavSection[];
  showSearchIcon?: boolean;
  onSearchClick: () => void;
  onApplyNowClick: () => void;
  applyNowRoute: NavSection;
  signInRoute: NavSection;
}

const SubMenu: React.FC<{
  activeRoute: string;
  links: NavSubSection[];
}> = ({ activeRoute, links }) => {
  const theme = useTheme();
  const CustomLink = React.useContext(LinkContext);
  return (
    <div className='submenu'>
      {links.map((link) => {
        const isActive = link.route === activeRoute;
        return (
          <SubMenuItem $isActive={isActive} key={link.route}>
            <CustomLink href={link.route} to={link.route}>
              <Typography
                margin='0 16px 0 13px'
                customLineHeight={3}
                variant={isActive ? 'meta-l-alt' : 'meta-l'}
                color={isActive ? theme?.navMenu?.activeLink ?? '#333F67' : undefined}
              >
                {link.label}
              </Typography>
            </CustomLink>
          </SubMenuItem>
        );
      })}
    </div>
  );
};

const Navbar: React.FC<NavbarProps> = ({
  sitemap = [],
  activeRoute,
  username = DEFAULT_PROFILE_USERNAME,
  userTitle,
  userDepartment,
  userSitemap,
  mobileUserSitemap,
  showSearchIcon = true,
  onSearchClick,
  onApplyNowClick,
  secondarySitemap,
  applyNowRoute,
  signInRoute,
  recruiterName,
  recruiterPhone,
  recruiterEmail,
  companyPhone,
  companyEmail,
  opHours,
  hasIncompleteProfile = false
}) => {
  const theme = useTheme();
  const Logo = styledLogo(theme.logos.default);
  const CustomLink = React.useContext(LinkContext);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);
  const { isDesktop, isMobile } = useIsBreakpoint();
  const breakpoint = useBreakpoints(theme.iuiBreakpoints);
  const isAboveDesktop =
    breakpoint === BreakpointSizes.XLARGE || breakpoint === BreakpointSizes.XXLARGE;

  const onAvatarClick = (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => {
    setUserMenuAnchorEl(event.currentTarget);
    setIsUserMenuOpen(true);
  };

  const userMenuClose = () => {
    setIsUserMenuOpen(false);
    setUserMenuAnchorEl(null);
  };

  const LogoWrapper = React.useMemo(
    () => styled(CustomLink)`
      position: absolute;
      margin: auto auto;
      left: 0;
      right: 0;
      text-align: center;
      width: max-content;
      ${MediaQuery(theme?.iuiBreakpoints?.xLarge ?? defaultBreakpoints.xLarge)} {
        position: relative;
        margin: 0;
        left: auto;
        right: auto;
        text-align: initial;
        width: auto;
      }
    `,
    [CustomLink, theme?.iuiBreakpoints]
  );

  return (
    <StyledBox background='white' data-test='iui-component-navbar'>
      <Container>
        <Box
          width='100%'
          height={`${isDesktop ? NavbarHeight.DESKTOP : NavbarHeight.MOBILE}px`}
          display='flex'
          flexBasis='row'
          alignItems='center'
          justifyContent='space-between'
          position='relative'
        >
          <StyledBurgerMenuIcon
            aria-label={isDrawerOpen ? 'collapse main menu' : 'expand main menu'}
            icon={MenuIcon}
            size={24}
            onClick={() => setIsDrawerOpen(true)}
          />
          <LogoWrapper href='/' to='/'>
            <Logo
              height={
                theme?.navbar?.logoHeight[!isDesktop ? 'small' : 'large'] ??
                (!isDesktop ? '24px' : '28px')
              }
              width={theme?.navbar?.logoWidth[!isDesktop ? 'small' : 'large'] ?? 'auto'}
              top={theme?.navbar?.logoTopPosition ?? undefined}
            />
            <span className='sr-only'>{theme.name} logo</span>
          </LogoWrapper>
          <NavLinks>
            {sitemap.map((section) => {
              // see if any subsection is current activeRoute, if so this is active section
              const sectionIsActive =
                Array.isArray(section.subsections) &&
                section.subsections.some((s) => s.route === activeRoute);
              const subSections = section.subsections ?? [];
              return (
                <NavLinkWrapper key={section.label}>
                  <CustomLink
                    href={section.route}
                    to={section.route}
                    target={section.type === 'external' ? '_blank' : undefined}
                    rel='noreferrer'
                  >
                    <NavLink $isActive={sectionIsActive}>
                      <Typography variant='button-primary' margin='4px 0 0 0'>
                        {section.label}
                      </Typography>
                    </NavLink>
                  </CustomLink>
                  <SubMenu activeRoute={activeRoute} links={subSections} />
                </NavLinkWrapper>
              );
            })}
          </NavLinks>
          {isDesktop ? (
            <UserMenu
              username={username ?? ''}
              title={userTitle ?? ''}
              department={userDepartment ?? ''}
              anchorElement={userMenuAnchorEl}
              sitemap={userSitemap}
              activeRoute={activeRoute}
              isOpen={isUserMenuOpen}
              onClose={userMenuClose}
            />
          ) : (
            <MobileUserMenu
              username={username ?? ''}
              userTitle={userTitle ?? ''}
              userDepartment={userDepartment ?? ''}
              userSitemap={mobileUserSitemap}
              activeRoute={activeRoute}
              isOpen={isUserMenuOpen}
              onClose={userMenuClose}
              sitemap={secondarySitemap}
              recruiterName={recruiterName}
              recruiterPhone={recruiterPhone}
              recruiterEmail={recruiterEmail}
              companyPhone={companyPhone}
              companyEmail={companyEmail}
              opHours={opHours}
            />
          )}
          <Box minWidth={isAboveDesktop ? '325px' : '60px'} zIndex='1'>
            <Box display='flex' flexBasis='row' alignItems='center' justifyContent='flex-end'>
              {showSearchIcon && (
                <StyledSearchIcon
                  icon={SearchIcon}
                  size={24}
                  hoverColor={theme?.navbar?.link?.hoverColor ?? '#4664C3'}
                  onClick={onSearchClick}
                  margin='0px 16px 0px 0px'
                />
              )}
              {username ? (
                <>
                  <StyledAvatarButton
                    aria-label={isUserMenuOpen ? 'close user menu' : 'open user menu'}
                    onClick={(event) => onAvatarClick(event)}
                  >
                    <Avatar
                      name={username}
                      width={32}
                      height={32}
                      badge={hasIncompleteProfile ? <Badge /> : null}
                    />
                  </StyledAvatarButton>
                </>
              ) : (
                <LoginWrapper>
                  <Button
                    label='Apply Now'
                    margin='0 16px 0 26px'
                    variant='secondary'
                    onClick={onApplyNowClick}
                  />
                  <Box display='flex' flexDirection='column' alignItems='flex-end' flexShrink='0'>
                    <Typography variant='body-s' className='have-an-account'>
                      Have an account?
                    </Typography>
                    {isMobile ? (
                      <ApplyNowLink label='Apply Now' href={applyNowRoute.route ?? '#'} />
                    ) : (
                      <SignInLink label='Sign in' href={signInRoute.route ?? '#'} />
                    )}
                  </Box>
                </LoginWrapper>
              )}
            </Box>
          </Box>
          {!isAboveDesktop && (
            <StyledDrawer anchor='left' open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
              <NavMenu
                sitemap={sitemap}
                signInRoute={signInRoute}
                activeRoute={activeRoute}
                username={username ?? ''}
                showSearchIcon={showSearchIcon}
                onSearchClick={onSearchClick}
                onClose={() => setIsDrawerOpen(false)}
                onApplyNowClick={onApplyNowClick}
              />
            </StyledDrawer>
          )}
        </Box>
      </Container>
    </StyledBox>
  );
};

export default Navbar;
