import * as React from 'react';
import styled from '@emotion/styled';
import { GraphicProps } from '../../../types';
import { GetGraphicDimensions } from '../../../mixins/icons';

const TEST_ID = 'iui-component-graphic--ingenovis';

const SVG: React.FC<GraphicProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 338 338'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#333F67'
      d='M73.47 91.573H147l17.256 17.298h85.088l-4.122 116.625-172.643-.468.893-133.455z'
    />
    <path fill='#B2C2E8' d='M244.201 99.733h-164.1V214.02h164.1V99.733z' />
    <path fill='#333F67' d='M72.578 225.028l21.634-107.104h171.197l-20.188 107.572-172.643-.468z' />
    <path
      fill='#F5995C'
      d='M273.612 194.257l-.213.042c-2.805-8.755-8.203-16.278-15.598-21.76-7.608-5.653-16.661-8.671-26.181-8.671-24.184 0-43.862 19.679-43.862 43.862 0 24.184 19.678 43.862 43.862 43.862v-24.821c-10.498 0-19.041-8.543-19.041-19.041 0-10.498 8.543-19.041 19.041-19.041 8.245 0 15.513 5.228 18.063 13.048l-.552.17-13.728 4.293 35.404 22.441 16.49-38.719-13.685 4.335z'
    />
  </svg>
);

const SvgIngenovis = styled(SVG)`
  ${(props: GraphicProps) => GetGraphicDimensions(props)}
`;
SvgIngenovis.displayName = 'Ingenovis';
export default SvgIngenovis;
