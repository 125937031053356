import * as React from 'react';
import { useIsBreakpoint, useResponsiveImage } from '../../hooks';
import { CCSObjectFit, ImageProps } from './image.types';
import { Img } from './image.styles';

// exported only for tests
export const getHeightByDevice = (
  isDesktop: boolean,
  isTablet: boolean,
  fixedHeights: [number, number, number]
): number => {
  if (isDesktop) {
    return fixedHeights[2];
  }
  if (isTablet) {
    return fixedHeights[1];
  }
  return fixedHeights[0];
};

// exported only for tests
export const useFixedHeight = (
  fixedHeights: [number, number, number] | undefined,
  objectFit: `${CCSObjectFit}` | undefined
): boolean =>
  objectFit === CCSObjectFit.COVER ? Array.isArray(fixedHeights) && fixedHeights.length > 2 : false;

const Image: React.FC<ImageProps> = (props: ImageProps) => {
  const { className, alt, src = '', responsive = [], options, ...imgProps } = props;
  const responsiveProps = useResponsiveImage(src, responsive, options);
  const { isDesktop, isTablet } = useIsBreakpoint();
  const { objectFit, borderRadius, transform, fixedHeights } = options || {};
  const useFixed = useFixedHeight(fixedHeights, objectFit);
  const height = useFixed && fixedHeights && getHeightByDevice(isDesktop, isTablet, fixedHeights);

  return (
    <Img
      alt={alt}
      className={className}
      objectFit={objectFit}
      transform={transform}
      borderRadius={borderRadius}
      {...imgProps}
      {...(useFixed && height ? { height } : {})}
      {...responsiveProps}
      data-test='iui-component-image'
    />
  );
};

export default Image;
