import {
  DESKTOP_ITEMS_PER_PAGE_321,
  TABLET_ITEMS_PER_PAGE_321,
  TABLET_SPACE_TAKEN_PER_PAGE_BY_GAPS_321,
  DESKTOP_SPACE_TAKEN_PER_PAGE_BY_GAPS_321,
  ITEMS_PER_PAGE_2WE,
  SPACE_TAKE_PER_PAGE_BY_GAPS_2WE
} from './carousel.data';

const getNumberOfPages = (itemCount: number, itemsPerPage: number): number =>
  Math.ceil(itemCount / itemsPerPage);

export const getDesktopNumberOfPages321 = (itemCount: number): number =>
  getNumberOfPages(itemCount, DESKTOP_ITEMS_PER_PAGE_321);

export const getTabletNumberOfPages321 = (itemCount: number): number =>
  getNumberOfPages(itemCount, TABLET_ITEMS_PER_PAGE_321);

export const getNumberOfPages2withEdge = (itemCount: number): number =>
  getNumberOfPages(itemCount, ITEMS_PER_PAGE_2WE);
/**
 * Gets the starting width percentages (without gaps)
 * eg 3 total cards and 3 cards per page => 33% to each card
 * eg 6 total cards and 2 cards per page => 16.67% to each card
 *
 * (1) 100 is width of full <ul>
 * (2) Math.ceil(itemcCount / DESKTOP_Items_Per_Page) is the number of pages. eg 7 items will give 3 pages (3, 3, 1)
 * (1) divided by (2) gives the width % of each page. We then divide this by number of items per page
 * eg 7 item count for tablet will give (100 / 4 / 2) = 12.5% for each card
 * @param itemCount The total number of slides
 * @param itemsPerPage The number of slides in one page
 * @returns the width percentage needed by each slide
 */
const getStartingWidthPercentage = (itemCount: number, itemsPerPage: number): string => {
  const numberOfPages = getNumberOfPages(itemCount, itemsPerPage);
  return `${100 / numberOfPages / itemsPerPage}%`;
};

export const getDesktopFlexBasisValue321 = (itemCount: number): string =>
  `calc(${getStartingWidthPercentage(
    itemCount,
    DESKTOP_ITEMS_PER_PAGE_321
  )} - ${DESKTOP_SPACE_TAKEN_PER_PAGE_BY_GAPS_321}px)`;

export const getTabletFlexBasisValue321 = (itemCount: number): string =>
  `calc(${getStartingWidthPercentage(
    itemCount,
    TABLET_ITEMS_PER_PAGE_321
  )} - ${TABLET_SPACE_TAKEN_PER_PAGE_BY_GAPS_321}px)`;

export const getFlexBasisValue2WithEdge = (itemCount: number): string =>
  `calc(${getStartingWidthPercentage(
    itemCount,
    ITEMS_PER_PAGE_2WE
  )} - ${SPACE_TAKE_PER_PAGE_BY_GAPS_2WE}px)`;
