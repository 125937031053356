/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled';
import React from 'react';
import { useTheme } from '@emotion/react';
import useIsBreakpoint from '../../hooks/useIsBreakpoint';
import { MediaQuery } from '../../mixins/mediaQuery';
import Typography from '../Typography';
import { ArticleType, NewsType } from '../../types';
import { ArticleIcon, BroadcastIcon, DocumentIcon } from '../Icons';
import Box from '../Box';

const Wrapper = styled.div<{ isCompact?: boolean; articleType: ArticleType }>`
  width: 100%;
  box-shadow: 0px 2px 7px rgba(51, 63, 103, 0.14);
  border-radius: 4px;
  overflow: hidden;
  :not(:last-of-type) {
    margin-bottom: 16px;
  }
  &:hover {
    cursor: pointer;
    box-shadow: 0px 5px 15px rgba(51, 63, 103, 0.14);
    .title {
      color: ${({ theme, articleType }) =>
        articleType === 'news' ? theme.iuiPalette.primaryAlt[300] : theme.iuiPalette.primary[300]};
    }
  }
  ${({ theme }) => MediaQuery(theme.iuiBreakpoints.medium)} {
    ${({ isCompact }) =>
      isCompact
        ? ''
        : `
    display: flex;
    align-items: center;
    height: 168px;
    `}
  }
  ${({ theme }) => MediaQuery(theme.iuiBreakpoints.large)} {
    ${({ isCompact }) => (isCompact ? '' : 'height: 226px;')}
  }
`;

const ArticleImageWrapper = styled.div<{ isCompact?: boolean; articleType: string }>`
  padding: 24px;
  width: 100%;
  height: fit-content;
  flex-shrink: 0;
  padding: ${({ articleType }) => (articleType === 'blog' ? 0 : '16px 16px 0 16px')};
  ${({ theme }) => MediaQuery(theme.iuiBreakpoints.medium)} {
    padding: ${({ articleType, isCompact }) =>
      articleType === 'blog' ? 0 : isCompact ? '16px 16px 0 16px' : '24px 0 24px 24px'};

    ${({ isCompact }) =>
      isCompact ? `width: 100%; height: fit-content;` : `width: fit-content; height: 100%;`}
  }

  ${({ theme }) => MediaQuery(theme.iuiBreakpoints.large)} {
    padding: ${({ articleType, isCompact }) =>
      articleType === 'blog' ? 0 : isCompact ? '24px' : '24px 48px'};
  }
`;

const ArticleImage = styled.img<{ isCompact?: boolean; articleType: string }>`
  object-fit: ${({ articleType }) => (articleType === 'blog' ? 'cover' : 'contain')};
  aspect-ratio: 3 / 2; // TODO - consider changing to use 66.6% padding hack
  width: 100%;
  height: auto;
  ${({ theme }) => MediaQuery(theme.iuiBreakpoints.medium)} {
    ${({ isCompact }) => (isCompact ? `width: 100%; height: auto;` : `width: auto; height: 100%;`)}
  }
`;

const ArticleTextWrapper = styled.div<{ articleType?: ArticleType; isCompact?: boolean }>`
  padding: 16px;
  ${({ theme }) => MediaQuery(theme.iuiBreakpoints.medium)} {
    padding: ${({ isCompact }) => (isCompact ? '16px' : '24px')};
  }
  ${({ theme }) => MediaQuery(theme.iuiBreakpoints.large)} {
    ${({ isCompact, articleType }) =>
      isCompact
        ? ''
        : articleType === 'news'
        ? 'flex-grow: 1;'
        : `
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
    padding: ${articleType === 'blog' ? '0 24px 0 40px' : '0 24px 0 16px'};
    `}
  }
`;

const DetailsWrapper = styled.p<{ lineClamp: number }>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: ${({ lineClamp }) => lineClamp};
  line-clamp: ${({ lineClamp }) => lineClamp};
  overflow: hidden;
`;

const DateWrapper = styled.div<{ isCompact?: boolean }>`
  ${({ theme }) => MediaQuery(theme.iuiBreakpoints.large)} {
    ${({ isCompact }) =>
      isCompact
        ? ''
        : `
    text-align: right;
    height: 100%;
    flex-shrink: 0;
    margin-left: 40px;
    `}
  }
`;

const NewsTagWrapper = styled.div<{ isCompact?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  ${({ theme }) => MediaQuery(theme.iuiBreakpoints.large)} {
    margin-bottom: ${({ isCompact }) => (isCompact ? '16px' : '24px')};
  }
`;
export interface ArticleCardProps {
  title: string;
  description: string;
  date: string;
  imgSrc: string;
  imgAlt: string;
  newsType?: NewsType;
  articleType: ArticleType;
  isCompact?: boolean;
  onClick?: () => void;
}

const NewsTag: React.FC<{ type: NewsType }> = ({ type }) => {
  const theme = useTheme();
  const text = type === 'article' ? 'Article' : type === 'broadcast' ? 'Broadcast' : 'PDF';
  return (
    <Box display='flex'>
      {type === 'article' && <ArticleIcon dimensions={24} />}
      {type === 'broadcast' && <BroadcastIcon dimensions={24} />}
      {type === 'pdf' && <DocumentIcon dimensions={24} />}
      <Typography variant='h6' margin='0 0 0 8px' color={theme.iuiPalette.grays.grays[600]}>
        {text}
      </Typography>
    </Box>
  );
};

const ArticleCard: React.FC<ArticleCardProps> = ({
  title,
  description,
  date,
  imgSrc,
  imgAlt,
  articleType,
  isCompact,
  onClick,
  newsType
}) => {
  const { isMobile, isTablet, isDesktop } = useIsBreakpoint();
  const theme = useTheme();
  const lineClamp = !!description || isMobile ? 4 : isTablet ? 3 : 2;

  return (
    <Wrapper
      isCompact={isCompact}
      onClick={onClick}
      articleType={articleType}
      data-test='iui-component-article-card'
    >
      <ArticleImageWrapper isCompact={isCompact} articleType={articleType}>
        <ArticleImage src={imgSrc} alt={imgAlt} isCompact={isCompact} articleType={articleType} />
      </ArticleImageWrapper>
      <ArticleTextWrapper articleType={articleType} isCompact={isCompact}>
        {articleType === 'news' && newsType && (
          <NewsTagWrapper isCompact={isCompact}>
            <NewsTag type={newsType} />
            {isDesktop && !isCompact && (
              <Typography variant='body-s' color={theme.iuiPalette.grays.grays[600]}>
                {date}
              </Typography>
            )}
          </NewsTagWrapper>
        )}
        <DetailsWrapper lineClamp={lineClamp}>
          <Typography
            variant={articleType === 'news' ? 'body-l-alt' : 'h4'}
            as='span'
            color={
              articleType === 'news' ? theme.iuiPalette.brand[2] : theme.iuiPalette.primary[500]
            }
            className='title'
          >
            {title}
          </Typography>
          {articleType !== 'news' && !!description && (
            <>
              {/* 
              Note: this hack with spans, empty string, and br is because safari 
              implementation of line-clamp is shit and instead of applying parent div's line-clamp 
              to all children paragraphs, applies line-clamp to each paragraph individually
              */}
              <Typography as='span' display='inline-block' margin={`0 0 ${isDesktop ? 16 : 8}px 0`}>
                {' '}
              </Typography>
              <br />
              <Typography
                variant='body-m'
                as='span'
                margin={isDesktop ? '16px 0 0 0' : '8px 0 0 0'}
              >
                {description}
              </Typography>
            </>
          )}
        </DetailsWrapper>
        {(articleType !== 'news' || isCompact || !isDesktop) && (
          <DateWrapper isCompact={isCompact}>
            <Typography
              variant='body-s'
              color={theme.iuiPalette.grays.grays[600]}
              margin={isCompact || !isDesktop ? '8px 0 0 0' : '0'}
            >
              {date}
            </Typography>
          </DateWrapper>
        )}
      </ArticleTextWrapper>
    </Wrapper>
  );
};

export default ArticleCard;
