import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--print';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 23'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#675DC6'
      d='M23.105 9.219H20.34V6.453c0-.23-.087-.46-.26-.662L14.52.259A.913.913 0 0013.858 0H4.61a.91.91 0 00-.922.922v8.326H.922A.91.91 0 000 10.17v7.404a.91.91 0 00.922.922h2.766v2.766a.91.91 0 00.922.922h14.779a.91.91 0 00.922-.922v-2.766h2.766a.91.91 0 00.922-.922V10.14c.028-.519-.375-.922-.893-.922zM14.78 3.14l2.39 2.391h-2.39v-2.39zM5.532 1.815h5.531c1.008 0 1.844.835 1.844 1.844v2.794a.91.91 0 00.922.922h4.61V9.22H5.532V1.815zM18.496 20.31H5.532v-5.56h12.935v5.56h.029zm1.844-6.483a.91.91 0 00-.922-.922H4.61a.91.91 0 00-.922.922v2.766H1.844v-5.531h20.34v5.531H20.34v-2.766z'
    />
    <path
      fill='#675DC6'
      d='M8.326 18.467h7.404a.91.91 0 00.922-.922.91.91 0 00-.922-.922H8.326a.91.91 0 00-.922.922c0 .49.404.922.922.922z'
    />
  </svg>
);

const SvgPrint = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgPrint.displayName = 'Print';
export default SvgPrint;
