import * as React from 'react';

import { useTheme } from '@emotion/react';
import { StyledToastWrapper } from './toast.styles';
import { safeProps } from '../../utils/safeProps';
import Typography from '../Typography';
import { ErrorOutlineIcon } from '../Icons';
import { ToastVariants } from './toast.types';

export interface ToastProps {
  variant?: `${ToastVariants}`;
  message: string;
  onAutoClose?: () => void;
  autoClose?: boolean;
  show?: boolean;
}

export const Toast: React.FC<ToastProps> = (props: ToastProps) => {
  const {
    variant = ToastVariants.DEFAULT,
    message,
    onAutoClose,
    show = true,
    autoClose = false,
    ...rest
  } = props;
  const theme = useTheme();

  const timer = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  React.useEffect(() => {
    if (onAutoClose && show) {
      timer.current = setTimeout(onAutoClose, 4000);
    }
    /* eslint-disable no-unused-expressions */
    () => timer.current && clearTimeout(timer.current);
  }, [show, autoClose, onAutoClose, timer]);

  if (!show) {
    return null;
  }

  return (
    <StyledToastWrapper
      theme={theme}
      {...safeProps(rest)}
      variant={variant}
      data-test='iui-component-toast'
    >
      {variant === ToastVariants.ERROR && <ErrorOutlineIcon customColor='white' />}
      <Typography as='span' variant='h6'>
        {message}
      </Typography>
    </StyledToastWrapper>
  );
};

export default Toast;
