import React from 'react';
import { useTheme, Theme } from '@emotion/react';

import Typography from '../Typography';
import { SmallTickIcon, ExclaimIcon, TickIcon } from '../Icons';
import { BadgeIconTypes, BadgeProps, BadgeSizeTypes, BadgeTypes } from './badge.types';
import { assertNever } from '../../utils/helpers';
import { StyledWrap } from './badge.styles';

export const getBadgeColor = (type: `${BadgeTypes}`, theme: Theme): string => {
  switch (type) {
    case BadgeTypes.ERROR:
      return theme.iuiPalette.semantic.red100;
    case BadgeTypes.WARNING:
      return theme.iuiPalette.brand[3];
    case BadgeTypes.SUCCESS:
      return theme.iuiPalette.semantic.green100;
    default:
      return assertNever(type as never);
  }
};

const Badge: React.FC<BadgeProps> = ({
  label,
  type = BadgeTypes.ERROR,
  size = BadgeSizeTypes.DEFAULT,
  forceColor,
  icon
}) => {
  const theme = useTheme();

  const hasIcon = !label && icon && icon.length;
  const labelToUse = label && label.length < 3 ? label : '?';
  const hasLabel = label && label.length;
  const isDot = !hasLabel && !hasIcon;
  const iconColor = forceColor ?? getBadgeColor(type, theme);

  return (
    <StyledWrap type={type} icon={icon} isDot={isDot} bg={iconColor} size={size}>
      {icon === BadgeIconTypes.CIRCLE_TICK && <SmallTickIcon customColor='white' />}
      {icon === BadgeIconTypes.TICK && <TickIcon customColor={iconColor} dimensions={14} />}
      {icon === BadgeIconTypes.EXCLAIM && <ExclaimIcon customColor='white' />}
      {!hasIcon && hasLabel ? (
        <Typography as='span' variant='meta-s-alt' color='white'>
          {labelToUse}
        </Typography>
      ) : (
        ''
      )}
    </StyledWrap>
  );
};

export default Badge;
