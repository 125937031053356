import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--filter';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#0F0027'
      fillRule='evenodd'
      d='M9.884 12.4a2.802 2.802 0 01-5.368 0H2.801a.8.8 0 110-1.6h1.715a2.801 2.801 0 015.368 0H20.4a.8.8 0 110 1.6H9.884zm3.432 5.2a2.802 2.802 0 015.368 0h1.715a.8.8 0 110 1.6h-1.715a2.801 2.801 0 01-5.368 0H2.8a.8.8 0 110-1.6h10.516zm0-13.6a2.802 2.802 0 015.368 0h1.715a.8.8 0 110 1.6h-1.715a2.801 2.801 0 01-5.368 0H2.8a.8.8 0 110-1.6h10.516zM16 6a1.2 1.2 0 100-2.4A1.2 1.2 0 0016 6zm0 13.6a1.2 1.2 0 100-2.4 1.2 1.2 0 000 2.4zm-8.8-9.2a1.2 1.2 0 100 2.4 1.2 1.2 0 000-2.4z'
      clipRule='evenodd'
    />
  </svg>
);

const SvgFilter = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgFilter.displayName = 'Filter';
export default SvgFilter;
