import * as React from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Typography, Box, Container, Card, TextButton } from '../../components';
import { CardProps } from '../../types';
import defaultBreakpoints from '../../themes/breakpoints';
import { MediaQuery } from '../../mixins/mediaQuery';

export interface NewModuleProps {
  title: string;
  onClick(): void;
  newsCards: CardProps[];
  moreText?: string;
}

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-start;
  padding: 32px 0 24px;
  align-items: flex-start;

  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    padding: 40px 0 32px;
  }
`;

const ROW_GRID_GAP = 24;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 37px;

  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    flex-direction: row;
    flex-wrap: wrap;

    gap: ${ROW_GRID_GAP}px;
    & > * {
      flex: 0 0 calc(33.33% - ${(ROW_GRID_GAP * 2) / 3}px);
    }
    padding-bottom: 32px;
  }

  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    padding-bottom: 74px;
  }
`;

const NewsModule: React.FC<NewModuleProps> = ({
  title,
  onClick,
  newsCards,
  moreText = 'See all'
}) => {
  const theme = useTheme();
  return (
    <Box
      width='100%'
      background={theme?.newsModule?.background ?? 'ghostwhite'}
      data-test='iui-module-news-module'
    >
      <Container>
        <Box width='100%'>
          <TitleWrapper>
            <Typography variant='h3'>{title}</Typography>
            <TextButton text={moreText} onClick={onClick} hasRightIcon />
          </TitleWrapper>
          <CardsWrapper>
            {newsCards.map((card, num) => (
              <Card {...card} key={`${card.title}-${num.toString()}`} />
            ))}
          </CardsWrapper>
        </Box>
      </Container>
    </Box>
  );
};

export default NewsModule;
