import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--work-history-trustaff';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 264 263'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#302599'
      d='M71.919 89.885h119.74c5.255 0 10.545 5.29 10.545 10.545l-1.065 78.075c0 5.255-4.259 9.48-9.48 9.48H70.785c-5.255 0-9.48-4.259-9.48-9.48v-77.972c-.035-5.908 4.74-10.648 10.614-10.648z'
    />
    <path
      fill='#8A8FD9'
      d='M74.254 93.01h119.981c5.049 0 9.171 4.122 9.171 9.171v74.262c0 5.049-4.122 9.171-9.171 9.171H74.255c-5.05 0-9.172-4.122-9.172-9.171v-74.262c0-5.05 4.122-9.171 9.171-9.171z'
    />
    <path
      fill='#E8EAFF'
      d='M74.804 94.728h118.538c4.568 0 8.278 3.71 8.278 8.279v81.269c0 4.568-3.71 8.278-8.278 8.278H74.804c-4.568 0-8.278-3.71-8.278-8.278v-81.235c0-4.603 3.71-8.313 8.278-8.313z'
    />
    <path
      fill='#8A8FD9'
      d='M156.21 91.19h-4.053V77.724c0-2.68-2.061-4.878-4.603-4.878h-30.948c-2.542 0-4.603 2.198-4.603 4.878v13.499h-4.053v-13.5c0-4.911 3.882-8.896 8.656-8.896h30.948c4.775 0 8.656 3.985 8.656 8.897v13.464z'
    />
    <path
      fill='#100765'
      d='M101.046 147.11c-.515 0-1.03-.035-1.546-.069-15.147-1.099-20.334-3.847-21.124-4.328-7.076-3.607-8.519-11.438-8.69-12.606-1.65-5.53-2.474-12.331-2.474-20.266h3.057c0 7.66.79 14.22 2.37 19.476l.035.103v.137c0 .069 1.065 7.351 7.11 10.442l.172.103c.034.035 4.603 2.783 19.75 3.882 2.405.172 4.466-.103 6.252-.825-.756-.206-1.511-.515-2.301-.858-2.645-1.202-3.573-3.882-2.577-7.317-3.194-.79-5.908-2.954-7.831-6.251-1.718-2.885-2.68-6.561-2.714-10.064-.034-.653.035-1.409.172-2.199.859-4.808 4.122-8.69 8.759-10.373 4.706-1.717 9.789-.824 13.602 2.37 3.504 2.954 5.118 7.213 4.809 12.709-.275 5.359-.687 12.812-3.778 18.411 2.198-1.065 4.396-2.851 6.835-5.187 10.133-9.686 16.247-25.315 16.316-25.452l2.851 1.099c-.275.653-6.424 16.385-17.037 26.552-3.401 3.263-7.317 6.526-12.126 7.041l-.068.069c-2.645 2.267-5.908 3.401-9.824 3.401zm2.851-10.923c0 .034 0 .068-.034.103-.584 2.507.584 3.057 1.03 3.263 1.649.755 3.195 1.099 4.637 1.133 4.431-4.809 4.912-14.014 5.221-19.647.241-4.534-.962-7.866-3.744-10.202-2.954-2.473-6.904-3.16-10.579-1.855-3.607 1.306-6.149 4.328-6.801 8.038a9.298 9.298 0 00-.138 1.614c.035 5.702 3.092 12.366 8.622 13.499l.137.035c1.821-3.298 4.981-6.595 7.557-6.217.996.137 2.645.893 2.645 4.19 0 2.92-.893 4.912-2.679 5.908-1.787 1.065-4.088.722-5.874.138zm1.168-2.817c1.168.344 2.439.515 3.194.069.928-.55 1.134-2.061 1.134-3.263 0-.653-.069-.997-.138-1.168-.858.171-2.816 1.992-4.19 4.362z'
    />
    <path
      fill='#100765'
      d='M68.724 111.388c-5.77 0-11.198-2.233-15.251-6.321-4.087-4.053-6.32-9.48-6.32-15.25v-1.82h3.057v1.82c0 4.946 1.923 9.583 5.427 13.086 3.504 3.504 8.14 5.428 13.087 5.428 10.201 0 18.514-8.313 18.514-18.514v-1.82h3.057v1.82c.034 11.884-9.652 21.571-21.571 21.571z'
    />
    <path
      fill='#C5C7F2'
      d='M90.055 88.097l-2.37-.171 1.408-18.102c.652-8.587-4.534-16.522-12.675-19.304l.756-2.233c9.17 3.126 15.044 12.09 14.289 21.743l-1.408 18.067zM47.6 88.099l-1.512-18.102c-.79-9.652 5.05-18.617 14.186-21.812l.79 2.233c-8.14 2.851-13.327 10.82-12.606 19.373l1.511 18.102-2.37.206z'
    />
    <path
      fill='#FFB05A'
      d='M74.117 51.242l2.954.24c.343.035.652-.24.687-.584l.206-2.507c.034-.344-.24-.653-.584-.687l-2.954-.24a1.905 1.905 0 00-2.027 1.717c-.068 1.065.687 1.958 1.718 2.06zM62.816 51.207l-2.954.24c-.343.035-.652-.24-.687-.583l-.206-2.508c-.034-.343.24-.652.584-.687l2.954-.206c1.03-.068 1.958.687 2.027 1.718.068 1.03-.687 1.958-1.718 2.026z'
    />
    <path
      fill='#302599'
      d='M198.081 180.668l19.236-51.764c.721-3.022-1.958-8.862-3.985-11.3-1.923-2.302-5.77-6.149-11.266-6.149H85.108a9.958 9.958 0 00-9.72 7.832L63.57 180.05c-1.27 7.11 6.286 14.804 12.606 14.804l-.824-6.595h113.111a9.88 9.88 0 009.617-7.591z'
    />
    <path
      fill='#675DC6'
      d='M203.543 188.122l13.774-58.05c1.512-6.354-3.263-12.468-9.755-12.468H89.505c-4.74 0-8.793 3.331-9.79 8.003L67.42 183.656c-1.34 6.286 3.435 12.229 9.79 12.229h116.579c4.637 0 8.691-3.195 9.755-7.763z'
    />
    <path
      fill='#C5C7F2'
      d='M191.693 119.252h-7.488l-17.243 74.709h7.488l17.243-74.709zM116.847 119.252h-7.488l-17.21 74.709h7.455l17.243-74.709z'
    />
    <path fill='#302599' d='M140.444 110.425a6.698 6.698 0 100-13.396 6.698 6.698 0 000 13.396z' />
    <path fill='#E8EAFF' d='M140.444 109.498a5.77 5.77 0 10.001-11.54 5.77 5.77 0 00-.001 11.54z' />
    <path fill='#FFB05A' d='M140.445 106.679a2.954 2.954 0 100-5.908 2.954 2.954 0 000 5.908z' />
    <mask
      id='mask0_3382_14252'
      style={{
        maskType: 'alpha'
      }}
      width={9}
      height={9}
      x={136}
      y={99}
      maskUnits='userSpaceOnUse'
    >
      <path fill='url(#pattern0)' d='M136.718 99.805h7.557v7.557h-7.557z' />
    </mask>
    <g mask='url(#mask0_3382_14252)'>
      <path
        fill='#0F0027'
        d='M140.445 106.679a2.954 2.954 0 100-5.908 2.954 2.954 0 000 5.908z'
        opacity={0.1}
      />
    </g>
    <defs>
      <pattern id='pattern0' width={1} height={1} patternContentUnits='objectBoundingBox'>
        <use xlinkHref='#image0_3382_14252' />
      </pattern>
      <image
        id='image0_3382_14252'
        xlinkHref='data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEASABIAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA'
      />
    </defs>
  </svg>
);

const SvgWorkHistoryTrustaff = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgWorkHistoryTrustaff.displayName = 'WorkHistoryTrustaff';
export default SvgWorkHistoryTrustaff;
