import * as React from 'react';
import styled from '@emotion/styled';
import { GraphicProps } from '../../../types';
import { GetGraphicDimensions } from '../../../mixins/icons';

const TEST_ID = 'iui-component-graphic--trustaff';

const SVG: React.FC<GraphicProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 338 338'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='silver'
      d='M205.539 272.167h-72.721c-9.204 0-16.642-7.438-16.642-16.641V85.093c0-9.204 7.438-16.642 16.642-16.642h72.721c9.204 0 16.642 7.438 16.642 16.642V255.58c0 9.15-7.438 16.588-16.642 16.588z'
    />
    <path
      fill='#EDEDED'
      d='M205.058 269.92H133.3c-8.134 0-14.716-6.582-14.716-14.716V85.574c0-8.133 6.582-14.715 14.716-14.715h71.758c8.134 0 14.716 6.582 14.716 14.716v169.629c0 8.081-6.582 14.716-14.716 14.716z'
    />
    <path
      stroke='#A174C1'
      strokeLinecap='round'
      strokeMiterlimit={10}
      strokeWidth={1.865}
      d='M150.477 261.144h37.404'
    />
    <path
      fill='#FFB05A'
      d='M182.69 64.599h-5.511a1.016 1.016 0 01-1.017-1.017v-5.565c0-.589.482-1.017 1.017-1.017h5.511c.589 0 1.017.482 1.017 1.017v5.511c.054.589-.428 1.07-1.017 1.07z'
    />
    <path
      fill='#fff'
      d='M179.426 62.458c-.107 0-.16-.053-.214-.107L177.66 60.8a.326.326 0 010-.428.327.327 0 01.429 0l1.337 1.338 2.569-2.568a.325.325 0 01.428 0 .326.326 0 010 .428l-2.783 2.782c-.053.107-.107.107-.214.107z'
    />
    <path
      fill='#512D6C'
      d='M226.302 62.886h-34.996a1.211 1.211 0 01-1.231-1.23v-1.713c0-.695.535-1.23 1.231-1.23h34.996c.695 0 1.231.535 1.231 1.23v1.713c-.054.695-.589 1.23-1.231 1.23z'
    />
    <path
      fill='#E0C9F1'
      d='M152.029 127.206v14.716c0 2.087-.696 3.96-1.82 5.511a7.755 7.755 0 01-.856 1.017 6.48 6.48 0 01-.642.589 7.717 7.717 0 01-1.07.749 8.986 8.986 0 01-4.763 1.338H98.197c-1.66 0-3.264-.482-4.602-1.231-.107-.054-.214-.107-.268-.161-.214-.107-.374-.267-.588-.428a10.33 10.33 0 01-1.873-1.873 9.017 9.017 0 01-.482-.749 8.98 8.98 0 01-1.338-4.762v-14.716h62.983z'
    />
    <path
      fill='#512D6C'
      d='M115.159 143.688H94.986a.96.96 0 01-.964-.964v-2.354c0-.535.428-.963.964-.963h20.173c.535 0 .963.428.963.963v2.354a.96.96 0 01-.963.964zM129.179 137.694H95.253c-.642 0-1.23-.535-1.23-1.23v-1.82c0-.642.535-1.23 1.23-1.23h33.926c.642 0 1.231.535 1.231 1.23v1.82c-.054.642-.589 1.23-1.231 1.23zM152.029 127.206H88.993V91.889a9.203 9.203 0 019.204-9.204h44.681c5.084 0 9.204 4.12 9.204 9.15l-.053 35.371zM145.928 192.971H57.1a6.097 6.097 0 01-6.1-6.1v-13.164c0-3.371 2.73-6.1 6.1-6.1h88.882c3.371 0 6.1 2.729 6.1 6.1v13.164c-.053 3.371-2.782 6.1-6.154 6.1z'
    />
    <path fill='#A174C1' d='M139.168 177.677l-4.73 4.73.53.53 4.729-4.73-.529-.53z' />
    <path fill='#A174C1' d='M130.671 177.683l-.529.53 4.729 4.73.53-.53-4.73-4.73z' />
    <path
      fill='#E0C9F1'
      d='M105.688 182.697H65.073a1.381 1.381 0 01-1.391-1.391v-2.034c0-.802.642-1.391 1.391-1.391h40.615c.802 0 1.391.642 1.391 1.391v2.034c0 .802-.642 1.391-1.391 1.391z'
    />
    <path
      fill='#512D6C'
      d='M159.145 232.89h-39.33c-5.351 0-9.739-4.334-9.739-9.739 0-5.351 4.334-9.739 9.739-9.739h39.33c5.352 0 9.739 4.335 9.739 9.739-.053 5.405-4.387 9.739-9.739 9.739z'
    />
    <path
      fill='#E0C9F1'
      d='M152.831 225.345h-26.702c-.588 0-1.07-.481-1.07-1.07v-2.14c0-.589.482-1.071 1.07-1.071h26.702c.589 0 1.07.482 1.07 1.071v2.14c0 .535-.481 1.07-1.07 1.07z'
    />
    <path
      fill='#512D6C'
      d='M258.783 221.278h-55.116c-7.331 0-13.218-5.939-13.218-13.217v-83.37c0-7.331 5.94-13.217 13.218-13.217h55.116c7.331 0 13.217 5.94 13.217 13.217v83.317c0 7.331-5.94 13.27-13.217 13.27z'
    />
    <path
      fill='#E0C9F1'
      d='M244.281 175.687h-26.113a1.21 1.21 0 01-1.231-1.231v-3.05c0-.695.535-1.23 1.231-1.23h26.113a1.21 1.21 0 011.231 1.23v3.05c0 .696-.589 1.231-1.231 1.231zM253.164 167.928h-43.879a1.553 1.553 0 01-1.552-1.552v-2.354c0-.856.696-1.552 1.552-1.552h43.879c.856 0 1.552.696 1.552 1.552v2.354c0 .856-.696 1.552-1.552 1.552z'
    />
    <path
      fill='#fff'
      d='M244.656 214.001H217.74c-3.692 0-6.635-2.997-6.635-6.635a6.634 6.634 0 016.635-6.636h26.916c3.692 0 6.635 2.997 6.635 6.636a6.6 6.6 0 01-6.635 6.635z'
    />
    <path
      fill='#512D6C'
      d='M240.322 208.811h-18.248a.732.732 0 01-.749-.75v-1.444c0-.429.321-.75.749-.75h18.248c.428 0 .749.321.749.75v1.444c0 .428-.321.75-.749.75z'
    />
    <path
      fill='#fff'
      d='M226.569 137.322h.589c.428 0 .696-.268.696-.589 0-.321-.268-.588-.696-.588-.428 0-.696.321-.696.642h-1.284c.053-1.017.803-1.659 2.087-1.659 1.124 0 1.98.589 2.033 1.445 0 .749-.588 1.123-.963 1.177v.053c.535.054 1.124.429 1.124 1.285 0 1.016-1.07 1.605-2.194 1.605-1.445 0-2.087-.803-2.087-1.712h1.284c0 .374.375.642.856.642.482 0 .803-.268.803-.642 0-.375-.267-.642-.803-.642h-.588v-1.017h-.161zM229.62 141.708l1.712-6.796h1.231l-1.712 6.796h-1.231zM236.79 135.233v3.425h.642v1.07h-.642v.91h-1.338v-.91h-2.622v-1.124c.589-1.177 1.284-2.301 1.926-3.425h2.034v.054zm-1.338 3.425v-2.408h-.053c-.482.802-.91 1.551-1.338 2.408h1.391z'
    />
    <path
      fill='#FFB05A'
      d='M231.225 153.266c-8.723 0-15.84-7.117-15.84-15.839 0-.749.589-1.338 1.338-1.338.749 0 1.338.589 1.338 1.338 0 7.277 5.886 13.164 13.164 13.164 7.277 0 13.163-5.887 13.163-13.164 0-7.278-5.886-13.164-13.163-13.164a1.325 1.325 0 01-1.338-1.338c0-.749.589-1.337 1.338-1.337 8.722 0 15.839 7.117 15.839 15.839 0 8.722-7.117 15.839-15.839 15.839z'
    />
    <path
      fill='#512D6C'
      d='M226.302 76.317h-34.996a1.21 1.21 0 01-1.231-1.23v-1.713c0-.695.535-1.23 1.231-1.23h34.996c.695 0 1.231.535 1.231 1.23v1.713c-.054.695-.589 1.23-1.231 1.23z'
    />
    <path
      fill='#FFB05A'
      d='M182.69 78.03h-5.511a1.016 1.016 0 01-1.017-1.017v-5.565c0-.589.482-1.017 1.017-1.017h5.511c.589 0 1.017.482 1.017 1.017v5.512c.054.588-.428 1.07-1.017 1.07z'
    />
    <path
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={0.637}
      d='M177.874 74.016l1.552 1.552 2.836-2.782'
    />
    <path
      fill='#512D6C'
      d='M226.302 89.749h-34.997a1.21 1.21 0 01-1.23-1.23v-1.713c0-.696.535-1.231 1.23-1.231h34.997c.695 0 1.23.535 1.23 1.23v1.713c-.053.696-.588 1.23-1.23 1.23z'
    />
    <path
      fill='#FFB05A'
      d='M182.69 91.461h-5.511a1.016 1.016 0 01-1.017-1.017v-5.511c0-.589.481-1.017 1.017-1.017h5.511c.589 0 1.017.482 1.017 1.017v5.511c.053.535-.428 1.017-1.017 1.017z'
    />
    <path
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={0.637}
      d='M177.874 87.448l1.552 1.605 2.836-2.836'
    />
  </svg>
);

const SvgTrustaff = styled(SVG)`
  ${(props: GraphicProps) => GetGraphicDimensions(props)}
`;
SvgTrustaff.displayName = 'Trustaff';
export default SvgTrustaff;
