import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--new-look-trustaff';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 264 263'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#C5C7F2'
      d='M160.432 211.774h-56.585a12.936 12.936 0 01-12.95-12.949V66.211c0-7.162 5.788-12.95 12.95-12.95h56.585a12.937 12.937 0 0112.949 12.95v132.656c0 7.12-5.788 12.907-12.949 12.907z'
    />
    <path
      fill='#E8EAFF'
      d='M160.057 210.025h-55.835c-6.33 0-11.45-5.121-11.45-11.45V66.585c0-6.329 5.12-11.45 11.45-11.45h55.835c6.329 0 11.45 5.121 11.45 11.45v131.99c0 6.287-5.121 11.45-11.45 11.45z'
    />
    <path
      stroke='#8A8FD9'
      strokeLinecap='round'
      strokeMiterlimit={10}
      strokeWidth={1.865}
      d='M117.587 203.197h29.104'
    />
    <path
      fill='#FFB05A'
      d='M142.653 50.264h-4.289a.79.79 0 01-.791-.791v-4.33a.79.79 0 01.791-.791h4.289a.79.79 0 01.791.79v4.29c.042.457-.333.832-.791.832z'
    />
    <path
      fill='#fff'
      d='M140.113 48.599c-.083 0-.125-.042-.166-.083l-1.208-1.208a.254.254 0 010-.333.253.253 0 01.333 0l1.041 1.041 1.999-1.998a.253.253 0 01.333 0 .254.254 0 010 .333l-2.165 2.165c-.042.083-.083.083-.167.083z'
    />
    <path
      fill='#302599'
      d='M176.587 48.931h-27.231a.943.943 0 01-.958-.957V46.64c0-.541.417-.957.958-.957h27.231c.541 0 .957.416.957.957v1.333c-.041.54-.458.957-.957.957z'
    />
    <path
      fill='#C5C7F2'
      d='M118.795 98.98v11.451c0 1.624-.541 3.081-1.416 4.288a5.969 5.969 0 01-.666.791 4.828 4.828 0 01-.499.458c-.25.209-.542.417-.833.583a6.985 6.985 0 01-3.706 1.041H76.908c-1.29 0-2.54-.374-3.58-.957-.084-.042-.167-.084-.209-.125-.166-.084-.291-.208-.458-.333a8.046 8.046 0 01-1.457-1.458 6.855 6.855 0 01-.375-.583 6.986 6.986 0 01-1.04-3.705v-11.45h49.006z'
    />
    <path
      fill='#302599'
      d='M90.106 111.804H74.409a.746.746 0 01-.75-.75v-1.832c0-.416.333-.749.75-.749h15.697c.416 0 .75.333.75.749v1.832c0 .417-.334.75-.75.75zM101.015 107.14H74.617a.969.969 0 01-.958-.958v-1.416c0-.499.416-.957.958-.957h26.398c.499 0 .957.416.957.957v1.416c-.041.5-.458.958-.957.958zM118.795 98.98H69.746V71.5a7.161 7.161 0 017.162-7.162h34.767c3.955 0 7.161 3.206 7.161 7.12l-.041 27.522zM114.048 150.152H44.93a4.744 4.744 0 01-4.746-4.747v-10.242a4.745 4.745 0 014.746-4.747h69.16a4.744 4.744 0 014.746 4.747v10.242c-.041 2.624-2.165 4.747-4.788 4.747z'
    />
    <path fill='#8A8FD9' d='M108.787 138.251l-3.681 3.681.413.412 3.68-3.68-.412-.413z' />
    <path fill='#8A8FD9' d='M102.176 138.256l-.412.412 3.68 3.68.412-.412-3.68-3.68z' />
    <path
      fill='#C5C7F2'
      d='M82.737 142.158H51.134c-.624 0-1.082-.5-1.082-1.083v-1.582c0-.625.5-1.083 1.082-1.083h31.603c.625 0 1.083.5 1.083 1.083v1.582c0 .625-.5 1.083-1.083 1.083z'
    />
    <path
      fill='#302599'
      d='M124.333 181.213H93.729c-4.163 0-7.578-3.373-7.578-7.578 0-4.164 3.373-7.578 7.578-7.578h30.604c4.163 0 7.578 3.372 7.578 7.578-.042 4.205-3.415 7.578-7.578 7.578z'
    />
    <path
      fill='#C5C7F2'
      d='M119.419 175.343H98.642a.835.835 0 01-.832-.833v-1.666c0-.458.374-.832.832-.832h20.777c.458 0 .833.374.833.832v1.666c0 .416-.375.833-.833.833z'
    />
    <path
      fill='#302599'
      d='M201.861 172.178h-42.886c-5.704 0-10.285-4.622-10.285-10.285v-64.87c0-5.705 4.622-10.285 10.285-10.285h42.886c5.704 0 10.284 4.622 10.284 10.285v64.829c0 5.704-4.621 10.326-10.284 10.326z'
    />
    <path
      fill='#C5C7F2'
      d='M190.577 136.703h-20.319a.942.942 0 01-.957-.958v-2.373c0-.542.416-.958.957-.958h20.319c.542 0 .958.416.958.958v2.373a.97.97 0 01-.958.958zM197.489 130.665h-34.143a1.208 1.208 0 01-1.207-1.208v-1.832c0-.666.541-1.207 1.207-1.207h34.143c.666 0 1.207.541 1.207 1.207v1.832c0 .667-.541 1.208-1.207 1.208z'
    />
    <path
      fill='#fff'
      d='M190.868 166.515h-20.943a5.162 5.162 0 01-5.163-5.163 5.162 5.162 0 015.163-5.163h20.943a5.162 5.162 0 015.163 5.163 5.136 5.136 0 01-5.163 5.163z'
    />
    <path
      fill='#302599'
      d='M187.496 162.476h-14.198a.57.57 0 01-.583-.583v-1.125c0-.333.25-.582.583-.582h14.198c.333 0 .583.249.583.582v1.125a.57.57 0 01-.583.583z'
    />
    <path
      fill='#fff'
      d='M176.795 106.85h.458c.334 0 .542-.208.542-.458s-.208-.458-.542-.458c-.333 0-.541.25-.541.499h-.999c.042-.791.624-1.29 1.624-1.29.874 0 1.54.458 1.582 1.124a.914.914 0 01-.75.916v.041c.417.042.875.334.875 1 0 .791-.833 1.249-1.707 1.249-1.124 0-1.624-.625-1.624-1.333h.999c0 .292.292.5.666.5.375 0 .625-.208.625-.5 0-.291-.208-.499-.625-.499h-.458v-.791h-.125zM179.169 110.263l1.332-5.288h.958l-1.332 5.288h-.958zM184.748 105.225v2.665h.5v.833h-.5v.708h-1.041v-.708h-2.04v-.875c.458-.916.999-1.79 1.499-2.664h1.582v.041zm-1.041 2.665v-1.874h-.041a27.062 27.062 0 00-1.041 1.874h1.082z'
    />
    <path
      fill='#FFB05A'
      d='M180.417 119.257c-6.786 0-12.324-5.538-12.324-12.325a1.03 1.03 0 011.041-1.041 1.03 1.03 0 011.041 1.041c0 5.663 4.58 10.243 10.242 10.243 5.663 0 10.243-4.58 10.243-10.243s-4.58-10.243-10.243-10.243a1.03 1.03 0 01-1.041-1.04 1.03 1.03 0 011.041-1.042c6.787 0 12.325 5.538 12.325 12.325 0 6.787-5.538 12.325-12.325 12.325z'
    />
    <path
      fill='#302599'
      d='M176.587 59.383h-27.231a.943.943 0 01-.958-.958v-1.333c0-.54.417-.957.958-.957h27.231c.541 0 .957.416.957.957v1.333c-.041.541-.458.958-.957.958z'
    />
    <path
      fill='#FFB05A'
      d='M142.653 60.715h-4.289a.79.79 0 01-.791-.79v-4.331a.79.79 0 01.791-.791h4.289a.79.79 0 01.791.79v4.29c.042.457-.333.832-.791.832z'
    />
    <path
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={0.637}
      d='M138.905 57.592l1.208 1.208 2.207-2.165'
    />
    <path
      fill='#302599'
      d='M176.587 69.834h-27.231a.943.943 0 01-.958-.958v-1.332c0-.542.417-.958.958-.958h27.231c.541 0 .957.416.957.958v1.332c-.041.541-.458.958-.957.958z'
    />
    <path
      fill='#FFB05A'
      d='M142.653 71.166h-4.289a.79.79 0 01-.791-.791v-4.289a.79.79 0 01.791-.791h4.289a.79.79 0 01.791.791v4.289c.042.416-.333.79-.791.79z'
    />
    <path
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={0.637}
      d='M138.905 68.044l1.208 1.249 2.207-2.207'
    />
  </svg>
);

const SvgNewLookTrustaff = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgNewLookTrustaff.displayName = 'NewLookTrustaff';
export default SvgNewLookTrustaff;
