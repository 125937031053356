import * as React from 'react';
import styled from '@emotion/styled';
import { GraphicProps } from '../../../types';
import { GetGraphicDimensions } from '../../../mixins/icons';

const TEST_ID = 'iui-component-graphic--trustaff';

const SVG: React.FC<GraphicProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 338 338'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#512D6C'
      d='M71.645 91.59h74.358l17.451 17.494h86.049l-4.169 117.941-174.591-.473.902-134.962z'
    />
    <path fill='#E0C9F1' d='M244.302 99.843H78.35V215.42h165.952V99.843z' />
    <path fill='#512D6C' d='M70.743 226.552L92.62 118.239h173.13l-20.416 108.786-174.591-.473z' />
    <path
      fill='#FFB05A'
      d='M233.127 237.04h38.339v-42.509H293l-40.532-47.924-40.918 47.924h21.577v42.509z'
    />
  </svg>
);

const SvgTrustaff = styled(SVG)`
  ${(props: GraphicProps) => GetGraphicDimensions(props)}
`;
SvgTrustaff.displayName = 'Trustaff';
export default SvgTrustaff;
