import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--phone';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#1D243D'
      d='M17.2 19.3H17c-2.2-.2-4.3-1-6.1-2.2-1.7-1.1-3.2-2.5-4.2-4.2-1.2-1.8-2-4-2.2-6.1 0-.3 0-.6.1-.9.1-.3.2-.5.4-.7.2-.2.5-.4.7-.5.3-.1.6-.2.9-.2h2c.5 0 1 .2 1.4.5.4.3.6.8.7 1.3 0 .6.2 1.2.4 1.7.1.4.2.8.1 1.2-.1.4-.3.7-.6 1l-.4.4c.8 1.2 1.8 2.2 2.9 2.9l.4-.4c.3-.3.6-.5 1-.6.5 0 .9 0 1.3.2.6.2 1.1.3 1.7.4.5.1 1 .3 1.3.7.3.4.5.9.5 1.4v2c0 .3-.1.6-.2.8-.1.3-.3.5-.5.7-.2.2-.5.3-.7.4-.3.1-.5.2-.7.2zM8.6 6H6.4c-.1 0-.137.037-.2.1-.138.138-.2.4-.2.3v.2c.2 1.9.9 3.8 1.9 5.4 1 1.5 2.3 2.8 3.8 3.8 1.6 1.1 3.5 1.7 5.4 1.9h.2c.1 0 .1-.1.2-.1.1-.1.1-.1.1-.2v-2.2c0-.1 0-.3-.1-.4-.1-.1-.2-.2-.4-.2-.7-.1-1.4-.3-2-.5h-.3c-.1 0-.2.1-.3.2l-.8.8c-.2.2-.6.3-.9.1-1.8-1-3.3-2.5-4.3-4.3-.1-.3 0-.7.2-.9l.8-.8c.1-.1.1-.2.2-.3.1-.1 0-.2 0-.3-.3-.7-.4-1.4-.5-2.1 0-.1-.1-.3-.2-.4-.1-.1-.3-.1-.4-.1z'
    />
  </svg>
);

const SvgPhone = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgPhone.displayName = 'Phone';
export default SvgPhone;
