import * as React from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import Typography from '../Typography';
import IUILink from '../Link';
import Box from '../Box';
import { useBreakpoints } from '../../hooks';
import defaultBreakpoints from '../../themes/breakpoints';
import { MediaQuery } from '../../mixins/mediaQuery';
import { ChevronRightIcon } from '../Icons';
import { shadow } from '../../mixins/shadow';
import CommonImageSizes from '../Image/commonImageSizes';
import Image from '../Image';
import { CardProps, ResourceTypes } from '../../types';
import useIsBreakpoint from '../../hooks/useIsBreakpoint';
import { LinkContext } from '../../providers/Linker';

const Wrapper = styled(Box)`
  &:hover {
    ${shadow({ level: 'heavy' })}
  }
`;

const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-top: 66.66%;
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    height: auto;
  }
`;

const StyledImage = styled(Image)`
  width: 100%;
  position: absolute;
  top: 0;
`;

const ContentWrapper = styled.div`
  padding: 16px 16px 24px 16px;
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    padding: 20px 16px;
  }
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    padding: 20px 20px 32px 20px;
  }
`;

const OverflowContainer = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
`;

const cardLinkTextByAResourceType = (type?: string, author?: string): string | null => {
  switch (type) {
    case ResourceTypes.BLOG:
      return 'Read blog';
    case ResourceTypes.NEWS:
      return 'Read news';
    case ResourceTypes.CHECKLIST:
      return 'Download checklist';
    case ResourceTypes.WEBINAR:
      return 'Watch webinar';
    case ResourceTypes.QA:
      return `Read Q & A${author ? ` with ${author}` : ''}`;
    case ResourceTypes.PRESS:
      return 'Read more';
    default:
      return null;
  }
};

const Card: React.FC<CardProps> = ({
  imgSrc,
  imgAlt,
  title,
  subText,
  link,
  linkText,
  resourceType,
  author,
  imgFocusArea = 'top'
}) => {
  const theme = useTheme();
  const breakpoint = useBreakpoints(theme.iuiBreakpoints);
  const titleVariant = React.useMemo(() => {
    switch (breakpoint) {
      case 'small':
      case 'medium':
        return 'h6';
      default:
        return 'h5';
    }
  }, [breakpoint]);

  const imgSrcWithFocus = React.useMemo(() => {
    const url = new URL(imgSrc);
    if (!url.searchParams.get('f')) {
      url.searchParams.set('f', imgFocusArea || 'top');
    }
    return url.toString();
  }, [imgSrc, imgFocusArea]);

  const { isMobile } = useIsBreakpoint();
  const linkTextToUse = linkText || cardLinkTextByAResourceType(resourceType, author);

  const CustomLink = React.useContext(LinkContext);

  // Required to style the linkText when hovering on the card
  const StyledA = styled(CustomLink)`
    text-decoration: none;
    display: flex;

    &:focus .faux-link > div {
      color: ${theme.link?.base.focusColor ?? 'inherit'};
    }
    &:hover .faux-link > div {
      color: ${theme.link?.base.hoverColor || 'inherit'};
    }
  `;

  return (
    <StyledA href={link} to={link} data-test='iui-component-card'>
      <Wrapper
        width='100%'
        cursor='pointer'
        boxShadowLevel='light'
        background={theme.iuiPalette.grays.white}
        borderRadius='4px'
        overflow='hidden'
      >
        <ImageWrapper>
          {isMobile ? (
            <StyledImage
              alt={imgAlt}
              src={imgSrcWithFocus}
              responsive={CommonImageSizes.fullWidthFourThreeRatio}
              options={{ vw: 100 }}
              loading='lazy'
            />
          ) : (
            <StyledImage
              alt={imgAlt}
              src={imgSrcWithFocus}
              responsive={CommonImageSizes.threeColFourThreeRatio}
              loading='lazy'
            />
          )}
        </ImageWrapper>
        <ContentWrapper>
          <OverflowContainer>
            <Typography variant={titleVariant} hoverColor={theme?.iuiPalette?.grays.grays[600]}>
              {title}
            </Typography>
            {resourceType && (
              <Typography
                variant='body-m'
                color={theme.iuiPalette.grays.grays[700]}
                margin='0.5rem 0 0 0'
              >
                {subText}
              </Typography>
            )}
          </OverflowContainer>
          {!linkTextToUse && (
            <Typography
              variant='body-s'
              color={theme.iuiPalette.grays.grays[600]}
              margin='0.5rem 0 0 0'
            >
              {subText}
            </Typography>
          )}

          {linkTextToUse && (
            <Box margin='0.5rem 0 0' className='faux-link'>
              <IUILink
                as='div'
                icon={
                  <ChevronRightIcon
                    customColor={theme.iuiPalette.grays.grays[400]}
                    dimensions={16}
                  />
                }
                iconToRight
                label={linkTextToUse}
                href={link}
              />
            </Box>
          )}
        </ContentWrapper>
      </Wrapper>
    </StyledA>
  );
};

export default Card;
