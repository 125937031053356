import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--chevron-left';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#1D243D'
      fillRule='evenodd'
      d='M16.971 3.357a1.25 1.25 0 01.019 1.768L10.5 11.75l6.49 6.625a1.25 1.25 0 11-1.786 1.75L7 11.75l8.204-8.375a1.25 1.25 0 011.767-.018z'
      clipRule='evenodd'
    />
  </svg>
);

const SvgChevronLeft = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgChevronLeft.displayName = 'ChevronLeft';
export default SvgChevronLeft;
