import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--tooltip';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#0F0027'
      fillRule='evenodd'
      d='M18.27 12.5a6.77 6.77 0 11-13.54 0 6.77 6.77 0 0113.54 0zm1.23 0a8 8 0 11-16 0 8 8 0 0116 0zm-9.196-1.99H8.892c.048-1.413 1.027-2.446 2.812-2.446 1.647 0 2.747.95 2.747 2.271 0 .92-.457 1.563-1.274 2.05-.788.456-1.01.769-1.01 1.37v.348h-1.394l-.006-.426c-.054-.89.294-1.43 1.124-1.917.745-.451.997-.776.997-1.37 0-.638-.505-1.1-1.262-1.1-.775 0-1.274.474-1.322 1.22zm2.23 5.504c0 .571-.385.944-.998.944-.6 0-.997-.373-.997-.944 0-.576.396-.95.997-.95.613 0 .998.373.998.95z'
      clipRule='evenodd'
    />
  </svg>
);

const SvgTooltip = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgTooltip.displayName = 'Tooltip';
export default SvgTooltip;
