import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--gearbox';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 247 200'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#333F67'
      d='M130.917 133.167v-8.292H72.958l-.083 33.167c0 9.203 7.38 16.583 16.584 16.583h116.083c9.204 0 16.583-7.38 16.583-16.583v-33.167h-58.042v8.292h-33.166zm82.916-74.625h-33.249V41.958l-16.583-16.583h-33.167l-16.583 16.583v16.584H81.167c-9.121 0-16.584 7.462-16.584 16.583V100c0 9.204 7.38 16.583 16.584 16.583h49.75V100h33.166v16.583h49.75c9.121 0 16.584-7.462 16.584-16.583V75.125c0-9.12-7.463-16.583-16.584-16.583zm-49.75 0h-33.166V41.958h33.166v16.584z'
    />
    <path
      fill='#F5995C'
      d='M80.413 104.921c.195-1.463.293-2.976.293-4.586 0-1.56-.098-3.122-.342-4.585l9.907-7.709c.878-.683 1.122-2 .585-2.976l-9.37-16.197c-.585-1.073-1.805-1.415-2.879-1.073l-11.663 4.683c-2.44-1.854-5.026-3.415-7.906-4.586L57.282 55.5a2.36 2.36 0 00-2.343-2H36.2c-1.171 0-2.098.83-2.294 2L32.15 67.892c-2.88 1.171-5.515 2.781-7.906 4.586L12.58 67.795c-1.074-.39-2.294 0-2.88 1.073L.381 85.065c-.586 1.025-.39 2.293.585 2.976l9.907 7.709c-.244 1.463-.44 3.073-.44 4.585 0 1.513.098 3.123.342 4.586L.87 112.63c-.879.683-1.123 2-.586 2.976l9.37 16.197c.585 1.073 1.805 1.415 2.879 1.073l11.663-4.683c2.44 1.854 5.027 3.415 7.906 4.586l1.757 12.392c.244 1.17 1.17 2 2.342 2h18.74c1.17 0 2.147-.83 2.293-2l1.757-12.392c2.879-1.171 5.514-2.732 7.905-4.586l11.663 4.683c1.074.391 2.294 0 2.88-1.073l9.37-16.197c.585-1.074.341-2.293-.586-2.976l-9.81-7.709zM45.57 117.899c-9.663 0-17.569-7.904-17.569-17.564s7.906-17.563 17.569-17.563c9.662 0 17.568 7.904 17.568 17.563 0 9.66-7.906 17.564-17.568 17.564z'
    />
  </svg>
);

const SvgGearbox = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgGearbox.displayName = 'Gearbox';
export default SvgGearbox;
