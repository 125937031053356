import * as React from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Typography from '../Typography';
import Box from '../Box';
import Button from '../Button';
import { PhoneIcon, EmailIcon } from '../Icons';
import { EmotionStyledProps } from '../../types';
import { useBreakpoints } from '../../hooks';
import defaultBreakpoints from '../../themes/breakpoints';
import { MediaQuery } from '../../mixins/mediaQuery';

interface RecruiterContactCardProps {
  name: string;
  children: React.ReactNode;
  onEmailClick: () => void;
  onCallClick: () => void;
}

const Wrapper = styled(Box)`
  padding: 28px 20px 36px;
  ${(props: EmotionStyledProps) =>
    MediaQuery(props.theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    padding: 28px 42px 32px 40px;
  }
  ${(props: EmotionStyledProps) =>
    MediaQuery(props.theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    padding: 28px 44px 50px 54px;
  }
`;

const StyledButtonWrapper = styled(Box)`
  gap: 14px;
  ${(props: EmotionStyledProps) =>
    MediaQuery(props.theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    gap: 20px;
  }
`;

const RecruiterContactCard: React.FC<RecruiterContactCardProps> = ({
  name,
  children,
  onEmailClick,
  onCallClick
}) => {
  const theme = useTheme();
  const breakpoint = useBreakpoints(theme.iuiBreakpoints);
  const isMobile = React.useMemo(() => breakpoint === 'small', [breakpoint]);
  return (
    <Wrapper
      boxShadowLevel='light'
      background={theme?.iuiPalette?.grays?.white ?? 'white'}
      data-test='iui-component-recruiter-contact-card'
    >
      <Box display='flex' flexDirection='column' height='100%'>
        <Box
          width='100%'
          display='flex'
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent={isMobile ? 'flex-start' : 'space-between'}
          alignItems={isMobile ? 'center' : 'flex-start'}
        >
          {/* TODO: Replace with the Avatar component once ready  */}
          <Box
            borderRadius='50%'
            width='58px'
            height='58px'
            background={theme?.iuiPalette?.primaryAlt[300] || 'green'}
          />
          <Typography
            color={theme?.iuiPalette?.grays[700] || 'gray'}
            variant={isMobile ? 'meta-l' : 'meta-m'}
            padding={isMobile ? '12px 0 0 0' : '0'}
          >
            Your Recruiter
          </Typography>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          alignItems={isMobile ? 'center' : 'flex-start'}
          padding={isMobile ? '8px 0 0 0' : '16px 0 0 0'}
          justifyContent='space-between'
          height='100%'
        >
          <Box
            display='flex'
            flexDirection='column'
            alignItems={isMobile ? 'center' : 'flex-start'}
          >
            <Typography variant='h5-alt' margin='0 0 16px 0'>
              {name}
            </Typography>
            {children}
          </Box>

          <StyledButtonWrapper
            display='flex'
            flexDirection='row'
            justifyContent={isMobile ? 'center' : 'flex-start'}
            margin='32px 0 0 0'
            flexWrap='wrap'
            width='100%'
          >
            <Button
              label='Email recruiter'
              colorway='light'
              icon={<EmailIcon iconType='primary' dimensions={16} />}
              variant='secondary'
              onClick={onEmailClick}
              fullWidthMobile
            />
            <Button
              label='Call recruiter'
              colorway='light'
              icon={<PhoneIcon iconType='primary' dimensions={16} />}
              variant='secondary'
              onClick={onCallClick}
              fullWidthMobile
            />
          </StyledButtonWrapper>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default RecruiterContactCard;
