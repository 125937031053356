import { css, Theme, SerializedStyles } from '@emotion/react';
import { rgba } from '../../utils/helpers';

export enum ShadowLevels {
  SLIGHT = 'slight',
  LIGHT = 'light',
  MEDIUM = 'medium',
  HEAVY = 'heavy',
  SUPER = 'super'
}

export interface ShadowMixinProps {
  // eslint-disable-note: theme is work in progress, this type can be update once defined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme?: Theme;
  level?: `${ShadowLevels}`; // internally i'm using enum, externally users use 'slight'|'light'|'heavy'|'super'
}

export const shadow = (props: ShadowMixinProps): SerializedStyles => {
  const { level = ShadowLevels.LIGHT } = props;
  const shadowBaseColor = props.theme?.shadowColor ?? '#000';
  const shadowColor = rgba(shadowBaseColor, 0.14);
  let shadowCss = `0 0 0 0 ${shadowColor}`;
  switch (level) {
    case ShadowLevels.SLIGHT:
      shadowCss = `0 1px 0 ${shadowColor}`;
      break;
    case ShadowLevels.LIGHT:
      shadowCss = `0 2px 7px ${shadowColor}`;
      break;
    case ShadowLevels.MEDIUM:
      shadowCss = `0 4px 13px ${shadowColor}`;
      break;
    case ShadowLevels.HEAVY:
      shadowCss = `0 5px 15px ${shadowColor}`;
      break;
    case ShadowLevels.SUPER:
      shadowCss = `0px 31px 98px ${rgba(shadowBaseColor, 0.07)}, 
      0px 18px 51px ${rgba(shadowBaseColor, 0.05)},
      0px 9px 24px ${rgba(shadowBaseColor, 0.04)},
      0px 4px 11px ${rgba(shadowBaseColor, 0.03)}, 
      0px 1px 5px ${rgba(shadowBaseColor, 0.02)};`;
      break;
    default:
      shadowCss = `0 0 0 0 ${shadowColor}`;
  }

  // TODO: topic: animations, transitions.
  return css`
    box-shadow: ${shadowCss};
    transition: 0.25s linear;
    transition-property: box-shadow;
  `;
};

export default shadow;
