import * as React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import ReactMarkdown from 'react-markdown';
import {
  Box,
  Typography,
  Container,
  Grid,
  GridColumns,
  Button,
  SectionTitle
} from '../../components';
import defaultBreakpoints from '../../themes/breakpoints';
import { MediaQuery } from '../../mixins/mediaQuery';
import Image from '../../components/Image';
import useIsBreakpoint from '../../hooks/useIsBreakpoint';

interface ContentAWithImageProps {
  imgUrl: string;
  imgAlt: string;
  title: string;
  bodyText: string;
  buttonText: string;
  ctaLink: string;
  onButtonClick: () => void;
  afterFold?: boolean;
}

const Wrapper = styled(Box)`
  ${({ theme }) => `background: ${theme?.iuiPalette?.brand?.[1] ?? 'navy'};`}
  width: 100%;
  padding: 0 0 24px;
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    padding: 0 0 48px;
  }
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    ${({ theme }) => (theme.name === 'trustaff' ? `padding: 65px 0 0;` : `padding: 122px 0 0;`)}
    ${({ theme }) =>
      ` background: linear-gradient(to bottom, ${theme?.iuiPalette?.brand?.[1] ?? 'navy'} 92.5%, ${
        theme?.iuiPalette?.grays.white ?? 'white'
      } 92.5%);`}
  }
`;

const StyledImage = styled(Image)`
  width: 90%;
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 19px 0 0 0;

  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    padding: 32px 0 0 10px;
  }

  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    padding: 15px 0 92px 0;
    ${({ theme }) => (theme.name === 'trustaff' ? `width: 100%;` : `width: 85%;`)}
`;

const StyledButton = styled(Button)`
  margin-top: 40px;
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    margin-top: 32px;
  }
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    margin-top: 40px;
  }
`;

const imageResponseiveSizes = [
  {
    size: {
      w: 350,
      h: 350
    },
    maxWidth: defaultBreakpoints.small
  },
  {
    size: {
      w: 372,
      h: 372
    },
    maxWidth: defaultBreakpoints.medium
  },
  {
    size: {
      w: 498,
      h: 498
    },
    maxWidth: defaultBreakpoints.large
  },
  {
    size: {
      w: 526,
      h: 526
    },
    maxWidth: defaultBreakpoints.xLarge
  }
];

const imageResponseiveSizesMobile = [
  {
    size: {
      w: 436,
      h: 436
    },
    maxWidth: 436 - 100
  },
  {
    size: {
      w: 500,
      h: 500
    },
    maxWidth: 500 - 100
  },
  {
    size: {
      w: 572,
      h: 572
    },
    maxWidth: 572 - 100
  },
  {
    size: {
      w: 872,
      h: 872
    },
    maxWidth: 872 - 100
  },
  {
    size: {
      w: 1000,
      h: 1000
    },
    maxWidth: 1000 - 100
  }
];

const ContentAWithImage: React.FC<ContentAWithImageProps> = ({
  imgUrl,
  imgAlt,
  title,
  bodyText,
  buttonText,
  onButtonClick,
  ctaLink,
  afterFold = false
}) => {
  const theme = useTheme();
  const { isMobile } = useIsBreakpoint();

  return (
    <Wrapper data-test='iui-module-content-a-with-img'>
      <Container>
        <Grid>
          <GridColumns
            columns={[5, 2, 2]}
            alignSelf={theme.name === 'trustaff' ? 'end' : undefined}
          >
            {isMobile ? (
              <StyledImage
                alt={imgAlt}
                src={imgUrl}
                responsive={imageResponseiveSizesMobile}
                options={{ vw: 90, transform: 'translate(-20px, 0)' }}
                width='90%'
                {...(afterFold ? { loading: 'lazy' } : {})}
              />
            ) : (
              <StyledImage
                alt={imgAlt}
                src={imgUrl}
                responsive={imageResponseiveSizes}
                options={{}}
                {...(afterFold ? { loading: 'lazy' } : {})}
              />
            )}
          </GridColumns>
          <GridColumns columns={[6, 2, 2]} columnStarts={[7, 'auto', 'auto']}>
            <ContentWrapper>
              <SectionTitle level='3' variant='secondary-alt' color='white' margin='0 0 2rem '>
                {title}
              </SectionTitle>
              <Typography as='span' color='white'>
                <ReactMarkdown>{bodyText}</ReactMarkdown>
              </Typography>
              <StyledButton
                label={buttonText}
                onClick={onButtonClick}
                colorway='light'
                fullWidthMobile
                href={ctaLink}
                asLink
              />
            </ContentWrapper>
          </GridColumns>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default ContentAWithImage;
