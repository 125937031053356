import React from 'react';
import IconLink from '../IconLink';
import {
  SocialTwitterIcon,
  SocialFacebookIcon,
  SocialInstagramIcon,
  SocialLinkedinIcon
} from '../Icons';
import { SocialGroupWrapper } from './socialGroup.styles';

const SocialGroup: React.FC<{
  company: string;
  variant: 'primary' | 'inverted' | 'secondary';
  facebookURL?: string;
  linkedinURL?: string;
  twitterURL?: string;
  instagramURL?: string;
  hasBorder?: boolean;
}> = ({
  company,
  variant,
  facebookURL,
  linkedinURL,
  twitterURL,
  instagramURL,
  hasBorder = true
}) => (
  <SocialGroupWrapper $hasBorder={hasBorder}>
    {facebookURL && (
      <IconLink
        icon={SocialFacebookIcon}
        href={facebookURL}
        alt={`${company} on Facebook`}
        variant={variant}
      />
    )}
    {linkedinURL && (
      <IconLink
        icon={SocialLinkedinIcon}
        href={linkedinURL}
        alt={`${company} on LinkedIn`}
        variant={variant}
      />
    )}
    {twitterURL && (
      <IconLink
        icon={SocialTwitterIcon}
        href={twitterURL}
        alt={`${company} on Twitter`}
        variant={variant}
      />
    )}
    {instagramURL && (
      <IconLink
        icon={SocialInstagramIcon}
        href={instagramURL}
        alt={`${company} on Instagram`}
        variant={variant}
      />
    )}
  </SocialGroupWrapper>
);

export default SocialGroup;
