import React from 'react';
import { useTheme } from '@emotion/react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import styled from '@emotion/styled';
import { CancelIcon, ExpandIcon, SearchIcon } from '../Icons';
import Box from '../Box';
import Button from '../Button';
import IconButton from '../IconButton';
import Typography from '../Typography';
import Link from '../Link';
import { NavSection } from '../../types';
import { LinkContext } from '../../providers/Linker';

const Wrapper = styled(Box)`
  color: ${({ theme }) => theme?.iuiPalette?.primary[700] ?? '#1D243D'};
`;

const StyledAccordion = styled(Accordion)<{ $isActive: boolean }>`
  box-shadow: none;
  &::before {
    height: 0;
  }

  &.Mui-disabled {
    background-color: unset;
  }

  ${({ theme, $isActive }) => `
    .MuiAccordionSummary-root {
      min-height: unset;
      padding: 12px 16px;
      background-color: ${
        $isActive ? theme?.iuiPalette?.grays?.grays[200] ?? '#F6F6F6' : 'transparent'
      };
      border-left: 3px solid ${
        $isActive ? theme?.navMenu?.activeBorder ?? '#616D98' : 'transparent'
      };
    }

    .MuiAccordionSummary-root:active {
      background-color: ${theme?.iuiPalette?.grays?.grays[200] ?? '#F6F6F6'};
      border-left: 3px solid ${theme?.link?.base?.focusColor ?? '#284CA4'};
    }

    .MuiAccordionSummary-root:active .MuiAccordionSummary-content > p {
      color: ${theme?.link?.base?.focusColor ?? '#284CA4'};
    }

    .MuiAccordionSummary-root:hover {
      background-color: ${theme?.iuiPalette?.grays?.grays[200] ?? '#F6F6F6'};
    }
  
    .MuiAccordionSummary-root.Mui-disabled {
      background-color: ${theme?.iuiPalette?.grays?.grays[100] ?? '#FCFCFC'};
      opacity: 1;
    }
  `}

  .MuiAccordionSummary-root.Mui-disabled .MuiAccordionSummary-expandIconWrapper {
    opacity: 0.38;
  }

  .MuiAccordionSummary-root.Mui-disabled p {
    opacity: 0.38;
  }

  .MuiAccordionSummary-content {
    margin: 0;
  }

  ${({ theme, $isActive }) =>
    $isActive &&
    `
    .MuiAccordionSummary-content > p {
      color: ${theme?.navMenu?.activeLink ?? '#333F67'};
    }
  `}

  .MuiAccordionDetails-root {
    margin: -4px 0 4px 0;
    padding: 0;
  }
`;

const LinkWrapper = styled.div<{ $isDisabled?: boolean }>`
  padding-left: 40px;

  a {
    text-decoration: none;
  }

  ${({ theme, $isDisabled }) =>
    $isDisabled &&
    `
      background-color: ${theme?.iuiPalette?.grays?.grays[200] ?? '#F6F6F6'};

      p {
        opacity: 0.38;
      }
  `};

  ${({ theme }) => `
    &:active p {
      color: ${theme?.link?.base?.focusColor ?? '#284CA4'};
    }

    &:active {
      background-color: ${theme?.iuiPalette?.grays?.grays[200] ?? '#F6F6F6'};
    }

    &:hover {
      background-color: ${theme?.iuiPalette?.grays?.grays[200] ?? '#F6F6F6'};
    }
  `}
`;

const NavAccordion: React.FC<{
  section: string;
  links: NavSection[];
  activeRoute: string;
  onLinkPress: () => void;
}> = ({ section, links, activeRoute, onLinkPress }) => {
  const theme = useTheme();
  const CustomLink = React.useContext(LinkContext);
  const isAnyLinkInSectionActive = links.some((link) => link.route === activeRoute);
  return (
    <StyledAccordion disableGutters square $isActive={isAnyLinkInSectionActive}>
      <AccordionSummary
        expandIcon={<ExpandIcon dimensions={24} />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography variant='meta-l-alt'>{section}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {links.map((link) => {
          const isLinkActive = link.route === activeRoute;
          return (
            <LinkWrapper key={link.route}>
              <CustomLink href={link.route} to={link.route} onClick={onLinkPress}>
                <Typography
                  customLineHeight={3}
                  variant='meta-l-alt'
                  color={isLinkActive ? theme?.navMenu?.activeLink ?? '#333F67' : undefined}
                >
                  {link.label}
                </Typography>
              </CustomLink>
            </LinkWrapper>
          );
        })}
      </AccordionDetails>
    </StyledAccordion>
  );
};

const SignInLink = styled(Link)`
  color: ${({ theme }) => theme?.navbar?.signInLink?.color ?? '#1D243D'};
`;

interface NavMenuProps {
  sitemap: NavSection[];
  signInRoute: NavSection;
  activeRoute: string;
  username: string;
  showSearchIcon?: boolean;
  onClose: () => void;
  onSearchClick: () => void;
  onApplyNowClick: () => void;
}

const NavMenu: React.FC<NavMenuProps> = ({
  sitemap,
  signInRoute,
  activeRoute,
  username,
  showSearchIcon = true,
  onClose,
  onSearchClick,
  onApplyNowClick
}) => {
  const theme = useTheme();

  return (
    <Wrapper height='100%' data-test='iui-component-nav-menu'>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        padding='24px 0'
        margin='0 20px'
        borderBottom={`1px solid ${theme?.navMenu?.border ?? '#E5E6EB'}`}
      >
        <IconButton aria-label='cancel' icon={CancelIcon} size={24} onClick={onClose} />
        {showSearchIcon && (
          <IconButton aria-label='search' icon={SearchIcon} size={24} onClick={onSearchClick} />
        )}
      </Box>
      {!username && (
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          margin='0 20px'
          padding='24px 0'
          borderBottom={`1px solid ${theme?.navMenu?.border ?? '#E5E6EB'}`}
        >
          <SignInLink label='Sign in' href={signInRoute.route ?? '#'} />
          <Button label='Apply Now' variant='secondary' onClick={onApplyNowClick} />
        </Box>
      )}
      <Box margin='24px 0'>
        {(sitemap ?? []).map((section) => (
          <NavAccordion
            key={section.label}
            section={section.label}
            links={section.subsections ?? []}
            activeRoute={activeRoute}
            onLinkPress={onClose}
          />
        ))}
      </Box>
      <Box
        margin='0 20px'
        padding='32px 0'
        borderTop={`1px solid ${theme?.navMenu?.border ?? '#E5E6EB'}`}
      >
        <Typography variant='meta-l' color={theme?.navMenu?.version ?? '#616575'}>
          Version number placeholder
        </Typography>
      </Box>
    </Wrapper>
  );
};

export default NavMenu;
