/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */

import React, { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import { MultiselectOption } from '../../types';
import { MediaQuery } from '../../mixins/mediaQuery';
import { useMultiselectItems } from '../../hooks';
import MultiselectCore from '../MultiselectCore';
import Typography from '../Typography';
import Button from '../Button';
import { removeSpaces } from '../../utils/helpers';

const MenuProps = {
  autoFocus: false,
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  },
  PaperProps: {
    style: {
      marginTop: 4,
      paddingTop: 32,
      minHeight: 200,
      width: 375,
      maxWidth: '100%',
      overflowY: 'hidden' as const,
      borderRadius: 8
    }
  }
};

const StyledFormControl = styled(FormControl)`
  background: white;
  .MuiInputLabel-root {
    top: 4px;
    left: 10px;
    ${({ theme }) => MediaQuery(theme.iuiBreakpoints.large)} {
      left: 0;
    }
  }
  .MuiFilledInput-input {
    padding-top: 27px;
    padding-left: 19px;
    padding-bottom: 8px;
    background-color: white;
    border: 2px solid transparent;
    &:focus {
      background-color: white;
    }
    ${({ theme }) => MediaQuery(theme.iuiBreakpoints.large)} {
      padding-left: 11px;
    }
  }

  & .MuiSelect-select.Mui-disabled {
    -webkit-text-fill-color: unset;
  }

  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.iuiPalette.grays.black};
  }

  & .MuiSvgIcon-root.Mui-disabled {
    color: ${({ theme }) => theme.iuiPalette.grays.black};
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  z-index: 100;
  margin-top: -48px;
  padding: 48px 24px 32px;
  background: linear-gradient(
    0deg,
    #ffffff 65%,
    rgba(255, 255, 255, 0.8) 85%,
    rgba(255, 255, 255, 0)
  );
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 100%;
`;

interface MultiselectDropdownProps {
  label: string;
  pluralLabel?: string;
  items: MultiselectOption[];
  value: string[];
  onChange: (items: string[]) => void;
  allowSearch?: boolean;
  isLoadingOptions?: boolean;
}

const MultiselectDropdown: React.FC<MultiselectDropdownProps> = ({
  label,
  pluralLabel,
  items,
  value,
  onChange,
  allowSearch,
  isLoadingOptions
}) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const { allSelected, noneSelected } = useMultiselectItems(items, value);

  const getSelectText = React.useCallback(
    (selected: string): string => {
      if (isLoadingOptions || allSelected) {
        return `All ${pluralLabel || label}`;
      }
      if (noneSelected) {
        return `Select ${pluralLabel || label}`;
      }
      return `${selected.length} item${selected.length > 1 ? 's' : ''} selected`;
    },
    [isLoadingOptions, label, noneSelected, allSelected, pluralLabel]
  );

  const labelColor = React.useMemo((): string => {
    if (isLoadingOptions) {
      return theme?.iuiPalette?.grays.grays[600] ?? '#616575';
    }
    if (isOpen) {
      return theme.select.value.active;
    }
    if (noneSelected) {
      return theme.select.value.error ?? '#E03842';
    }
    return theme.select.value.selected;
  }, [isOpen, noneSelected, isLoadingOptions, theme]);

  const getSelectTypography = (selected) => {
    const text = getSelectText(selected);
    return (
      <Typography variant={isOpen || noneSelected ? 'body-m-alt' : 'body-m'} color={labelColor}>
        {text}
      </Typography>
    );
  };

  return (
    <div data-test='iui-component-multi-select-dropdown'>
      <StyledFormControl variant='filled' sx={{ width: '100%' }} disabled={isLoadingOptions}>
        <InputLabel id={`select-label-${label}`} shrink>
          <Typography variant='meta-l-alt' color={theme.select.label}>
            {label.toUpperCase()}
          </Typography>
        </InputLabel>
        <Select
          open={isOpen}
          labelId={`select-label-${removeSpaces(label)}`}
          multiple
          value={value}
          renderValue={getSelectTypography}
          MenuProps={MenuProps}
          displayEmpty
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          disableUnderline
        >
          <MultiselectCore
            label={label}
            items={items}
            selectedValues={value}
            onChange={onChange}
            allowSearch={allowSearch}
          />
          <ButtonWrapper>
            <StyledButton
              variant='secondary'
              label='Done'
              onClick={() => setIsOpen(false)}
              disabled={value.length === 0}
            />
          </ButtonWrapper>
        </Select>
      </StyledFormControl>
    </div>
  );
};

export default MultiselectDropdown;
