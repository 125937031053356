import React from 'react';

import { IconType } from './index';
import { FooterThemeOverrides } from '../modules/Footer/footer.types';
import { SectionTitleThemeOverrides } from '../components/SectionTitle/sectionTitle.types';
import { ButtonThemeOverrides } from '../components/Button/button.types';
import { ToggleThemeOverrides } from '../components/Toggle/toggle.types';
import { TypographyList, TypographyVariant } from '../components/Typography/typography.types';
import { PageTitleThemeOverrides } from '../components/PageTitle/pageTitle.types';

/* Contains all the types for our themes */

/* value corresponds to the minimum width of the respective breakpoint */
export interface Breakpoints {
  small: number;
  medium: number;
  large: number;
  xLarge: number;
  xxLarge: number;
}

/* Plaette Types */
type GrayscalePalette = {
  black: string;
  white: string;
  grays: {
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
  };
};

type BrandPalette = {
  1: string;
  2: string;
  3: string;
  4: string;
};

type PrimaryPalette = {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
};

type PrimaryAltPalette = {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
};

type SecondaryPalette = {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
};

type SecondaryAltPalette = {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
};

type SemanticPalette = {
  green100: string;
  red100: string;
  red200: string;
};

/* Palette sub model of BaseTheme */
export interface Palette {
  brand: BrandPalette;
  primary: PrimaryPalette;
  primaryAlt: PrimaryAltPalette;
  secondary: SecondaryPalette;
  secondaryAlt: SecondaryAltPalette;
  grays: GrayscalePalette;
  semantic: SemanticPalette;
  gradient: string;
}

/* Typography sub model of BaseTheme */
export interface ThemeTypography {
  baseDirectory: string;
  fonts: FontFamily[];
  styles: TypographyList;
  ingenovisFonts: FontFamily[];
  ingenovisStyles: TypographyList;
}

/* Theme */

/* Types of themes available in IUI */
export enum CompanyCode {
  FASTAFF = 'fastaff',
  TRUSTAFF = 'trustaff',
  USNURSING = 'usnursing',
  CARDIO = 'cardio'
}

export enum BreakpointSizes {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xLarge',
  XXLARGE = 'xxLarge'
}

/* Theme options used with CreateTheme() to customise how a theme is initialized */
export interface IthemeOptions {
  brand: string;
  fontDirectory?: string;
}

export interface BaseTheme {
  name: string;
  iuiBreakpoints: Breakpoints;
  iuiPalette: Palette;
  iuiTypography: ThemeTypography;
  shadowColor: string;
  focusColor: string;
  metaThemeColor: string;
  boldTextFontWeight: number;
  fixedHeights: {
    navBar: {
      desktop: number;
      mobile: number;
    };
    masthead: number;
    footer: number;
    minFooter: number;
  };
  icon: {
    default: string;
    invert: string;
    primary: string;
    secondary: string;
  };
  body: {
    color: string;
  };
  pageTitle: PageTitleThemeOverrides;
  link: {
    base: {
      color: string;
      hoverColor: string;
      focusColor: string;
    };
    alt: {
      color: string;
      hoverColor: string;
      focusColor: string;
    };
  };
  buttons: ButtonThemeOverrides;
  textButtons: {
    colors: {
      static: string;
      hover: string;
      active: string;
      disabled: string;
    };
  };
  toggles: ToggleThemeOverrides;
  snackbar: {
    default: string;
    error?: string;
  };
  borderRadius: string;
  footer: FooterThemeOverrides;
  heading?: SectionTitleThemeOverrides;
  // {
  //   bg?: string;
  // };
  TitleWith3IconCards: {
    titleColor: string;
    backgroundColor: string;
    dividerColor: string;
    arrowIconColor?: string;
    linkHoverColor: string;
    linkActiveColor: string;
  };
  logos: {
    default: React.FC<{ className?: string }>;
  };
  navbar: {
    logoHeight: {
      large: string;
      small: string;
    };
    logoTopPosition?: string;
    logoWidth: {
      large: string;
      small: string;
    };
    link: {
      color: string;
      hoverColor: string;
    };
    signInLink: {
      color: string;
    };
    hover: {
      color: string;
      bg: string;
    };
    selected: {
      color: string;
      underline: string;
    };
  };
  navMenu: {
    activeBorder: string;
    activeLink: string;
    title: string;
    divider: string;
    department: string;
    version: string;
    border: string;
  };
  subNavbar: {
    bg: string;
    height: number;
  };
  checkbox: {
    default: {
      restingSelected: string;
      restingUnselected: string;
      hover: string;
      pressed: string;
    };
    leadGen: {
      resting: string;
      hoverSelected: string;
      hoverUnselected: string;
      pressedSelected: string;
      pressedUnselected: string;
    };
  };
  select: {
    divider: string;
    label: string;
    value: {
      active: string;
      selected: string;
      error?: string;
    };
    menuList: {
      activeBackground: string;
    };
  };
  jobSearch: {
    selectBorder: string;
  };
  avatar: {
    bg: string;
  };
  contactBanner: {
    bg: string;
  };
  jobCard: {
    jobTitleColor: string;
    jobTitleHoverColor: string;
    jobTitleActiveColor: string;
    locationColor: string;
    detailTitleColor: string;
  };
  share: {
    iconColor: string;
    textColor: string;
    textHoverColor: string;
    iconHoverColor: string;
    activeColor: string;
    iconWrapperColor: string;
    backgroundColor: string;
    backgroundHoverColor: string;
  };
  referral: {
    bg: string;
    text: string;
  };
  toast: {
    standard: {
      color: string;
    };
    success: {
      color: string;
    };
    error: {
      color: string;
    };
  };
  modal: {
    backgroundColor: string;
  };

  dropdown: {
    hover: string;
    selected: string;
  };
  legalBar: {
    default: {
      backgroundColor: string;
      textColor: string;
    };
    inverted: {
      backgroundColor: string;
      textColor: string;
      borderColor: string;
    };
  };
  newsModule: {
    background: string;
  };
  pagination: {
    selectedHoverBackgroundColor: string;
  };
  contactCard: {
    iconType: IconType;
    headerVariant: TypographyVariant;
  };
  Graphics: {
    primary: string;
    secondary: string;
  };
}

/* Fonts */

type FontVariant = {
  file: string; // the name of the fontfile
  style: 'normal' | 'italic';
  weight: number;
};

export type FontFamily = {
  name: string;
  variants: FontVariant[];
};
