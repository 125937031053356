/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Typography, Container, Button, JobCard, TextButton } from '../../components';
import { useBreakpoints, useIsBreakpoint } from '../../hooks';
import defaultBreakpoints from '../../themes/breakpoints';
import { MediaQuery } from '../../mixins/mediaQuery';
import { JobCardProps } from '../../components/JobCard';

enum FeaturedJobsVariant {
  DEFAULT = 'default',
  HOME = 'home',
  DASHBOARD = 'dashboard'
}

type StateFilter = {
  name: string;
  abbr: string;
};
interface FeaturedJobsProps {
  title: string;
  subtitle?: string;
  categories?: string[];
  jobCards: JobCardProps[];
  onLinkClick?: () => void;
  onClickCategory?: (category: string) => void;
  activeCategory?: string;
  stateFilter?: StateFilter;
  variant?: `${FeaturedJobsVariant}`;
}

const Wrapper = styled.div`
  padding: 40px 0;
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    padding: 64px 0;
  }
`;

const DesktopCategoriesRowContainer = styled.div<{
  hasStateFilter: boolean;
  variant: `${FeaturedJobsVariant}`;
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ variant }) => (variant === 'dashboard' ? 'flex-start' : 'center')};
  width: 100%;
  align-items: center;
  padding: ${({ hasStateFilter, variant }) => {
    if (hasStateFilter) {
      return '0 0 24px';
    }
    if (variant === 'dashboard') {
      return '0 0 16px';
    }
    return '0 137px 24px';
  }};
  position: relative;
`;

const DesktopCategoriesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;

  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
    padding: 16px;
  }
`;

const PositionedLink = styled(TextButton)`
  position: absolute;
  right: 0;
  margin: 0 0 0 auto;
`;

const MobileCategoriesContainer = styled.div`
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  gap: 8px;
  padding-bottom: 24px;
`;

const JobCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  ${({ theme }) => MediaQuery(theme?.iuiBreakpoints?.medium ?? defaultBreakpoints.medium)} {
    gap: 8px;
  }
`;

const StyledButton = styled(Button)<{ isInactive: boolean }>`
  ${({ isInactive }) =>
    isInactive
      ? `
  &:hover {
    color: ${({ theme }) => theme?.iuiPalette?.primary[400] ?? 'blue'};
    border-color: ${({ theme }) => theme?.iuiPalette?.primary[400] ?? 'blue'};
  }
  `
      : ''}
`;

const FeaturedJobs: React.FC<FeaturedJobsProps> = ({
  title,
  subtitle,
  onLinkClick,
  categories,
  activeCategory,
  onClickCategory,
  jobCards,
  stateFilter,
  variant
}) => {
  const theme = useTheme();
  const breakpoint = useBreakpoints(theme.iuiBreakpoints);
  const { isMobile } = useIsBreakpoint();
  const label =
    variant === 'dashboard' || variant === 'home' || !stateFilter
      ? 'See all jobs'
      : `See all ${stateFilter.name} jobs`;

  const positionedLink = <PositionedLink text={label} onClick={onLinkClick} hasRightIcon />;

  const CategoryButtons: React.FC = () => (
    <>
      {Array.isArray(categories) &&
        categories.map((category) => (
          <StyledButton
            label={category}
            variant='secondary'
            colorway={category === activeCategory ? 'dark' : 'light'}
            onClick={() => onClickCategory && onClickCategory(category)}
            key={category}
            withBorder
            isInactive={category !== activeCategory}
          />
        ))}
    </>
  );

  if (variant === FeaturedJobsVariant.DASHBOARD) {
    return (
      <>
        <DesktopCategoriesRowContainer hasStateFilter={!!stateFilter} variant='dashboard'>
          <Typography variant='h3'>{title}</Typography>
          {positionedLink}
        </DesktopCategoriesRowContainer>
        <JobCardsContainer>
          {jobCards.map((jobCard) => (
            <JobCard {...jobCard} key={jobCard.link} />
          ))}
          {breakpoint === 'small' && (
            <Button
              label={label}
              variant='secondary'
              colorway='light'
              onClick={onLinkClick}
              margin='16px 0 0 0'
              fullWidthMobile
            />
          )}
        </JobCardsContainer>
      </>
    );
  }

  return (
    <Wrapper data-test='iui-module-featured-jobs'>
      {(variant === 'home' || !stateFilter) && (
        <Container>
          <Typography
            variant='h3'
            margin={
              breakpoint === 'medium'
                ? variant === 'home'
                  ? '0 0 16px 0'
                  : '0 0 32px 0'
                : '0 0 24px 0'
            }
            textAlign='center'
            width='100%'
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography
              variant='body-l'
              margin={isMobile ? '0 0 24px 0' : '0 0 40px 0'}
              textAlign='center'
            >
              {subtitle}
            </Typography>
          )}
        </Container>
      )}
      {variant === 'home' ? null : breakpoint === 'small' ? (
        stateFilter ? (
          <Typography variant='h5-alt' padding='0 0 12px 24px'>
            {stateFilter.name} Jobs
          </Typography>
        ) : (
          <MobileCategoriesContainer>
            <CategoryButtons />
          </MobileCategoriesContainer>
        )
      ) : (
        <Container>
          <DesktopCategoriesRowContainer hasStateFilter={!!stateFilter} variant='default'>
            {stateFilter ? (
              <Typography variant='h5-alt'>{stateFilter.name} Jobs</Typography>
            ) : (
              <DesktopCategoriesContainer>
                <CategoryButtons />
              </DesktopCategoriesContainer>
            )}
            {positionedLink}
          </DesktopCategoriesRowContainer>
        </Container>
      )}
      <Container>
        <JobCardsContainer>
          {jobCards.map((jobCard) => (
            <JobCard {...jobCard} key={jobCard.link} />
          ))}
          {(variant === 'home' || breakpoint === 'small') && (
            <Button label={label} onClick={onLinkClick} margin='24px auto 0' fullWidthMobile />
          )}
        </JobCardsContainer>
      </Container>
    </Wrapper>
  );
};

FeaturedJobs.defaultProps = {
  activeCategory: '',
  stateFilter: undefined,
  variant: FeaturedJobsVariant.DEFAULT
};

export default FeaturedJobs;
