import * as React from 'react';
import styled from '@emotion/styled';
import Radio, { RadioProps } from '@mui/material/Radio';
import { GetTypographyCss } from '../../mixins';

// https://mui.com/components/radio-buttons/#customization
const Icon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 20,
  height: 20,
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#fff',
  '.Mui-focusVisible &': {
    outline: `1px solid ${theme.iuiPalette.primaryAlt[500] ?? '#284CA4'}`,
    outlineOffset: 2
  },
  'input:hover ~ &': {
    boxShadow: 'none',
    outline: `2px solid ${theme.iuiPalette.primaryAlt[300] ?? '#758BD2'}`
  },
  'input:active ~ &': {
    boxShadow: 'none',
    outline: `2px solid ${theme.iuiPalette.primaryAlt[500] ?? '#284CA4'}`
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    outline: `1px solid ${theme.iuiPalette.grays.grays[400] ?? '#BCBEC7'}`,
    opacity: 0.5
  }
}));

const CheckedIcon = styled(Icon)(({ theme }) => ({
  backgroundColor: theme.iuiPalette.primaryAlt[400] ?? '#4664C3',
  boxShadow: 'none',
  '&:before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""'
  },
  'input:hover ~ &': {
    backgroundColor: theme.iuiPalette.primaryAlt[300] ?? '#758BD2'
  },
  'input:active ~ &': {
    backgroundColor: theme.iuiPalette.primaryAlt[500] ?? '#284CA4'
  }
}));

const StyledRadio = styled(Radio)`
  .MuiFormLabel-root {
    ${({ theme }) => GetTypographyCss({ variant: 'meta-l-caps-alt', theme })}
  }
`;

export const CustomRadio: React.FC<RadioProps> = (props) => (
  <StyledRadio
    sx={{
      '&:hover': {
        bgcolor: 'transparent'
      }
    }}
    disableRipple
    color='default'
    checkedIcon={<CheckedIcon />}
    icon={<Icon />}
    {...props}
  />
);

export default CustomRadio;
