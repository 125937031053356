import * as React from 'react';
import styled from '@emotion/styled';
import { GraphicProps } from '../../../types';
import { GetGraphicDimensions } from '../../../mixins/icons';

const TEST_ID = 'iui-component-graphic--fastaff';

const SVG: React.FC<GraphicProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 338 338'
    className={className}
    data-test={TEST_ID}
  >
    <path fill='url(#pattern0)' d='M84.886 55.285h150.246v195.788H84.886z' />
    <path
      fill='url(#paint0_linear_1769_13903)'
      d='M233.101 248.703l-147.224.042L87.92 55.979h142.202l2.979 192.724z'
    />
    <g filter='url(#filter0_d_1769_13903)'>
      <path fill='#FCFCFC' d='M233.101 248.703l-147.224.042L87.92 55.979h142.202l2.979 192.724z' />
    </g>
    <path
      stroke='#616D98'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={4.895}
      d='M121.502 92.54h49.288'
    />
    <path
      stroke='#616D98'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={2.627}
      d='M120.523 102.628h77.507M120.523 111.268h77.507'
    />
    <path
      stroke='#616D98'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={4.895}
      d='M121.502 131.571h49.288'
    />
    <path
      stroke='#616D98'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={2.627}
      d='M120.523 141.658h77.507M120.523 150.256h77.507'
    />
    <path
      stroke='#616D98'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={4.895}
      d='M121.502 170.6h49.288'
    />
    <path
      stroke='#616D98'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={2.627}
      d='M120.523 180.688h77.507M120.523 189.285h77.507'
    />
    <path
      stroke='#616D98'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={4.895}
      d='M121.502 209.63h49.288'
    />
    <path
      stroke='#616D98'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={2.627}
      d='M120.523 219.718h77.507'
    />
    <path fill='url(#pattern1)' d='M167.064 135.761h88.956v154.077h-88.956z' />
    <path
      fill='#9BA3C0'
      d='M235.144 280.157h-48.563c-6.129 0-11.109-4.98-11.109-11.109V155.193c0-6.129 4.98-11.109 11.109-11.109h48.563c6.129 0 11.109 4.98 11.109 11.109v113.855a11.09 11.09 0 01-11.109 11.109z'
    />
    <path
      fill='#fff'
      d='M234.804 278.624h-47.926a9.81 9.81 0 01-9.832-9.832V155.533a9.81 9.81 0 019.832-9.832h47.926a9.81 9.81 0 019.832 9.832v113.259a9.81 9.81 0 01-9.832 9.832z'
    />
    <path
      stroke='#616D98'
      strokeLinecap='round'
      strokeMiterlimit={10}
      strokeWidth={1.246}
      d='M198.37 272.793h24.985'
    />
    <path fill='url(#pattern2)' d='M183.552 165.018h54.48v70.654h-54.48z' />
    <g filter='url(#filter1_d_1769_13903)'>
      <path fill='#FCFCFC' d='M185.346 168.643l45.457-1.916 4.98 64.61-49.415 2.086-1.022-64.78z' />
    </g>
    <path
      stroke='#616D98'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={1.618}
      d='M197.221 178.475l16.302-.639'
    />
    <path
      stroke='#616D98'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={0.868}
      d='M197.051 181.837l25.58-.979M197.136 184.646l25.623-.979'
    />
    <path
      stroke='#616D98'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={1.618}
      d='M197.732 191.371l16.301-.638'
    />
    <path
      stroke='#616D98'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={0.868}
      d='M197.519 194.691l25.623-.936M197.647 197.543l25.58-.979'
    />
    <path
      stroke='#616D98'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={1.618}
      d='M198.2 204.268l16.301-.639'
    />
    <path
      stroke='#616D98'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={0.868}
      d='M198.03 207.588l25.58-.937M198.115 210.439l25.623-.979'
    />
    <path
      stroke='#616D98'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={1.618}
      d='M198.711 217.164l16.301-.639'
    />
    <path
      stroke='#616D98'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={0.868}
      d='M198.498 220.484l25.623-.979'
    />
    <path
      stroke='#616D98'
      strokeMiterlimit={10}
      strokeWidth={0.426}
      d='M211.65 263.813a5.788 5.788 0 100-11.577 5.788 5.788 0 000 11.577z'
      opacity={0.3}
    />
    <path
      fill='#333F67'
      d='M185.133 152.767l-.808 2h1.064l-.639 2 2.043-2.553h-1.362l1.022-1.405h-1.32v-.042zM227.568 257.811a.539.539 0 010 .766.539.539 0 01-.766 0 .539.539 0 010-.766.539.539 0 01.766 0z'
    />
    <path
      fill='#333F67'
      d='M228.803 256.705h-.384l-.255-.511h-1.915l-.256.511h-.383a.516.516 0 00-.51.51v2.128c0 .298.255.511.51.511h3.193c.297 0 .51-.255.51-.511v-2.128c0-.255-.255-.51-.51-.51zm-1.618 2.511a1.053 1.053 0 01-1.064-1.064c0-.596.468-1.064 1.064-1.064.596 0 1.064.468 1.064 1.064 0 .596-.468 1.064-1.064 1.064z'
    />
    <path
      fill='#333F67'
      d='M211.65 262.493a4.47 4.47 0 100-8.938 4.47 4.47 0 000 8.938z'
      opacity={0.3}
    />
    <defs>
      <pattern id='pattern0' width={1} height={1} patternContentUnits='objectBoundingBox'>
        <use xlinkHref='#image0_1769_13903' />
      </pattern>
      <pattern id='pattern1' width={1} height={1} patternContentUnits='objectBoundingBox'>
        <use xlinkHref='#image1_1769_13903' />
      </pattern>
      <pattern id='pattern2' width={1} height={1} patternContentUnits='objectBoundingBox'>
        <use xlinkHref='#image2_1769_13903' />
      </pattern>
      <image
        id='image0_1769_13903'
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAWEAAAHMCAYAAAAEfRnnAAAACXBIWXMAAAsSAAALEgHS3X78AAAA'
      />
      <image
        id='image1_1769_13903'
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANIAAAFrCAYAAACkK9dLAAAACXBIWXMAAAsSAAALEgHS3X78AAAA'
      />
      <image
        id='image2_1769_13903'
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIEAAACnCAYAAAA2VT97AAAACXBIWXMAAAsSAAALEgHS3X78AAAA'
      />
      <filter
        id='filter0_d_1769_13903'
        width={151.224}
        height={196.766}
        x={84.377}
        y={54.98}
        colorInterpolationFilters='sRGB'
        filterUnits='userSpaceOnUse'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          result='hardAlpha'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dx={0.5} dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_1769_13903' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow_1769_13903' result='shape' />
      </filter>
      <filter
        id='filter1_d_1769_13903'
        width={54.437}
        height={70.696}
        x={183.846}
        y={165.727}
        colorInterpolationFilters='sRGB'
        filterUnits='userSpaceOnUse'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          result='hardAlpha'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dx={0.5} dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_1769_13903' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow_1769_13903' result='shape' />
      </filter>
      <linearGradient
        id='paint0_linear_1769_13903'
        x1={68.532}
        x2={252.157}
        y1={128.379}
        y2={177.581}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FEFFFF' />
        <stop offset={1} stopColor='#F7F6F6' />
      </linearGradient>
    </defs>
  </svg>
);

const SvgFastaff = styled(SVG)`
  ${(props: GraphicProps) => GetGraphicDimensions(props)}
`;
SvgFastaff.displayName = 'Fastaff';
export default SvgFastaff;
