import styled from '@emotion/styled';
import { MediaQuery } from '../../mixins';
import { EmotionStyledProps } from '../../types';

export const SocialGroupWrapper = styled.div<{ $hasBorder?: boolean }>`
  padding-bottom: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  ${({ theme, $hasBorder }) =>
    $hasBorder && `border-bottom: 1px solid ${theme.iuiPalette.primary[200]}`};

  ${(props: EmotionStyledProps) => MediaQuery(props.theme.iuiBreakpoints.medium)} {
    margin-top: 0;
    margin-bottom: 1rem;
  }
`;
