import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--rapid-response';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#1D243D'
      d='M20.68 10.893c-.557-.092-1.09.325-1.182.881a7.584 7.584 0 01-2.155 4.333 7.564 7.564 0 01-6.441 2.155l-1.344 1.853a9.186 9.186 0 002.363.302c2.48 0 4.982-.95 6.882-2.85a9.66 9.66 0 002.757-5.515 1.01 1.01 0 00-.88-1.158zM20.68 2.159c-.58 0-1.043.463-1.043 1.042v1.483l-.834-.834c-3.8-3.8-9.94-3.8-13.74 0-2.503 2.502-3.337 6.047-2.526 9.268l1.784-2.456A7.724 7.724 0 016.546 5.31c2.989-2.99 7.831-2.99 10.797 0l.834.834h-1.483c-.58 0-1.042.463-1.042 1.042 0 .58.463 1.043 1.042 1.043h3.985c.58 0 1.043-.463 1.043-1.043V3.201c0-.58-.463-1.042-1.043-1.042z'
    />
    <path
      fill='#1D243D'
      d='M11.898 5.31c-.58 0-1.043.463-1.043 1.042v4.356c0 .464.302.881.765.997l3.198.903c.092.023.185.047.277.047.464 0 .881-.302.997-.765a1.015 1.015 0 00-.718-1.274L12.94 9.92V6.352c0-.579-.464-1.042-1.043-1.042zM9.511 14.833H7.727l.533-3.916c.093-.672-.764-.996-1.158-.463L2.12 17.335c-.301.417 0 1.02.51 1.02h1.784L3.88 22.27c-.093.672.765.997 1.159.464l4.981-6.905c.324-.417.023-.996-.51-.996z'
    />
  </svg>
);

const SvgRapidResponse = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgRapidResponse.displayName = 'RapidResponse';
export default SvgRapidResponse;
