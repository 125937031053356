import styled from '@emotion/styled';

import { MastheadProps } from '.';
import { CompanyCode } from '../../types/theme';
import shadow from '../../mixins/shadow';
import { ExactMediaQuery } from '../../mixins/mediaQuery';
import defaultBreakpoints from '../../themes/breakpoints';

export const StyledMastheadWrapper = styled.header<MastheadProps>`
  background: white;
  padding: 11px 72px;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  ${shadow({ level: 'slight' })};
  height: 72px;
  overflow: hidden;
  > div {
    justify-content: flex-end;
    > span {
      font-weight: normal;
    }
  }
  .svg-wrap {
    height: 100%;
    align-items: center;
    display: flex;
    margin-top: ${({ brand }) => (brand === CompanyCode.TRUSTAFF ? '-6px' : '0')};
    &:hover {
      cursor: pointer;
    }
  }

  ${ExactMediaQuery(defaultBreakpoints.small, defaultBreakpoints.medium)} {
    padding: 11px 20px;
    .svg-wrap {
      left: 20px;
    }
    > div + div {
      text-align: right;
      min-width: 100px;
      margin-left: 1rem;
      svg {
        height: 20px;
        margin-right: -7px;
        margin-top: -1px;
      }
    }
    > div > div {
      justify-content: flex-end;
    }
  }
`;
