export enum ToggleVariants {
  SMALL = 'small',
  LARGE = 'large'
}

interface ToggleColorOverrides {
  enabled?: string;
  hover?: string;
  active?: string;
  disabled?: string;
}

interface ToggleSizeOverrides {
  width?: string;
  height?: string;
  radius?: string;
}

export interface ToggleThemeOverrides {
  small?: ToggleSizeOverrides;
  large?: ToggleSizeOverrides;
  colors?: ToggleColorOverrides;
}
