import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--tick';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 13 10'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#FF8300'
      fillRule='evenodd'
      d='M11.948 1.088a.583.583 0 010 .824L4.533 9.328a.583.583 0 01-.825 0l-3.37-3.37a.583.583 0 01.824-.826l2.959 2.959 7.002-7.003a.583.583 0 01.825 0z'
      clipRule='evenodd'
    />
  </svg>
);

const SvgTick = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgTick.displayName = 'Tick';
export default SvgTick;
