import { css, SerializedStyles } from '@emotion/react';
import { TypographyVariant, TypographyProps } from '../../components/Typography/typography.types';
import { EmotionStyledProps } from '../../types';
import { DEFAULT_THEME, GetTheme } from '../../themes';
import defaultBreakpoints from '../../themes/breakpoints';
import { MediaQuery } from '../mediaQuery';
import { getStylesList } from '../../utils';

interface GetTypographyCssProps extends Partial<EmotionStyledProps> {
  variant: TypographyVariant;
}

const getTypographyCss = (props: GetTypographyCssProps): SerializedStyles => {
  const { variant, theme } = props;
  /* Get styles for that particular variant from theme or default theme */
  const stylesList = getStylesList(theme);
  const variantStyles: TypographyProps = stylesList[variant];
  return css`
    font-family: ${variantStyles.fontFamily};
    font-weight: ${variantStyles.fontWeight};
    font-size: ${variantStyles.mobileFontSize || variantStyles.fontSize}rem;
    line-height: ${variantStyles.mobileLineHeight || variantStyles.lineHeight}rem;
    font-style: ${variantStyles.fontStyle};
    ${variantStyles.uppercase ? 'text-transform: uppercase;' : ''}
    ${variantStyles.textUnderline ? 'text-decoration: underline;' : 'text-decoration: none;'}
  ${MediaQuery(theme?.iuiBreakpoints?.large ?? defaultBreakpoints.large)} {
      font-size: ${variantStyles.fontSize}rem;
      line-height: ${variantStyles.lineHeight}rem;
    }
  `;
};

export default getTypographyCss;
