import * as React from 'react';
import { useTheme } from '@emotion/react';
import Typography from '../Typography';
import { ExternalIconWrap, StyledSideMenuWrapper } from './side-menu.styles';
import { SectionStatusTypes, SideMenuProps, ICondition } from './side-menu.types';
import { safeProps } from '../../utils/safeProps';

import IconButton from '../IconButton';
import Avatar from '../Avatar';
import Badge from '../Badge';
import Box from '../Box';
import Link from '../Link';
import { ChevronLeftIcon, PhoneIcon, EmailIcon, ArrowTopRightIcon } from '../Icons';
import { BadgeIconTypes, BadgeSizeTypes, BadgeTypes } from '../Badge/badge.types';
import { assertNever } from '../../utils/helpers';
import { LinkTypes } from '../../types';
import { LinkContext } from '../../providers/Linker';

export const getBadgeTypeFromStatus = (
  status?: `${SectionStatusTypes}`
):
  | {
      type: BadgeTypes.WARNING | BadgeTypes.SUCCESS;
      icon: BadgeIconTypes;
    }
  | { type: BadgeTypes.ERROR } => {
  switch (status) {
    case SectionStatusTypes.COMPLETE:
      return {
        type: BadgeTypes.SUCCESS,
        icon: BadgeIconTypes.CIRCLE_TICK
      };
    case SectionStatusTypes.INCOMPLETE:
      return {
        type: BadgeTypes.ERROR
      };
    default:
      return assertNever(status as never);
  }
};

const getExpandedState = (sitemap, activeSection) =>
  sitemap && Array.isArray(sitemap)
    ? sitemap.map((s) => ({
        ...s,
        ...(s.route?.includes(activeSection) ? { expanded: true } : { expanded: false })
      }))
    : [];

export const SideMenu: React.FC<SideMenuProps> = (props: SideMenuProps) => {
  const {
    sitemap,
    activeRoute,
    recruiterName,
    recruiterPhone,
    recruiterEmail,
    companyPhone,
    companyEmail,
    opHours,
    conditions = [],
    onLinkClick,
    ...rest
  } = props;
  const theme = useTheme();
  const CustomLink = React.useContext(LinkContext);
  const activeRouteParts = activeRoute.split('/');
  const activeSection =
    Array.isArray(activeRouteParts) && activeRouteParts.length > 1
      ? activeRoute.split('/')[1]
      : '/none';

  const [sections, setSections] = React.useState(getExpandedState(sitemap, activeSection));

  const hasCompanySection = !!companyPhone && !!companyEmail && !!opHours;

  // toggles expanded bool for index with route matching section
  // todo write a test for this
  const expandSection = (section: string) =>
    setSections(
      sections.map((s) => (s.label?.includes(section) ? { ...s, expanded: !s.expanded } : s))
    );

  React.useEffect(() => {
    setSections(getExpandedState(sitemap, activeSection));
  }, [sitemap, activeSection]);

  return (
    <StyledSideMenuWrapper {...safeProps(rest)} data-test='iui-component-side-menu'>
      <ul className='menu'>
        {(sections ?? []).map((section, i) => {
          const { subsections, status, totalPending, route, condition } = section;
          const hasExpansion = subsections && Array.isArray(subsections);
          const isActive =
            route === activeRoute ||
            (subsections &&
              Array.isArray(subsections) &&
              subsections.some((s) => s.route === activeRoute));

          const expansionState = hasExpansion && (section?.expanded ?? false);
          const badgeProps = status && getBadgeTypeFromStatus(status);

          const checkConditions = condition && condition.key && condition.value;
          if (
            checkConditions &&
            conditions.some(
              (c: ICondition) => c && c.key === condition.key && c.value === condition.value
            )
          ) {
            return <></>;
          }

          const Tier1LinkInternals = () => (
            <Typography as='span' variant='button-secondary' color='black' wordBreak='break-word'>
              {section.label}
            </Typography>
          );

          return (
            <li key={`tier-1-${i.toString()}`} className={isActive ? 'is-active' : ''}>
              <div className='tier-1-link-wrap'>
                {route && (
                  <CustomLink
                    href={route}
                    to={route}
                    onClick={() => !!onLinkClick && onLinkClick()}
                  >
                    <Tier1LinkInternals />
                  </CustomLink>
                )}
                {!route && (
                  <button
                    type='button'
                    className='faux-link'
                    onClick={() => expandSection(section.label ?? 'unknown')}
                  >
                    <Tier1LinkInternals />
                  </button>
                )}

                {badgeProps && badgeProps?.type !== BadgeTypes.ERROR && (
                  <div className='tier-1-badge-wrap'>
                    <Badge
                      type={badgeProps.type}
                      {...(badgeProps.type === BadgeTypes.WARNING
                        ? {
                            icon: badgeProps.icon,
                            forceColor: theme.iuiPalette.brand[4]
                          }
                        : {})}
                      {...(badgeProps.type === BadgeTypes.SUCCESS ? { icon: badgeProps.icon } : {})}
                    />
                  </div>
                )}

                {badgeProps && badgeProps?.type === BadgeTypes.ERROR && totalPending && (
                  <div className='tier-1-badge-wrap'>
                    <Badge
                      type={badgeProps?.type}
                      {...(badgeProps?.type === BadgeTypes.ERROR
                        ? { label: totalPending.toString() }
                        : {})}
                    />
                  </div>
                )}

                {hasExpansion && (
                  <IconButton
                    aria-label={expansionState ? 'collapse section' : 'expand section'}
                    className={`toggle-icon ${expansionState ? 'is-expanded' : 'is-collapsed'}`}
                    icon={ChevronLeftIcon}
                    size={24}
                    onClick={() => expandSection(section.label ?? 'unknown')}
                  />
                )}
              </div>
              {hasExpansion && (
                <ul
                  className={`${expansionState ? 'is-expanded' : 'is-collapsed'}`}
                  aria-hidden={!expansionState}
                >
                  {(section.subsections ?? []).map((link) => {
                    const { route: subRoute, label: subLabel, status: subStatus, type } = link;
                    const subRouteIsActive = activeRoute === subRoute;
                    const sectionBadgeType = status && getBadgeTypeFromStatus(subStatus);

                    const LinkInternals = () => (
                      <Typography as='span' variant='button-secondary' wordBreak='break-word'>
                        {subLabel}
                        {type === LinkTypes.EXTERNAL && (
                          <ExternalIconWrap>
                            <ArrowTopRightIcon
                              customColor='var(--color-grays-700)'
                              dimensions={16}
                            />
                          </ExternalIconWrap>
                        )}
                      </Typography>
                    );

                    return (
                      <li
                        key={`tier-2-${subLabel}`}
                        className={subRouteIsActive ? 'is-active' : ''}
                      >
                        {type === LinkTypes.EXTERNAL && (
                          <a target='_blank' href={subRoute} rel='noreferrer'>
                            <LinkInternals />
                          </a>
                        )}
                        {type !== LinkTypes.EXTERNAL && (
                          <CustomLink
                            href={subRoute}
                            to={subRoute}
                            {...(expansionState === false ? { tabIndex: -1 } : {})}
                            onClick={() => !!onLinkClick && onLinkClick()}
                          >
                            <LinkInternals />
                          </CustomLink>
                        )}
                        {sectionBadgeType && (
                          <div className='tier-2-badge-wrap'>
                            <Badge
                              size={BadgeSizeTypes.SMALL}
                              type={
                                sectionBadgeType.type !== BadgeTypes.ERROR
                                  ? BadgeTypes.WARNING
                                  : BadgeTypes.ERROR
                              }
                              {...(sectionBadgeType.type !== BadgeTypes.ERROR
                                ? { icon: 'tick' }
                                : {})}
                            />
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>

      {recruiterPhone && recruiterEmail && (
        <>
          <Box
            height='1px'
            background={theme?.iuiPalette?.grays.grays[300] ?? 'gray'}
            margin='1rem 12px 0 12px'
          />
          <Box padding='1.5rem 12px 1.5rem 12px' className='contact contact--recruiter'>
            <Typography variant='meta-s' as='h2'>
              <div className='recruiter-contact-card'>
                {recruiterName ? (
                  <>
                    <Box margin='0 1rem 0 0'>
                      <Avatar name={recruiterName} width={48} height={48} />
                    </Box>

                    <div>
                      Your recruiter <span className='sr-only'>is</span>
                      <Typography variant='meta-l-alt' as='div' margin='4px 0 0'>
                        {recruiterName}
                      </Typography>
                    </div>
                  </>
                ) : (
                  <Typography variant='meta-l-alt' as='div' margin='4px 0 0'>
                    Your recruiter team
                  </Typography>
                )}
              </div>

              <Typography variant='meta-m' as='p' margin='.75rem 0 1rem'>
                The best way to reach out to {recruiterName ? recruiterName.split(' ')[0] : 'us'} is
                by calling during business hours, {opHours}.
              </Typography>
            </Typography>
            <ul>
              <li>
                <Link
                  label={
                    <Typography
                      variant='button-secondary'
                      as='span'
                      color='var(--color-secondary-500)'
                    >
                      Call recruiter
                    </Typography>
                  }
                  href={`tel:${recruiterPhone}`}
                  icon={<PhoneIcon dimensions={22} customColor='var(--color-brand-3)' />}
                />
              </li>
              <li>
                <Link
                  label={
                    <Typography variant='button-secondary' as='span' color='var(--color-brand-500)'>
                      Email recruiter
                    </Typography>
                  }
                  href={`mailto:${recruiterEmail}`}
                  icon={<EmailIcon dimensions={20} customColor='var(--color-brand-3)' />}
                />
              </li>
            </ul>
          </Box>
        </>
      )}
      {hasCompanySection && (
        <>
          <Box
            height='1px'
            background={theme?.iuiPalette?.grays.grays[300] ?? 'gray'}
            margin='0 12px 0 12px'
          />
          <Box className='contact contact--us' padding='1.5rem 12px 1.5rem 12px'>
            <Typography variant='meta-l-alt' as='h2' margin='0 0 1.5rem'>
              Contact us
            </Typography>
            <ul>
              <li>
                <Link
                  label={
                    <Typography
                      variant='button-secondary'
                      as='span'
                      color='var(--color-secondary-500)'
                    >
                      {companyPhone}
                    </Typography>
                  }
                  href={`tel:${companyPhone}`}
                  icon={<PhoneIcon dimensions={22} customColor='var(--color-brand-3)' />}
                />
              </li>
              <li>
                <Link
                  label={
                    <Typography
                      variant='button-secondary'
                      as='span'
                      color='var(--color-secondary-500)'
                    >
                      Email
                    </Typography>
                  }
                  href={`mailto:${companyEmail}`}
                  icon={<EmailIcon dimensions={20} customColor='var(--color-brand-3)' />}
                />
              </li>
            </ul>
          </Box>
        </>
      )}
    </StyledSideMenuWrapper>
  );
};

export default SideMenu;
