import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--list';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 121 144'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#333F67'
      d='M108.379 60.729h7.381a4.605 4.605 0 004.598-4.598V14.533a4.605 4.605 0 00-4.598-4.598h-7.381a4.606 4.606 0 00-4.598 4.598V56.13a4.586 4.586 0 004.598 4.598z'
    />
    <path fill='url(#pattern0)' d='M99.84 6.744h14.7v58.488h-14.7z' />
    <path
      fill='#333F67'
      d='M106.752 60.729h.969a2.97 2.97 0 002.972-2.972V12.906a2.97 2.97 0 00-2.972-2.971h-.969a2.97 2.97 0 00-2.972 2.97v44.852a2.95 2.95 0 002.972 2.972z'
    />
    <path
      fill='#333F67'
      d='M107.503 141.706H6.696a3.204 3.204 0 01-3.19-3.191V4.555a3.204 3.204 0 013.19-3.19h100.807a3.204 3.204 0 013.19 3.19v133.929c0 1.783-1.439 3.222-3.19 3.222z'
    />
    <path fill='url(#pattern1)' d='M.691 1.115h113.223v142.311H.691z' />
    <path fill='#E5E6EB' d='M104.125 9.966H10.043v123.17h94.082V9.966z' />
    <path
      fill='#F6F6F6'
      d='M100.059 136.013l-93.863-6.068L14.11 7.057l93.895 6.037-7.945 122.919z'
    />
    <path fill='#fff' d='M97.37 138.015L3.881 127.443 17.738 5.055l93.487 10.572-13.856 122.388z' />
    <path fill='#F5995C' d='M32.72 42.65a7.694 7.694 0 100-15.388 7.694 7.694 0 000 15.389z' />
    <path
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={1.608}
      d='M29.436 34.612l1.845 2.284 4.691-3.754'
    />
    <path
      stroke='#B2C2E8'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={4.02}
      d='M47.045 31.891l31.308 3.535'
    />
    <path
      stroke='#B2C2E8'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={2.157}
      d='M45.7 38.21l49.23 5.567M45.074 43.683l49.23 5.567'
    />
    <path fill='#F5995C' d='M29.28 73.052a7.694 7.694 0 100-15.388 7.694 7.694 0 000 15.388z' />
    <path
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={1.608}
      d='M25.995 65.045l1.845 2.283 4.692-3.753'
    />
    <path
      stroke='#B2C2E8'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={4.02}
      d='M43.605 62.292l31.308 3.566'
    />
    <path
      stroke='#B2C2E8'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={2.157}
      d='M42.26 68.642l49.23 5.567M41.634 74.115l49.23 5.568'
    />
    <path
      fill='#F5995C'
      d='M25.838 89.316c.782 0 1.564.157 2.315.438a6.477 6.477 0 013.722 8.32c-.939 2.471-3.378 4.128-6.005 4.128a6.612 6.612 0 01-2.315-.437 6.478 6.478 0 01-3.722-8.32c.938-2.44 3.347-4.129 6.005-4.129zm0-1.25a7.715 7.715 0 00-7.194 4.941c-1.532 3.972.47 8.414 4.442 9.946a7.77 7.77 0 002.752.501 7.715 7.715 0 007.194-4.942c1.533-3.972-.47-8.414-4.441-9.946-.907-.313-1.846-.5-2.753-.5z'
      opacity={0.28}
    />
    <path
      stroke='#B2C2E8'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={4.02}
      d='M40.163 92.726l31.309 3.534'
    />
    <path
      stroke='#B2C2E8'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={2.157}
      d='M38.818 99.043l49.23 5.568M38.192 104.517l49.23 5.567'
    />
    <defs>
      <pattern id='pattern0' width={1} height={1} patternContentUnits='objectBoundingBox'>
        <use xlinkHref='#image0_2841_1456' />
      </pattern>
      <pattern id='pattern1' width={1} height={1} patternContentUnits='objectBoundingBox'>
        <use xlinkHref='#image1_2841_1456' />
      </pattern>
      <image
        id='image0_2841_1456'
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAC7CAYAAAD10Mf5AAAACXBIWXMAAAsSAAALEgHS3X78AAAA'
      />
      <image
        id='image1_2841_1456'
        xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAWoAAAHHCAYAAACWWhLTAAAACXBIWXMAAAsSAAALEgHS3X78AAAA'
      />
    </defs>
  </svg>
);

const SvgList = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgList.displayName = 'List';
export default SvgList;
