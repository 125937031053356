import * as React from 'react';
import styled from '@emotion/styled';
import { GraphicProps } from '../../../types';
import { GetGraphicDimensions } from '../../../mixins/icons';

const TEST_ID = 'iui-component-graphic--fastaff';

const SVG: React.FC<GraphicProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 338 338'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#333F67'
      d='M72.605 90.056h74.168l17.406 17.448h85.828l-4.158 117.639-174.144-.471.9-134.616z'
    />
    <path fill='#B2C2E8' d='M244.82 98.287H79.293v115.281H244.82V98.287z' />
    <path fill='#333F67' d='M71.705 224.672l21.821-108.036h172.687l-20.364 108.507-174.144-.471z' />
  </svg>
);

const SvgFastaff = styled(SVG)`
  ${(props: GraphicProps) => GetGraphicDimensions(props)}
`;
SvgFastaff.displayName = 'Fastaff';
export default SvgFastaff;
