import { TrustaffDefaultLogo } from '../components/Logos';
import { BaseTheme, Palette } from '../types';
import defaultBreakpoints from './breakpoints';
import {
  TrustaffDefaultFonts,
  TrustaffDefaultTypographyStyles,
  IngenovisDefaultFonts,
  IngenovisDefaultTypographyStyles
} from './typography';
import { NavbarHeight, MastheadHeight, FooterHeight, MinFooterHeight } from '../constants';

const TrustaffColorPalette: Palette = {
  brand: {
    1: '#512D6C',
    2: '#A174C1',
    3: '#FF8300',
    4: '#FFB05A'
  },
  primary: {
    100: '#F7EDFF',
    200: '#E0C9F1',
    300: '#A174C1',
    400: '#784B99',
    500: '#512D6C',
    600: '#3D155B',
    700: '#2D004F'
  },
  primaryAlt: {
    100: '#F7EDFF',
    200: '#E0C9F1',
    300: '#A174C1',
    400: '#784B99',
    500: '#512D6C'
  },
  secondary: {
    100: '#FFF7EC',
    200: '#FCCAA3',
    300: '#FFB05A',
    400: '#FF8300',
    500: '#EA730B',
    600: '#AB5805'
  },
  secondaryAlt: {
    100: '#FFF7EC',
    200: '#FCCAA3',
    300: '#FFB05A',
    400: '#FF8300',
    500: '#EA730B',
    600: '#AB5805'
  },
  grays: {
    black: '#0F0027',
    white: '#FFFFFF',
    grays: {
      100: '#F9F9F9',
      200: '#EDEDED',
      300: '#D9D9D9',
      400: '#C0C0C0',
      500: '#999999',
      600: '#707070',
      700: '#363636'
    }
  },
  semantic: {
    green100: '#00854B',
    red100: '#E03842',
    red200: '#B00020'
  },
  gradient: 'linear-gradient(to right bottom, #784B99, #512D6C)'
};

export const TrustaffTheme: BaseTheme = {
  name: 'trustaff',
  iuiBreakpoints: defaultBreakpoints,
  iuiPalette: TrustaffColorPalette,
  iuiTypography: {
    fonts: TrustaffDefaultFonts,
    styles: TrustaffDefaultTypographyStyles,
    baseDirectory: './fonts/',
    ingenovisFonts: IngenovisDefaultFonts,
    ingenovisStyles: IngenovisDefaultTypographyStyles
  },
  boldTextFontWeight: 600,
  shadowColor: '#363636',
  metaThemeColor: TrustaffColorPalette.brand[1],
  focusColor: '#ffcc00',
  fixedHeights: {
    navBar: {
      desktop: NavbarHeight.DESKTOP,
      mobile: NavbarHeight.MOBILE
    },
    masthead: MastheadHeight,
    footer: FooterHeight,
    minFooter: MinFooterHeight
  },
  icon: {
    default: TrustaffColorPalette.grays.black,
    invert: TrustaffColorPalette.grays.white,
    primary: TrustaffColorPalette.secondary[500],
    secondary: TrustaffColorPalette.primary[400]
  },
  body: {
    color: TrustaffColorPalette.grays.black
  },
  link: {
    base: {
      color: TrustaffColorPalette.secondary[500],
      hoverColor: TrustaffColorPalette.secondary[400],
      focusColor: TrustaffColorPalette.secondary[600]
    },
    alt: {
      color: TrustaffColorPalette.grays.white,
      hoverColor: TrustaffColorPalette.secondary[200],
      focusColor: TrustaffColorPalette.secondary[300]
    }
  },
  buttons: {
    radius: '100px',
    primary: {
      font: 'button-primary',
      weight: 700,
      padding: '16px 40px',
      withBorderPadding: '15px 40px',
      fontSize: '1rem',
      lineHeight: '1rem'
    },
    secondary: {
      font: 'button-secondary',
      weight: 700,
      padding: '12px 16px',
      withBorderPadding: '7px 12px',
      fontSize: '0.875rem',
      lineHeight: '1rem'
    },
    tertiary: {
      font: 'button-tertiary',
      padding: '4px 12px',
      fontSize: '0.875rem',
      lineHeight: '1rem'
    },
    'faux-auth0': {
      font: 'button-primary',
      weight: 700,
      padding: '16px 40px',
      withBorderPadding: '15px 40px',
      fontSize: '1.125rem',
      lineHeight: '1.13rem'
    },
    badge: {
      font: 'meta-m-alt',
      default: TrustaffColorPalette.grays.black,
      hover: TrustaffColorPalette.brand[4],
      pressed: TrustaffColorPalette.secondary[500],
      disabled: TrustaffColorPalette.grays.black,
      active: TrustaffColorPalette.brand[3],
      fontSize: '1rem',
      lineHeight: '0.75rem'
    },
    colorways: {
      dark: {
        color: TrustaffColorPalette.grays.white, // this will set all if you remove static/hover/active
        static: {
          bg: TrustaffColorPalette.secondary[400]
        },
        hover: {
          bg: TrustaffColorPalette.secondary[300]
        },
        active: {
          bg: TrustaffColorPalette.secondary[500]
        },
        disabled: {
          bg: TrustaffColorPalette.secondary[500]
        }
      },
      light: {
        static: {
          color: TrustaffColorPalette.secondary[500],
          bg: TrustaffColorPalette.grays.white
        },
        hover: {
          color: TrustaffColorPalette.secondary[500],
          bg: TrustaffColorPalette.grays.grays[100]
        },
        active: {
          color: TrustaffColorPalette.secondary[500],
          bg: TrustaffColorPalette.grays.grays[200]
        },
        disabled: {
          bg: TrustaffColorPalette.grays.grays[200]
        }
      }
    }
  },
  textButtons: {
    colors: {
      static: TrustaffColorPalette.secondary[500],
      hover: TrustaffColorPalette.brand[3],
      active: TrustaffColorPalette.secondaryAlt[600],
      disabled: TrustaffColorPalette.secondaryAlt[600]
    }
  },
  toggles: {
    small: {
      width: '52px',
      height: '24px',
      radius: '100px'
    },
    large: {
      width: '52px',
      height: '32px',
      radius: '100px'
    },
    colors: {
      enabled: TrustaffColorPalette.primary[300],
      hover: TrustaffColorPalette.primary[200],
      active: TrustaffColorPalette.primary[500],
      disabled: TrustaffColorPalette.primary[500]
    }
  },
  snackbar: {
    default: TrustaffColorPalette.primary[700],
    error: TrustaffColorPalette.semantic.red100
  },
  borderRadius: '8px',
  footer: {
    bg: TrustaffColorPalette.brand[1],
    color: TrustaffColorPalette.grays.white,
    phoneColor: TrustaffColorPalette.secondary[200],
    fontFamily: 'Open Sans',
    social: 'inverted',
    maxPerRow: 3,
    legals: {
      bg: TrustaffColorPalette.primary[600],
      color: TrustaffColorPalette.grays.white
    }
  },
  TitleWith3IconCards: {
    titleColor: TrustaffColorPalette.primary[600],
    backgroundColor: TrustaffColorPalette.brand[3],
    dividerColor: TrustaffColorPalette.brand[4],
    arrowIconColor: TrustaffColorPalette.grays.grays[400],
    linkHoverColor: TrustaffColorPalette.secondary[200],
    linkActiveColor: TrustaffColorPalette.secondary[300]
  },
  logos: {
    default: TrustaffDefaultLogo
  },
  navbar: {
    logoHeight: { large: 'auto', small: 'auto' },
    logoWidth: { large: '114px', small: '106px' },
    logoTopPosition: '3.5px',
    link: {
      color: TrustaffColorPalette.primary[700],
      hoverColor: TrustaffColorPalette.secondary[500]
    },
    signInLink: {
      color: TrustaffColorPalette.grays.grays[700]
    },
    hover: {
      color: TrustaffColorPalette.secondary[500],
      bg: TrustaffColorPalette.grays.grays[200]
    },
    selected: {
      color: TrustaffColorPalette.secondary[500],
      underline: TrustaffColorPalette.brand[3]
    }
  },
  navMenu: {
    activeBorder: TrustaffColorPalette.secondary[300],
    activeLink: TrustaffColorPalette.secondary[500],
    title: TrustaffColorPalette.grays.grays[600],
    divider: TrustaffColorPalette.grays.grays[400],
    department: TrustaffColorPalette.grays.grays[500],
    version: TrustaffColorPalette.grays.grays[600],
    border: TrustaffColorPalette.grays.grays[300]
  },

  pageTitle: {
    bg: TrustaffColorPalette.brand[1],
    bgAlt: TrustaffColorPalette.primary[200]
  },
  subNavbar: {
    bg: TrustaffColorPalette.primary[500],
    height: 48
  },
  checkbox: {
    default: {
      restingSelected: TrustaffColorPalette.primaryAlt[400],
      restingUnselected: TrustaffColorPalette.grays.grays[400],
      hover: TrustaffColorPalette.primaryAlt[300],
      pressed: TrustaffColorPalette.primaryAlt[500]
    },
    leadGen: {
      resting: `1px solid ${TrustaffColorPalette.grays.grays[400]}`,
      hoverSelected: `1px solid ${TrustaffColorPalette.grays.grays[300]}`,
      hoverUnselected: `2px solid ${TrustaffColorPalette.grays.grays[300]}`,
      pressedSelected: `1px solid ${TrustaffColorPalette.grays.grays[500]}`,
      pressedUnselected: `2px solid ${TrustaffColorPalette.grays.grays[500]}`
    }
  },
  select: {
    divider: TrustaffColorPalette.grays.grays[400],
    label: TrustaffColorPalette.grays.black,
    value: {
      active: TrustaffColorPalette.brand[1],
      selected: TrustaffColorPalette.grays.grays[700],
      error: TrustaffColorPalette.semantic.red100
    },
    menuList: {
      activeBackground: TrustaffColorPalette.grays.grays[300]
    }
  },
  heading: {
    stroke: TrustaffColorPalette.brand[3]
  },
  jobSearch: {
    selectBorder: TrustaffColorPalette.grays.grays[300]
  },
  avatar: {
    bg: TrustaffColorPalette.brand[1]
  },
  contactBanner: {
    bg: TrustaffColorPalette.brand[1]
  },
  jobCard: {
    jobTitleColor: TrustaffColorPalette.primary[400],
    jobTitleHoverColor: TrustaffColorPalette.primaryAlt[300],
    jobTitleActiveColor: TrustaffColorPalette.primaryAlt[500],
    locationColor: TrustaffColorPalette.grays.black,
    detailTitleColor: TrustaffColorPalette.grays.grays[500]
  },
  share: {
    iconColor: TrustaffColorPalette.brand[3],
    textColor: TrustaffColorPalette.secondary[500],
    textHoverColor: TrustaffColorPalette.brand[3],
    activeColor: TrustaffColorPalette.secondary[600],
    iconWrapperColor: TrustaffColorPalette.secondary[100],
    iconHoverColor: TrustaffColorPalette.brand[3],
    backgroundColor: TrustaffColorPalette.secondary[100],
    backgroundHoverColor: TrustaffColorPalette.secondary[200]
  },
  referral: {
    bg: TrustaffColorPalette.primary[100],
    text: TrustaffColorPalette.grays.black
  },
  toast: {
    standard: {
      color: TrustaffColorPalette.primary[700]
    },
    success: {
      color: TrustaffColorPalette.semantic.green100
    },
    error: {
      color: TrustaffColorPalette.semantic.red100
    }
  },
  modal: {
    backgroundColor: TrustaffColorPalette.grays.grays[100]
  },

  dropdown: {
    hover: TrustaffColorPalette.grays.grays[100],
    selected: TrustaffColorPalette.grays.grays[200]
  },
  legalBar: {
    default: {
      backgroundColor: TrustaffColorPalette.primary[600],
      textColor: TrustaffColorPalette.grays.white
    },
    inverted: {
      backgroundColor: TrustaffColorPalette.grays.white,
      textColor: TrustaffColorPalette.grays.grays[600],
      borderColor: TrustaffColorPalette.grays.grays[200]
    }
  },
  newsModule: {
    background: TrustaffColorPalette.secondary[100]
  },
  pagination: {
    selectedHoverBackgroundColor: TrustaffColorPalette.primary[300]
  },
  contactCard: {
    iconType: 'primary',
    headerVariant: 'h5'
  },
  Graphics: {
    primary: TrustaffColorPalette.primary[100],
    secondary: TrustaffColorPalette.secondary[100]
  }
};
