import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--plus';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 16 16'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#EA730B'
      fillRule='evenodd'
      d='M8.666 3.333a.667.667 0 10-1.333 0v4h-4a.667.667 0 000 1.334h4v4a.667.667 0 001.333 0v-4h4a.667.667 0 100-1.334h-4v-4z'
      clipRule='evenodd'
    />
  </svg>
);

const SvgPlus = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgPlus.displayName = 'Plus';
export default SvgPlus;
