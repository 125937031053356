import { USNursingDefaultLogo } from '../components/Logos';
import { BaseTheme, Palette } from '../types';
import defaultBreakpoints from './breakpoints';
import {
  USNursingDefaultFonts,
  USNursingDefaultTypographyStyles,
  IngenovisDefaultFonts,
  IngenovisDefaultTypographyStyles
} from './typography';
import { NavbarHeight, MastheadHeight, FooterHeight, MinFooterHeight } from '../constants';

const USNursingColorPalette: Palette = {
  brand: {
    1: '#3B58BF',
    2: '#7B91DF',
    3: '#EFAA15',
    4: '#0D1B51'
  },
  primary: {
    100: '#E7EBFC',
    200: '#C2CEF6',
    300: '#7B91DF',
    400: '#3B58BF',
    500: '#1B307D',
    600: '#13266C',
    700: '#0D1B51'
  },
  primaryAlt: {
    100: '#E7EBFC',
    200: '#C2CEF6',
    300: '#7B91DF',
    400: '#3B58BF',
    500: '#1B307D'
  },
  secondary: {
    100: '#FFF2D6',
    200: '#FFE19F',
    300: '#FFCA59',
    400: '#EFAA15',
    500: '#D28E18',
    600: '#B97B0F'
  },
  secondaryAlt: {
    100: '#FFF2D6',
    200: '#FFE19F',
    300: '#FFCA59',
    400: '#EFAA15',
    500: '#D28E18',
    600: '#B97B0F'
  },
  grays: {
    black: '#1D243D',
    white: '#FFFFFF',
    grays: {
      100: '#F6F6F6',
      200: '#E7E8EE',
      300: '#BCBEC7',
      400: '#9194A1',
      500: '#6C7494',
      600: '#3E4974',
      700: '#293356'
    }
  },
  semantic: {
    green100: '#00854B',
    red100: '#E03842',
    red200: '#B00020'
  },
  gradient: 'linear-gradient(to right bottom, #3B58BF, #1B307D)'
};

export const USNursingTheme: BaseTheme = {
  name: 'usnursing',
  iuiBreakpoints: defaultBreakpoints,
  iuiPalette: USNursingColorPalette,
  iuiTypography: {
    fonts: USNursingDefaultFonts,
    styles: USNursingDefaultTypographyStyles,
    baseDirectory: './fonts/',
    ingenovisFonts: IngenovisDefaultFonts,
    ingenovisStyles: IngenovisDefaultTypographyStyles
  },
  boldTextFontWeight: 600,
  metaThemeColor: USNursingColorPalette.brand[1],
  shadowColor: USNursingColorPalette.primary[500],
  focusColor: 'cyan',
  fixedHeights: {
    navBar: {
      desktop: NavbarHeight.DESKTOP,
      mobile: NavbarHeight.MOBILE
    },
    masthead: MastheadHeight,
    footer: FooterHeight,
    minFooter: MinFooterHeight
  },
  icon: {
    default: USNursingColorPalette.grays.black,
    invert: USNursingColorPalette.grays.white,
    primary: USNursingColorPalette.primary[400],
    secondary: USNursingColorPalette.secondary[500]
  },
  body: {
    color: USNursingColorPalette.grays.black
  },
  link: {
    base: {
      color: USNursingColorPalette.brand[2],
      hoverColor: USNursingColorPalette.primaryAlt[300],
      focusColor: USNursingColorPalette.primaryAlt[500]
    },
    alt: {
      color: USNursingColorPalette.grays.white,
      hoverColor: USNursingColorPalette.secondary[200],
      focusColor: USNursingColorPalette.secondary[300]
    }
  },
  buttons: {
    radius: '100px',
    primary: {
      font: 'button-primary',
      weight: 700,
      padding: '16px 40px',
      withBorderPadding: '15px 40px',
      fontSize: '1rem',
      lineHeight: '1rem'
    },
    secondary: {
      font: 'button-secondary',
      weight: 700,
      padding: '12px 16px',
      withBorderPadding: '7px 12px',
      fontSize: '0.875rem',
      lineHeight: '1rem'
    },
    tertiary: {
      font: 'button-tertiary',
      padding: '4px 12px',
      fontSize: '0.875rem',
      lineHeight: '1rem'
    },
    'faux-auth0': {
      font: 'button-primary',
      weight: 700,
      padding: '16px 40px',
      withBorderPadding: '15px 40px',
      fontSize: '1.125rem',
      lineHeight: '1.13rem'
    },
    badge: {
      font: 'meta-m-alt',
      default: USNursingColorPalette.grays.black,
      hover: USNursingColorPalette.brand[4],
      pressed: USNursingColorPalette.secondary[500],
      disabled: USNursingColorPalette.grays.black,
      active: USNursingColorPalette.brand[3],
      fontSize: '1rem',
      lineHeight: '0.75rem'
    },
    colorways: {
      dark: {
        color: USNursingColorPalette.grays.white, // this will set all if you remove static/hover/active
        static: {
          bg: USNursingColorPalette.brand[1]
        },
        hover: {
          bg: USNursingColorPalette.primary[300]
        },
        active: {
          bg: USNursingColorPalette.primary[600]
        },
        disabled: {
          bg: USNursingColorPalette.primary[600]
        }
      },
      light: {
        static: {
          color: USNursingColorPalette.brand[1],
          bg: USNursingColorPalette.grays.white
        },
        hover: {
          color: USNursingColorPalette.primary[400],
          bg: USNursingColorPalette.grays.grays[100]
        },
        active: {
          color: USNursingColorPalette.brand[1],
          bg: USNursingColorPalette.grays.grays[200]
        },
        disabled: {
          bg: USNursingColorPalette.grays.grays[200]
        }
      }
    }
  },
  textButtons: {
    colors: {
      static: USNursingColorPalette.primary[400],
      hover: USNursingColorPalette.primaryAlt[300],
      active: USNursingColorPalette.primaryAlt[500],
      disabled: USNursingColorPalette.primaryAlt[500]
    }
  },
  toggles: {
    small: {
      width: '52px',
      height: '24px',
      radius: '100px'
    },
    large: {
      width: '52px',
      height: '32px',
      radius: '100px'
    },
    colors: {
      enabled: USNursingColorPalette.primary[300],
      hover: USNursingColorPalette.primaryAlt[200],
      active: USNursingColorPalette.primaryAlt[500],
      disabled: USNursingColorPalette.primaryAlt[500]
    }
  },
  snackbar: {
    default: USNursingColorPalette.primary[700],
    error: USNursingColorPalette.semantic.red100
  },
  borderRadius: '8px',
  footer: {
    bg: USNursingColorPalette.grays.grays[100],
    color: USNursingColorPalette.primary[700],
    phoneColor: USNursingColorPalette.secondary[500],
    fontFamily: 'Heebo',
    maxPerRow: 3,
    legals: {
      bg: USNursingColorPalette.brand[4],
      color: USNursingColorPalette.grays.white
    }
  },
  TitleWith3IconCards: {
    titleColor: USNursingColorPalette.brand[1],
    backgroundColor: USNursingColorPalette.brand[3],
    dividerColor: USNursingColorPalette.brand[4],
    arrowIconColor: USNursingColorPalette.grays.grays[400],
    linkHoverColor: USNursingColorPalette.secondary[200],
    linkActiveColor: USNursingColorPalette.secondary[300]
  },
  logos: {
    default: USNursingDefaultLogo
  },
  navbar: {
    logoWidth: { large: 'auto', small: 'auto' },
    logoHeight: { large: '28px', small: '24px' },
    link: {
      color: USNursingColorPalette.primary[700],
      hoverColor: USNursingColorPalette.primaryAlt[300]
    },
    signInLink: {
      color: USNursingColorPalette.primary[700]
    },
    hover: {
      color: USNursingColorPalette.brand[1],
      bg: USNursingColorPalette.grays.grays[200]
    },
    selected: {
      color: USNursingColorPalette.brand[1],
      underline: USNursingColorPalette.brand[1]
    }
  },
  navMenu: {
    activeBorder: USNursingColorPalette.primary[300],
    activeLink: USNursingColorPalette.brand[1],
    title: USNursingColorPalette.grays.grays[600],
    divider: USNursingColorPalette.grays.grays[400],
    department: USNursingColorPalette.grays.grays[500],
    version: USNursingColorPalette.grays.grays[600],
    border: USNursingColorPalette.grays.grays[300]
  },
  pageTitle: {
    bg: USNursingColorPalette.primary[600],
    bgAlt: USNursingColorPalette.brand[1]
  },
  subNavbar: {
    bg: USNursingColorPalette.brand[1],
    height: 48
  },
  checkbox: {
    default: {
      restingSelected: USNursingColorPalette.primaryAlt[400],
      restingUnselected: USNursingColorPalette.grays.grays[400],
      hover: USNursingColorPalette.primaryAlt[300],
      pressed: USNursingColorPalette.primaryAlt[500]
    },
    leadGen: {
      resting: `1px solid ${USNursingColorPalette.grays.grays[400]}`,
      hoverSelected: `1px solid ${USNursingColorPalette.grays.grays[300]}`,
      hoverUnselected: `2px solid ${USNursingColorPalette.grays.grays[300]}`,
      pressedSelected: `1px solid ${USNursingColorPalette.grays.grays[500]}`,
      pressedUnselected: `2px solid ${USNursingColorPalette.grays.grays[500]}`
    }
  },
  select: {
    divider: USNursingColorPalette.grays.grays[400],
    label: USNursingColorPalette.grays.black,
    value: {
      active: USNursingColorPalette.brand[1],
      selected: USNursingColorPalette.grays.grays[700],
      error: USNursingColorPalette.semantic.red100
    },
    menuList: {
      activeBackground: USNursingColorPalette.grays.grays[300]
    }
  },
  jobSearch: {
    selectBorder: USNursingColorPalette.grays.grays[300]
  },
  avatar: {
    bg: USNursingColorPalette.primary[500]
  },
  contactBanner: {
    bg: USNursingColorPalette.primary[600]
  },
  jobCard: {
    jobTitleColor: USNursingColorPalette.brand[1],
    jobTitleHoverColor: USNursingColorPalette.primary[400],
    jobTitleActiveColor: USNursingColorPalette.primary[600],
    locationColor: USNursingColorPalette.grays.grays[700],
    detailTitleColor: USNursingColorPalette.grays.grays[700]
  },
  share: {
    iconColor: USNursingColorPalette.brand[1],
    textColor: USNursingColorPalette.brand[1],
    textHoverColor: USNursingColorPalette.primaryAlt[300],
    activeColor: USNursingColorPalette.primaryAlt[500],
    iconWrapperColor: USNursingColorPalette.primary[100],
    iconHoverColor: USNursingColorPalette.primary[400],
    backgroundColor: USNursingColorPalette.primary[100],
    backgroundHoverColor: USNursingColorPalette.primary[200]
  },
  referral: {
    bg: USNursingColorPalette.primary[100],
    text: USNursingColorPalette.grays.white
  },
  toast: {
    standard: {
      color: USNursingColorPalette.primary[700]
    },
    success: {
      color: USNursingColorPalette.semantic.green100
    },
    error: {
      color: USNursingColorPalette.semantic.red100
    }
  },
  modal: {
    backgroundColor: USNursingColorPalette.grays.grays[100]
  },
  dropdown: {
    hover: USNursingColorPalette.grays.grays[100],
    selected: USNursingColorPalette.grays.grays[200]
  },
  legalBar: {
    default: {
      backgroundColor: USNursingColorPalette.primary[600],
      textColor: USNursingColorPalette.grays.white
    },
    inverted: {
      backgroundColor: USNursingColorPalette.grays.white,
      textColor: USNursingColorPalette.grays.grays[600],
      borderColor: USNursingColorPalette.grays.grays[200]
    }
  },
  newsModule: {
    background: USNursingColorPalette.primary[100]
  },
  pagination: {
    selectedHoverBackgroundColor: USNursingColorPalette.primary[300]
  },
  contactCard: {
    iconType: 'secondary',
    headerVariant: 'h5-alt'
  },
  Graphics: {
    primary: USNursingColorPalette.primary[100],
    secondary: USNursingColorPalette.secondary[100]
  }
};
