import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--link';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 16 16'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#0F0027'
      d='M11.334 4.666H8.667v1.333h2.667c1.1 0 2 .9 2 2s-.9 2-2 2H8.667v1.334h2.667a3.335 3.335 0 000-6.667zm-4 5.333H4.667c-1.1 0-2-.9-2-2s.9-2 2-2h2.667V4.666H4.667a3.335 3.335 0 000 6.667h2.667V9.999zm-2-2.666h5.333v1.333H5.334V7.333z'
    />
  </svg>
);

const SvgLink = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgLink.displayName = 'Link';
export default SvgLink;
