import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--upload';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 175 116'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#333F67'
      d='M2.247 1.267h57.859l13.578 13.612h66.955l-3.244 91.77-135.85-.367.702-105.015z'
    />
    <path fill='#B2C2E8' d='M136.593 7.688H7.465V97.62h129.128V7.688z' />
    <path fill='#333F67' d='M1.545 106.282l17.023-84.28h134.713l-15.886 84.648-135.85-.368z' />
    <path
      fill='#F5995C'
      d='M127.897 114.442h29.832V81.366h16.756l-31.538-37.29-31.839 37.29h16.789v33.076z'
    />
  </svg>
);

const SvgUpload = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgUpload.displayName = 'Upload';
export default SvgUpload;
