import qs from 'query-string';
import type {
  APISizelessOptions,
  ResponsiveSize,
  SizelessOptions,
  TransformOptions
} from '../../components/Image/image.types';

const DEV_IMAGE_COLORS = [
  'FDC123',
  'B5A0BE',
  'FF2609',
  '37BBAE',
  'FF5D09',
  'FDC123',
  'B5A0BE',
  'FF2609',
  '37BBAE',
  'FF5D09'
];

export type ImageSource = {
  src?: string;
};

export type ResponsiveHookResult = {
  src: string;
  srcSet?: string;
  sizes?: string;
};

export const decodeQuery = (queryParams: URLSearchParams, key: string): string | null =>
  queryParams.has(key) ? decodeURIComponent(queryParams.get(key) ?? '') : null;

// adds additional query string values on top of any that were provided in the src url.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const encodeQuery = (url: string, query: Record<string, any>): string => {
  const suppliedQueryInSrc = query;

  return qs.stringifyUrl(
    {
      url,
      query: suppliedQueryInSrc
    },
    {
      skipNull: true,
      arrayFormat: 'bracket',
      sort: false
    }
  );
};

export const removeAPIlessOptions = (options: TransformOptions): APISizelessOptions => {
  const newOpts = { ...options };
  delete newOpts.vw;
  delete newOpts.dev;
  delete newOpts.transform;
  delete newOpts.fixedHeights;
  delete newOpts.borderRadius;

  return newOpts;
};

const useResponsiveImage = (
  src: string,
  responsive: ResponsiveSize[],
  options: SizelessOptions = {}
): ResponsiveHookResult => {
  let largestSrc = src || '';
  let largestWidth = 0;
  const srcSet: string[] = [];
  const { dev, vw } = options;
  const newOpts = removeAPIlessOptions(options);

  responsive.forEach((item, i) => {
    const { size, maxWidth } = item;
    const srcSetUrl = encodeQuery(src, {
      w: size.w,
      h: size.h,
      fl: 'progressive',
      fm: 'jpg',
      ...newOpts
    });

    const srcToUse = dev
      ? `https://via.placeholder.com/${size.w}x${size.h}/${DEV_IMAGE_COLORS[i]}/000?text=${
          i + 1
        }-of-${responsive.length}-${size.w}x${size.h}`
      : srcSetUrl;

    srcSet.push(`${srcToUse} ${vw ? maxWidth : size.w}w`);

    if (size.w && size.w > largestWidth) {
      largestWidth = size.w ?? 0;
      largestSrc = srcSetUrl;
    }
  });

  const sizes = [...responsive]
    .sort((resp1, resp2) => (resp1.size.w ?? 0) - (resp2.size.w ?? 0))
    .map((resp) =>
      resp.maxWidth ? `(max-width: ${resp.maxWidth}px) ${resp.size.w}px` : `${resp.size.w}px`
    );

  if (responsive.length === 1 && responsive[0].maxWidth != null) {
    sizes.push(`${responsive[0].size.w}px`);
  }

  return {
    src: largestSrc,
    ...(srcSet.length && {
      srcSet: srcSet.join(', '),
      ...(!vw ? { sizes: sizes.join(', ') } : {})
    }),
    ...(vw ? { sizes: `${vw}vw` } : {})
  };
};

export default useResponsiveImage;
