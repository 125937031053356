import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--social-instagram-small';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#0F0027'
      d='M12.384 8.023a4.335 4.335 0 00-4.327 4.327c0 2.4 1.927 4.327 4.327 4.327s4.327-1.96 4.327-4.327c0-2.366-1.96-4.327-4.327-4.327zm0 7.099a2.785 2.785 0 01-2.772-2.772 2.785 2.785 0 012.772-2.772 2.785 2.785 0 012.772 2.772 2.785 2.785 0 01-2.772 2.772zM16.88 8.902a.98.98 0 100-1.961.98.98 0 000 1.96z'
    />
    <path
      fill='#0F0027'
      d='M19.415 5.386C18.536 4.473 17.285 4 15.865 4H8.903C5.96 4 4 5.96 4 8.902v6.93c0 1.454.473 2.704 1.42 3.617.913.88 2.13 1.318 3.516 1.318h6.896c1.454 0 2.67-.473 3.55-1.318.912-.879 1.386-2.13 1.386-3.583V8.902c0-1.42-.474-2.637-1.353-3.516zm-.135 10.48c0 1.048-.372 1.893-.98 2.468-.609.574-1.454.878-2.468.878H8.936c-1.015 0-1.86-.304-2.468-.878-.609-.609-.913-1.454-.913-2.502v-6.93c0-1.014.304-1.86.913-2.468.575-.575 1.453-.879 2.468-.879H15.9c1.014 0 1.859.304 2.467.913.575.608.913 1.453.913 2.434v6.964z'
    />
  </svg>
);

const SvgSocialInstagramSmall = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgSocialInstagramSmall.displayName = 'SocialInstagramSmall';
export default SvgSocialInstagramSmall;
