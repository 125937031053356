import * as React from 'react';
import styled from '@emotion/styled';
import { IconProps } from '../../types';
import { GetIconFillColor, GetIconDimensions } from '../../mixins/icons';

const TEST_ID = 'iui-component-icon--search';

const SVG: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 24 24'
    className={className}
    data-test={TEST_ID}
  >
    <path
      fill='#1D243D'
      fillRule='evenodd'
      d='M18 10.5a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0zm-1.527 7.387A9.46 9.46 0 0110.5 20a9.5 9.5 0 119.5-9.5 9.46 9.46 0 01-2.113 5.973l4.82 4.82a1 1 0 01-1.414 1.414l-4.82-4.82z'
      clipRule='evenodd'
    />
  </svg>
);

const SvgSearch = styled(SVG)`
  ${(props: IconProps) => GetIconDimensions(props)}
  ${(props: IconProps) => GetIconFillColor(props)}
`;
SvgSearch.displayName = 'Search';
export default SvgSearch;
