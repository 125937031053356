import { FontFamily } from '../../../types';
import { ThemedTypographyList } from '../../../components/Typography/typography.types';
import { combineTypographyStyles } from '../../../components/Typography/typography.utils';

export const FastaffDefaultFonts: FontFamily[] = [
  {
    name: 'Meta Serif Pro',
    variants: [{ file: 'MetaSerifPro-Bold.otf', style: 'normal', weight: 700 }]
  },
  {
    name: 'Acumin Pro',
    variants: [
      { file: 'acuminpro-bold.otf', style: 'normal', weight: 700 },
      { file: 'acuminpro-regular.otf', style: 'normal', weight: 400 },
      {
        file: 'acuminpro-italic.otf',
        style: 'italic',
        weight: 400
      },
      {
        file: 'acuminpro-semibold.otf',
        style: 'normal',
        weight: 600
      },
      {
        file: 'acuminpro-semibolditalic.otf',
        style: 'italic',
        weight: 600
      }
    ]
  }
];

export const FastaffSpecificTheme: ThemedTypographyList = {
  h1: {
    fontFamily: 'Meta Serif Pro',
    fontWeight: 700
  },
  h2: {
    fontFamily: 'Meta Serif Pro',
    fontWeight: 700
  },
  h3: {
    fontFamily: 'Meta Serif Pro',
    fontWeight: 700
  },
  h4: {
    fontFamily: 'Meta Serif Pro',
    fontWeight: 700
  },
  h5: {
    fontFamily: 'Meta Serif Pro',
    fontWeight: 700
  },
  h6: {
    fontFamily: 'Meta Serif Pro',
    fontWeight: 700
  },
  'h1-alt': {
    fontFamily: 'Acumin Pro',
    fontWeight: 700
  },
  'h2-alt': {
    fontFamily: 'Acumin Pro',
    fontWeight: 700
  },
  'h3-alt': {
    fontFamily: 'Acumin Pro',
    fontWeight: 700
  },
  'h4-alt': {
    fontFamily: 'Acumin Pro',
    fontWeight: 700
  },
  'h5-alt': {
    fontFamily: 'Acumin Pro',
    fontWeight: 700
  },
  'h6-alt': {
    fontFamily: 'Acumin Pro',
    fontWeight: 700
  },
  'body-l': {
    fontFamily: 'Acumin Pro',
    fontWeight: 400
  },
  'body-m': {
    fontFamily: 'Acumin Pro',
    fontWeight: 400
  },
  'body-s': {
    fontFamily: 'Acumin Pro',
    fontWeight: 400
  },
  'body-s-e': {
    fontFamily: 'Acumin Pro',
    fontWeight: 400
  },
  'body-l-alt': {
    fontFamily: 'Acumin Pro',
    fontWeight: 600
  },
  'body-m-alt': {
    fontFamily: 'Acumin Pro',
    fontWeight: 600
  },
  'body-s-alt': {
    fontFamily: 'Acumin Pro',
    fontWeight: 600
  },
  'body-s-alt-e': {
    fontFamily: 'Acumin Pro',
    fontWeight: 600
  },
  'body-l-link': {
    fontFamily: 'Acumin Pro',
    fontWeight: 400,
    textUnderline: true
  },
  'body-m-link': {
    fontFamily: 'Acumin Pro',
    fontWeight: 400,
    textUnderline: true
  },
  'body-s-link': {
    fontFamily: 'Acumin Pro',
    fontWeight: 400,
    textUnderline: true
  },
  'meta-l': {
    fontFamily: 'Acumin Pro',
    fontWeight: 400
  },
  'meta-m': {
    fontFamily: 'Acumin Pro',
    fontWeight: 400
  },
  'meta-s': {
    fontFamily: 'Acumin Pro',
    fontWeight: 400
  },
  'meta-l-caps': {
    fontFamily: 'Acumin Pro',
    fontWeight: 400,
    uppercase: true
  },
  'meta-m-caps': {
    fontFamily: 'Acumin Pro',
    fontWeight: 400,
    uppercase: true
  },
  'meta-s-caps': {
    fontFamily: 'Acumin Pro',
    fontWeight: 400,
    uppercase: true
  },
  'meta-l-alt': {
    fontFamily: 'Acumin Pro',
    fontWeight: 600
  },
  'meta-m-alt': {
    fontFamily: 'Acumin Pro',
    fontWeight: 600
  },
  'meta-s-alt': {
    fontFamily: 'Acumin Pro',
    fontWeight: 600
  },
  'meta-l-caps-alt': {
    fontFamily: 'Acumin Pro',
    fontWeight: 600,
    uppercase: true
  },
  'meta-m-caps-alt': {
    fontFamily: 'Acumin Pro',
    fontWeight: 600,
    uppercase: true
  },
  'meta-s-caps-alt': {
    fontFamily: 'Acumin Pro',
    fontWeight: 600,
    uppercase: true
  },
  'button-primary': {
    fontFamily: 'Acumin Pro',
    fontWeight: 700
  },
  'button-secondary': {
    fontFamily: 'Acumin Pro',
    fontWeight: 700
  },
  'button-tertiary': {
    fontFamily: 'Acumin Pro',
    fontWeight: 700
  },
  'button-tertiary-icon': {
    fontFamily: 'Acumin Pro',
    fontWeight: 400
  },
  'button-text-link': {
    fontFamily: 'Acumin Pro',
    fontWeight: 400
  }
};

export const FastaffDefaultTypographyStyles = combineTypographyStyles(FastaffSpecificTheme);
