import styled from '@emotion/styled';
import { css, Theme } from '@emotion/react';

import { SideMenuProps } from './side-menu.types';

interface StyledSideMenuProps extends SideMenuProps {
  margin?: string;
  theme: Theme;
}

const toggleButtonWidth = '47px';

export const ExternalIconWrap = styled.span`
  svg {
    margin-bottom: -3px;
    margin-left: 5px;
  }
`;

// some of these relative zindex is more for the focus outline than anything
// tier-1-link-wrap is related to letting button take dynamic height(100%) of first anchor
const menuLink = ({ theme }: StyledSideMenuProps) => css`
  .tier-1-link-wrap {
    position: relative;
  }
  .tier-1-badge-wrap,
  .tier-2-badge-wrap {
    position: absolute;
    right: 12px;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    width: 20px;
    pointer-events: none;
  }

  .tier-1-link-wrap .faux-link {
    width: 100%;
    border: 0;
    text-align: left;
    border-left: 3px solid transparent;
    background: none;
  }

  .tier-1-link-wrap .faux-link,
  a {
    padding: 13px;
    text-decoration: none;
    cursor: pointer;
    display: block;
    position: relative;
    color: ${theme.iuiPalette.grays.black};

    &:hover,
    &:active {
      background: ${theme.iuiPalette.grays.grays[200]};
    }

    &:focus {
      outline: none;
    }
  }
  > li a {
    padding-right: 50px;
  }

  > li {
    &:hover {
      background: ${theme.iuiPalette.grays.grays[200]};
    }
    > div .faux-link,
    > div > a {
      border-left: 3px solid transparent;

      &:active {
        border-left-color: ${theme.iuiPalette.secondary[600]};
        span {
          color: ${theme.iuiPalette.secondary[600]};
        }
      }
    }
    &.is-active > div > a {
      border-left-color: ${theme.iuiPalette.secondary[300]};
      background: ${theme.iuiPalette.grays.grays[200]};
      span {
        color: ${theme.iuiPalette.secondary[500]};
      }
    }
  }

  > li > ul a {
    padding-left: 1.5rem;

    span,
    &:hover span {
      color: ${theme.iuiPalette.grays.grays[700]};
    }

    &:focus {
      outline: none;
    }
  }
  > li.is-active > ul .is-active a span,
  > li.is-active > ul a:active span,
  > li > ul a:active span {
    color: ${theme.iuiPalette.secondary[500]};
  }

  > li > ul {
    overflow: hidden;
    max-height: 1000px;
    transition: max-height 0.28s ease-in-out;
    background: ${theme.iuiPalette.grays.grays[100]};
  }
  > li > ul.is-expanded {
    padding: 3px;
    margin-top: -3px;
    margin-left: -3px;
    margin-bottom: -3px;
    margin-right: -3px;
  }
  > li > ul.is-collapsed {
    max-height: 0;
  }

  .toggle-icon {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    max-width: ${toggleButtonWidth};

    &:focus {
      outline: none;
      z-index: 1;
    }

    &.is-expanded {
      transform: rotate(180deg);
    }
    svg {
      transform: rotate(-90deg);
      width: 21px;
    }
  }
`;

export const StyledSideMenuWrapper = styled.div<StyledSideMenuProps>`
  list-style: none;
  background: ${({ theme }) => theme.iuiPalette.grays.grays[100]};

  li {
    position: relative;
  }
  ul {
    list-style: none;
    padding: 0;
  }

  .recruiter-contact-card {
    display: flex;
    flex-direction: row;
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: left;
    }
  }

  .contact {
    ul li:not(:last-of-type) {
      margin-bottom: 1rem;
    }
    ul li a > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      > div {
        width: 30px;
      }
    }
  }

  .menu {
    ${menuLink}
  }
`;
