import * as React from 'react';
import styled from '@emotion/styled';
import { GraphicProps } from '../../../types';
import { GetGraphicDimensions } from '../../../mixins/icons';

const TEST_ID = 'iui-component-graphic--trustaff';

const SVG: React.FC<GraphicProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    fill='none'
    viewBox='0 0 120 121'
    className={className}
    data-test={TEST_ID}
  >
    <g clipPath='url(#clip0_4337_157610)'>
      <circle cx={55.402} cy={54.438} r={26.667} fill='#fff' />
      <path
        fill='#A174C1'
        d='M83.175 80.01l-4.166-4.166c-2.472 2.836-3.28 3.273-4.399 4.27l4.177 4.177v.146a9.202 9.202 0 011.824-2.547 10.4 10.4 0 012.564-1.88z'
      />
      <path
        fill='#512D6C'
        d='M95.062 89.81l-9.763-9.761a4.804 4.804 0 00-3.444-1.358 10.406 10.406 0 00-2.572 1.88 9.202 9.202 0 00-1.824 2.547 4.689 4.689 0 001.365 3.615l9.772 9.76a4.729 4.729 0 006.58 0c1.767-1.775 1.767-4.906-.114-6.683z'
      />
      <path
        fill='#512D6C'
        d='M55.425 23.108c-17.597 0-31.912 14.315-31.912 31.912 0 17.596 14.315 31.909 31.912 31.909 17.596 0 31.909-14.315 31.909-31.91 0-17.594-14.315-31.91-31.91-31.91zm0 57.982a26.072 26.072 0 11-.005-52.144 26.072 26.072 0 01.005 52.144z'
      />
      <path
        fill='#E0C9F1'
        d='M55.425 28.947c-14.382 0-26.073 11.69-26.073 26.073 0 14.381 11.691 26.07 26.073 26.07 14.381 0 26.07-11.696 26.07-26.07 0-14.375-11.696-26.073-26.07-26.073zm0 49.398a23.327 23.327 0 11-.004-46.655 23.327 23.327 0 01.004 46.655z'
      />
      <path
        fill='#A174C1'
        d='M67.971 45.4h-5.669V43.25a2.734 2.734 0 00-2.73-2.73h-8.157a2.734 2.734 0 00-2.726 2.73v2.155h-5.675a2.984 2.984 0 00-2.984 2.984v15.69a2.984 2.984 0 002.984 2.983h24.957a2.984 2.984 0 002.984-2.984V48.384a2.984 2.984 0 00-2.984-2.983zm-17.343-1.793a1.119 1.119 0 011.119-1.12h7.495a1.119 1.119 0 011.119 1.12V45.4H50.63l-.002-1.794z'
      />
      <path
        fill='#fff'
        d='M60.249 54.736h-3.264v-3.263h-2.983v3.263h-3.264v2.984h3.264v3.263h2.983V57.72h3.264v-2.984z'
      />
    </g>
    <defs>
      <clipPath id='clip0_4337_157610'>
        <path fill='#fff' d='M0 0h72.973v74.717H0z' transform='translate(23.513 23.108)' />
      </clipPath>
    </defs>
  </svg>
);

const SvgTrustaff = styled(SVG)`
  ${(props: GraphicProps) => GetGraphicDimensions(props)}
`;
SvgTrustaff.displayName = 'Trustaff';
export default SvgTrustaff;
